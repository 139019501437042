module LS.Client3DEditor {

    function onBeforeMouseDown() {
        var viewModel = this as ViewModel,
            controller = Controller.Current;
        if (!controller) return;
        var mouse = viewModel.mouse;
        if (controller.controlHelper.handleMouseDown(mouse, controller.controls, controller))
            viewModel.cancelMouseDown = true;
    }

    function onMouseDown() {
        var viewModel = this as ViewModel,
            controller = Controller.Current,
            instanceContext = viewModel.currentInstance;
        if (!controller) return;
        var raycaster = controller.worldRaycaster,
            objectHolders = controller.objectHolders;

        if (viewModel.canSelect) {
            var intersections = instanceContext.getInstanceIntersections(raycaster);
            if (intersections.length > 0 && (viewModel.EditorViewMode !== "Electric" || !objectHolders.some(o => !!o.hoverObject))) {
                var instance = intersections[0];
                var instanceInSelection = viewModel.selected.some((s) => { return instance.id === s.id; });
                if (viewModel.shiftDown) {
                    if (!instanceInSelection && viewModel.selected.length === 1) {
                        //select from last selected to current
                        var lastSelected = viewModel.selected[0];
                        var lastbb = lastSelected.geometry.boundingBox.clone().translate(lastSelected.position);
                        var currbb = instance.geometry.boundingBox.clone().translate(instance.position);
                        var searchbox = lastbb.union(currbb);
                        var searchCenter = searchbox.getCenter(new THREE.Vector3());
                        var searchDelta = searchbox.getSize(new THREE.Vector3()).multiplyScalar(0.5);
                        searchDelta.setX(Math.max(1, Math.abs(searchDelta.x) - 10)).setY(Math.max(1, Math.abs(searchDelta.y) - 10));
                        searchbox.set(searchCenter.clone().sub(searchDelta), searchCenter.clone().add(searchDelta));
                        var selected = instanceContext.getBoxIntersections(searchbox) as ClientObjectInstance[];
                        viewModel.selectedUi.removeAll();
                        viewModel.addToSelection(selected);
                        viewModel.canSelect = false;
                        return;
                    }
                    if (viewModel.ctrlDown && instanceInSelection) {
                        //special case
                        viewModel.canSelect = false;
                        return;
                    }
                    //select now (instead on onMouseUp)
                    viewModel.selectedUi.removeAll();
                    viewModel.addToSelection([instance]);
                    viewModel.canSelect = false;
                    return;
                } else if (!instanceInSelection) {
                    //select now (instead on onMouseUp)
                    viewModel.selectedUi.removeAll();
                    viewModel.addToSelection([instance]);
                    viewModel.canSelect = false;
                    return;
                }
            }
        }
    }

    function onMouseUp() {
        var viewModel = this as ViewModel,
            controller = Controller.Current,
            instanceContext = viewModel.currentInstance;
        if (!controller) return;

        var raycaster = controller.worldRaycaster;

        if (viewModel.isDragging) {
            viewModel.ApplyDiffSnapPosition();
            return;
        }

        if (viewModel.isScaling) {
            viewModel.ApplyDiffScalePosition();
            return;
        }

        if (viewModel.isSelecting) {
            var selectionHelper = controller.selectionHelper,
                uicamera = controller.uicamera;
            selectionHelper.visible = false;

            var x1 = selectionHelper.corner1.x / uicamera.right,
                y1 = selectionHelper.corner1.y / uicamera.top,
                x2 = selectionHelper.corner2.x / uicamera.right,
                y2 = selectionHelper.corner2.y / uicamera.top;

            var viewBox = new THREE.Box2(new THREE.Vector2(Math.min(x1, x2), Math.min(y1, y2)), new THREE.Vector2(Math.max(x1, x2), Math.max(y1, y2)));

            selectByBox(viewBox, raycaster);

            return;
        }

        if (viewModel.canSelect) {
            var intersections: LS.Client3DEditor.ClientObjectInstance[] = viewModel.EditorViewMode === "Electric" ? [] : instanceContext.getInstanceIntersections(controller.worldRaycaster);
            if (intersections.length > 0) {
                viewModel.selectedUi.removeAll();
                viewModel.addToSelection([intersections[0]]);
            } else {
                viewModel.addToSelection([]);

                var linesHelper = controller.linesHelper,
                    measureObjectHolder = controller.measureObjectHolder,
                    directedAreaHolder = controller.directedAreaHolder,
                    interferenceHelper = controller.interferenceHelper,
                    objectHolders = controller.objectHolders;

                if (viewModel.selected.length === 0) {
                    if (linesHelper.hoverLine) {
                        //helper lines
                        viewModel.addToSelection([linesHelper.hoverLine], false, false, viewModel.selectedUi);
                    } else if (directedAreaHolder.hoverAreaObj) {
                        //directedArea
                        viewModel.addToSelection([directedAreaHolder.hoverAreaObj], false, false, viewModel.selectedUi);
                    } else if (measureObjectHolder.hoverDimObj) {
                        //MeasureObject
                        viewModel.addToSelection([measureObjectHolder.hoverDimObj], false, false, viewModel.selectedUi);
                    } else if (viewModel.selectInterferencesEnabled && interferenceHelper.hoverObject) {
                        //StringObjectInstance
                        viewModel.addToSelection([interferenceHelper.hoverObject], false, false, viewModel.selectedUi);
                    } else {
                        var objHolder = objectHolders.find(o => !!o.hoverObject);

                        if (objHolder) {
                            //generic object holders
                            viewModel.addToSelection([objHolder.hoverObject], false, false, viewModel.selectedUi);

                            if (LS.Electric.InvertersModel && controller.stringObjectHolder === objHolder) {
                                var mso = LS.Electric.InvertersModel.getModulesStringObject(objHolder.hoverObject.IdString);
                                if (mso)
                                    LS.Electric.InvertersModel.doSelectString(mso.Id);
                            }
                        } else {
                            if (intersections.length > 0) {
                                viewModel.selectedUi.removeAll();
                                viewModel.addToSelection([intersections[0]]);
                            } else
                                viewModel.addToSelection([], false, false, viewModel.selectedUi);
                        }
                    }
                }
            }
        }
    }

    function beforeMouseMove() {
        var viewModel = this as ViewModel,
            instanceContext = viewModel.currentInstance,
            raycaster = Controller.Current.worldRaycaster;

        if (viewModel.disableMouseMove) return;

        instanceContext.getLocalPosition(raycaster.ray, instanceContext.roofPosition);

        //var roofPos = instanceContext.getLocalPosition(raycaster.ray);
        //if (roofPos)
        //    instanceContext.roofPosition.copy(roofPos);
    }

    function onMouseMove() {
        var viewModel = this as ViewModel,
            controller = Controller.Current,
            instanceContext = viewModel.currentInstance,
            mouse = viewModel.mouse;

        viewModel.highlightedInstance = null;

        controller.controlHelper.onMouseMove(mouse);

        if (viewModel.disableMouseMove) return;

        //var roofPos = instanceContext.getLocalPosition(raycaster.ray);
        //if (roofPos)
        //    instanceContext.roofPosition.copy(roofPos);

        if (viewModel.isDragging || viewModel.isScaling) {
            //dragging or scaling
            viewModel.updateSelected();
            return;
        } else if (viewModel.isSelecting) {
            //RECT SELECTING
            //controller.selectionHelper.setCorner2(viewModel.roofPosition);
            controller.selectionHelper.setCorner2XY(viewModel.viewPosition.x + controller.uicamera.left, controller.uicamera.top - viewModel.viewPosition.y);
            return;
        }

        //MOUSE HOVER
        if (!viewModel.selectedTool && !viewModel.mouseDown) {

            var worldRaycaster = controller.worldRaycaster,
                localRaycaster = controller.localRaycaster,
                linesHelper = controller.linesHelper,
                measureObjectHolder = controller.measureObjectHolder,
                directedAreaHolder = controller.directedAreaHolder,
                interferenceHelper = controller.interferenceHelper,
                objectHolders = controller.objectHolders;

            linesHelper.setHoverLine(null);
            directedAreaHolder.setHoverAreaObj(null);
            measureObjectHolder.setHoverDimObj(null);
            interferenceHelper.setHoverObject(null);
            objectHolders.forEach(o => o.setHoverObject(null));

            var intersections: LS.Client3DEditor.ClientObjectInstance[] = viewModel.EditorViewMode === "Electric" ? [] : instanceContext.getInstanceIntersections(worldRaycaster);

            if (intersections.length > 0) {
                setBoxhelper(intersections[0]);
            } else {
                setBoxhelper(null);

                var precision = controller.linePrecision,
                    found = false;

                //Helperlines
                var hl = linesHelper.GetByPoint(instanceContext.roofPosition, precision);
                if (hl) {
                    linesHelper.setHoverLine(hl);
                    found = true;
                }

                if (!found) {
                    var da = directedAreaHolder.getByRaycaster(localRaycaster, precision);
                    if (da) {
                        //directed area
                        directedAreaHolder.setHoverAreaObj(da);
                        found = true;
                    }
                }

                if (!found) {
                    var mo = measureObjectHolder.getByRaycaster(localRaycaster, precision);
                    if (mo) {
                        //MeasureObject
                        measureObjectHolder.setHoverDimObj(mo);
                        found = true;
                    }
                }

                if (!found) {
                    //interferences
                    var io = viewModel.selectInterferencesEnabled ? interferenceHelper.getByRaycaster(worldRaycaster) : null;
                    if (io) {
                        interferenceHelper.setHoverObject(io);
                        found = true;
                    }
                }

                if (!found) {
                    //objectholders
                    var obj: IObjectIntersection<IGenericObjectInstance>;
                    var o = objectHolders.find(o => !!(obj = o.getByRaycaster(localRaycaster, precision)));
                    if (obj) {
                        o.setHoverObject(obj);
                        found = true;
                    }
                }

                if (!found && viewModel.EditorViewMode === "Electric") {
                    intersections = instanceContext.getInstanceIntersections(worldRaycaster);

                    if (intersections.length > 0) {
                        setBoxhelper(intersections[0]);
                    }
                }

                if (!found && viewModel.instances.length > 1 && viewModel.EditorViewMode === "Anordnung") {
                    //highlight another roofarea
                    var instanceContext = viewModel.findInstanceContext(worldRaycaster.ray, null, 5);
                    if (instanceContext && instanceContext !== viewModel.currentInstance)
                        viewModel.highlightedInstance = instanceContext;
                }
            }
        }
    }

    function onKeyDown() {
        var viewModel = this as ViewModel,
            controller = Controller.Current;
        if (viewModel.isSelecting || viewModel.isDragging)
            return;

        if (viewModel.ctrlDown) {
            if (viewModel.keyPressed(90)) //strg + z
                controller.doUndo();
            else if (viewModel.keyPressed(89)) //strg + y
                controller.doRedo();
            else if (viewModel.keyPressed(68)) //strg + d
                controller.doDuplicate();
        } else {
            if (viewModel.keyPressed([37, 38, 39, 40])) {//Left, Up, Right, Down
                var l = viewModel.keyPressed(37) ? -1 : 0;
                var u = viewModel.keyPressed(38) ? 1 : 0;
                var r = viewModel.keyPressed(39) ? 1 : 0;
                var d = viewModel.keyPressed(40) ? -1 : 0;
                var steps = viewModel.keyBoardSteps.internalValue;
                controller.moveSelectedStatic(steps * l + steps * r, steps * u + steps * d, 0);
            }
            //else if (viewModel.keyPressed(68)) //d
            //    controller.switchActiveStates();
            else if (viewModel.keyPressed(46)) //del
                controller.deleteSelected();
            else if (viewModel.keyPressed(72) && viewModel.selectedToolUri !== "dimensionLines") //h
                controller.switchTransparenceAll();
            else if (viewModel.keyPressed(65)) //a
                controller.selectAll();
            else if (viewModel.keyPressed(67)) //c
                controller.switchCamera();
            else if (viewModel.keyPressed(82)) //r
                controller.startRotate();
            else if (viewModel.keyPressed(77)) //m
                viewModel.snappingDeactivatedClick();
            else if (viewModel.keyPressed(76) && window.EditorViewMode === "Anordnung") //l
                Controller.Current.reloadModulePlan(true, null, "ShowAnlagen");
            else if (viewModel.keyPressed(78)) //n
                controller.regenerateModulePlan(false);
            //else if (window.currLogin === "janh" && viewModel.keyPressed(74)) //j
            //    viewModel.EnableMultiEdit = !viewModel.EnableMultiEdit;
            else if (viewModel.keyPressed(80)) //p
                controller.switchVisibleStates();
        }
    }

    function onKeyUp() {
        //var viewModel = this as ViewModel;
    }

    function onDblclick() {
        Controller.Current.onDblclick();
    }

    function onStartDrag() {
        var viewModel = this as ViewModel,
            controller = Controller.Current,
            instanceContext = viewModel.currentInstance;
        if (!controller) return;
        var boxHelper = controller.boxHelper;

        if (boxHelper.visible)
            setBoxhelper(null);

        if (viewModel.isSelecting || viewModel.isDragging || viewModel.isScaling) return;

        var worldRaycaster = controller.getWorldRaycaster(null, viewModel.startMouse);

        var intersections = instanceContext.getInstanceIntersections(worldRaycaster, Client3DObectOptions.CanSelectInstances | Client3DObectOptions.CanMoveInstances | Client3DObectOptions.CanBeScaledX | Client3DObectOptions.CanBeScaledY);
        if (intersections.length > 0 && !viewModel.shiftDown) {
            var edgeInter = setScalehelper(intersections[0], controller.getLocalRaycaster(worldRaycaster));
            if (edgeInter) {
                var isHorizontal = Math.abs(edgeInter.direction.x) > Math.abs(edgeInter.direction.y);
                viewModel.isScaling = true;
                viewModel.scaleHelperObject = edgeInter;
                viewModel.filterSelection(o => isHorizontal ? o.clientObject.canBeScaledX : o.clientObject.canBeScaledY);
            } else {
                viewModel.isDragging = true;
                viewModel.filterSelection(o => o.clientObject.canMoveInstances);
            }
            controller.edgeHighlightHelper.visible = false;
        } else {
            var directedAreaHolder = controller.directedAreaHolder;
            if (directedAreaHolder.hoverAreaObj && !viewModel.shiftDown) {
                if (!directedAreaHolder.hoverAreaObj.isSelected)
                    viewModel.addToSelection(<any[]>[directedAreaHolder.hoverAreaObj], false, false, <any[]>viewModel.selectedUi);
                viewModel.isDragging = true;
            } else {
                var interferenceHelper = controller.interferenceHelper;

                if (viewModel.selectInterferencesEnabled && interferenceHelper.hoverObject && interferenceHelper.hoverObject.isSelected && !viewModel.shiftDown) {
                    if (!interferenceHelper.hoverObject.isSelected)
                        viewModel.addToSelection(<any[]>[interferenceHelper.hoverObject], false, false, <any[]>viewModel.selectedUi);
                    viewModel.isDragging = true;
                } else {

                    var objectHolder = !viewModel.shiftDown && controller.objectHolders.find(o => o.hoverObject && o.hoverObject.isSelected);
                    if (objectHolder) {
                        viewModel.isDragging = true;
                    } else {
                        var selectionHelper = controller.selectionHelper;
                        //selectionHelper.setCorner1(viewModel.startRoofPosition);
                        selectionHelper.setCorner1XY(viewModel.startViewPosition.x + controller.uicamera.left, controller.uicamera.top - viewModel.startViewPosition.y);
                        selectionHelper.visible = true;
                        viewModel.isSelecting = true;
                    }
                }
            }
        }
    }

    function selectByBox(viewBox: THREE.Box2, raycaster: THREE.Raycaster) {
        var controller = Controller.Current,
            viewModel = controller.viewModel,
            testVolume = controller.testVolume,
            instanceContext = viewModel.currentInstance;

        testVolume.calculate(raycaster, controller.camera, viewBox);

        var selected: ClientObjectInstance[];
        var selectedUi: ISelectableUi[] = [];

        if (viewModel.EditorViewMode === "Electric") {

            var objectHolders = controller.objectHolders,
                objHolder = objectHolders.find(o => {
                    var selUi = o.getByTestVolume(testVolume);
                    if (selUi && selUi.length) {
                        selectedUi = selUi;
                        return true;
                    }
                    return false;
                });

            viewModel.addToSelection(selectedUi, false, false, <any[]>viewModel.selectedUi);

            if (!selectedUi || !selectedUi.length) {
                viewModel.selectedUi.removeAll();

                selected = orderObjectsForElectric((instanceContext.getIntersectionsByViewVolume(testVolume) as ClientObjectInstance[]).filter(ci => ci.clientObject.canSelectInstances));

                viewModel.addToSelection(selected);

                if (selected && selected.length)
                    viewModel.selectedUi.removeAll();
            } else
                viewModel.selected.removeAll();

            return;
        }

        selected = (instanceContext.getIntersectionsByViewVolume(testVolume) as ClientObjectInstance[]).filter(ci => ci.clientObject.canSelectInstances);

        viewModel.addToSelection(selected);

        if (!selected || !selected.length) {

            if (controller.directedAreaHolder.children.length) {
                //viewModel.addToSelection(controller.directedAreaHolder.getByBox(box), false, false, <any[]>viewModel.selectedUi);
                selectedUi = controller.directedAreaHolder.getByTestVolume(testVolume);
                viewModel.addToSelection(selectedUi, false, false, <any[]>viewModel.selectedUi);
            }
            if (!selectedUi || !selectedUi.length) {
                var objectHolders = controller.objectHolders,
                    objHolder = objectHolders.find(o => {
                        var selUi = o.getByTestVolume(testVolume);
                        if (selUi && selUi.length) {
                            selectedUi = selUi;
                            return true;
                        }
                        return false;
                    });
                viewModel.addToSelection(selectedUi, false, false, <any[]>viewModel.selectedUi);
            }
            if (viewModel.selectInterferencesEnabled && (!selectedUi || !selectedUi.length)) {
                viewModel.addToSelection(controller.interferenceHelper.getByTestVolume(testVolume), false, false, <any[]>viewModel.selectedUi);
            }
        }
        else
            viewModel.selectedUi.removeAll();
    }

    function orderObjectsForElectric(selected: ClientObjectInstance[], orderHorizontal?: boolean) {
        if (selected && selected.length > 1) {
            var result: ClientObjectInstance[] = [],
                bucket: any = {},
                order = 1;

            if (orderHorizontal) {
                selected.forEach(o => {
                    var y = Math.round(o.position.y);
                    if (!bucket[y])
                        bucket[y] = [o];
                    else
                        bucket[y].push(o);
                });

                Object.keys(bucket).forEach(k => {
                    var l = bucket[k];
                    if (l.length > 1)
                        l.sort((a, b) => (a.position.x - b.position.x) * order);
                    result.splice.apply(result, [result.length, 0].concat(<any>l));
                    order *= -1;
                });
            } else {
                selected.forEach(o => {
                    var x = Math.round(o.position.x);
                    if (!bucket[x])
                        bucket[x] = [o];
                    else
                        bucket[x].push(o);
                });

                Object.keys(bucket).forEach(k => {
                    var l = bucket[k];
                    if (l.length > 1)
                        l.sort((a, b) => (a.position.y - b.position.y) * order);
                    result.splice.apply(result, [result.length, 0].concat(<any>l));
                    order *= -1;
                });
            }

            return result;
        }
        return selected;
    }

    export function mapInputsFromViewmodel(viewModel: LS.Client3DEditor.ViewModel) {
        viewModel.addListener('beforeMouseMove', beforeMouseMove);
        viewModel.addListener('mouseMove', onMouseMove);
        viewModel.addListener('mouseDown', onMouseDown);
        viewModel.addListener('beforeMouseDown', onBeforeMouseDown);
        viewModel.addListener('mouseUp', onMouseUp);
        viewModel.addListener('startDrag', onStartDrag);
        viewModel.addListener('keyDown', onKeyDown);
        viewModel.addListener('keyUp', onKeyUp);
        viewModel.addListener('dblclick', onDblclick);
    }
}