module LS.Client3DEditor {

    export class SelectionHelper extends THREE.Object3D {
        constructor() {
            super();
            this.visible = false;
            this.corner1 = new THREE.Vector3(-0.5, -0.5, 0);
            this.corner2 = new THREE.Vector3(0.5, 0.5, 0);

            var planeGeometry = new THREE.PlaneBufferGeometry(1, 1, 1, 1);
            var lineGeometry = new THREE.BufferGeometry();

            lineGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array([
                -0.5, -0.5, 0,
                0.5, -0.5, 0,
                0.5, 0.5, 0,
                -0.5, 0.5, 0,
                -0.5, -0.5, 0
            ]), 3));
            lineGeometry.boundingSphere = new THREE.Sphere(new THREE.Vector3(), 0.7071067811865476);

            var planeMesh = new THREE.Mesh(planeGeometry, new THREE.MeshBasicMaterial({
                transparent: true,
                opacity: 0.5,
                color: new THREE.Color(0x2194ce),
                fog: false,
                flatShading: true,
                depthTest: false,
                depthWrite: false
            }));
            planeMesh.renderOrder = LS.Client3DEditor.UIRenderOrder - 1;
            var lineMesh = new THREE.Line(lineGeometry, new THREE.LineBasicMaterial({
                transparent: true,
                opacity: 0.5,
                color: new THREE.Color(0x195799),
                fog: false,
                depthTest: false,
                depthWrite: false
            }));
            lineMesh.renderOrder = LS.Client3DEditor.UIRenderOrder;
            this.add(planeMesh);
            this.add(lineMesh);
        }

        setCorner1(v: THREE.Vector3) {
            this.corner1.copy(v);
            this.update();
        }

        setCorner2(v: THREE.Vector3) {
            this.corner2.copy(v);
            this.update();
        }

        setCorner1XY(x: number, y: number) {
            this.corner1.x = x;
            this.corner1.y = y;
            this.update();
        }

        setCorner2XY(x: number, y: number) {
            this.corner2.x = x;
            this.corner2.y = y;
            this.update();
        }

        update = (() => {
            var d = new THREE.Vector3();
            return function () {
                var c1 = this.corner1,
                    c2 = this.corner2;
                this.position.copy(c1).add(c2).divideScalar(2);
                d.copy(c2).sub(c1);

                var lenx = Math.max(0.0001, Math.abs(d.x));
                var leny = Math.max(0.0001, Math.abs(d.y));

                var children = this.children;

                for (var i = children.length; i--;) {
                    children[i].scale.set(lenx, leny, 1);
                }
            };
        })();

        corner1: THREE.Vector3;
        corner2: THREE.Vector3;
    }

    export class PointingHelper extends THREE.Mesh {
        constructor(plane?: THREE.Plane) {
            super(new THREE.SphereGeometry(1, 25, 15),
                new THREE.MeshBasicMaterial({
                    transparent: true,
                    opacity: 0.5,
                    color: new THREE.Color(0x030b6f) as any,
                    fog: false,
                    flatShading: true,
                    depthTest: false,
                    depthWrite: false
                }));

            var lineGeometry = new THREE.BufferGeometry();

            var lineSize = 0.2;

            lineGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array([
                -lineSize, -lineSize, 0,
                lineSize, -lineSize, 0,
                lineSize, lineSize, 0,
                -lineSize, lineSize, 0,
                -lineSize, -lineSize, 0
            ]), 3));

            var lineSeg = new THREE.Line(lineGeometry, new THREE.LineBasicMaterial({
                transparent: false,
                opacity: 1,
                color: new THREE.Color(0x030b6f) as any,
                fog: false,
                depthTest: false,
                depthWrite: false
            }));

            lineSeg.renderOrder = LS.Client3DEditor.UIRenderOrder;

            this.add(lineSeg);

            this.visible = false;
            this.renderOrder = LS.Client3DEditor.UIRenderOrder;

            if (plane)
                spt.ThreeJs.utils.applyPlaneRotationToObject(plane, this);
        }
    }

    export class HelperLine extends THREE.Line implements LS.Client3DEditor.ISelectableUi {

        private static vlineGeometry: THREE.BufferGeometry = null;
        private static hlineGeometry: THREE.BufferGeometry = null;

        constructor(isVertical?: boolean, position?: THREE.Vector3, target?: THREE.Vector3) {
            super();
            this.isVertical = !!isVertical && !target;
            this.isFree = !!target;

            if (HelperLine.vlineGeometry === null) {
                HelperLine.vlineGeometry = new THREE.BufferGeometry();

                HelperLine.vlineGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array([
                    0, -0.5, 0,
                    0, 0.5, 0
                ]), 3));
                HelperLine.vlineGeometry.boundingSphere = new THREE.Sphere(new THREE.Vector3(), 0.5);
            }

            if (HelperLine.hlineGeometry === null) {
                HelperLine.hlineGeometry = new THREE.BufferGeometry();

                HelperLine.hlineGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array([
                    -0.5, 0, 0,
                    0.5, 0, 0
                ]), 3));
                HelperLine.hlineGeometry.boundingSphere = new THREE.Sphere(new THREE.Vector3(), 0.5);
            }

            this.material = new THREE.LineBasicMaterial({
                color: 0x57EEFF,
                linewidth: 1,
                fog: false,
                transparent: true,
                opacity: 0.7,
                depthTest: false,
                depthWrite: false
            });

            this.geometry = isVertical ? HelperLine.vlineGeometry : HelperLine.hlineGeometry;

            this.renderOrder = LS.Client3DEditor.UIRenderOrder;
            this.frustumCulled = false;
            if (position)
                this.setPosition(position);

            this.target = target;

            this.isSelected = false;
            this.isHovered = false;
            this._isDisposed = false;
            Object.defineProperty(this, 'pos', { enumerable: true, configurable: true, get: () => { return this.isVertical ? this.position.x : this.position.y; } });

            this.rebuild();
        }

        GetPositionProperty(k: string) {
            if (this.isFree)
                return this.position[k];
            var isVertical = this.isVertical;
            if (isVertical && k === 'x')
                return this.position.x;
            if (!isVertical && k === 'y')
                return this.position.y;
            return null;
        }

        GetSizeProperty(k: string): number {
            return 0;
        }

        moveBy(v: THREE.Vector3) {
            var tp = new THREE.Vector3().copy(v).setZ(0);

            if (!this.isFree) {
                if (this.isVertical)
                    tp.setY(0);
                else
                    tp.setX(0);
            }

            this.position.add(tp);
            this._target.add(tp);

            this.rebuild();
        }

        distanceToPoint(p: THREE.Vector3 | THREE.Vector2) {
            return Math.abs(this.normal.dot(new THREE.Vector3().set(p.x, p.y, 0).sub(this.position)));
        }

        setPosition(position: THREE.Vector3, target?: THREE.Vector3) {
            if (target) {
                this.position.copy(position);
                this.target = target;
                this.rebuild();
            } else
                this.moveBy(new THREE.Vector3().copy(position).sub(this.position));
        }

        setTarget(t: THREE.Vector3) {
            this.target = t;
            this.rebuild();
        }

        rebuild() {
            if (!this.isFree)
                return;

            var n = this.normal;
            this.rotation.z = -Math.atan2(n.x, n.y);
            this.updateMatrix();
        }

        OnChanged(viewModel?: ViewModel) {
            var material = this.material as THREE.LineBasicMaterial;
            material.opacity = this.isSelected || this.isHovered ? 1 : 0.5;
            material.color.set(this.isSelected ? 0xD52CE8 : (this.isHovered ? 0xffff00 : 0x57EEFF));
            material.needsUpdate = true;
        }

        doDispose() {
            spt.ThreeJs.utils.disposeObject3D(this, false, false, true);
            this._isDisposed = true;
        }

        removeInstance() {
            if (this.parent && this.parent.parent instanceof LinesHelper) {
                var linesHelper = this.parent.parent as LinesHelper;
                if (linesHelper.hoverLine && linesHelper.hoverLine.id === this.id)
                    linesHelper.setHoverLine(null);

                linesHelper.removeLine(this);
            }
        }

        normal = new THREE.Vector3(1, 0, 0);
        direction = new THREE.Vector3(0, 1, 0);
        private _target = new THREE.Vector3();
        isVertical: boolean;
        isFree: boolean;
        isSelected: boolean;
        isHovered: boolean;
        _isDisposed: boolean;
        pos: number;

        get target() {
            return this._target;
        }

        set target(value) {
            if (!this.isFree || !value) {
                if (this.isVertical)
                    this._target.copy(this.position).add(new THREE.Vector3(0, 1, 0));
                else
                    this._target.copy(this.position).add(new THREE.Vector3(1, 0, 0));
            }
            else
                this._target.copy(value);

            if (this._target.equals(this.position)) {
                this.normal.set(1, 0, 0);
                this.direction.set(0, 1, 0);
                this._target.copy(this.position).add(new THREE.Vector3(0, 1, 0));
            } else {
                this.direction.copy(this._target).sub(this.position).setZ(0).normalize();
                this.normal.set(this.direction.y, -this.direction.x, 0).normalize();
                //this.normal.copy(this._target).sub(this.position).cross(new THREE.Vector3(0, 0, 1)).normalize();
            }
            if (this.direction.y < 0)
                this.direction.negate();
        }

        applyObjectPosition() {

        }

        duplicate() {

        }

        rotateByPivot(pivot: THREE.Vector3, angleRad: number) { }

        getPivot(): THREE.Vector3 { return null; }
    }

    export class LinesHelper extends THREE.Object3D {

        static LinesHelperSnapping = (() => {
            var snapping = {
                NONE: 0,
                UP: 1,
                DOWN: 1 << 1,
                LEFT: 1 << 2,
                RIGHT: 1 << 3,
                CENTER: 1 << 4,
                ALL: -1,

                BORDER: 0,
                VERTICAL: 0,
                HORIZONTAL: 0
            };

            snapping.BORDER = snapping.UP | snapping.DOWN | snapping.LEFT | snapping.RIGHT;
            snapping.VERTICAL = snapping.CENTER | snapping.LEFT | snapping.RIGHT;
            snapping.HORIZONTAL = snapping.CENTER | snapping.UP | snapping.DOWN;

            return snapping;
        })();

        constructor() {
            super();

            var vLines = new THREE.Object3D(),
                hLines = new THREE.Object3D(),
                freeLines = new THREE.Object3D();
            this.vLines = vLines;
            this.hLines = hLines;
            this.freeLines = freeLines;

            //this.visible = false;
            this.renderOrder = LS.Client3DEditor.UIRenderOrder;
            this.frustumCulled = false;
            this.vLines.renderOrder = LS.Client3DEditor.UIRenderOrder;
            this.vLines.frustumCulled = false;
            this.hLines.renderOrder = LS.Client3DEditor.UIRenderOrder;
            this.hLines.frustumCulled = false;
            this.freeLines.renderOrder = LS.Client3DEditor.UIRenderOrder;
            this.freeLines.frustumCulled = false;
            this.position.set(0, 0, 50);
            this.add(vLines);
            this.add(hLines);
            this.add(freeLines);
            this.hoverLine = null;
        }

        setMinMax(minx: number, miny: number, maxx: number, maxy: number) {
            var w = maxx - minx,
                h = maxy - miny;
            this.w = w;
            this.h = h;
            this.hLines.scale.set(w, 1, 1);
            this.vLines.scale.set(1, h, 1);
            this.hLines.position.set(w * 0.5 + minx, 0, 0);
            this.vLines.position.set(0, h * 0.5 + miny, 0);
            var s = Math.max(w, h);
            this.freeLines.children.forEach(l => l.scale.set(s, 1, 1));
        }

        addLine(line: HelperLine) {
            if (line.isFree) {
                var s = Math.max(this.w, this.h);
                line.scale.set(s, 1, 1);
                this.freeLines.add(line);
            }
            else if (line.isVertical)
                this.vLines.add(line);
            else
                this.hLines.add(line);

            Controller.Current.viewModel.canSnap = true;
        }

        setHoverLine(hoverLine?: HelperLine) {
            if (this.hoverLine) {
                this.hoverLine.isHovered = false;
                this.hoverLine.OnChanged();
                this.hoverLine = null;
            }
            if (hoverLine) {
                hoverLine.isHovered = true;
                hoverLine.OnChanged();
                this.hoverLine = hoverLine;
            }
        }

        removeLine(line: HelperLine) {
            if (line.isFree)
                this.freeLines.remove(line);
            else if (line.isVertical)
                this.vLines.remove(line);
            else
                this.hLines.remove(line);

            line.doDispose();

            Controller.Current.viewModel.canSnap = this.hasLines();
        }

        hasLines() {
            return this.vLines.children.length > 0 || this.hLines.children.length > 0 || this.freeLines.children.length > 0;
        }

        getByClosestDirection(dir: THREE.Vector3) {
            var dist = 1,
                foundLine = null,
                vLines = this.vLines,
                hLines = this.hLines,
                freeLines = this.freeLines;

            for (let i = freeLines.children.length; i--;) {
                let line = (freeLines.children[i] as HelperLine);
                if (!line.visible)
                    continue;
                let d = 1 - Math.abs(line.direction.dot(dir));
                if (d < dist) {
                    dist = d;
                    foundLine = line;
                }
            }

            for (let i = vLines.children.length; i--;) {
                let line = (vLines.children[i] as HelperLine);
                if (!line.visible)
                    continue;
                let d = 1 - Math.abs(line.direction.dot(dir));
                if (d < dist) {
                    dist = d;
                    foundLine = line;
                }
            }

            for (let i = hLines.children.length; i--;) {
                let line = (hLines.children[i] as HelperLine);
                if (!line.visible)
                    continue;
                let d = 1 - Math.abs(line.direction.dot(dir));
                if (d < dist) {
                    dist = d;
                    foundLine = line;
                }
            }

            return foundLine;
        }

        hasLine(line: HelperLine) {

            var lines = line.isFree ? this.freeLines : line.isVertical ? this.vLines : this.hLines;

            if (line.isFree) {
                for (let i = lines.children.length; i--;) {
                    var ln = lines.children[i] as HelperLine;
                    if (ln.pos !== null && ln.normal.equals(line.normal) && ln.position.clone().sub(line.position).dot(line.normal) === 0)
                        return true;
                }
                return false;
            }

            var pos = line.pos;

            for (let i = lines.children.length; i--;) {
                if ((lines.children[i] as HelperLine).pos === pos)
                    return true;
            }
            return false;
        }

        GetByPoint(point: THREE.Vector3 | THREE.Vector2, tolerance: number) {
            if (!this.visible || !this.hasLines())
                return null;

            var dist = Number.MAX_VALUE,
                foundLine = null,
                vLines = this.vLines,
                hLines = this.hLines,
                freeLines = this.freeLines;

            var line: HelperLine;

            for (let i = freeLines.children.length; i--;) {
                line = (freeLines.children[i] as HelperLine);
                if (!line.visible)
                    continue;
                let d = line.distanceToPoint(point);
                if (d < dist && d <= tolerance) {
                    dist = d;
                    foundLine = line;
                }
            }

            for (let i = vLines.children.length; i--;) {
                line = (vLines.children[i] as HelperLine);
                if (!line.visible)
                    continue;
                let d = Math.abs(line.pos - point.x);
                if (d < dist && d <= tolerance) {
                    dist = d;
                    foundLine = line;
                }
            }

            for (let i = hLines.children.length; i--;) {
                line = (hLines.children[i] as HelperLine);
                if (!line.visible)
                    continue;
                let d = Math.abs(line.pos - point.y);
                if (d < dist && d <= tolerance) {
                    dist = d;
                    foundLine = line;
                }
            }

            return foundLine;
        }

        GetSnapWithBoxOffset = (() => {
            var box1;
            return function (box: THREE.Box3, offset: THREE.Vector3, tolerance: number) {
                if (!box1)
                    box1 = new THREE.Box3();
                box1.copy(box).translate(offset);
                return this.GetSnapWithBox(box1, tolerance);
            };
        })();

        GetSnapWithBox = (() => {
            var s = new THREE.Vector3();
            var sHalf = new THREE.Vector3();
            var boxCenter = new THREE.Vector3();
            return function (box: THREE.Box3, tolerance: number, snappingType?: number) {
                if (!box || !this.visible || !this.hasLines())
                    return null;

                var dist = Number.MAX_VALUE,
                    x = box.min.x,
                    y = box.min.y,
                    vLines = this.vLines,
                    hLines = this.hLines,
                    freeLines = this.freeLines,
                    snapType = typeof snappingType == "number" ? snappingType : LinesHelper.LinesHelperSnapping.BORDER,
                    snapping = LinesHelper.LinesHelperSnapping;

                box.getSize(s);
                box.getCenter(boxCenter);
                sHalf.copy(s).multiplyScalar(0.5);

                var d: number, line: HelperLine, i: number;

                for (i = vLines.children.length; i--;) {
                    line = vLines.children[i] as HelperLine;
                    if (!line.visible)
                        continue;

                    if (snapType & snapping.RIGHT) {
                        d = Math.abs(line.pos - box.max.x);
                        if (d < dist && d <= tolerance) {
                            dist = d;
                            x = line.pos - s.x;
                        }
                    }

                    if (snapType & snapping.CENTER) {
                        d = Math.abs(line.pos - boxCenter.x);
                        if (d < dist && d <= tolerance) {
                            dist = d;
                            x = line.pos - sHalf.x;
                        }
                    }

                    if (snapType & snapping.LEFT) {
                        d = Math.abs(line.pos - box.min.x);
                        if (d < dist && d <= tolerance) {
                            dist = d;
                            x = line.pos;
                        }
                    }

                }

                dist = Number.MAX_VALUE;

                for (i = hLines.children.length; i--;) {
                    line = hLines.children[i] as HelperLine;
                    if (!line.visible)
                        continue;

                    if (snapType & snapping.UP) {
                        d = Math.abs(line.pos - box.max.y);
                        if (d < dist && d <= tolerance) {
                            dist = d;
                            y = line.pos - s.y;
                        }
                    }

                    if (snapType & snapping.CENTER) {
                        d = Math.abs(line.pos - boxCenter.y);
                        if (d < dist && d <= tolerance) {
                            dist = d;
                            y = line.pos - sHalf.y;
                        }
                    }

                    if (snapType & snapping.DOWN) {
                        d = Math.abs(line.pos - box.min.y);
                        if (d < dist && d <= tolerance) {
                            dist = d;
                            y = line.pos;
                        }
                    }
                }

                dist = Number.MAX_VALUE;

                for (i = freeLines.children.length; i--;) {
                    line = freeLines.children[i] as HelperLine;
                    if (!line.visible)
                        continue;

                    let bOff = spt.ThreeJs.utils.GetBoxOffsetByDirection(line.normal, sHalf.x, sHalf.y);
                    var sps: THREE.Vector3[] = [];

                    if (snapType & snapping.CENTER)
                        sps.push(boxCenter);

                    if (bOff.x != 0 || bOff.y != 0) {
                        if ((bOff.y >= 0 || (snapType & snapping.DOWN)) &&
                            (bOff.x <= 0 || (snapType & snapping.RIGHT)) &&
                            (bOff.y <= 0 || (snapType & snapping.UP)) &&
                            (bOff.x >= 0 || (snapType & snapping.LEFT)))
                            sps.push(boxCenter.clone().add(bOff));

                        bOff.negate();

                        if ((bOff.y >= 0 || (snapType & snapping.DOWN)) &&
                            (bOff.x <= 0 || (snapType & snapping.RIGHT)) &&
                            (bOff.y <= 0 || (snapType & snapping.UP)) &&
                            (bOff.x >= 0 || (snapType & snapping.LEFT)))
                            sps.push(boxCenter.clone().add(bOff));
                    }



                    let dir = line.target.clone().sub(line.position);
                    if (dir.lengthSq() <= 0)
                        dir.set(1, 0, 0);
                    else
                        dir.normalize();

                    sps.forEach(sp => {
                        var tp = sp.clone().sub(line.position);
                        var t = dir.dot(tp);
                        tp.copy(dir).multiplyScalar(t).add(line.position).sub(sp);

                        d = tp.length();
                        if (d < dist && d <= tolerance) {
                            dist = d;
                            y = box.min.x + tp.x;
                            x = box.min.y + tp.y;
                        }
                    });
                }

                return s.set(x - box.min.x, y - box.min.y, 0);
            };
        })();

        GetSnapWithPoint(point: THREE.Vector2 | THREE.Vector3, tolerance: number, snappingType?: number) {
            if (!point || !this.visible || !this.hasLines())
                return null;

            var dist = Number.MAX_VALUE,
                x = point.x,
                y = point.y,
                vLines = this.vLines,
                hLines = this.hLines,
                freeLines = this.freeLines,
                snapType = typeof snappingType == "number" ? snappingType : LinesHelper.LinesHelperSnapping.CENTER,
                snapping = LinesHelper.LinesHelperSnapping,
                snapped = false;

            var d, line, i;

            if (snapType & snapping.VERTICAL) {
                for (i = vLines.children.length; i--;) {
                    line = vLines.children[i];
                    if (!line.visible)
                        continue;
                    d = Math.abs(line.pos - point.x);
                    if (d < dist && d <= tolerance) {
                        dist = d;
                        x = line.pos;
                        snapped = true;
                    }
                }

                dist = Number.MAX_VALUE;
            }

            if (snapType & snapping.HORIZONTAL) {
                for (i = hLines.children.length; i--;) {
                    line = hLines.children[i];
                    if (!line.visible)
                        continue;
                    d = Math.abs(line.pos - point.y);
                    if (d < dist && d <= tolerance) {
                        dist = d;
                        y = line.pos;
                        snapped = true;
                    }
                }

                dist = Number.MAX_VALUE;
            }

            if (snapType & snapping.CENTER) {
                for (i = freeLines.children.length; i--;) {
                    line = freeLines.children[i] as HelperLine;
                    if (!line.visible)
                        continue;

                    let sp = new THREE.Vector3().set(point.x, point.y, 0);

                    let n = line.target.clone().sub(line.position);
                    if (n.lengthSq() <= 0)
                        n.set(1, 0, 0);
                    else
                        n.normalize();

                    var tp = sp.clone().sub(line.position);
                    var t = n.dot(tp);
                    tp.copy(n).multiplyScalar(t).add(line.position).sub(sp);

                    d = tp.length();
                    if (d < dist && d <= tolerance) {
                        dist = d;
                        x = point.x + tp.x;
                        y = point.y + tp.y;
                        snapped = true;
                    }
                }
            }
            if (snapped)
                return new THREE.Vector3(x - point.x, y - point.y, 0);
            return null;
        }

        w: number = 10;
        h: number = 10;
        vLines: THREE.Object3D;
        hLines: THREE.Object3D;
        freeLines: THREE.Object3D;
        hoverLine: HelperLine;
    }

    export class SegmentsHelper extends THREE.Object3D {

        static linesMaterial: THREE.LineMaterial;
        private _lineGeo: THREE.LineGeometry;
        private _lineMesh: THREE.Line2;
        private _pointNumber = -1;

        constructor() {
            super();

            this.visible = false;
        }

        setLinePoints(points?: THREE.Vector3[]) {

            if (!points || !points.length) {
                this.visible = false;
                return;
            }

            if (!SegmentsHelper.linesMaterial) {
                SegmentsHelper.linesMaterial = spt.ThreeJs.utils.LineMaterialHelper.GetLineMaterial({
                    color: 0x00aacc,
                    linewidth: 2.5, // in pixels
                    vertexColors: false, //THREE.NoColors,
                    transparent: true,
                    //resolution:  // to be set by renderer, eventually
                    dashed: false,
                    depthTest: false,
                    depthWrite: false
                });
            }

            var len = points.length,
                pointNumber = this._pointNumber,
                edgeGeo = this._lineGeo;

            if (pointNumber < len) {
                pointNumber = this._pointNumber = len;

                this.reset();

                edgeGeo = this._lineGeo = new THREE.LineGeometry();

                if (!edgeGeo.boundingSphere)
                    edgeGeo.boundingSphere = new THREE.Sphere();
                edgeGeo.setPositions(new Float32Array((pointNumber) * 3));

                var line = this._lineMesh = new THREE.Line2(edgeGeo, SegmentsHelper.linesMaterial);

                line.computeLineDistances();

                line.renderOrder = LS.Client3DEditor.UIRenderOrder;

                this.add(line);

            }

            var instanceBuffer = (edgeGeo.getAttribute('instanceStart') as THREE.InterleavedBufferAttribute).data as THREE.InstancedInterleavedBuffer,
                arr = instanceBuffer.array as Float32Array;

            var idx = 0,
                p1: THREE.Vector3,
                p2: THREE.Vector3;

            for (var i = 1; i < len; i++ , idx += 6) {
                p1 = points[i - 1];
                p2 = points[i];

                arr[idx] = p1.x;
                arr[idx + 1] = p1.y;
                arr[idx + 2] = p1.z;

                arr[idx + 3] = p2.x;
                arr[idx + 4] = p2.y;
                arr[idx + 5] = p2.z;
            }

            edgeGeo.maxInstancedCount = len - 1;

            instanceBuffer.needsUpdate = true;

            edgeGeo.boundingSphere.setFromPoints(points);

        }

        private reset() {
            spt.ThreeJs.utils.emptyObject3D(this, true, true);
        }
    }

    export class SegmentHighlightHelper extends THREE.Mesh {
        constructor() {
            super(new THREE.BoxBufferGeometry(1, 1, 1, 1, 1, 1),
                new THREE.MeshBasicMaterial({
                    transparent: true,
                    opacity: 0.5,
                    color: new THREE.Color(0x030b6f) as any,
                    fog: false,
                    flatShading: true,
                    depthTest: false,
                    depthWrite: false
                }));

            this.visible = false;
            this.renderOrder = 11;
        }

        reset() {
            this.start.set(0, 0, 0);
            this.end.set(0, 0, 0);
            this.visible = false;
            this.onChanged();
        }

        setStartEnd(start: THREE.Vector3, end: THREE.Vector3, visible?: boolean, thickness?: number, zThickness?: number) {
            if (start)
                this.start.copy(start);
            if (end)
                this.end.copy(end);
            if (thickness && thickness > 0) {
                this.thickness = thickness;
                this.zThickness = thickness;
            }
            if (zThickness && zThickness > 0)
                this.zThickness = zThickness;
            if (visible !== undefined)
                this.visible = !!visible;
            this.onChanged();
        }

        setVisible(visible: boolean) {
            if (this.visible !== visible) {
                this.visible = visible;
                this.onChanged();
            }
        }

        onChanged() {
            if (!this.visible)
                return;

            var s = this.start;
            var e = this.end;
            var d = e.clone().sub(s);
            var lenSq = d.lengthSq();
            var th = this.thickness;
            var thz = this.zThickness;

            if (th <= 0 || thz <= 0 || lenSq <= 0) {
                this.visible = false;
                return;
            }

            var len = Math.sqrt(lenSq);

            var zAngle = Math.atan2(d.y, d.x);
            var yAngle = -Math.atan2(d.z, Math.sqrt(d.x * d.x + d.y * d.y));

            this.matrix.copy(new THREE.Matrix4().makeTranslation(s.x, s.y, s.z)
                .multiply(new THREE.Matrix4().makeRotationFromEuler(new THREE.Euler(0, yAngle, zAngle, "ZYX"))
                    .multiply(new THREE.Matrix4().makeScale(len, th, thz)
                        .multiply(new THREE.Matrix4().makeTranslation(0.5, 0, 0)))));

            this.matrix.decompose(this.position, this.quaternion, this.scale);

            this.matrixWorldNeedsUpdate = true;
        }

        start: THREE.Vector3 = new THREE.Vector3(0, 0, 0);
        end: THREE.Vector3 = new THREE.Vector3(1, 0, 0);
        thickness: number = 1000;
        zThickness: number = 1000;
    }

    export interface DirectedAreaVerticalLine {
        t: number;
        p1: THREE.Vector3;
        p2: THREE.Vector3;
        area: spt.ThreeJs.utils.DirectedAreaDefinition;
    }

    export class DirectedAreaHolder extends THREE.Object3D {
        constructor() {
            super();
        }

        hoverAreaObj: spt.ThreeJs.utils.DirectedAreaDefinition = null;
        hoverEdge: THREE.Vector3[] = null;
        hoverEdgeIdent: number = 0;
        deferredDuplicateAreas: spt.ThreeJs.utils.DirectedAreaDefinition[] = null;
        deferredRemoveAreas: spt.ThreeJs.utils.DirectedAreaDefinition[] = null;
        checkDuplicate: number;
        checkRemoved: number;
        _alpha: number = 1;

        clear() {
            this.hoverAreaObj = null;
            spt.ThreeJs.utils.emptyObject3D(this, true, true);
        }

        getById(id: string) {
            if (!id)
                return null;
            var mObjs = this.children;
            for (var i = mObjs.length; i--;) {
                var mObj = mObjs[i] as spt.ThreeJs.utils.DirectedAreaDefinition;
                if (mObj.IdString === id)
                    return mObj;
            }
            return null;
        }

        changeAreaDirection(areas: spt.ThreeJs.utils.DirectedAreaDefinition[]) {
            if (!areas || !areas.length)
                return;

            areas.forEach(da => {
                da.flip = true;
                da.applyFlip();
            });

            this.saveDirectedAreaChanges(areas, false);
        }

        saveDirectedAreaChanges(areas: spt.ThreeJs.utils.DirectedAreaDefinition[], settingsChanged: boolean, callBack?: (results: SolarProTool.IDirectedAreaDefinition[]) => void) {
            if (!areas || !areas.length)
                return;

            var controller = Controller.Current,
                buildingParams: SolarProTool.BuildingParams[] = [];

            areas.forEach(da => {
                buildingParams.push({
                    Id: da.IdString,
                    GenericSettings: settingsChanged ? JSON.stringify(da.userData) : null,
                    BuildingClassName: controller.BuildingClassName,
                    BuildingSettingsClassName: da.userData['TypeName'],
                    AreaDefinition: da.GetSimple()
                });
            });

            if (buildingParams.length) {
                SolarProTool.Ajax("WebServices/Anordnung3DServiceBuildings.asmx").Call('EditBuildings').Data({ buildingParams: buildingParams }).CallBack((results: SolarProTool.IDirectedAreaDefinition[]) => {
                    if (results && results.length)
                        controller.directedAreaHolder.addDirectedAreaDefinitions(results);
                    if (callBack)
                        callBack(results);
                });
            }
        }

        addDirectedAreaDefinitions(areaObjs: SolarProTool.IDirectedAreaDefinition[]) {
            for (var i = areaObjs.length; i--;) {
                this.addDirectedAreaDefinition(areaObjs[i]);
            }
        }

        addDirectedAreaDefinition(areaObj: SolarProTool.IDirectedAreaDefinition) {
            var ao = areaObj.IdString ? this.getById(areaObj.IdString) : null;
            if (ao != null) {
                ao.copyFrom(areaObj);
            } else {
                ao = new spt.ThreeJs.utils.DirectedAreaDefinition(areaObj);
                this.addArea(ao);
            }
            return ao;
        }

        removeById(id: string) {
            var ao = this.getById(id);
            if (ao)
                this.remove(ao);
        }

        onGenericParameterChanges(event: IEventManagerEvent) {
            if (event && event.data && event.data.length) {
                var directedAreas = (event.data as any[]).map(o => o._myDirectedArea as spt.ThreeJs.utils.DirectedAreaDefinition).filter(o => !!o && o instanceof spt.ThreeJs.utils.DirectedAreaDefinition);

                var entry = typeof event.source === "string" ? null : event.source as ClientGenericParamsEntry;

                if (entry) {
                    directedAreas.forEach(da => {
                        da.setParameter(entry.name, entry.oldValue, entry.unwrapValue());
                    });
                }

                //GenericSettings has changed
                var buildingParams = directedAreas.map((da: spt.ThreeJs.utils.DirectedAreaDefinition) => {
                    return {
                        Id: da.IdString,
                        GenericSettings: entry && entry.isOverload ? null : JSON.stringify(da.userData),
                        BuildingClassName: Controller.Current.BuildingClassName,
                        BuildingSettingsClassName: da.userData['TypeName'],
                        AreaDefinition: da.GetSimple()
                    } as SolarProTool.BuildingParams;
                });

                SolarProTool.Ajax("WebServices/Anordnung3DServiceBuildings.asmx").Call('EditBuildings').Data({ buildingParams: buildingParams }).CallBack((result) => {
                    this.addDirectedAreaDefinitions(result);
                    Controller.Current.viewModel.genericParameters.updateEntries();
                });
            }
        }

        setAlpha(alpha: number) {
            if (this._alpha != alpha) {

                var mObjs = this.children;
                for (var i = mObjs.length; i--;) {
                    var mObj = mObjs[i] as spt.ThreeJs.utils.DirectedAreaDefinition;
                    mObj.alpha = alpha;
                }

                this._alpha = alpha;
            }
        }

        onDirectedAreasChanged(objs: ISelectableUi[], settingChanged: boolean) {

            if (!objs || !objs.length)
                return;

            var directedAreas = objs.filter(o => o instanceof spt.ThreeJs.utils.DirectedAreaDefinition);

            if (!directedAreas.length)
                return;

            //GenericSettings has changed
            var buildingParams = directedAreas.map((da: spt.ThreeJs.utils.DirectedAreaDefinition) => {
                return {
                    Id: da.IdString,
                    GenericSettings: settingChanged ? JSON.stringify(da.userData) : null,
                    BuildingClassName: Controller.Current.BuildingClassName,
                    BuildingSettingsClassName: da.userData['TypeName'],
                    AreaDefinition: da.GetSimple()
                } as SolarProTool.BuildingParams;
            });

            SolarProTool.Ajax("WebServices/Anordnung3DServiceBuildings.asmx").Call('EditBuildings').Data({ buildingParams: buildingParams }).CallBack((result) => {
                this.addDirectedAreaDefinitions(result);
            });
        }

        addArea(areaObj: spt.ThreeJs.utils.DirectedAreaDefinition) {
            areaObj.alpha = this._alpha;
            this.add(areaObj);
            Controller.Current.notifyRefreshView();
        }

        removeArea(area: spt.ThreeJs.utils.DirectedAreaDefinition) {
            if (!this.deferredRemoveAreas)
                this.deferredRemoveAreas = [];
            this.deferredRemoveAreas.push(area);
            if (!this.checkRemoved)
                this.checkRemoved = setTimeout(this.checkDeferredRemoved.bind(this), 0) as any;
        }

        checkDeferredRemoved() {
            if (!this.deferredRemoveAreas || !this.deferredRemoveAreas.length)
                return;

            var controller = Controller.Current,
                dareas = this.deferredRemoveAreas.slice(),
                ids = dareas.map(ra => ra.IdString).filter(id => !!id);

            if (ids.length) {
                SolarProTool.Ajax("WebServices/Anordnung3DServiceBuildings.asmx").Call('RemoveBuildings').Data({ ids: ids }).CallBack((results) => {
                    if (!results)
                        DManager.ShowSmallInfo("Error on deleting buildings!");
                    else {
                        dareas.forEach(da => {
                            this.remove(da);
                            da.dispose();
                        });
                        results.forEach(result => {
                            if (result)
                                controller.directedAreaHolder.addDirectedAreaDefinition(result);
                        });
                    }
                });
            }

            this.deferredRemoveAreas = null;
            delete this.checkRemoved;

            Controller.Current.notifyRefreshView();
        }

        addDuplicate(area: spt.ThreeJs.utils.DirectedAreaDefinition, count?: number, distance?: number, direction?: THREE.Vector3) {
            if (!this.deferredDuplicateAreas)
                this.deferredDuplicateAreas = [];
            this.deferredDuplicateAreas.push(area);
            if (!this.checkDuplicate)
                this.checkDuplicate = setTimeout(this.checkDeferredDuplicates.bind(this, count, distance, direction), 0) as any;
        }

        checkDeferredDuplicates(count?: number, distance?: number, direction?: THREE.Vector3) {
            if (!this.deferredDuplicateAreas)
                return;

            var controller = Controller.Current,
                buildingParams: SolarProTool.BuildingParams[] = [],
                relativeOffset = true,
                offset = new THREE.Vector3();

            count = Math.max(count || 1, 1);

            if (typeof distance !== "number") {
                distance = 2000;
                relativeOffset = false;
            }

            distance = Math.max(distance || 0, 0);

            direction = direction || new THREE.Vector3(1, -1, 0);


            this.deferredDuplicateAreas.forEach(da => {

                if (da.length > 10 && da.height > 10) {

                    var relativeLen = Math.sqrt(Math.pow(direction.x * da.length, 2) + Math.pow(direction.y * da.height, 2));

                    for (var i = 1; i <= count; i++) {

                        if (relativeOffset) {
                            offset.copy(direction).applyEuler(da.rotation).multiplyScalar((distance + relativeLen) * i);
                        } else
                            offset.copy(direction).multiplyScalar(distance * i);

                        var id = spt.Utils.GenerateGuid();
                        buildingParams.push({
                            Id: id,
                            GenericSettings: JSON.stringify(da.userData),
                            BuildingClassName: controller.BuildingClassName,
                            BuildingSettingsClassName: da.userData['TypeName'],
                            AreaDefinition: da.GetSimple(id, offset)
                        });
                    }

                }
            });

            if (buildingParams.length) {
                SolarProTool.Ajax("WebServices/Anordnung3DServiceBuildings.asmx").Call('EditBuildings').Data({ buildingParams: buildingParams }).CallBack((results) => {
                    if (results && results.length) {
                        var newAreas: SolarProTool.IDirectedAreaDefinition[] = [];
                        results.forEach(result => {
                            if (result)
                                newAreas.push(controller.directedAreaHolder.addDirectedAreaDefinition(result));
                        });
                        //replace selection
                        controller.viewModel.selectedUi.removeAll();
                        controller.viewModel.selectedUi.splice.apply(controller.viewModel.selectedUi, ([0, 0] as any[]).concat(newAreas));
                    }
                });
            }

            this.deferredDuplicateAreas = null;
            delete this.checkDuplicate;

            Controller.Current.notifyRefreshView();
        }

        getHorizontalLengthByPoint(p: THREE.Vector3, area: spt.ThreeJs.utils.DirectedAreaDefinition): DirectedAreaVerticalLine {
            var tp = p.clone().sub(area.position),
                axis = new THREE.Vector3(1, 0, 0).applyEuler(area.rotation),
                x = Math.max(0, Math.min(area.length, axis.dot(tp))),
                s = tp.copy(axis).multiplyScalar(x).add(area.position),
                e = area.start2.clone().sub(area.start).add(s);
            return { t: x, p1: s, p2: e, area: area };
        }

        getClosestByPosition(p: THREE.Vector3, tolerance: number) {
            var das = this.getByBox(new THREE.Box3().expandByPoint(p).expandByScalar(tolerance));
            var minDist = Number.MAX_VALUE;
            var e: THREE.Vector3[] = null;
            var pt = new THREE.Vector3();
            var eIdent = 0;
            var area: spt.ThreeJs.utils.DirectedAreaDefinition = null;
            for (var i = 0, j = das.length; i < j; i++) {
                var da = das[i];
                var edges = da.edges;
                for (var k = 0, l = edges.length; k < l; k++) {
                    var edge = edges[k];
                    var tp = spt.ThreeJs.utils.GetClosestPointToLine(edge[0], edge[1], p).p;
                    var dist = tp.distanceTo(p);
                    if (dist < minDist) {
                        minDist = dist;
                        e = edge;
                        eIdent = k;
                        area = da;
                        pt.copy(tp);
                    }
                }
            }

            return area != null ? { area: area, edge: e, edgeIdent: eIdent, distance: minDist, point: pt } : null;
        }

        getByBox(searchBox: THREE.Box3): spt.ThreeJs.utils.DirectedAreaDefinition[] {
            if (this.children.length)
                return this.children.filter(mObj => (mObj as spt.ThreeJs.utils.DirectedAreaDefinition).intersecsBox(searchBox)) as spt.ThreeJs.utils.DirectedAreaDefinition[];
            return [];
        }

        getByTestVolume(testVolume: spt.ThreeJs.utils.TestVolume): spt.ThreeJs.utils.DirectedAreaDefinition[] {
            if (this.children.length)
                return this.children.filter(mObj => (mObj as spt.ThreeJs.utils.DirectedAreaDefinition).intersecsTestVolume(testVolume)) as spt.ThreeJs.utils.DirectedAreaDefinition[];
            return [];
        }

        getByRaycaster(raycaster: THREE.Raycaster, tolerance?: number) {
            var ray = raycaster.ray,
                vt = new THREE.Vector3();

            var thresholdSq = Math.pow(tolerance || raycaster.params.Line.threshold, 2);

            var mObjs = this.children;

            var foundObj: spt.ThreeJs.utils.DirectedAreaDefinition = null;
            var minDist = Number.MAX_VALUE;

            for (var i = mObjs.length; i--;) {
                var mObj = mObjs[i] as spt.ThreeJs.utils.DirectedAreaDefinition;
                var dist: number;

                if (mObj.hasHeight) {
                    if (ray.intersectsBox(mObj.BoundingBox) && (ray.intersectTriangle(mObj.start, mObj.end, mObj.end2, false, vt) || ray.intersectTriangle(mObj.start, mObj.end2, mObj.start2, false, vt)))
                        return mObj;
                } else {
                    dist = ray.distanceSqToSegment(mObj.start, mObj.end);
                    if (dist < thresholdSq && dist < minDist) {
                        minDist = dist;
                        foundObj = mObj;
                    }
                }
            }

            return foundObj;
        }

        deselectAll() {
            var mObjs = this.children;
            for (var i = mObjs.length; i--;) {
                var mObj = mObjs[i] as spt.ThreeJs.utils.DirectedAreaDefinition;
                if (mObj.isSelected) {
                    mObj.isSelected = false;
                    mObj.OnChanged();
                }
            }
        }

        setHoverAreaObj(areaObj?: spt.ThreeJs.utils.DirectedAreaDefinition, edge?: THREE.Vector3[], edgeIdent?: number) {
            var controller = Controller.Current,
                segmentHighlightHelper = controller.segmentHighlightHelper;

            if (this.hoverAreaObj && (!areaObj || areaObj.IdString !== this.hoverAreaObj.IdString)) {
                this.hoverAreaObj.isHovered = false;
                this.hoverAreaObj.OnChanged();
                this.hoverAreaObj = null;
                this.hoverEdge = null;
                segmentHighlightHelper.setVisible(false);
            }
            if (areaObj) {
                areaObj.isHovered = true;
                areaObj.OnChanged();
                this.hoverAreaObj = areaObj;
                this.hoverEdge = edge || null;
                this.hoverEdgeIdent = edgeIdent || 0;
            }
        }
    }

    export class MeasureObjectHolder extends THREE.Object3D {
        constructor() {
            super();
        }

        hoverDimObj: spt.ThreeJs.utils.DimensionAligned = null;

        clear() {
            this.hoverDimObj = null;
            spt.ThreeJs.utils.emptyObject3D(this, true, true);
        }

        addMeasureObjects(mObjs: SolarProTool.IMeasureObject[]) {
            for (var i = mObjs.length; i--;) {
                this.addMeasureObject(mObjs[i]);
            }
        }

        addMeasureObject(mObj: SolarProTool.IMeasureObject) {
            var dimObj = this.getById(mObj.IdString);
            var colHex = mObj.ColorHex,
                textSize = mObj.TextSize;
            if (window.EditorViewMode === "Anordnung" && colHex) {
                colHex = 0xdbe2fc;
                textSize = 150;
            } else {
                textSize = 100;
            }
            if (dimObj) {
                dimObj.setParameter(mObj.StartX, mObj.StartY, mObj.StartZ, mObj.EndX, mObj.EndY, mObj.EndZ, mObj.PositionX, mObj.PositionY, mObj.PositionZ, colHex, textSize, mObj.FontName);
            } else {
                dimObj = new spt.ThreeJs.utils.DimensionAligned(new THREE.Vector3(mObj.StartX, mObj.StartY, mObj.StartZ),
                    new THREE.Vector3(mObj.EndX, mObj.EndY, mObj.EndZ),
                    new THREE.Vector3(mObj.PositionX, mObj.PositionY, mObj.PositionZ),
                    new THREE.Color(colHex), textSize, mObj.FontName);
                this.add(dimObj);
            }
            if (dimObj.userData.prevDimensionPosition)
                delete dimObj.userData.prevDimensionPosition;

            dimObj.userData.Id = mObj.IdString;
        }

        saveDimensionLine(dimObj: spt.ThreeJs.utils.DimensionAligned) {
            if (!dimObj)
                return;

            var diff = dimObj.end.clone().sub(dimObj.start);
            if (diff.lengthSq() <= 1)
                return;

            LoaderManager.addLoadingJob();
            AManager.Ajax('Anordnung3DService.asmx', 'SaveDimensionAligned', JSON.stringify({
                idString: dimObj.userData.Id || null,
                startx: dimObj.start.x, starty: dimObj.start.y, startz: dimObj.start.z,
                endx: dimObj.end.x, endy: dimObj.end.y, endz: dimObj.end.z,
                positionx: dimObj.dimensionPosition.x, positiony: dimObj.dimensionPosition.y, positionz: dimObj.dimensionPosition.z
            }), (mObj: SolarProTool.IMeasureObject) => {
                this.addMeasureObject(mObj);
                LoaderManager.finishLoadingJob();
            }, () => {
                DManager.showErrorMessage("Error on sending changes to the server.");
                LoaderManager.finishLoadingJob();
            });
        }

        removeDimensionLine(dimObj: spt.ThreeJs.utils.DimensionAligned) {
            var id = dimObj.userData.Id;
            this.remove(dimObj);
            if (id) {
                LoaderManager.addLoadingJob();
                AManager.Ajax('Anordnung3DService.asmx', 'DeleteDimensionAligned', JSON.stringify({ idString: id }), (result: boolean) => {
                    LoaderManager.finishLoadingJob();
                }, () => {
                    DManager.showErrorMessage("Error on sending changes to the server.");
                    LoaderManager.finishLoadingJob();
                });
            }

            dimObj.dispose();
        }

        getById(id: string) {
            var mObjs = this.children;
            for (var i = mObjs.length; i--;) {
                var mObj = mObjs[i] as spt.ThreeJs.utils.DimensionAligned;
                if (mObj.userData.Id === id) {
                    return mObj;
                }
            }
            return null;
        }

        getByRaycaster(raycaster: THREE.Raycaster, tolerance?: number) {
            var ray = raycaster.ray;

            var threshold = tolerance || raycaster.params.Line.threshold;
            var thresholdSq = threshold * threshold;

            var mObjs = this.children;

            var foundObj: spt.ThreeJs.utils.DimensionAligned = null;
            var minDist = Number.MAX_VALUE;

            for (var i = mObjs.length; i--;) {
                var mObj = mObjs[i] as spt.ThreeJs.utils.DimensionAligned;

                var dist = ray.distanceSqToSegment(mObj.mlineStart, mObj.mlineEnd);
                if (dist < thresholdSq && dist < minDist) {
                    minDist = dist;
                    foundObj = mObj;
                }
            }

            return foundObj;
        }

        setHoverDimObj(dimObj?: spt.ThreeJs.utils.DimensionAligned) {
            if (this.hoverDimObj) {
                this.hoverDimObj.isHovered = false;
                this.hoverDimObj.OnChanged();
                this.hoverDimObj = null;
            }
            if (dimObj) {
                dimObj.isHovered = true;
                dimObj.OnChanged();
                this.hoverDimObj = dimObj;
            }
        }

    }

    export class DashedLineMaterial extends THREE.RawShaderMaterial {

        get lineDirection() {
            return this.uniforms["lineDir"].value as THREE.Vector3;
        }

        set lineDirection(value: THREE.Vector3) {
            this.uniforms["lineDir"].value = value;
        }

        constructor(elements: number[], lineDirection: THREE.Vector3, color?: THREE.Color) {
            super();

            if (!elements || !elements.length)
                elements = [100, -100];

            var hasColor = !!color;

            var ev = new THREE.Vector4(0, 0, 0, 0);

            for (var i = 0, j = Math.min(elements.length, 4); i < j; i++) {
                ev.setComponent(i, elements[i]);
            }

            this.vertexColors = true; //THREE.VertexColors;

            var shaderPrecision = Detector.webglParameters.maxFloatPrecision;

            this.uniforms = {
                ev: <THREE.IUniform>{ type: "v4", value: ev },
                lineDir: <THREE.IUniform>{ type: "v3", value: lineDirection }
            };
            if (hasColor)
                this.uniforms.color = <THREE.IUniform>{ type: "c", value: new THREE.Color(color) };

            this.vertexShader = [
                "precision " + shaderPrecision + " float;",

                "uniform mat4 modelViewMatrix;",
                "uniform mat4 projectionMatrix;",
                "uniform vec3 lineDir;",
                hasColor ? "uniform vec3 color;" : "",

                "attribute vec3 position;",
                hasColor ? "" : "attribute vec3 color;",

                "varying float dt;",
                "varying vec3 vColor;",

                "void main() {",
                "dt = dot(position.xyz, lineDir);",
                "vColor = color;",

                "gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",
                "}"
            ].join("\n");

            //this.vertexShader = 'void main() {\n\tgl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );\n}';

            this.fragmentShader = [
                "precision " + shaderPrecision + " float;",

                "uniform vec4 ev;",

                "varying float dt;",
                "varying vec3 vColor;",

                //elements is the pattern with negatives equal holes. Eg [x: 100, y: -100, ...] returns 0 if v is in a hole else 1
                "float checkPattern( in float v, in vec4 elements ) {",

                "vec4 aEv = vec4(abs(elements.x), abs(elements.y), abs(elements.z), abs(elements.w));",

                "float sum = dot(aEv, vec4(1.0,1.0,1.0,1.0));",

                "float d = mod(v, sum) * step(0.0, v) + (sum + mod(v, sum)) * (1.0 - step(0.0, v));",

                "vec4 pa = vec4(step(d, aEv.x),",
                "step(d - aEv.x, aEv.y),",
                "step(d - aEv.x - aEv.y, aEv.z),",
                "step(d - aEv.x - aEv.y - aEv.z, aEv.w));",

                "vec4 p = vec4((1.0 - step(aEv.x, sum - d - aEv.w - aEv.z - aEv.y)) * pa.x + (step(d, 0.0) * step(0.0, d)),",
                "(1.0 - step(aEv.y, sum - d - aEv.w - aEv.z)) * pa.y,",
                "(1.0 - step(aEv.z, sum - d - aEv.w)) * pa.z,",
                "(1.0 - step(aEv.w, sum - d)) * pa.w);",

                "return dot(p, vec4(step(0.0, elements.x), step(0.0, elements.y), step(0.0, elements.z), step(0.0, elements.w)));",
                "}",

                "void main() {",
                "if(checkPattern(dt, ev) <= 0.0) {",
                "discard;",
                "}",
                "gl_FragColor = vec4( vColor, 1.0 );",
                "}"
            ].join("\n");

            this.flatShading = true;
            this.side = THREE.DoubleSide;
            this.transparent = false;
        }
    }

    export enum ModuleCellType {
        Monocrystalline = 0,
        Polycrystalline,
        Thinfilm,
        Flat,
        Custom
    }

    export class DefaultAppearanceShader extends THREE.RawShaderMaterial {

        get map() {
            return this.uniforms["map"].value as THREE.Texture;
        }

        set map(value: THREE.Texture) {
            this.uniforms["map"].value = value;
        }

        get color() {
            return this.uniforms["diffuse"].value;
        }

        set color(value: THREE.Color) {
            this.uniforms["diffuse"].value = value as THREE.Color;
        }

        constructor(map?: THREE.Texture, color?: THREE.Color, side?: THREE.Side, transparent?: boolean, notInstanced?: boolean, noDepth?: boolean, isDistanceMap?: boolean, alpha?: number, noInstanceColors?: boolean, hasScale?: boolean) {
            super();

            var a: number;

            if (alpha && alpha > 0) {
                a = Math.min(1, +alpha);
                if (a < 1)
                    transparent = true;
            } else
                a = 1;

            var uniforms: { [uniform: string]: THREE.IUniform } = {
                diffuse: { value: new THREE.Color(color instanceof THREE.Color ? color : 0xFFFFFF) },
                map: { value: map instanceof THREE.Texture ? map : null },
                alpha: { value: a }
            };

            var useTexture = map !== null && map instanceof THREE.Texture;

            var floatPrecision = Detector.webglParameters.maxFloatPrecision;

            var lightDir = Controller.Current ? Controller.Current.mainLight.position.clone() : new THREE.Vector3(1.2, -1, 2).normalize();

            var light = `(clamp(dot(normal, vec3(${lightDir.x}, ${lightDir.y}, ${lightDir.z})), 0.0, 1.0) * 0.8 + 0.4)`;

            var vertexShader = [
                "precision " + floatPrecision + " float;",

                "uniform mat4 modelViewMatrix;",
                "uniform mat4 projectionMatrix;",
                "uniform vec3 diffuse;",
                "uniform float alpha;",

                notInstanced || noInstanceColors ? "" : "attribute vec4 colori;",
                notInstanced ? "" : "attribute vec3 offseti;",
                !notInstanced && hasScale ? "attribute vec3 scalei;" : "",
                "attribute vec3 position;",
                "attribute vec3 normal;",
                useTexture ? "attribute vec2 uv;" : "",

                useTexture ? "varying vec2 vUv;" : "",

                "varying vec4 vColor;",
                "varying float vAlpha;",

                "void main() {",

                "vec3 vPosition = position;",
                "vAlpha = alpha;",

                useTexture ? "vUv = uv;" : "",

                notInstanced || noInstanceColors ? "vColor = vec4(" + light + " * diffuse, 1.0);" : "vColor = vec4(" + light + " * diffuse + colori.rgb, colori.a);",

                "gl_Position = projectionMatrix * modelViewMatrix * vec4( " + (notInstanced ? "" : (hasScale ? "offseti + scalei * " : "offseti + ")) + "vPosition, 1.0 );",

                "}"
            ].join("\n"),
                fragmentShader = [
                    "precision " + floatPrecision + " float;",

                    useTexture ? "uniform sampler2D map;" : "",
                    "uniform vec3 diffuse;",
                    useTexture ? "varying vec2 vUv;" : "",

                    isDistanceMap ? "const float smoothing = 1.0/64.0;" : "",

                    "varying vec4 vColor;",
                    "varying float vAlpha;",

                    "void main() {",

                    useTexture ? "vec4 tex = texture2D(map, vUv);" : "",
                    isDistanceMap ? "vec4 col = vec4(vColor.rgb, smoothstep(0.5 - smoothing, 0.5 + smoothing, tex.r) * vColor.a * vAlpha);" : (useTexture ? "vec4 col = vec4((tex.rgb * vColor.rgb) / (tex.a * vColor.a * vAlpha), (tex.a * vColor.a * vAlpha));" : "vec4 col = vec4(vColor.rgb, vColor.a * vAlpha);"),

                    "if(col.a <= 0.0) {",
                    "discard;",
                    "}",

                    "gl_FragColor = col;",

                    "}"
                ].join("\n");

            this.uniforms = uniforms;
            this.vertexShader = vertexShader;
            this.fragmentShader = fragmentShader;
            this.side = side || THREE.FrontSide;
            this.transparent = !!transparent;
            if (noDepth) {
                this.depthTest = false;
                this.depthWrite = false;
            }
        }
    }

    export class BaseInstancedShader extends THREE.RawShaderMaterial {
        get color() {
            return this.uniforms["diffuse"].value as THREE.Color;
        }

        set color(value: THREE.Color) {
            var curColor = this.uniforms["diffuse"].value as THREE.Color;
            if (value && (!curColor.equals(value))) {
                curColor.copy(value);
                this.needsUpdate = true;
            }
        }

        get map() {
            return this.uniforms["map"] && this.uniforms["map"].value as THREE.Texture;
        }

        set map(value: THREE.Texture) {
            this.uniforms["map"].value = value;
            this.needsUpdate = true;
        }

        get alpha(): number {
            return this.uniforms["alpha"].value as number;
        }

        set alpha(value: number) {
            var curVal = this.uniforms["alpha"].value as number;
            if (curVal != value) {
                this.uniforms["alpha"].value = value || 0;
                this.needsUpdate = true;
            }
        }

        constructor(color?: THREE.Color, map?: THREE.Texture, alpha?: number, instanceColors?: boolean, instanceRotation?: boolean, instanceScale?: boolean, mapIsAdditive?: boolean) {
            super();

            var a: number;

            if (alpha && alpha > 0) {
                a = Math.min(1, +alpha);
                if (a < 1)
                    this.transparent = true;
            } else
                a = 1;

            var uniforms: { [uniform: string]: THREE.IUniform } = {
                diffuse: { value: color instanceof THREE.Color ? color : new THREE.Color(0xFFFFFF) },
                alpha: { value: a }
            };

            var useTexture = map && map instanceof THREE.Texture;
            if (useTexture)
                uniforms.map = { value: map };

            var floatPrecision = Detector.webglParameters.maxFloatPrecision;

            var p = "position";
            if (instanceScale)
                p += " * scalei";
            if (instanceRotation)
                p = `applyQuaternion(${p}, roti)`;

            var vertexShader = [
                "precision " + floatPrecision + " float;",

                "uniform mat4 modelViewMatrix;",
                "uniform mat4 projectionMatrix;",
                "uniform vec3 diffuse;",
                "uniform float alpha;",

                instanceColors ? "attribute vec4 colori;" : "",
                instanceRotation ? "attribute vec4 roti;" : "",
                instanceScale ? "attribute vec3 scalei;" : "",
                "attribute vec3 offseti;",
                "attribute vec3 position;",
                useTexture ? "attribute vec2 uv;" : "",
                useTexture ? "varying vec2 vUv;" : "",

                "varying vec4 vColor;",
                "varying float vAlpha;",

                instanceRotation ? "vec3 applyQuaternion(vec3 p, vec4 q) {" : "",
                instanceRotation ? "vec4 i = vec4(q.w * p.x + q.y * p.z - q.z * p.y,q.w * p.y + q.z * p.x - q.x * p.z,q.w * p.z + q.x * p.y - q.y * p.x,- q.x * p.x - q.y * p.y - q.z * p.z);" : "",
                instanceRotation ? "return vec3(i.x * q.w + i.w * - q.x + i.y * - q.z - i.z * - q.y, i.y * q.w + i.w * - q.y + i.z * - q.x - i.x * - q.z, i.z * q.w + i.w * - q.z + i.x * - q.y - i.y * - q.x);" : "",
                instanceRotation ? "}" : "",

                "void main() {",

                "vec3 vPosition = position;",
                "vAlpha = alpha;",

                useTexture ? "vUv = uv;" : "",

                instanceColors ? "vColor = vec4(diffuse + colori.rgb, colori.a);" : "vColor = vec4(diffuse, 1.0);",

                "gl_Position = projectionMatrix * modelViewMatrix * vec4(offseti + " + p + ", 1.0 );",

                "}"
            ].join("\n"),
                fragmentShader = [
                    "precision " + floatPrecision + " float;",

                    useTexture ? "uniform sampler2D map;" : "",
                    useTexture ? "varying vec2 vUv;" : "",

                    "varying vec4 vColor;",
                    "varying float vAlpha;",

                    "void main() {",

                    useTexture ? "vec4 tex = texture2D(map, vUv);" : "",
                    useTexture ? (mapIsAdditive ? "vec4 col = vec4((tex.rgb * tex.a) + vColor.rgb, vColor.a * vAlpha);" : "vec4 col = vec4((tex.rgb * vColor.rgb) / (tex.a * vColor.a * vAlpha), (tex.a * vColor.a * vAlpha));") : "vec4 col = vec4(vColor.rgb, vColor.a * vAlpha);",

                    "if(col.a <= 0.0) {",
                    "discard;",
                    "}",

                    "gl_FragColor = col;",

                    "}"
                ].join("\n");

            this.uniforms = uniforms;
            this.vertexShader = vertexShader;
            this.fragmentShader = fragmentShader;

        }
    }

    export class ModuleAppearanceShader extends THREE.RawShaderMaterial {

        isModuleAppearanceShader = true;

        private GetCellTexture() {
            //"../../handler/StaticImagesHandler.ashx?ID=ModuleCellShape&cellWidth=128&cellHeight=128&cellEdgeRound=16&cellEColumn=40&cellPadding=2";
            var cellTypeName: string = ModuleCellType[this._cellType];
            var url = <string>UI3DImages["ModuleCellShape_" + cellTypeName];

            if (this._cellType === ModuleCellType.Custom) {
                url += `&cellWidth=${this.cellWidth}&cellHeight=${this.cellHeight}&cellEdgeRound=${this.cellEdgeRound}&cellEColumn=${this.cellEColumn}&cellPadding=${this.cellPadding}`;
            }

            var loader = new THREE.TextureLoader();
            var tex = loader.load(url, () => {
                LoaderManager.notifyResourceLoaded();
            });
            tex.anisotropy = Detector.webglParameters.maxAnisotropy || 1;
            tex.wrapS = tex.wrapT = THREE.RepeatWrapping;
            return tex;
        }

        get opacity(): number {
            if (this.uniforms && this.uniforms["opacity"])
                return this.uniforms["opacity"].value;
            return 1;
        }

        set opacity(v: number) {
            if (this.uniforms && this.uniforms["opacity"])
                this.uniforms["opacity"].value = v;
        }

        get moduleCellColor(): THREE.Color {
            return this.uniforms["diffuse"].value;
        }

        set moduleCellColor(c: THREE.Color) {
            this.uniforms["diffuse"].value.copy(c);
        }

        get moduleFrameColor(): THREE.Color {
            return this.uniforms["frame"].value;
        }

        set moduleFrameColor(c: THREE.Color) {
            this.uniforms["frame"].value.copy(c);
        }

        get moduleBacksheetColor(): THREE.Color {
            return this.uniforms["backsheet"].value;
        }

        set moduleBacksheetColor(c: THREE.Color) {
            this.uniforms["backsheet"].value.copy(c);
        }

        get cols(): number {
            return this.uniforms["uvScale"].value.x;
        }

        set cols(v: number) {
            this.uniforms["uvScale"].value.x = v;
        }

        get rows(): number {
            return this.uniforms["uvScale"].value.y;
        }

        set rows(v: number) {
            this.uniforms["uvScale"].value.y = v;
        }

        get frameWidth(): number {
            return this.uniforms["frameWidth"].value;
        }

        set frameWidth(v: number) {
            this.uniforms["frameWidth"].value = v;
        }

        private _cellType: ModuleCellType;
        private _cellWidth: number;
        private _cellHeight: number;
        private _cellEdgeRound: number;
        private _cellEColumn: number;
        private _cellPadding: number;

        get cellType(): ModuleCellType {
            return this._cellType;
        }

        set cellType(cellType: ModuleCellType) {
            this._cellType = cellType;

            this.uniforms["map"].value = this.GetCellTexture();
        }

        get cellWidth() {
            return this._cellWidth;
        }

        set cellWidth(value: number) {
            this._cellWidth = value;

            this.uniforms["map"].value = this.GetCellTexture();
        }

        get cellHeight() {
            return this._cellHeight;
        }

        set cellHeight(value: number) {
            this._cellHeight = value;

            this.uniforms["map"].value = this.GetCellTexture();
        }

        get cellEdgeRound() {
            return this._cellEdgeRound;
        }

        set cellEdgeRound(value: number) {
            this._cellEdgeRound = value;

            this.uniforms["map"].value = this.GetCellTexture();
        }

        get cellEColumn() {
            return this._cellEColumn;
        }

        set cellEColumn(value: number) {
            this._cellEColumn = value;

            this.uniforms["map"].value = this.GetCellTexture();
        }

        get cellPadding() {
            return this._cellPadding;
        }

        set cellPadding(value: number) {
            this._cellPadding = value;

            this.uniforms["map"].value = this.GetCellTexture();
        }

        constructor(cellType?: ModuleCellType, color?: number, cols?: number, rows?: number, dimensionX?: number, dimensionY?: number, framingWidth?: number, notInstanced?: boolean, blending?: THREE.Blending, opacity?: number, reflection?: THREE.CubeTexture, colorFrame?: number, colorBacksheet?: number, cellWidth?: number, cellHeight?: number, cellEdgeRound?: number, cellEColumn?: number, cellPadding?: number, reflect90Degree?: boolean) {
            super();

            if (!framingWidth) {
                framingWidth = 20;
            }

            var floatPrecision = Detector.webglParameters.maxFloatPrecision;
            //var maxAnisotropy = Detector.webglParameters.maxAnisotropy || 1;

            this._cellType = cellType || ModuleCellType.Monocrystalline;
            this._cellWidth = cellWidth || 128;
            this._cellHeight = cellHeight || 128;

            if (cellEdgeRound === undefined)
                cellEdgeRound = 16;

            if (cellEColumn === undefined)
                cellEColumn = 40;

            if (cellPadding === undefined)
                cellPadding = 2;

            this._cellEdgeRound = cellEdgeRound;
            this._cellEColumn = cellEColumn;
            this._cellPadding = cellPadding;

            var uniforms: any = {
                diffuse: { type: "c", value: new THREE.Color(color !== undefined ? color : 0x0b1b3c) },
                backsheet: { type: "c", value: new THREE.Color(colorBacksheet !== undefined ? colorBacksheet : 0xe5e5e5) },
                frame: { type: "c", value: new THREE.Color(colorFrame !== undefined ? colorFrame : 0xb5b5b5) },
                frameWidth: { type: "1f", value: framingWidth },
                opacity: { type: "1f", value: opacity !== undefined ? opacity : 1 },
                uvScale: { type: "v2", value: new THREE.Vector2(cols || 6, rows || 10) },
                dim: { type: "v2", value: new THREE.Vector2((dimensionX || 900), (dimensionY || 1900)) },
                map: { type: "t", value: this.GetCellTexture() }
            };

            var useEnvMap = !!reflection && reflection instanceof THREE.CubeTexture;

            if (useEnvMap) {
                uniforms.envMap = { type: "t", value: reflection }
            }

            var vertexShader = [
                "precision " + floatPrecision + " float;",

                useEnvMap ? '#define USE_ENVMAP' : '',

                useEnvMap ? "uniform mat4 modelMatrix;" : "",
                useEnvMap ? "uniform mat4 viewMatrix;" : "",
                useEnvMap ? "" : "uniform mat4 modelViewMatrix;",
                "uniform mat4 projectionMatrix;",
                "uniform vec3 diffuse;",
                "uniform float frameWidth;",
                "uniform float opacity;",
                "uniform vec2 dim;",
                "uniform vec2 uvScale;",

                notInstanced ? "" : "attribute vec4 colori;",
                notInstanced ? "" : "attribute vec3 offseti;",
                "attribute vec3 position;",
                "attribute vec2 uv;",

                "#ifdef USE_ENVMAP",
                "attribute vec3 normal;",
                "uniform mat3 normalMatrix;",
                "uniform vec3 cameraPosition;",
                "varying vec3 vReflect;",
                "varying float fReflectVal;",
                "#endif",

                "varying vec2 vUv;",

                "varying float vOpacity;",

                "varying vec2 framing;",

                "varying vec3 cell;",

                notInstanced ? "" : "varying vec4 vColor;",

                "vec3 inverseTransformDirection( in vec3 dir, in mat4 matrix ) {",
                "return normalize( ( vec4( dir, 0.0 ) * matrix ).xyz );",
                "}",

                "void main() {",

                "#ifdef USE_ENVMAP",
                "vec3 transformedNormal = normalMatrix * normal;",
                "vec4 worldPosition = modelMatrix * vec4( position, 1.0 );",
                "vec3 cameraToVertex = normalize( worldPosition.xyz - cameraPosition );",
                "vec3 worldNormal = inverseTransformDirection( transformedNormal, viewMatrix );",
                "vReflect = reflect( cameraToVertex, worldNormal );",
                "fReflectVal = (dot( cameraToVertex, vReflect ) + 1.4) * 0.5;",
                "#endif",

                "vec3 vPosition = position;",

                "vUv = uv;",

                "vOpacity = opacity;",

                "framing = vec2((frameWidth / dim.x) * uvScale.x, (frameWidth / dim.y) * uvScale.y );",

                notInstanced ? "" : "vColor = colori;",

                "cell = diffuse;",

                "gl_Position = projectionMatrix * " + (useEnvMap ? "viewMatrix * modelMatrix" : "modelViewMatrix") + " * vec4( " + (notInstanced ? "" : "offseti + ") + "position, 1.0 );",

                "}"
            ].join("\n"),
                fragmentShader = [
                    "precision " + floatPrecision + " float;",

                    useEnvMap ? '#define USE_ENVMAP' : '',

                    "uniform sampler2D map;",

                    "uniform vec2 uvScale;",

                    "#ifdef USE_ENVMAP",
                    "uniform samplerCube envMap;",
                    "varying vec3 vReflect;",
                    "varying float fReflectVal;",
                    "#endif",

                    "varying vec2 vUv;",

                    "varying float vOpacity;",

                    "varying vec2 framing;",

                    notInstanced ? "" : "varying vec4 vColor;",

                    "varying vec3 cell;",

                    "uniform vec3 backsheet;",

                    "uniform vec3 frame;",

                    "void main() {",

                    "vec2 borderL = framing / uvScale;",
                    "vec2 border = step(borderL, vUv) * step(vUv, vec2(1.0, 1.0) - borderL);",
                    "vec2 borderH = framing / uvScale;",
                    "vec2 border2 = step(borderH, vUv) * step(vUv, vec2(1.0, 1.0) - borderH);",
                    "vec4 tex = texture2D(map, uvScale * ((vUv - borderL) / (vec2(1.0, 1.0) - (borderL * 2.0))));",

                    "float framef = border2.x * border2.y;",
                    "float backsheetf = tex.r * border.x * border.y;",

                    notInstanced ? "vec3 outgoingLight = mix(frame, mix(backsheet, tex.rgb * cell, backsheetf), framef);" : "vec3 outgoingLight = mix(frame + vColor.rgb * 0.5, mix(backsheet + vColor.rgb * 0.5, tex.rgb * (cell + vColor.rgb), backsheetf), framef);",

                    "#ifdef USE_ENVMAP",
                    "vec3 reflectVec = vReflect;",
                    reflect90Degree ? "vec4 envColor = textureCube( envMap, vec3(reflectVec.x, reflectVec.z, -reflectVec.y) );" : "vec4 envColor = textureCube( envMap, reflectVec.xyz );",
                    "outgoingLight += mix(vec3(0.0), envColor.xyz * fReflectVal, framef);",
                    "#endif",

                    notInstanced ? "gl_FragColor = vec4(outgoingLight, vOpacity);" : "gl_FragColor = vec4(outgoingLight, vColor.a * vOpacity);",

                    "}"
                ].join("\n");

            this.uniforms = uniforms;
            this.vertexShader = vertexShader;
            this.fragmentShader = fragmentShader;
            this.side = THREE.FrontSide;
            this.transparent = true;
            if (blending !== undefined)
                this.blending = blending;
            if (opacity !== undefined)
                this.opacity = opacity;
        }
    }

    export class HeatmapHelper extends THREE.Object3D {

        private map: THREE.CanvasTexture;
        private heatCanvas: HeatMap.HeatCanvas;
        private mesh: THREE.Mesh;
        private bounds: THREE.Box2;
        private textureSize: number;

        constructor() {
            super();
        }

        public init(poly: THREE.Vector2[], textureSize: number = 512) {
            this.textureSize = textureSize;
            var heatCanvas = this.heatCanvas,
                map = this.map,
                mesh = this.mesh;

            if (heatCanvas) {
                if (heatCanvas.width !== textureSize || heatCanvas.height !== textureSize)
                    heatCanvas.resize(textureSize, textureSize);
            } else {
                heatCanvas = this.heatCanvas = new HeatMap.HeatCanvas(textureSize, textureSize);
            }

            if (!map)
                map = this.map = new THREE.CanvasTexture(heatCanvas.canvas);

            if (mesh) {
                this.remove(mesh);
                spt.ThreeJs.utils.disposeObject3D(mesh, false, true, false);
                mesh = this.mesh = null;
            }

            var material = new THREE.MeshBasicMaterial({
                map: map
            });

            mesh = this.mesh = new THREE.Mesh(spt.ThreeJs.utils.triangulateToGeometry(poly), material);

            this.add(mesh);


            this.bounds = new THREE.Box2().setFromPoints(poly);

        }

        public setData(dataPoints: { x: number, y: number, z: number }[], minVal: number, maxVal: number, radius?: number, degree?: HeatMap.DegreeEnum) {
            var heatCanvas = this.heatCanvas,
                bounds = this.bounds,
                min = bounds.min,
                size = bounds.getSize(new THREE.Vector2()),
                textureSize = this.textureSize,
                s = Math.max(size.x, size.y);

            if (!heatCanvas.needsRender)
                heatCanvas.clear();

            if (dataPoints)
                dataPoints.forEach(p => {
                    heatCanvas.push((p.x - min.x) / s * textureSize, (1 - (p.y - min.y) / s) * textureSize, p.z);
                });

            heatCanvas.setMinMax(minVal, maxVal);

            if (radius)
                heatCanvas.setRadiusDegree(radius / s * textureSize, degree);
        }

        public draw(): void {
            this.heatCanvas.render();
            this.map.needsUpdate = true;
        }

    }

    export class MeasureVisualizer extends THREE.Object3D {

        minX = new THREE.Vector3();
        minY = new THREE.Vector3();
        maxX = new THREE.Vector3();
        maxY = new THREE.Vector3();

        padding = 1000;
        textSize = 200;

        steps: THREE.Vector3[] = [];
        private _needUpdate = true;

        private _measureObjectsX: spt.ThreeJs.utils.DimensionAligned[] = [];
        private _measureObjectsY: spt.ThreeJs.utils.DimensionAligned[] = [];

        stepsX: THREE.Vector3[];
        stepsY: THREE.Vector3[];

        _stepsXCount: number = 0;
        _stepsYCount: number = 0;

        constructor() {
            super();
            ko.track(this, ["steps", "minX", "minY", "maxX", "maxY"]);

            ko.defineProperty(this, "stepsX", () => {
                return ArrayHelper.distinct(this.steps.concat([this.minX, this.maxX]), (a, b) => a.x - b.x);
                //return ArrayHelper.distinct(this.steps.concat([new THREE.Vector3(this.minX, this.minY), new THREE.Vector3(this.maxX, this.minY)]), (a, b) => a.x - b.x);
            });

            ko.defineProperty(this, "stepsY", () => {
                return ArrayHelper.distinct(this.steps.concat([this.minY, this.maxY]), (a, b) => a.y - b.y);
                //return ArrayHelper.distinct(this.steps.concat([new THREE.Vector3(this.minX, this.minY), new THREE.Vector3(this.minX, this.maxY)]), (a, b) => a.y - b.y);
            });
        }

        setOuter(poly: THREE.Vector3[]) {
            var minX = this.minX.set(Number.MAX_VALUE, 0, 0),
                minY = this.minY.set(0, Number.MAX_VALUE, 0),
                maxX = this.maxX.set(0, 0, 0),
                maxY = this.maxY.set(0, 0, 0);

            if (poly && poly.length) {
                poly.forEach(p => {
                    if (p.x < minX.x || (p.x === minX.x && p.y < minX.y))
                        minX.copy(p);
                    if (p.y < minY.y || (p.y === minY.y && p.x < minY.x))
                        minY.copy(p);
                    if (p.x > maxX.x || (p.x === maxX.x && p.y < maxX.y))
                        maxX.copy(p);
                    if (p.y > maxY.y || (p.y === maxY.y && p.x < maxY.x))
                        maxY.copy(p);
                });
            }
        }

        setBounds(c1: THREE.Vector3, c2: THREE.Vector3) {
            var bd = new THREE.Box3().expandByPoint(c1).expandByPoint(c2);

            this.minX.copy(bd.min);
            this.minY.copy(bd.min);
            this.maxX.copy(bd.max);
            this.maxY.copy(bd.max);

            this.onChanged();
        }

        addStep(v: { x: number, y: number }) {
            this.steps.push(new THREE.Vector3(v.x || 0, v.y || 0, 0));

            this.onChanged();
        }

        clearSteps() {
            this.steps.removeAll();

            this.onChanged();
        }

        onChanged() {
            this._needUpdate = true;
        }

        clear() {
            this.children.slice().forEach((dim: spt.ThreeJs.utils.DimensionAligned) => {
                this.remove(dim);
                dim.dispose();
            });
            this._measureObjectsX = [];
            this._measureObjectsY = [];

            this.steps.removeAll();
            //spt.ThreeJs.utils.emptyObject3D(this, true, true);
        }

        update() {
            if (!this._needUpdate)
                return;

            this._needUpdate = false;

            if (!this.steps.length) {
                if (this.visible)
                    this.visible = false;
                return;
            }

            if (!this.visible)
                this.visible = true;

            var stepsX = this.stepsX,
                stepsY = this.stepsY,
                measureObjectsXCount = stepsX.length - 1,
                measureObjectsYCount = stepsY.length - 1;

            if (this._measureObjectsX.length < measureObjectsXCount || this._measureObjectsY.length < measureObjectsYCount)
                this.build(measureObjectsXCount, measureObjectsYCount);

            var measureObjectsX = this._measureObjectsX,
                measureObjectsY = this._measureObjectsY;

            var pos = new THREE.Vector3(),
                padding = this.padding,
                minDist = 400,
                startX = this.minX.x - padding,
                startY = this.minY.y - padding;

            for (var i = 0, l = this._measureObjectsX.length; i < l; i++) {
                var mo = measureObjectsX[i];

                if (i >= measureObjectsXCount) {
                    mo.visible = false;
                    continue;
                }

                var p1 = stepsX[i],
                    p2 = stepsX[i + 1],
                    d = p2.x - p1.x;

                mo.setPositions(p1, p2, pos.set((p1.x + p2.x) * 0.5, d < minDist ? startY - minDist : startY, 0));
                mo.visible = true;
            }

            for (var i = 0, l = this._measureObjectsY.length; i < l; i++) {
                var mo = measureObjectsY[i];

                if (i >= measureObjectsYCount) {
                    mo.visible = false;
                    continue;
                }

                var p1 = stepsY[i],
                    p2 = stepsY[i + 1],
                    d = p2.y - p1.y;

                mo.setPositions(p1, p2, pos.set(d < minDist ? startX - minDist : startX, (p1.y + p2.y) * 0.5, 0));
                mo.visible = true;
            }
        }

        build(measureObjectsXCount: number, measureObjectsYCount: number) {
            var measureObjectsX = this._measureObjectsX,
                measureObjectsY = this._measureObjectsY;

            while (measureObjectsX.length < measureObjectsXCount) {
                var dim = new spt.ThreeJs.utils.DimensionAligned(new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Color(0, 0, 0), this.textSize, null, true, false, null, true, 0, 1);
                this.add(dim);
                measureObjectsX.push(dim);
            }

            while (measureObjectsY.length < measureObjectsYCount) {
                var dim = new spt.ThreeJs.utils.DimensionAligned(new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Color(0, 0, 0), this.textSize, null, true, false, null, true, Math.PI * 0.5, 0);
                this.add(dim);
                measureObjectsY.push(dim);
            }

        }
    }

    export class LineSegmentsHelper extends THREE.Object3D {

        lineSegments: { [k: number]: THREE.LineSegments2 } = {};

        private _updateQueue: { [k: number]: THREE.Vector3[] } = {};
        private _needsUpdate = false;

        hasSegments() {
            return this.children.length > 0;
        }

        clear() {
            spt.ThreeJs.utils.emptyObject3D(this);
            this.lineSegments = {};
            this._updateQueue = {};
        }

        update() {
            if (!this._needsUpdate)
                return;

            this._needsUpdate = false;
            var updateQueue = this._updateQueue,
                lineSegments = this.lineSegments;

            this._updateQueue = {};

            for (var k in updateQueue) {
                var color = new THREE.Color().setHex(+k),
                    vertices: THREE.Vector3[] = updateQueue[k],
                    lineSegment = lineSegments[k] as THREE.LineSegments2,
                    posArr: Float32Array,
                    idx = 0;

                if (lineSegment) {
                    //var geo = lineSegment.geometry as THREE.BufferGeometry,
                    //    positionAttrib = geo.getAttribute("position") as THREE.BufferAttribute,
                    //    arr = positionAttrib.array as Float32Array,
                    //    arrLen = arr.length;

                    //posArr = new Float32Array(vertices.length * 3 + arrLen);
                    //for (var i = 0, len = arr.length; i < len; i++) {
                    //    posArr[i] = arr[i];
                    //}
                    //idx = arrLen;

                    this.remove(lineSegment);

                    spt.ThreeJs.utils.disposeObject3D(lineSegment);

                    posArr = new Float32Array(vertices.length * 3);
                } else {
                    posArr = new Float32Array(vertices.length * 3);
                }

                for (var i = 0, len = vertices.length; i < len; i++) {
                    var v = vertices[i];

                    posArr[idx++] = v.x;
                    posArr[idx++] = v.y;
                    posArr[idx++] = v.z;
                }

                var geometry = new THREE.LineSegmentsGeometry();
                geometry.setPositions(posArr);
                //geometry.setAttribute('position', new THREE.Float32BufferAttribute(posArr, 3));
                //geometry.setAttribute( 'color', new THREE.Float32BufferAttribute( colors, 3 ) );
                //geometry.computeBoundingSphere();

                //var material = new THREE.LineBasicMaterial({ color: color });
                var material = this.getMaterial(color);

                lineSegment = new THREE.LineSegments2(geometry, material);

                this.add(lineSegment);

                lineSegments[k] = lineSegment;
            }

            //for (var i = conduitObjects.length; i--;)
        }

        private getMaterial(color: THREE.Color) {
            return spt.ThreeJs.utils.LineMaterialHelper.GetLineMaterial({
                color: color.getHex(),
                linewidth: 2, // in pixels
                vertexColors: false, //THREE.NoColors,
                transparent: true,
                //resolution:  // to be set by renderer, eventually
                dashed: false,
                depthTest: false,
                depthWrite: false
            });
        }

        addPolyline(vertices: THREE.Vector3[], color: THREE.Color, drawAngled?: boolean) {
            if (!vertices || vertices.length < 2)
                return;
            var k = color.getHex();
            var a = this._updateQueue[k] || [];
            if (drawAngled) {
                var dir = spt.ThreeJs.utils.ToClosestAxis(new THREE.Vector3().subVectors(vertices[1], vertices[0])).setZ(0),
                    p3 = new THREE.Vector3();

                //p2 -- p3 -- p1
                for (let i = 1, l = vertices.length - 1; i <= l; i++) {

                    var i2 = i - 1,
                        p1 = vertices[i],
                        p2 = vertices[i2];

                    p3.subVectors(p1, p2);

                    if (Math.abs(p3.x) < 0.00001 || Math.abs(p3.y) < 0.00001) {
                        a.push(p2);
                        a.push(p1);

                        spt.ThreeJs.utils.ToClosestAxis(dir.subVectors(p1, p2)).setZ(0);
                    } else {
                        var dt = dir.dot(p3);
                        if (dt < 0) {
                            dir.set(-dir.y, dir.x, dir.z);
                            dt = dir.dot(p3);
                        }
                        var pt = p3.copy(dir).multiplyScalar(dt).add(p2).clone();

                        a.push(p2);
                        a.push(pt);
                        a.push(pt);
                        a.push(p1);

                        spt.ThreeJs.utils.ToClosestAxis(dir.subVectors(p1, p3)).setZ(0);
                    }
                }
            } else {
                for (var i = 1, l = vertices.length; i < l; i++) {
                    a.push(vertices[i - 1]);
                    a.push(vertices[i]);
                }
            }

            this._updateQueue[k] = a;
            this._needsUpdate = true;
        }
    }

    export function uncompressClientObectDataArrays(dataArray: SolarProTool.IClientObectDataArrays, compressedIdentifier = "_Compressed") {
        if (!dataArray || !compressedIdentifier)
            return;

        Object.keys(dataArray).forEach(key => {
            if (key !== compressedIdentifier && key.indexOf(compressedIdentifier) !== -1) {
                if (dataArray[key]) {
                    var data = LZString.decompressFromEncodedURIComponent(dataArray[key]);
                    dataArray[key.substr(0, key.length - compressedIdentifier.length)] = JSON.parse(data);
                }
                delete dataArray[key];
            }
        });
    }

    export function IterateClientObectDataArrays(dataArray: SolarProTool.IClientObectDataArrays, fn: (d: IInstanceData) => void, count?: number) {

        uncompressClientObectDataArrays(dataArray);

        if (!dataArray || !dataArray.Id || !dataArray.Id.length)
            return;

        if (!count)
            count = dataArray.Id.length;

        var datakeys = Object.keys(dataArray).filter((key) => dataArray[key] && (<any[]>dataArray[key]).length === count),
            datakeysLength = datakeys.length;

        ArrayHelper.iterateTimes(count, (idx) => {
            var keys = datakeys, keysLength = datakeysLength, genericData = dataArray, data: IInstanceData = {} as any;
            for (var j = keysLength; j--;) {
                var key = keys[j];
                data[key] = genericData[key][idx];
            }
            fn(data);
        });
    }
}

THREE.DashedLineMaterial = LS.Client3DEditor.DashedLineMaterial;
THREE.DefaultAppearanceShader = LS.Client3DEditor.DefaultAppearanceShader;
THREE.ModuleAppearanceShader = LS.Client3DEditor.ModuleAppearanceShader;
THREE.BaseInstancedShader = LS.Client3DEditor.BaseInstancedShader;

//module THREE {
//    export var DashedLineMaterial: typeof LS.Client3DEditor.DashedLineMaterial = LS.Client3DEditor.DashedLineMaterial;
//    export var DefaultAppearanceShader: typeof LS.Client3DEditor.DefaultAppearanceShader = LS.Client3DEditor.DefaultAppearanceShader;
//    export var ModuleAppearanceShader: typeof LS.Client3DEditor.ModuleAppearanceShader = LS.Client3DEditor.ModuleAppearanceShader;
//    export var BaseInstancedShader: typeof LS.Client3DEditor.BaseInstancedShader = LS.Client3DEditor.BaseInstancedShader;
//}