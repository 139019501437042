module HeatMap {

    const hsla2rgbaResult = new Uint8ClampedArray(4);
    const defaultValue2ColorResult = new Float64Array([0, 0.8, 0, 1.0]); //h,s,l,a

    function hue2rgb(p, q, t) {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
    }

    export enum DegreeEnum {
        LINEAR = 1,
        QUAD = 2,
        CUBIC = 3
    }

    export class HeatCanvas {

        canvas: HTMLCanvasElement;
        width: number;
        height: number;
        radius: number;
        degree: DegreeEnum;
        min: number;
        max: number;

        needsRender = true;

        data: { [x: number]: { [y: number]: number } } = {};

        value: { [id: number]: number } = {};

        bgcolor = [0, 0, 0, 255];

        constructor(width?: number, height?: number, radius?: number, min?: number, max?: number, degree?: DegreeEnum) {
            this.canvas = document.createElement('canvas') as HTMLCanvasElement;
            this.width = this.canvas.width = width || 512;
            this.height = this.canvas.height = height || 512;
            this.radius = radius || 1;
            this.degree = degree || DegreeEnum.LINEAR;
            this.min = min || 0;
            this.max = max || 100;
        }

        private calc() {
            var value = this.value,
                degree = this.degree,
                step = 1 / this.radius;

            for (var yd in this.data) {
                var dataY = this.data[yd];
                for (var xd in dataY) {
                    var data = dataY[xd];
                    var y = +yd;
                    var x = +xd;
                    var radius = Math.floor(Math.pow((data / step), 1 / degree));
                    var radiusSq = radius * radius;

                    // calculate point x.y 
                    for (var scanx = x - radius; scanx < x + radius; scanx += 1) {
                        // out of extend
                        if (scanx < 0 || scanx > this.width) {
                            continue;
                        }
                        for (var scany = y - radius; scany < y + radius; scany += 1) {

                            if (scany < 0 || scany > this.height)
                                continue;

                            var dx = scanx - x;
                            var dy = scany - y;

                            var distSq = dx * dx + dy * dy;
                            if (distSq > radiusSq) {
                                continue;
                            } else {
                                
                                var v = data - step * Math.pow(Math.sqrt(distSq), degree);

                                var id = Math.round(scanx) + Math.round(scany) * this.width;

                                if (value[id]) {
                                    value[id] = value[id] + v;
                                } else {
                                    value[id] = v;
                                }
                            }
                        }
                    }
                }
            }
        }

        setRadiusDegree(radius: number, degree?: DegreeEnum) {
            this.radius = radius || 1;
            if (degree)
                this.degree = degree;

            this.needsRender = true;
        }

        setMinMax(min: number, max: number) {
            this.min = min || 0;
            this.max = max || 100;

            this.needsRender = true;
        }

        resize(w: number, h: number) {
            this.width = this.canvas.width = w;
            this.height = this.canvas.height = h;

            this.canvas.style.width = w + 'px';
            this.canvas.style.height = h + 'px';

            this.needsRender = true;
        }

        push(x: number, y: number, data: number) {
            // ignore all data out of extent
            if (x < 0 || x > this.width) {
                return;
            }
            if (y < 0 || y > this.height) {
                return;
            }

            var dataY = this.data[y];

            if (dataY) {
                dataY[x] = (dataY[x] || 0) + data;
            } else {
                this.data[y] = {};
                this.data[y][x] = data;
            }

            this.needsRender = true;
        }

        render() {
            this.calc();
            this.data = {};
            this.draw();
        }

        private draw() {
            var bgcolor = this.bgcolor,
                ctx = this.canvas.getContext("2d");

            //ctx.clearRect(0, 0, this.width, this.height);

            ctx.fillStyle = `rgb(${bgcolor[0]},${bgcolor[1]},${bgcolor[2]})`;
            ctx.fillRect(0, 0, this.width, this.height);

            var canvasData = ctx.createImageData(this.width, this.height);
            //for (var i = 0; i < canvasData.data.length; i += 4) {
            //    canvasData.data[i] = defaultColor[0]; // r
            //    canvasData.data[i + 1] = defaultColor[1];
            //    canvasData.data[i + 2] = defaultColor[2];
            //    canvasData.data[i + 3] = defaultColor[3];
            //}

            var min = this.min,
                max = this.max,
                delta = max - min;

            // maximum 
            //var maxValue = 0;
            //for (var id in this.value) {
            //    maxValue = Math.max(this.value[id], maxValue);
            //}

            for (var k in this.value) {
                var pos = +k;
                var x = Math.floor(pos % this.width);
                var y = Math.floor(pos / this.width);

                // MDC ImageData:
                // data = [r1, g1, b1, a1, r2, g2, b2, a2 ...]
                var pixelColorIndex = y * this.width * 4 + x * 4;

                var color = HeatCanvas.hsla2rgba.apply(null, HeatCanvas.defaultValue2Color((this.value[pos] - min) / delta));
                canvasData.data[pixelColorIndex] = color[0]; //r
                canvasData.data[pixelColorIndex + 1] = color[1]; //g
                canvasData.data[pixelColorIndex + 2] = color[2]; //b
                canvasData.data[pixelColorIndex + 3] = color[3]; //a
            }

            ctx.putImageData(canvasData, 0, 0);

            this.needsRender = false;
        }

        clear() {
            this.data = {};
            this.value = {};

            this.canvas.getContext("2d").clearRect(0, 0, this.width, this.height);
        }

        exportImage() {
            return this.canvas.toDataURL();
        }

        static defaultValue2Color(v: number) {
            var value = Math.min(1, Math.max(0, v));
            defaultValue2ColorResult[0] = (1 - value);// h
            defaultValue2ColorResult[2] = value * 0.6;// l
            return defaultValue2ColorResult;
        }

        // http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
        static hsla2rgba(h: number, s: number, l: number, a: number) {
            var r: number, g: number, b: number;

            if (s == 0) {
                r = g = b = l;
            } else {
                var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
                var p = 2 * l - q;
                r = hue2rgb(p, q, h + 1 / 3);
                g = hue2rgb(p, q, h);
                b = hue2rgb(p, q, h - 1 / 3);
            }

            hsla2rgbaResult[0] = r * 255;
            hsla2rgbaResult[1] = g * 255;
            hsla2rgbaResult[2] = b * 255;
            hsla2rgbaResult[3] = a * 255;
            return hsla2rgbaResult;
        }
    }

}