module LS.Client3DEditor {
    import DegreeEnum = HeatMap.DegreeEnum;

    export class InstanceHolderConfiguration implements SolarProTool.IInstanceHolderConfiguration {
        PvModuleId: string = null;
        InstanceHolderType: string = null;
        MountingSystemType: string = null;
        MountingSystemId: string = null;
        IsElevation = false;
        IsEastWest = false;

        copy(config: SolarProTool.IInstanceHolderConfiguration): this {
            if (config) {
                this.PvModuleId = config.PvModuleId || null;
                this.InstanceHolderType = config.InstanceHolderType || null;
                this.MountingSystemType = config.MountingSystemType || null;
                this.MountingSystemId = config.MountingSystemId || null;
                this.IsElevation = !!config.IsElevation;
                this.IsEastWest = !!config.IsEastWest;
            }

            return this;
        }

        equals(other: SolarProTool.IInstanceHolderConfiguration): boolean {
            if (!other)
                return false;

            return Object.keys(this).every(k => this[k] === other[k]);
        }
    }

    export class InstanceContext {
        public static idKey = "InstanceId";

        get Id(): string {
            return this.root.userData[InstanceContext.idKey];
        }

        set Id(v: string) {
            this.root.userData[InstanceContext.idKey] = v;
        }

        RoofAreaStartX = "";
        RoofAreaStartY = "";
        RoofAreaDistanceToRoofBorder = "";
        RoofOrientation = 180; //instanceHolder.Orientation - instanceHolder.LayoutOrientation
        IsElevation = true;
        ProcessStep = 0;

        //startRoofPosition --> roofPosition
        readonly diffRoofPosition: ReadonlyVector3Property;
        //diffRoofPosition after snapping
        readonly diffSnapPosition: THREE.Vector3;
        //current mouse position on roof
        readonly roofPosition = new ObservableVector3();

        readonly diffScale: THREE.Vector3;
        readonly diffScalePosition: THREE.Vector3;

        LayoutOrientationMoveToZero = new THREE.Vector3();

        getLayoutOrientationTransform(m?: THREE.Matrix4): THREE.Matrix4 {
            if (!m)
                m = new THREE.Matrix4();
            if (this.LayoutOrientation !== 0) {
                var translate = this.LayoutOrientationMoveToZero;
                //Transformation4D.RotateZ(-LayoutOrientationRad) * Transformation4D.Translation(-LayoutOrientationMoveToZero)
                return m.makeRotationZ(-this.LayoutOrientationRad).multiply(new THREE.Matrix4().makeTranslation(-translate.x, -translate.y, -translate.z));
            }
            return m.identity();
        }

        getLayoutOrientationReverseTransform(m?: THREE.Matrix4): THREE.Matrix4 {
            if (!m)
                m = new THREE.Matrix4();
            if (this.LayoutOrientation !== 0) {
                var translate = this.LayoutOrientationMoveToZero;
                //Transformation4D.Translation(LayoutOrientationMoveToZero) * Transformation4D.RotateZ(LayoutOrientationRad)
                return m.makeTranslation(translate.x, translate.y, translate.z).multiply(new THREE.Matrix4().makeRotationZ(this.LayoutOrientationRad));
            }

            return m.identity();
        }

        readonly insertableClientObjects: ClientObject[] = [];
        readonly scaleInsertableClientObjects: ClientObject[] = [];
        selectedInsertable: ClientObject = null;
        ModuleNominalPower = 0;
        currentRoofLayers: string[] = [];

        ModuleCellColor = '0b1b3c';
        ModuleFrameColor = 'b5b5b5';
        ModuleBacksheetColor = 'e5e5e5';
        ModuleCellType: ModuleCellType = ModuleCellType.Monocrystalline;
        ModuleCellcols = 6;
        ModuleCellrows = 10;
        ModuleFrameWidth = 20;

        instancePolygons: THREE.Vector3[][] = [[]]; //RoofPolygons
        instanceAreaPolygons: THREE.Vector3[][] = [[]]; //RoofPolygons
        HasShadows = false;
        GlobalOrientationRadian = 0;
        Orientation = 180; //instanceHolder.Orientation
        LayoutOrientation = 0;
        Angle = 0; //RoofAngle
        Bounds = new THREE.Box3();
        Width = 0;
        Height = 0;
        GroundZ = 0;
        LatLngPolygon: MapDrawing.LatLng[] = [];
        WorldOrigin: MapDrawing.LatLng;
        RoofBackgroundType = 0;
        HasMapsBackground = false;
        InstanceHolderConfiguration = new InstanceHolderConfiguration();
        FixationType: string = null;
        ElevationType: string = null;
        heatmap: HeatmapHelper = null;
        isActive = true;
        boxHelper: THREE.BoxHelper;
        selectionHelper: SelectionHelper;

        get LayoutOrientationRad(): number {
            return this.LayoutOrientation / 180 * Math.PI;
        }

        set LayoutOrientationRad(v: number) {
            this.LayoutOrientation = v / Math.PI * 180;
        }

        get AngleRad(): number {
            return this.Angle / 180 * Math.PI;
        }

        set AngleRad(v: number) {
            this.Angle = v / Math.PI * 180;
        }

        get AngleQuat(): THREE.Quaternion {
            return new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1, 0, 0).applyMatrix4(new THREE.Matrix4().makeRotationZ(-this.LayoutOrientationRad)), this.AngleRad);
        }

        root: THREE.Object3D = new THREE.Object3D();
        surfacePolygons: { plane: THREE.Vector4, polygons: ClipperLib.Paths }[] = [];
        instanceObject: THREE.Object3D = null;
        instanceMaterials: { material: {color: THREE.Color, needsUpdate: boolean}, defaultColor: THREE.Color }[] = [];
        instanceContainer: THREE.Object3D;
        readonly worldToLocalTransform: THREE.Matrix4;
        readonly localToWorldTransform: THREE.Matrix4;
        private readonly octree: spt.ThreeJs.utils.Octree;
        private readonly ray: THREE.Ray;
        readonly plane: THREE.Plane;
        readonly worldPlane: THREE.Plane;
        private readonly testVolume: spt.ThreeJs.utils.TestVolume;
        readonly segmentHighlightHelper: SegmentHighlightHelper;
        readonly edgeHighlightHelper: SegmentHighlightHelper;
        readonly segmentsHelper: SegmentsHelper;
        readonly linesHelper: LinesHelper;
        readonly measureObjectHolder: MeasureObjectHolder;
        readonly directedAreaHolder: DirectedAreaHolder;
        readonly stringObjectHolder: StringObjectHolder;
        readonly conduitObjectHolder: ConduitObjectHolder;
        readonly objectHolders: GenericObjectHolder<IGenericObjectInstance>[] = [];
        readonly pointingHelper: PointingHelper;
        readonly measureVisualizer: MeasureVisualizer;
        readonly lineSegmentsHelper: LineSegmentsHelper;
        private _highlighted: boolean = false;
        highlighted: boolean;
        readonly isCurrentActive: boolean;

        constructor(id: string, controller?: Controller) {

            this.Id = id;

            ko.track(this);

            if (!controller)
                controller = Controller.Current;

            var viewModel = controller ? controller.viewModel : null;

            (["ModuleCellColor", "ModuleFrameColor", "ModuleBacksheetColor", "ModuleCellType", "ModuleCellcols", "ModuleCellrows", "ModuleFrameWidth"] as (keyof InstanceContext)[]).forEach(k => {
                ko.getObservable(this, k).subscribe(() => {
                    this.updateModuleAppearance();
                });
            });

            ko.defineProperty(this, 'isCurrentActive', () => {
                return viewModel && viewModel.currentInstance === this;
            });

            ko.defineProperty(this, 'highlighted',
                {
                    get: () => {
                        return this._highlighted || (viewModel && viewModel.currentInstance === this);
                    },
                    set: (v) => {
                        this._highlighted = v;
                    }
                });

            ko.getObservable(this, "highlighted").subscribe(this.onHighlightingChanged.bind(this));
            
            this.ray = new THREE.Ray();
            this.plane = new THREE.Plane().setComponents(0, 0, 1, 0);
            this.worldPlane = new THREE.Plane().setComponents(0, 0, 1, 0);
            this.testVolume = new spt.ThreeJs.utils.TestVolume();

            var instanceContainer = this.instanceContainer = new THREE.Object3D(),
                objectHolders = this.objectHolders;

            //OCTREE
            var octree = this.octree = new spt.ThreeJs.utils.Octree({
                //scene: instanceContainer,
                undeferred: false,
                depthMax: Infinity,
                objectsThreshold: 8,
                overlapPct: 0.15
            });
            octree._needsRebuild = false;

            this.worldToLocalTransform = new THREE.Matrix4();
            this.localToWorldTransform = new THREE.Matrix4();

            this.root.add(instanceContainer);

            //Boxhelper
            var boxHelper = this.boxHelper = new THREE.BoxHelper();
            if((boxHelper.geometry as any).setUsage)
                (boxHelper.geometry as any).setUsage(THREE.DynamicDrawUsage);
            boxHelper.visible = false;
            instanceContainer.add(boxHelper);

            //selection helper
            instanceContainer.add(this.selectionHelper = new SelectionHelper());

            //pointing helper
            instanceContainer.add(this.pointingHelper = new PointingHelper());

            //segment highlight helper
            instanceContainer.add(this.segmentHighlightHelper = new SegmentHighlightHelper());

            //edge highlight helper
            instanceContainer.add(this.edgeHighlightHelper = new SegmentHighlightHelper());

            //segment helper
            var segmentsHelper = this.segmentsHelper = new SegmentsHelper();
            instanceContainer.add(segmentsHelper);

            //lines helper
            var linesHelper = this.linesHelper = new LinesHelper();
            instanceContainer.add(linesHelper);

            //measure objects
            instanceContainer.add(this.measureObjectHolder = new MeasureObjectHolder());

            //string objects
            var stringObjectHolder = this.stringObjectHolder = new StringObjectHolder();
            instanceContainer.add(stringObjectHolder);
            objectHolders.push(stringObjectHolder);

            //conduit objects
            var conduitObjectHolder = this.conduitObjectHolder = new ConduitObjectHolder();
            instanceContainer.add(conduitObjectHolder);
            objectHolders.push(conduitObjectHolder);

            //directed Areas
            var directedAreaHolder = this.directedAreaHolder = new DirectedAreaHolder();
            instanceContainer.add(directedAreaHolder);

            //Measure Visualizer
            var measureVisualizer = this.measureVisualizer = new MeasureVisualizer();
            measureVisualizer.position.z = 10;
            instanceContainer.add(measureVisualizer);

            //LineSegments Helper
            instanceContainer.add(this.lineSegmentsHelper = new LineSegmentsHelper());

            if (viewModel) {

                ko.getObservable(viewModel, "currentInstance").subscribe(this.onHighlightingChanged.bind(this));

                viewModel.genericParameters.addListener('applyEntry', directedAreaHolder.onGenericParameterChanges.bind(directedAreaHolder));

                viewModel.addListener('SnapPoint', (e: ISnapEvent) => {
                    if (!e.snap) {
                        var ct = Controller.Current;
                        e.snap = linesHelper.GetSnapWithPoint(e.source as THREE.Vector3, ct.linePrecision);
                    }
                });

                viewModel.addListener('SnapBox', (e: ISnapEvent) => {
                    if (!e.snap) {
                        var ct = Controller.Current;
                        e.snap = linesHelper.GetSnapWithBox(e.source as THREE.Box3, ct.linePrecision);
                    }
                });

                this.diffRoofPosition = new ReadonlyVector3Property((k) => {
                    return this.roofPosition[k] - viewModel.startRoofPosition[k];
                });

                let _dbox1 = new THREE.Box3(),
                    _dv1 = new THREE.Vector3();

                Object.defineProperty(this, 'diffSnapPosition', {
                    enumerable: true, configurable: false, get: () => {
                        _dv1.copy(this.diffRoofPosition);
                        if (viewModel.enableSnapping && viewModel.canSnap) {
                            var startRoofPosition = viewModel.startRoofPosition,
                                selBounds = viewModel.selectedBounds;

                            _dbox1.min.set(selBounds.x + _dv1.x, selBounds.y + _dv1.y, startRoofPosition.z + _dv1.z);
                            _dbox1.max.set(_dbox1.min.x + selBounds.w, _dbox1.min.y + selBounds.h, _dbox1.min.z + selBounds.d);
                            _dv1.add(viewModel.GetSnap(_dbox1));
                        }
                        return _dv1;
                    }
                });

                let _ds = new THREE.Vector3(),
                    _dsp = new THREE.Vector3();

                Object.defineProperty(this, 'diffScale', {
                    enumerable: true, configurable: false, get: () => {
                        var scaleHelper = viewModel.scaleHelperObject,
                            diff = viewModel.diffSnapPosition,
                            dir = scaleHelper.direction,
                            w = scaleHelper.size.x,
                            h = scaleHelper.size.y,
                            dx = diff.x * dir.x,
                            dy = diff.y * dir.y,
                            nw = Math.min(1000000, Math.max(10, w + dx)),
                            nh = Math.min(1000000, Math.max(10, h + dy));

                        return _ds.set(nw / w, nh / h, 1);
                    }
                });
                                
                Object.defineProperty(this, 'diffScalePosition', {
                    enumerable: true, configurable: false, get: () => {
                        var scaleHelper = viewModel.scaleHelperObject,
                            dir = scaleHelper.direction,
                            w = scaleHelper.size.x,
                            h = scaleHelper.size.y,
                            sc = this.diffScale,
                            tx = dir.x * w * (sc.x * 0.5 - 0.5),
                            ty = dir.y * h * (sc.y * 0.5 - 0.5);

                        return _dsp.set(tx, ty, 0);
                    }
                });

            }

        }

        generateWorldTransform(origin: MapDrawing.LatLng, orientationRad: number, groundZ: number, layoutOrientationRad: number, layoutOrientationMoveToZero: THREE.Vector3, targetMatrix?: THREE.Matrix4) {
            var myGroundZ = this.GroundZ;
            var currentOrigin = this.WorldOrigin;
            var myLayoutOrientationMoveToZero = this.LayoutOrientationMoveToZero;
            var myLayoutOrientationRad = this.LayoutOrientationRad;
            var currentOrientationRad = this.GlobalOrientationRadian + myLayoutOrientationRad;
            var targetOrientationRad = orientationRad + layoutOrientationRad;
            
            if (!targetMatrix)
                targetMatrix = new THREE.Matrix4();

            var move0 = layoutOrientationMoveToZero.clone().negate().applyMatrix4(targetMatrix.makeRotationZ(-layoutOrientationRad));
            var myMove0 = myLayoutOrientationMoveToZero.clone().applyMatrix4(targetMatrix.makeRotationZ(-myLayoutOrientationRad));

            var worldOffset = origin.DirectionTo(currentOrigin).mul(1000);

            var offset = new THREE.Vector3(worldOffset.x, worldOffset.y, 0).applyMatrix4(targetMatrix.makeRotationZ(-targetOrientationRad)).sub(move0).setZ(groundZ - myGroundZ);
            
            return targetMatrix.multiplyMatrices(new THREE.Matrix4().makeTranslation(offset.x, offset.y, offset.z), targetMatrix.multiplyMatrices(new THREE.Matrix4().makeRotationZ(currentOrientationRad - targetOrientationRad), targetMatrix.makeTranslation(myMove0.x, myMove0.y, myMove0.z)));

        }

        setByEditorViewObject(data: SolarProTool.IEditorViewObject, origin: MapDrawing.LatLng, orientationRad: number, groundz: number, layoutOrientationRad: number, layoutOrientationMoveToZero: THREE.Vector3, controller?: Controller): this {

            var dataRoofObject = data.RoofObject,
                linesHelper = this.linesHelper,
                heatmap = this.heatmap;

            this.Id = data.InstanceHolderId;
            this.RoofAreaStartX = ((data.RoofAreaStartX ? spt.Utils.ApplyNumberconstraints(convertLengthUnit(spt.Utils.ConvertStringToValue(data.RoofAreaStartX), { from: "mm", to: UseImperialSystem ? "in" : "mm" }), { precision: 2 }) : "")) as any;
            this.RoofAreaStartY = ((data.RoofAreaStartY ? spt.Utils.ApplyNumberconstraints(convertLengthUnit(spt.Utils.ConvertStringToValue(data.RoofAreaStartY), { from: "mm", to: UseImperialSystem ? "in" : "mm" }), { precision: 2 }) : "")) as any;
            this.RoofAreaDistanceToRoofBorder = ((data.RoofAreaDistanceToRoofBorder ? spt.Utils.ApplyNumberconstraints(convertLengthUnit(spt.Utils.ConvertStringToValue(data.RoofAreaDistanceToRoofBorder), { from: "mm", to: UseImperialSystem ? "in" : "mm" }), { precision: 2 }) : "")) as any;
            this.RoofOrientation = data.RoofOrientation || 0;
            this.IsElevation = !!data.IsElevation;
            this.ModuleNominalPower = data.ModuleNominalPower || 0;

            this.HasShadows = !!data.HasShadows;
            this.GlobalOrientationRadian = data.GlobalOrientationRadian || 0;
            this.GroundZ = data.GroundZ || 0;
            this.WorldOrigin = new MapDrawing.LatLng(data.WorldOrigin.Latitude, data.WorldOrigin.Longitude);
            this.Orientation = data.Orientation || 0;
            this.Angle = data.RoofAngle || 0;
            this.LayoutOrientationMoveToZero.copy(data.LayoutOrientationMoveToZero as any);
            this.LayoutOrientation = data.LayoutOrientation || 0;
            this.Bounds.set(new THREE.Vector3(data.BoundsX || 0, data.BoundsY || 0, data.BoundsZ || 0), new THREE.Vector3((data.BoundsX || 0) + (data.BoundsWidth || 0), (data.BoundsY || 0) + (data.BoundsHeight || 0), (data.BoundsZ || 0) + (data.BoundsDepth || 0)));
            var w = this.Width = data.RoofWidth || 0;
            var h = this.Height = data.RoofHeight || 0;
            this.LatLngPolygon = data.LatLngPolygon ? data.LatLngPolygon.map(ll => new MapDrawing.LatLng().Copy(ll)) : [];
            this.RoofBackgroundType = data.RoofBackgroundType || 0;
            this.HasMapsBackground = !!data.HasMapsBackground;
            this.InstanceHolderConfiguration.copy(data.InstanceHolderConfiguration);
            this.FixationType = data.FixationType;
            this.ElevationType = data.ElevationType;
            this.ProcessStep = data.ProcessStep || 0;
            this.measureVisualizer.setBounds(this.Bounds.min, this.Bounds.max);
            
            if (data.RoofPolygons) {
                var roofPolys: THREE.Vector3[][] = this.instancePolygons = [];
                data.RoofPolygons.forEach(rp => {
                    if (rp && rp.length) {
                        if (isNaN(rp[0] as any)) {
                            roofPolys.push(rp.map(p => new THREE.Vector3(p.x, p.y, p.z)));
                        } else {
                            var poly: THREE.Vector3[] = [];
                            for (var i = 0, l = rp.length - 1; i < l; i += 2) {
                                var x = (<any>rp[i]) as number,
                                    y = (<any>rp[i + 1]) as number;
                                poly.push(new THREE.Vector3(x, y, 0));
                            }
                            roofPolys.push(poly);
                        }
                    }
                });
                if (roofPolys.length && roofPolys[0].length)
                    this.measureVisualizer.setOuter(roofPolys[0]);
            }

            if (data.RoofAreaPolygons) {
                var roofAreaPolys: THREE.Vector3[][] = this.instanceAreaPolygons = [];
                data.RoofAreaPolygons.forEach(rp => {
                    if (rp && rp.length) {
                        if (isNaN(rp[0] as any)) {
                            roofAreaPolys.push(rp.map(p => new THREE.Vector3(p.x, p.y, p.z)));
                        } else {
                            var poly: THREE.Vector3[] = [];
                            for (var i = 0, l = rp.length - 1; i < l; i += 2) {
                                var x = (<any>rp[i]) as number,
                                    y = (<any>rp[i + 1]) as number;
                                poly.push(new THREE.Vector3(x, y, 0));
                            }
                            roofAreaPolys.push(poly);
                        }
                    }
                });
            }

            if (data.HeatmapDataPoints && data.HeatmapDataPoints.length && this.instancePolygons && this.instancePolygons.length) {

                var poly = this.instancePolygons[0].map(p => new THREE.Vector2(p.x, p.y));

                if (!heatmap)
                    heatmap = this.heatmap = new HeatmapHelper();

                heatmap.init(poly);

                heatmap.setData(data.HeatmapDataPoints, data.HeatMapMinValue, data.HeatMapMaxValue, data.HeatMapRadius, DegreeEnum.LINEAR);

                heatmap.draw();

                this.add(heatmap);

            }
            else if (heatmap)
            {
                this.remove(heatmap);
            }

            linesHelper.setMinMax(-w, -h, w * 2, h * 2);

            ArrayHelper.replaceContent(this.currentRoofLayers, data.Layers);

            if (this.instanceObject) {
                this.remove(this.instanceObject);
                spt.ThreeJs.utils.disposeObject3D(this.instanceObject);
                this.instanceObject = null;
                this.instanceMaterials = [];
            }

            var worldTransform = this.generateWorldTransform(origin, orientationRad, groundz, layoutOrientationRad, layoutOrientationMoveToZero);

            if (dataRoofObject) {
                //var triangles: THREE.Vector3[][] = [];
                var instanceObject: THREE.Object3D = this.instanceObject = spt.ThreeJs.utils.ConvertThreeJsObject(dataRoofObject) || new THREE.Object3D();
                instanceObject.traverse(obj => {
                    if (obj["LayerType"] === "RoofObjects") {
                        obj.traverse(o => {
                            if (o instanceof THREE.Mesh) {
                                var mat = o.material as THREE.MeshLambertMaterial;
                                if (mat && mat.color)
                                    this.instanceMaterials.push({
                                        material: mat,
                                        defaultColor: new THREE.Color(mat.color)
                                    });
                            }
                        });
                    }
                    //if (data.InstanceHolderConfiguration.InstanceHolderType === "FreeAreaObject") {
                    //    obj.traverse(o => {
                    //        if (o instanceof THREE.Mesh) {
                    //            if (data.InstanceHolderConfiguration.InstanceHolderType === "FreeAreaObject") {
                    //                var trs = spt.ThreeJs.utils.GetTriangles(o);
                    //                if (trs.length)
                    //                    triangles.push.apply(triangles, trs);
                    //            }
                    //        }
                    //    });
                    //}
                });
                //if (triangles.length)
                //    this.surfacePolygons = spt.ThreeJs.utils.TrianglesToSurfacePolygons(triangles);

                this.add(instanceObject);
            }

            this.surfacePolygons = null;

            if (data.SurfacePolygons && data.SurfacePolygons.length) {
                this.surfacePolygons = data.SurfacePolygons.map(surfacePolygon => {
                    var plane = surfacePolygon.Plane;
                    var polys = surfacePolygon.Polygons;
                    var paths = new ClipperLib.Paths();
                    polys.forEach(poly => {
                        var path = new ClipperLib.Path();
                        poly.forEach(p => {
                            path.push(new ClipperLib.IntPoint(Math.round(p.x), Math.round(p.y), Math.round(p.z)));
                        });
                        paths.push(path);
                    });
                    return { plane: new THREE.Vector4(plane.x, plane.y, plane.z, plane.w), polygons: paths }
                });
            }

            var instanceContainer = this.instanceContainer;

            this.getLayoutOrientationTransform(this.localToWorldTransform).multiply(new THREE.Matrix4().makeRotationX(this.AngleRad)).multiply(this.getLayoutOrientationReverseTransform());

            spt.ThreeJs.utils.setMatrix4(instanceContainer, this.localToWorldTransform);
            
            this.addToScene(controller);

            this.localToWorldTransform.multiplyMatrices(worldTransform, this.localToWorldTransform);

            this.worldToLocalTransform.getInverse(this.localToWorldTransform, false);

            spt.ThreeJs.utils.setMatrix4(this.root, worldTransform);

            this.worldPlane.setComponents(0, 0, 1, 0).applyMatrix4(this.localToWorldTransform);

            var measureObjectHolder = this.measureObjectHolder,
                directedAreaHolder = this.directedAreaHolder;

            var measureObjects = data.MeasureObjects,
                directedAreas = data.DirectedAreas,
                interferenceObjects = data.InterferenceObjects,
                oldInterferenceObjects = this.getInterferenceObjects();

            if (measureObjects && measureObjects.length)
                measureObjectHolder.addMeasureObjects(measureObjects);

            if (directedAreas && directedAreas.length)
                directedAreaHolder.addDirectedAreaDefinitions(directedAreas);

            oldInterferenceObjects.forEach(o => o.dispose());

            if (interferenceObjects && interferenceObjects.length) {
                interferenceObjects.forEach(d => {
                    Controller.Current.interferenceHelper.createInterferenceObjectFromData(d);
                });
            }

            this.onHighlightingChanged();

            return this;
        }

        onHighlightingChanged() {
            var highlighted = this.highlighted;

            if (this.instanceMaterials.length) {

                if (highlighted)
                    this.instanceMaterials.forEach(instanceMaterial => {
                        instanceMaterial.material.color.copy(instanceMaterial.defaultColor);
                        instanceMaterial.material.needsUpdate = true;
                    });
                    //this.instanceMaterial.color.setRGB(1, 1, 1);
                else {
                    this.instanceMaterials.forEach(instanceMaterial => {
                        instanceMaterial.material.color.copy(instanceMaterial.defaultColor).multiplyScalar(0.8);
                        instanceMaterial.material.needsUpdate = true;
                    });
                    //if (this.IsElevation) {
                    //    this.instanceMaterial.color.setRGB(0.8, 0.8, 0.8);
                    //} else {
                    //    this.instanceMaterial.color.setRGB(0.7, 0.9, 0.9);
                    //}
                }
                //this.instanceMaterial.needsUpdate = true;
                //console.log("instanceMaterial updated " + highlighted);
            }
        }

        add(object: THREE.Object3D, asInstance?: boolean): void {
            if (asInstance) {
                if (object.parent) {
                    if (object.parent === this.instanceContainer)
                        return;
                    else
                        object.parent.remove(object);
                }
                if (this.instanceContainer.children.indexOf(object) === -1)
                    this.instanceContainer.add(object);
            } else if (this.root.children.indexOf(object) === -1) {
                if (object.parent) {
                    if (object.parent === this.root)
                        return;
                    else
                        object.parent.remove(object);
                }
                this.root.add(object);
            }
        }

        remove(object: THREE.Object3D, asInstance?: boolean): void {
            if (asInstance)
                this.instanceContainer.remove(object);
            else
                this.root.remove(object);
        }

        getInterferenceObjects(): IDynamicInterferenceObject[] {
            return Controller.Current.viewModel.interferenceObjects.filter(o => o.BaseParentId === this.Id);
        }

        getClientObjects(): ClientObject[] {
            return this.instanceContainer.children.filter((o: ClientObject) => o.isClientObject) as ClientObject[];
        }

        addToOctree(object: THREE.Mesh, options?: spt.ThreeJs.utils.OctreeAddOptions) {
            this.octree.add(object, options);
        }

        removeFromOctree(object: THREE.Object3D) {
            this.octree.remove(object);
        }

        addToScene(controller?: Controller) {

            if (!controller)
                controller = Controller.Current;

            if (!controller)
                return;

            if (controller.scene.children.indexOf(this.root) === -1)
                controller.scene.add(this.root);
        }

        removeFromScene(controller?: Controller) {

            if (!controller)
                controller = Controller.Current;

            if (!controller)
                return;

            controller.scene.remove(this.root);
        }

        forEachOctreeObjects(fn: (o: THREE.Object3D) => void) {
            this.octree.objects.forEach(fn);
        }

        notifyOctree() {
            this.octree._needsRebuild = true;
        }

        searchOctree(position: THREE.Vector3, radius: number, organizeByObject?: boolean, direction?: THREE.Vector3): THREE.Object3D[] {
            return this.octree.search(position, radius, organizeByObject, direction).map(o => o.object);
        }

        getInstanceIntersections(worldRayCaster: THREE.Raycaster, filterOptions?: number): ClientObjectInstance[] {
            var octree = this.octree;
            this.ray.copy(worldRayCaster.ray);
            worldRayCaster.ray.applyMatrix4(this.worldToLocalTransform);
            var result = spt.ThreeJs.utils.getInstanceIntersections(worldRayCaster, octree, filterOptions);
            worldRayCaster.ray.copy(this.ray);
            return result;
        }

        getBoxIntersections(searchBox: THREE.Box3): THREE.Object3D[] {
            var octree = this.octree;
            return spt.ThreeJs.utils.getBoxIntersections(searchBox, octree);
        }

        getIntersectionsByViewVolume(viewVolume: spt.ThreeJs.utils.TestVolume): THREE.Object3D[] {
            return spt.ThreeJs.utils.getIntersectionsByViewVolume(this.testVolume.copy(viewVolume).applyMatrix4(this.worldToLocalTransform), this.octree);
        }

        getLocalPosition(worldRay: THREE.Ray, target?: THREE.Vector3): THREE.Vector3 {
            return this.ray.copy(worldRay).applyMatrix4(this.worldToLocalTransform).intersectPlane(this.plane, target || new THREE.Vector3());
        }

        updateModuleAppearance() {

            var baseParentId = this.Id;
            if (!baseParentId)
                return;

            var moduleCellType = (parseInt("" + this.ModuleCellType) || ModuleCellType.Monocrystalline) as ModuleCellType;
            var moduleCellcols = Math.min(200, Math.max(0, parseInt("" + this.ModuleCellcols) || 0));
            var moduleCellrows = Math.min(200, Math.max(0, parseInt("" + this.ModuleCellrows) || 0));
            var moduleFrameWidth = Math.min(200, Math.max(0, parseFloat(("" + this.ModuleFrameWidth).replace(",", ".")) || 0));
            var moduleCellColor = this.ModuleCellColor || '0b1b3c';;
            var moduleFrameColor = this.ModuleFrameColor || 'b5b5b5';;
            var moduleBacksheetColor = this.ModuleBacksheetColor || 'e5e5e5';;

            if (moduleCellColor && moduleCellColor.length && moduleCellColor.charAt(0) === '#')
                moduleCellColor = moduleCellColor.substr(1);
            moduleCellColor = moduleCellColor.toLowerCase();

            if (moduleFrameColor && moduleFrameColor.length && moduleFrameColor.charAt(0) === '#')
                moduleFrameColor = moduleFrameColor.substr(1);
            moduleFrameColor = moduleFrameColor.toLowerCase();

            if (moduleBacksheetColor && moduleBacksheetColor.length && moduleBacksheetColor.charAt(0) === '#')
                moduleBacksheetColor = moduleBacksheetColor.substr(1);
            moduleBacksheetColor = moduleBacksheetColor.toLowerCase();

            var objects = Controller.Current.Objects;
            for (var id in objects) {
                var clientObject = objects[id] as ClientObject;
                if (clientObject && clientObject.userData && clientObject.userData.BaseParentId === baseParentId && clientObject.SharedMeshes && clientObject.SharedMeshes.length) {
                    for (var i = clientObject.SharedMeshes.length; i--;) {
                        var sharedMesh = clientObject.SharedMeshes[i],
                            material = sharedMesh.material;
                        if (material instanceof THREE.ModuleAppearanceShader) {
                            material.cellType = moduleCellType;
                            material.cols = moduleCellcols;
                            material.rows = moduleCellrows;
                            material.frameWidth = moduleFrameWidth;
                            material.moduleCellColor.set('#' + moduleCellColor);
                            material.moduleFrameColor.set('#' + moduleFrameColor);
                            material.moduleBacksheetColor.set('#' + moduleBacksheetColor);
                        } else if (material instanceof THREE.DefaultAppearanceShader) {
                            material.color.set('#' + moduleFrameColor);
                        }
                    }
                }
            }
        }

        update() {
            var octree = this.octree;
            octree.update();
            if ((<any>octree)._needsRebuild) {
                octree.rebuild();
                (<any>octree)._needsRebuild = false;
                //console.log("octree count: " + octree.objects.length);
            }
        }

        onDeactivate() {
            this.measureVisualizer.clearSteps();
            this.measureVisualizer.update();
        }

        onActivate() {

        }

        dispose() {
            this.insertableClientObjects.removeAll();
            this.scaleInsertableClientObjects.removeAll();
            this.removeFromScene();
            spt.ThreeJs.utils.disposeObject3D(this.root);
        }

    }
}