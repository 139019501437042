module LS.Client3DEditor {
    export class KeyboardSteps extends BaseTool {
        needsUpdate: boolean = false;

        onSelect(viewModel: ViewModel, params: { enforce?: string }) {
            if ((!viewModel.selected.length && !viewModel.selectedUi.length) || (params.enforce != "1" && viewModel.mouseDown)) {
                viewModel.deselectTool();
                return;
            }
            viewModel.isDragging = true;
            viewModel.startDrag();
            viewModel.disableMouseMove = true;
            viewModel.startRoofPosition.set(0, 0, 0);
            viewModel.roofPosition.set(0, 0, 0);
            this.needsUpdate = false;
        }

        onDeselect(viewModel: ViewModel) {
            if (this.needsUpdate)
                this.applyMove(false);
            viewModel.disableMouseMove = false;
        }

        onMouseMove(viewModel: ViewModel) {
            return false;
        }

        onMouseDown(viewModel: ViewModel) {
            this.applyMove(true);
            return false;
        }

        onMouseUp(viewModel: ViewModel) {
            this.applyMove(true);
            return false;
        }

        onKeyDown(viewModel: ViewModel) {
            if (viewModel.keyPressed([37, 38, 39, 40])) { //Left, Up, Right, Down
                var l = viewModel.keyPressed(37) ? -1 : 0;
                var u = viewModel.keyPressed(38) ? 1 : 0;
                var r = viewModel.keyPressed(39) ? 1 : 0;
                var d = viewModel.keyPressed(40) ? -1 : 0;
                var steps = viewModel.keyBoardSteps.internalValue;
                this.moveSelected(steps * l + steps * r, steps * u + steps * d, 0);
            }
            return false;
        }

        applyMove(unselectTool) {
            var controller = Controller.Current,
                viewModel = controller.viewModel,
                ray = controller.worldRaycaster.ray,
                incstanceContext = viewModel.currentInstance;

            if (viewModel.selectedTool !== 'keyboardSteps') return;

            viewModel.ApplyDiffSnapPosition();

            this.needsUpdate = false;

            var roofPos = incstanceContext.getLocalPosition(ray);
            if (roofPos) {
                viewModel.roofPosition.copy(roofPos);
                viewModel.startRoofPosition.copy(viewModel.roofPosition);
            }
            viewModel.startViewPosition.copy(viewModel.viewPosition);
            viewModel.disableMouseMove = false;
            viewModel.isDragging = false;
            viewModel.isSelecting = false;
            viewModel.canSelect = false;
            viewModel.canDrag = false;

            if (unselectTool)
                viewModel.deselectTool();
        }

        moveSelected(x, y, z) {
            var viewModel = Controller.Current.viewModel;
            var roofPosition = viewModel.roofPosition;
            roofPosition.x += x || 0;
            roofPosition.y += y || 0;
            roofPosition.z += z || 0;
            viewModel.updateSelected();
            this.needsUpdate = true;
        }
    }

    export class SmartDelete extends BaseTool {
        cursor: string = 'default';

        onSelect(viewModel: ViewModel) {
            var controller = Controller.Current,
                linesHelper = controller.linesHelper,
                boxHelper = controller.boxHelper,
                measureObjectHolder = controller.measureObjectHolder,
                stringObjectHolder = controller.stringObjectHolder;

            if (viewModel.selected.length || viewModel.selectedUi.length) {
                viewModel.deselectTool();
                controller.deleteSelected();
                return;
            }

            viewModel.deselectAll();

            linesHelper.setHoverLine(null);
            measureObjectHolder.setHoverDimObj(null);
            stringObjectHolder.setHoverObject(null);

            if (boxHelper.visible)
                setBoxhelper(null);
        }

        onDeselect(viewModel: LS.Client3DEditor.ViewModel) {

        }

        onMouseMove(viewModel: ViewModel) {
            var controller = Controller.Current,
                instanceContext = controller.viewModel.currentInstance,
                linesHelper = controller.linesHelper,
                raycaster = controller.worldRaycaster,
                boxHelper = controller.boxHelper,
                measureObjectHolder = controller.measureObjectHolder,
                stringObjectHolder = controller.stringObjectHolder;

            var cursor = this.cursor;

            linesHelper.setHoverLine(null);
            measureObjectHolder.setHoverDimObj(null);
            stringObjectHolder.setHoverObject(null);

            var intersections = instanceContext.getInstanceIntersections(raycaster);

            if (intersections.length > 0 && viewModel.EditorViewMode !== "Electric") {
                LS.Client3DEditor.setBoxhelper(intersections[0]);
                cursor = 'pointer';
            } else {
                LS.Client3DEditor.setBoxhelper(null);

                var precision = controller.ClickTolerance / controller.orthoCamera.zoom;

                //Helperlines
                var hl = linesHelper.GetByPoint(viewModel.roofPosition, precision);

                if (hl) {
                    linesHelper.setHoverLine(hl);
                    cursor = 'pointer';
                } else {
                    //MeasureObject
                    var mo = measureObjectHolder.getByRaycaster(raycaster, precision);
                    if (mo) {
                        measureObjectHolder.setHoverDimObj(mo);
                        cursor = 'pointer';
                    } else {
                        var so = stringObjectHolder.getByRaycaster(raycaster, precision);
                        if (so) {
                            stringObjectHolder.setHoverObject(so);
                            cursor = 'pointer';
                        } else if (intersections.length > 0) {
                            LS.Client3DEditor.setBoxhelper(intersections[0]);

                            cursor = 'pointer';
                        }
                    }
                }

            }

            controller.viewModel.Cursor = cursor;
            return false;
        }

        onMouseUp(viewModel: ViewModel) {
            var controller = Controller.Current,
                instanceContext = controller.viewModel.currentInstance,
                linesHelper = controller.linesHelper,
                raycaster = controller.worldRaycaster,
                boxHelper = controller.boxHelper,
                measureObjectHolder = controller.measureObjectHolder,
                stringObjectHolder = controller.stringObjectHolder;

            if (boxHelper.visible) {
                var intersections = instanceContext.getInstanceIntersections(raycaster, Client3DObectOptions.CanDeleteInstances);

                if (intersections.length > 0) {
                    var instance = intersections[0];

                    viewModel.addToSelection([instance], true);

                    controller.deleteSelected();
                }

                setBoxhelper(null);
            }
            if (linesHelper.hoverLine) {
                linesHelper.hoverLine.removeInstance();
                linesHelper.setHoverLine(null);
            }
            if (measureObjectHolder.hoverDimObj) {
                measureObjectHolder.hoverDimObj.removeInstance();
                measureObjectHolder.setHoverDimObj(null);
            }
            if (stringObjectHolder.hoverObject) {
                stringObjectHolder.hoverObject.removeInstance();
                stringObjectHolder.setHoverObject(null);
            }

            controller.viewModel.Cursor = this.cursor;
            return false;
        }
    }

    export class DimensionLines extends BaseTool {
        cursor = 'crosshair';
        private _p1 = new THREE.Vector3();
        private _p2 = new THREE.Vector3();
        private _p3 = new THREE.Vector3();
        private _octree: spt.ThreeJs.utils.Octree = null;
        private _lineMat: THREE.LineBasicMaterial = new THREE.LineBasicMaterial();
        private _dimObj: spt.ThreeJs.utils.DimensionAligned = null;
        private _step: number = 0;
        private _shiftDown = false;
        private _ctrlDown = false;

        addLineToOctree(p1: THREE.Vector3, p2: THREE.Vector3) {
            if (!this._octree)
                return;
            var geo = new THREE.Geometry();
            geo.vertices.push(p1, p2);
            geo.computeBoundingSphere();

            geo.boundingSphere.radius += 200;

            var line = new THREE.Line(geo, this._lineMat);
            this._octree.add(line);
        }

        onSelect(viewModel: ViewModel) {
            var controller = Controller.Current,
                pointingHelper = controller.pointingHelper,
                measureObjectHolder = controller.measureObjectHolder;

            measureObjectHolder.setHoverDimObj(null);

            viewModel.deselectAll();

            if (this._octree == null) {
                this._octree = new spt.ThreeJs.utils.Octree({
                    //scene: scene,
                    undeferred: false,
                    depthMax: Infinity,
                    objectsThreshold: 8,
                    overlapPct: 0.15
                });

                ko.getObservable(viewModel, 'align90Degree').subscribe(() => {
                    if (viewModel.selectedTool === "dimensionLines")
                        this.onMouseMove(viewModel);
                });

                ko.getObservable(viewModel, 'keepDepth').subscribe(() => {
                    if (viewModel.selectedTool === "dimensionLines")
                        this.onMouseMove(viewModel);
                });
            }

            this._shiftDown = false;
            this._ctrlDown = false;

            var col = window.EditorViewMode === "Anordnung" ? 0xdbe2fc : 0x414141;
            var textSize = window.EditorViewMode === "Anordnung" ? 150 : 100;

            if (this._dimObj == null)
                this._dimObj = new THREE.DimensionAligned(undefined, undefined, undefined, new THREE.Color(col), textSize, undefined, true);
            else {
                this._dimObj.textSize = textSize;
                this._dimObj.color.setHex(col);
                this._dimObj.reset();
            }

            Controller.Current.viewModel.currentInstance.add(this._dimObj, true);

            this._step = 0;

            pointingHelper.visible = false;

            //var p1 = this._p1;
            //var p2 = this._p2;

            var instanceContext = controller.viewModel.currentInstance;

            if (window.EditorViewMode === "Anordnung") {
                instanceContext.forEachOctreeObjects(obj => {
                    if (obj instanceof THREE.Mesh) {
                        this.addMeshToOctree(obj);
                    }
                });
                var polygons = instanceContext.instancePolygons;
                if (polygons && polygons.length) {
                    polygons.forEach(poly => {
                        if (poly && poly.length) {
                            for (var i = 0, l = poly.length; i < l; i++) {
                                var p1 = poly[i],
                                    p2 = poly[(i + 1) % l];
                                this.addLineToOctree(p1.clone().setZ(10), p2.clone().setZ(10));
                            }
                        }
                    });
                }
            } else {
                var instanceObject = instanceContext.instanceObject,
                    transform = instanceContext.worldToLocalTransform;
                if (instanceObject) {
                    instanceObject.traverse((obj) => {
                        if (obj instanceof THREE.Line) {
                            this.addLineMeshToOctree(obj, transform);
                        }
                    });
                }
            }

            this._octree.update();
        }

        addMeshToOctree = (() => {
            var box = new THREE.Box3(),
                p2 = new THREE.Vector3(),
                p4 = new THREE.Vector3();
            return (mesh: THREE.Mesh) => {
                box.copy(mesh.geometry.boundingBox).translate(mesh.position);
                if (mesh.parent)
                    box.applyMatrix4(mesh.parent.matrixWorld);
                var p1 = box.min.setZ(Math.max(10, box.min.z)),
                    p3 = box.max.setZ(p1.z);
                p2.set(p3.x, p1.y, p1.z);
                p4.set(p1.x, p3.y, p1.z);
                if (p3.x - p1.x > 0) {
                    this.addLineToOctree(p1.clone(), p2.clone());
                    this.addLineToOctree(p3.clone(), p4.clone());
                }
                if (p3.y - p1.y > 0) {
                    this.addLineToOctree(p2.clone(), p3.clone());
                    this.addLineToOctree(p4.clone(), p1.clone());
                }
            };
        })();

        addLineMeshToOctree = (() => {
            var p1 = new THREE.Vector3(),
                p2 = new THREE.Vector3(),
                m = new THREE.Matrix4();
            return (obj: THREE.Line, transform: THREE.Matrix4) => {
                obj.updateMatrixWorld(true);
                m.copy(transform).multiply(obj.matrixWorld);
                var geometry = obj.geometry;
                if (geometry instanceof THREE.BufferGeometry) {
                    var position = <THREE.BufferAttribute>geometry.attributes['position'];
                    if (!position)
                        return;
                    var arr = position.array;
                    var ipp = obj instanceof THREE.LineSegments ? 6 : 3;
                    var l = obj instanceof THREE.LineSegments ? arr.length : arr.length - 3;
                    for (var i = 0; i < l; i += ipp) {
                        var v1 = p1.set(arr[i], arr[i + 1], arr[i + 2]).applyMatrix4(m);
                        var v2 = p2.set(arr[i + 3], arr[i + 4], arr[i + 5]).applyMatrix4(m);

                        this.addLineToOctree(v1.clone(), v2.clone());
                    }
                }
            };
        })();

        onDeselect(viewModel: ViewModel) {
            var controller = Controller.Current,
                pointingHelper = controller.pointingHelper,
                measureObjectHolder = controller.measureObjectHolder;

            pointingHelper.visible = false;

            viewModel.deselectAll();

            measureObjectHolder.setHoverDimObj(null);

            this._octree = null;

            Controller.Current.viewModel.currentInstance.remove(this._dimObj, true);

        }

        onMouseMove(viewModel: ViewModel) {
            var controller = Controller.Current,
                pointingHelper = controller.pointingHelper,
                tolerance = controller.linePrecision,
                toleranceSq = tolerance * tolerance,
                octree = this._octree,
                raycaster = controller.localRaycaster,
                EPS = 0.0001,
                measureObjectHolder = controller.measureObjectHolder,
                align90degree = viewModel.align90Degree,
                keepDepth = viewModel.keepDepth;

            if (!octree)
                return false;

            controller.viewModel.Cursor = this.cursor;

            if (this._step === 2 || this._step === 3) {
                var dimObj = this._step === 3 ? measureObjectHolder.hoverDimObj : this._dimObj;
                dimObj.dimensionPosition.copy(viewModel.roofPosition);
                dimObj.dimensionPosition.setZ((dimObj.start.z + dimObj.end.z) * 0.5);
                dimObj.rebuild();
                return false;
            }

            measureObjectHolder.setHoverDimObj(null);

            raycaster.params.Line.threshold = tolerance;

            if (this._step === 0) {
                var mObj = controller.measureObjectHolder.getByRaycaster(raycaster);
                if (mObj) {
                    measureObjectHolder.setHoverDimObj(mObj);
                    controller.viewModel.Cursor = 'pointer';
                    if (pointingHelper.visible)
                        pointingHelper.visible = false;
                    return false;
                }
            }

            var ray = raycaster.ray;
            var objs = octree.search(ray.origin, raycaster.far, true, ray.direction).map((m) => { return m.object; });
            if (!objs.length)
                return false;

            var intersections = raycaster.intersectObjects(objs, false);
            if (intersections && intersections.length) {

                var minDist = Number.MAX_VALUE;
                var minP: THREE.Vector3 = null;

                var intersecLines = intersections.map((it) => { return it.object as THREE.Line; });

                var p43 = this._p1;
                var p21 = this._p2;
                var p13 = this._p3;

                for (var j = 0, k = intersecLines.length - 1; j < k; j++) {
                    var l1 = intersecLines[j];
                    var geo1 = l1.geometry as THREE.Geometry;
                    var p1 = geo1.vertices[0];
                    var p2 = geo1.vertices[1];
                    for (var l = j + 1, n = intersecLines.length; l < n; l++) {
                        var l2 = intersecLines[l];
                        var geo2 = l2.geometry as THREE.Geometry;
                        var p3 = geo2.vertices[0];
                        var p4 = geo2.vertices[1];

                        p43.copy(p4).sub(p3);

                        if (p43.lengthSq() < EPS)
                            continue;

                        p21.copy(p2).sub(p1);

                        if (p21.lengthSq() < EPS)
                            continue;

                        p13.copy(p1).sub(p3);

                        var d1343 = p13.x * p43.x + p13.y * p43.y + p13.z * p43.z;
                        var d4321 = p43.x * p21.x + p43.y * p21.y + p43.z * p21.z;
                        var d1321 = p13.x * p21.x + p13.y * p21.y + p13.z * p21.z;
                        var d4343 = p43.x * p43.x + p43.y * p43.y + p43.z * p43.z;
                        var d2121 = p21.x * p21.x + p21.y * p21.y + p21.z * p21.z;

                        var denom = d2121 * d4343 - d4321 * d4321;

                        if (Math.abs(denom) < EPS)
                            continue;

                        var numer = d1343 * d4321 - d1321 * d4343;

                        var mua = numer / denom;
                        var mub = (d1343 + d4321 * (mua)) / d4343;

                        if (mua >= 0 && mua <= 1 && mub >= 0 && mub <= 1) {
                            p21.set(p1.x + mua * p21.x, p1.y + mua * p21.y, p1.z + mua * p21.z);
                            p43.set(p3.x + mub * p43.x, p3.y + mub * p43.y, p3.z + mub * p43.z);

                            p13.copy(p21).sub(p43);
                            if (p13.lengthSq() <= EPS) {
                                //intersection
                                var rdistSq = ray.distanceSqToPoint(p21);
                                if (rdistSq <= toleranceSq && rdistSq < minDist) {
                                    minDist = rdistSq;
                                    if (minP == null)
                                        minP = p21.clone();
                                    else
                                        minP.copy(p21);
                                }
                            }
                        }
                    }
                }

                if (minP == null) {
                    //var interpoints = intersections.map((it) => { return it.point; });

                    //for (var o = 0, q = interpoints.length; o < q; o++) {
                    //    var p = interpoints[o];
                    //    var rdistSqp = ray.distanceSqToPoint(p);
                    //    if (rdistSqp <= toleranceSq && rdistSqp < minDist) {
                    //        minDist = rdistSqp;
                    //        minP = p43.copy(p);
                    //    }
                    //}

                    //if (minP == null) {
                    for (var i = intersections.length; i--;) {
                        var cur = intersections[i],
                            curP = cur.point,
                            d = ray.distanceSqToPoint(curP);

                        if (d < minDist) {
                            minDist = d;
                            minP = p43.copy(curP);
                        }
                    }
                    //}
                }

                pointingHelper.position.copy(minP);

                if (this._step === 1) {
                    if (viewModel.keyPressed(72)) { // h
                        minP.setY(this._dimObj.start.y);
                    } else if (viewModel.keyPressed(86)) { // v
                        minP.setX(this._dimObj.start.x);
                    } else if (align90degree) {
                        if (Math.abs(this._dimObj.start.x - minP.x) > Math.abs(this._dimObj.start.y - minP.y))
                            minP.setY(this._dimObj.start.y);
                        else
                            minP.setX(this._dimObj.start.x);
                    }
                    if (keepDepth)
                        minP.setZ(this._dimObj.start.z);

                    this._dimObj.setEndAutoPosition(minP);
                }

                if (!pointingHelper.visible)
                    pointingHelper.visible = true;
            } else if (this._step === 1) {

                var minP = this._p1.copy(viewModel.roofPosition);

                pointingHelper.position.copy(minP);

                if (viewModel.keyPressed(72)) { // h
                    minP.setY(this._dimObj.start.y);
                } else if (viewModel.keyPressed(86)) { // v
                    minP.setX(this._dimObj.start.x);
                } else if (align90degree) {
                    if (Math.abs(this._dimObj.start.x - minP.x) > Math.abs(this._dimObj.start.y - minP.y))
                        minP.setY(this._dimObj.start.y);
                    else
                        minP.setX(this._dimObj.start.x);
                }
                if (keepDepth)
                    minP.setZ(this._dimObj.start.z);

                this._dimObj.setEndAutoPosition(minP);

                if (!pointingHelper.visible)
                    pointingHelper.visible = true;
            } else if (pointingHelper.visible) {
                pointingHelper.visible = false;
            }

            return false;
        }

        onMouseDown(viewModel: ViewModel) {
            var controller = Controller.Current,
                pointingHelper = controller.pointingHelper,
                measureObjectHolder = controller.measureObjectHolder;

            if (!this._octree)
                return false;

            switch (this._step) {
                case 0:
                    if (measureObjectHolder.hoverDimObj) {
                        var hoverDimObj = measureObjectHolder.hoverDimObj;

                        controller.viewModel.Cursor = this.cursor;
                        this.reset();

                        this._step = 3;
                        hoverDimObj.isSelected = true;
                        hoverDimObj.userData.prevDimensionPosition = measureObjectHolder.hoverDimObj.dimensionPosition.clone();
                        hoverDimObj.OnChanged();

                    } else if (pointingHelper.visible) {
                        this._dimObj.start.copy(pointingHelper.position);
                        this._dimObj.end.copy(pointingHelper.position);
                        this._dimObj.rebuild();
                        this._step = 1;
                    }
                    break;
                case 1:
                    var diff = this._dimObj.end.clone().sub(this._dimObj.start);

                    if (diff.lengthSq() > 1)
                        this._step = 2;
                    else
                        this.reset();
                    break;
                case 2:
                    this.saveDimesionLine(false);
                    break;
                case 3:
                    this.saveDimesionLine(true);
                    measureObjectHolder.setHoverDimObj(null);
                    break;
                default:
                    this.reset();
                    break;
            }

            return false;
        }

        saveDimesionLine(hovered: boolean) {
            var controller = Controller.Current;
            controller.measureObjectHolder.saveDimensionLine(hovered ? controller.measureObjectHolder.hoverDimObj : this._dimObj);
            this.reset();
        }

        onKeyDown(viewModel: ViewModel) {
            if (!this._octree)
                return false;

            if (viewModel.keyPressed(27)) // esc
                this.reset();
            else {
                if (viewModel.shiftDown && !this._shiftDown) {
                    this._shiftDown = true;
                    viewModel.align90Degree = true;
                } else if (viewModel.ctrlDown && !this._ctrlDown) {
                    this._ctrlDown = true;
                    viewModel.keepDepth = true;
                } else if (viewModel.keyPressed([72, 86])) {
                    this.onMouseMove(viewModel);
                }
            }
            return false;
        }

        onKeyUp(viewModel: ViewModel) {
            if (!this._octree)
                return false;

            if (!viewModel.shiftDown && this._shiftDown) {
                this._shiftDown = false;
                viewModel.align90Degree = false;
            } else if (!viewModel.ctrlDown && this._ctrlDown) {
                this._ctrlDown = false;
                viewModel.keepDepth = false;
            }
            return false;
        }

        reset() {
            var controller = Controller.Current,
                measureObjectHolder = controller.measureObjectHolder,
                hoverDimObj = measureObjectHolder.hoverDimObj;
            this._dimObj.reset();
            this._step = 0;
            if (hoverDimObj && hoverDimObj.userData.prevDimensionPosition) {
                hoverDimObj.dimensionPosition = hoverDimObj.userData.prevDimensionPosition;
                hoverDimObj.isSelected = false;
                delete hoverDimObj.userData.prevDimensionPosition;
            }
        }
    }

    export class HelperLines extends BaseTool {
        cursor = 'crosshair';
        v1 = new THREE.Vector3();
        v2 = new THREE.Vector3();
        closestPoint = new THREE.Vector3();
        isVertical = false;
        box1 = new THREE.Box3();
        box2 = new THREE.Box3();
        vLine: HelperLine = null;
        hLine: HelperLine = null;
        freeLine: HelperLine = null;
        lineMode = 3;
        lineModeVertival = 1;
        lineModeHorizontal = 2;
        lineModeAuto = 3;
        lineModeFree = 4;
        lineDeleteMode = false;
        step = 0;

        onSelect(viewModel: ViewModel, params: { lineMode?: string, delete?: string }) {
            var controller = Controller.Current,
                pointingHelper = controller.pointingHelper,
                linesHelper = controller.linesHelper;

            pointingHelper.visible = false;
            this.lineMode = params.lineMode ? parseInt(params.lineMode) : 0;
            this.lineDeleteMode = params.delete == "1";

            this.step = 0;

            if (!this.vLine)
                this.vLine = new HelperLine(true);
            this.vLine.visible = false;
            Object.defineProperty(this.vLine, 'pos', { enumerable: true, configurable: true, writable: false, value: null });

            if (!this.hLine)
                this.hLine = new HelperLine(false);
            this.hLine.visible = false;
            Object.defineProperty(this.hLine, 'pos', { enumerable: true, configurable: true, writable: false, value: null });

            if (!this.freeLine)
                this.freeLine = new HelperLine(false, new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 1, 0));
            this.freeLine.visible = false;
            Object.defineProperty(this.freeLine, 'pos', { enumerable: true, configurable: true, writable: false, value: null });

            var s = Math.max(linesHelper.w, linesHelper.h);
            this.freeLine.scale.set(s, 1, 1);

            linesHelper.setHoverLine(null);

            linesHelper.addLine(this.vLine);
            linesHelper.addLine(this.hLine);
            linesHelper.addLine(this.freeLine);

            linesHelper.visible = true;
        }

        onDeselect(viewModel: ViewModel) {
            var controller = Controller.Current,
                pointingHelper = controller.pointingHelper,
                linesHelper = controller.linesHelper;

            pointingHelper.visible = false;

            linesHelper.setHoverLine(null);

            if (this.vLine) {
                this.vLine.removeInstance();
                this.vLine = null;
            }

            if (this.hLine) {
                this.hLine.removeInstance();
                this.hLine = null;
            }

            if (this.freeLine) {
                this.freeLine.removeInstance();
                this.freeLine = null;
            }
        }

        onMouseMove(viewModel: ViewModel) {
            var controller = Controller.Current,
                instanceContext = controller.viewModel.currentInstance,
                pointingHelper = controller.pointingHelper,
                rp = viewModel.roofPosition,
                camera = controller.orthoCamera,
                tolerance = controller.ClickTolerance / camera.zoom,
                toleranceSq = tolerance * tolerance,
                v1 = this.v1,
                v2 = this.v2,
                closestPoint = this.closestPoint,
                box1 = this.box1,
                box2 = this.box2,
                vLine = this.vLine,
                hLine = this.hLine,
                freeLine = this.freeLine,
                linesHelper = controller.linesHelper,
                lineMode = this.lineMode,
                lineDeleteMode = this.lineDeleteMode,
                modeVertical = this.lineModeVertival,
                modeFree = this.lineModeFree,
                modeHorizontal = this.lineModeHorizontal,
                modeAuto = this.lineModeAuto,
                directedAreaHolder = controller.directedAreaHolder;

            vLine.visible = hLine.visible = freeLine.visible = false;

            if (lineDeleteMode) {
                var cursor = this.cursor;

                var hoverLine = linesHelper.GetByPoint(viewModel.roofPosition, tolerance);
                linesHelper.setHoverLine(hoverLine);

                if (hoverLine != null)
                    cursor = 'pointer';

                controller.viewModel.Cursor = cursor;
                return false;
            }

            box1.min.set(rp.x - tolerance, rp.y - tolerance, -camera.far);
            box1.max.set(rp.x + tolerance, rp.y + tolerance, camera.far);

            var distance = Number.MAX_VALUE;
            var pointFound = false;
            var isVertical = false;
            var isFree = lineMode === modeFree;
            var radius = box1.getSize(v2).setZ(0).length() * 0.5;
            var octreeObjects = instanceContext.searchOctree(rp, radius, false);

            for (var i = octreeObjects.length; i--;) {
                var object = octreeObjects[i] as THREE.Mesh;

                box2.copy(object.geometry.boundingBox).translate(object.position);
                if (object.parent)
                    box2.applyMatrix4(object.parent.matrixWorld);

                if (box1.intersectsBox(box2)) {

                    v1.copy(rp).clamp(box2.min, box2.max).setZ(0);
                    if (lineMode === modeVertical || lineMode === modeFree)
                        v1.x += (Math.abs(box2.min.x - v1.x) < Math.abs(box2.max.x - v1.x)) ? box2.min.x - v1.x : box2.max.x - v1.x;
                    if (lineMode === modeHorizontal || lineMode === modeFree)
                        v1.y += (Math.abs(box2.min.y - v1.y) < Math.abs(box2.max.y - v1.y)) ? box2.min.y - v1.y : box2.max.y - v1.y;

                    var dist = v2.copy(v1).sub(rp).lengthSq();

                    if (dist < distance && dist <= toleranceSq) {
                        distance = dist;
                        if (lineMode === modeAuto || lineMode === modeFree) {
                            //automatic
                            box2.getCenter(v2).sub(rp);

                            //center of the box
                            if (v2.y === 0) {
                                isVertical = true;

                                box2.getCenter(closestPoint).setZ(box2.min.z);
                                if (v2.x > 0)
                                    closestPoint.setX(box2.min.x);
                                else
                                    closestPoint.setX(box2.max.x);
                                pointFound = true;
                            } else {
                                var r = Math.abs(v2.x) / Math.abs(v2.y);

                                box2.getSize(v1);

                                //box with zero size
                                if (v1.y === 0)
                                    continue;

                                var rBox = Math.abs(v1.x) / Math.abs(v1.y);

                                if (r > rBox) {
                                    isVertical = true;
                                    var cp = (v2.x > 0) ? spt.ThreeJs.utils.GetClosestPointToLine(v1.set(box2.min.x, box2.min.y, box2.min.z), v2.set(box2.min.x, box2.max.y, box2.min.z), rp) : spt.ThreeJs.utils.GetClosestPointToLine(v1.set(box2.max.x, box2.min.y, box2.min.z), v2.set(box2.max.x, box2.max.y, box2.min.z), rp);
                                    closestPoint.copy(cp.p);
                                    pointFound = true;
                                } else {
                                    isVertical = false;
                                    var cp = (v2.y > 0) ? spt.ThreeJs.utils.GetClosestPointToLine(v1.set(box2.min.x, box2.min.y, box2.min.z), v2.set(box2.max.x, box2.min.y, box2.min.z), rp) : spt.ThreeJs.utils.GetClosestPointToLine(v1.set(box2.min.x, box2.max.y, box2.min.z), v2.set(box2.max.x, box2.max.y, box2.min.z), rp);
                                    closestPoint.copy(cp.p);
                                    pointFound = true;
                                }
                            }
                        } else {
                            isVertical = lineMode === modeVertical;
                            closestPoint.copy(v1).setZ(box2.min.z);
                            pointFound = true;
                        }
                    }
                }
            }

            var da = directedAreaHolder.getClosestByPosition(rp, tolerance);
            if (da && da.distance < distance && da.distance <= toleranceSq) {
                closestPoint.copy(da.point);
                pointFound = true;
            }

            if (!pointFound) {
                if (isFree) {
                    closestPoint.copy(viewModel.roofPosition);
                    if (this.step === 1 && viewModel.shiftDown)
                        spt.ThreeJs.utils.ProjectVectorToClosestAxis(closestPoint.sub(freeLine.position), 1).add(freeLine.position);
                    pointFound = true;
                } else if (lineMode !== modeAuto) {
                    isVertical = lineMode === modeVertical;
                    closestPoint.copy(viewModel.roofPosition);
                    pointFound = true;
                }
            }

            this.isVertical = isVertical && !isFree;

            if (pointFound) {
                if (isFree) {
                    freeLine.visible = true;
                    vLine.visible = false;
                    hLine.visible = false;

                    if (this.step === 0)
                        freeLine.setPosition(closestPoint, closestPoint);
                    else
                        freeLine.setTarget(closestPoint);
                } else if (isVertical) {
                    freeLine.visible = false;
                    vLine.visible = true;
                    hLine.visible = false;

                    vLine.setPosition(closestPoint);
                } else {
                    freeLine.visible = false;
                    vLine.visible = false;
                    hLine.visible = true;

                    hLine.setPosition(closestPoint);
                }

                pointingHelper.position.copy(closestPoint);
            } else {
                freeLine.visible = false;
                vLine.visible = false;
                hLine.visible = false;
            }

            if (!pointingHelper.visible)
                pointingHelper.visible = true;

            return false;
        }

        onMouseDown(viewModel: ViewModel) {
            var controller = Controller.Current,
                pointingHelper = controller.pointingHelper,
                linesHelper = controller.linesHelper;

            if (this.lineDeleteMode && linesHelper.hoverLine) {
                linesHelper.hoverLine.removeInstance();
                controller.viewModel.Cursor = this.cursor;
            }

            if (pointingHelper.visible) {
                if (this.lineMode === this.lineModeFree) {
                    if (this.step === 0) {
                        this.step = 1;
                    } else {
                        if (!this.freeLine.position.equals(this.freeLine.target)) {
                            let newLine = new HelperLine(false, this.freeLine.position, this.freeLine.target);

                            if (!linesHelper.hasLine(newLine))
                                linesHelper.addLine(newLine);
                        }

                        pointingHelper.visible = false;

                        this.step = 0;
                    }
                } else {
                    let newLine = new HelperLine(this.isVertical, this.closestPoint);

                    if (!linesHelper.hasLine(newLine))
                        linesHelper.addLine(newLine);

                    pointingHelper.visible = false;
                }
            }
            return false;
        }

        onKeyDown(viewModel: ViewModel) {
            if (viewModel.keyPressed(27)) { //esc
                var controller = Controller.Current,
                    pointingHelper = controller.pointingHelper;

                if (this.lineMode === this.lineModeFree) {
                    if (this.step === 1) {

                        var pos = new THREE.Vector3().copy(viewModel.roofPosition);
                        this.freeLine.setPosition(pos, pos);

                        pointingHelper.visible = false;

                        this.step = 0;

                        return true;
                    }
                }

                //viewModel.deselectTool();
                //return true;
            }
            return false;
        }
    }

    export class RectInsert extends BaseTool {

        cursor = 'crosshair';
        orientation: number;
        ModCount: number;
        target: string;
        typeId: string;
        tempColors: number[];
        canSelect: boolean;
        isSelecting: boolean;

        private _v1 = new THREE.Vector3();

        onSelect(viewModel: ViewModel) {
            if (!viewModel.selectedInsertable) {
                viewModel.deselectTool();
                return;
            }
            var controller = Controller.Current,
                selectionHelper = controller.instanceSelectionHelper,
                clientObject = viewModel.selectedInsertable;

            selectionHelper.visible = false;
            this.target = clientObject.dataType;
            this.orientation = clientObject.userData.Orientation;
            this.ModCount = clientObject.userData.ModCount;
            this.typeId = clientObject.userData.TypeId;

            if (!this.target) {
                viewModel.deselectTool();
                return;
            }

            var tempColors: number[] = [];
            for (var i = 0, l = selectionHelper.children.length; i < l; i++) {
                var mat = (selectionHelper.children[i] as THREE.Mesh).material as THREE.MeshBasicMaterial;
                tempColors.push(mat.color.getHex());
                var hsl = mat.color.getHSL({ h: 0, s: 0, l: 0 });
                mat.color.setHSL(0, hsl.s, hsl.l);
                mat.needsUpdate = true;
            }
            this.tempColors = tempColors;

            viewModel.deselectAll();
        }

        onDeselect() {
            var controller = Controller.Current,
                selectionHelper = controller.instanceSelectionHelper,
                pointingHelper = controller.pointingHelper;
            var tempColors = this.tempColors;
            selectionHelper.visible = false;
            pointingHelper.visible = false;
            if (tempColors) {
                for (var i = 0, l = selectionHelper.children.length; i < l; i++) {
                    var mat = (selectionHelper.children[i] as THREE.Mesh).material as THREE.MeshBasicMaterial;
                    mat.color.setHex(tempColors[i]);
                    mat.needsUpdate = true;
                }
            }
        }

        onMouseMove(viewModel: ViewModel) {
            var controller = Controller.Current,
                selectionHelper = controller.instanceSelectionHelper,
                pointingHelper = controller.pointingHelper,
                _v1 = this._v1;
            if (!pointingHelper.visible)
                pointingHelper.visible = true;

            if (this.canSelect && viewModel.mouseDown && viewModel.diffViewPosition.lengthSq() >= 100) {
                _v1.copy(viewModel.startRoofPosition);
                if (viewModel.enableSnapping && viewModel.canSnap) {
                    var sp = controller.linesHelper.GetSnapWithPoint(_v1, controller.ClickTolerance / controller.orthoCamera.zoom);
                    if (sp)
                        _v1.add(sp);
                }

                selectionHelper.setCorner1(_v1);
                selectionHelper.visible = true;
                this.isSelecting = true;
                this.canSelect = false;
            }

            _v1.copy(viewModel.roofPosition);
            if (viewModel.enableSnapping && viewModel.canSnap) {
                var sp = controller.linesHelper.GetSnapWithPoint(_v1, controller.ClickTolerance / controller.orthoCamera.zoom);
                if (sp)
                    _v1.add(sp);
            }
            pointingHelper.position.copy(_v1);

            if (this.isSelecting)
                selectionHelper.setCorner2(_v1);
            return false;
        }

        onMouseDown(viewModel: ViewModel) {
            this.isSelecting = false;
            this.canSelect = !viewModel.keyPressed(32); //spacebar
            return false;
        }

        onMouseUp(viewModel: ViewModel) {
            if (this.isSelecting) {
                var controller = Controller.Current,
                    selectionHelper = controller.instanceSelectionHelper;
                selectionHelper.visible = false;
                var box = new THREE.Box3().setFromPoints([selectionHelper.corner1, selectionHelper.corner2]);
                if (box.max.x - box.min.x > 0 && box.max.y - box.min.y > 0) {
                    SolarProTool.Ajax("WebServices/Anordnung3DService.asmx").Call("InsertClientObjectByRect").Data({ x1: box.min.x, y1: box.min.y, x2: box.max.x, y2: box.max.y, orientation: this.orientation, typeString: this.target, typeId: this.typeId || null, disableSnapping: !!Controller.Current.viewModel.snappingDeactivated }).CallBack(result => {
                        controller.updateManager.clearHistory();
                        controller.clearClientObjects();
                        if (result) {
                            var clientObjects = result.ClientObects,
                                changes = result.Changes;

                            if (clientObjects && clientObjects.length)
                                controller.addClientObjects(clientObjects);

                            controller.updateManager.handleModifiedChanges(changes, false);
                        }
                    }, () => {
                        DManager.showErrorMessage("Error on sending changes to the server.");
                    });
                }
            }
            this.isSelecting = false;

            return false;
        }
    }

    export class SwitchTransparenceAll extends BaseTool {

        isSwitchOn = false;

        onSelect(viewModel: ViewModel) {
            console.log("SwitchTransparenceAll onSelect!!!");
            Controller.Current.switchTransparenceAll();
            this.isSwitchOn = !this.isSwitchOn;
            //if (!this.isSwitchOn)
            //    viewModel.setTool(null);
            viewModel.deselectTool();
            //viewModel.deselectAll();
        }

        onDeselect(viewModel: ViewModel) {
            console.log("SwitchTransparenceAll DESELECT!!!");
        }

        //onMouseMove(viewModel: ViewModel) {
        //    return false;
        //}

        //onMouseUp(viewModel: ViewModel) {
        //    console.log("SwitchTransparenceAll");
        //    return false;
        //}
    }

    export class ChangeInstanceType extends BaseTool {

        onSelect(viewModel: ViewModel, params: { newType?: string }) {
            var controller = Controller.Current;
            if (params && params.newType && typeof params.newType == "string") {

                var newType = params.newType,
                    selectedIds = viewModel.selected.map((o) => {
                        return o.instanceData.Id;
                    });
                if (selectedIds.length) {
                    LoaderManager.addLoadingJob();
                    AManager.Ajax('Anordnung3DService.asmx', 'ChangeModuleObjectTypes', { idStrings: selectedIds, newType: newType }, (clientObjects: SolarProTool.Client3DObjectHolder) => {
                        controller.setClientObjects(clientObjects, true);
                        LoaderManager.finishLoadingJob();
                    }, () => {
                        DManager.showErrorMessage("Error on sending changes to the server.");
                        LoaderManager.finishLoadingJob();
                    });
                }
            }

            viewModel.deselectTool();
        }

        onDeselect(viewModel: ViewModel) {

        }
    }

    export class ChangeStaticSelected extends BaseTool {

        onSelect(viewModel: ViewModel, params: { set?: string }) {

            Controller.Current.switchStaticSelected(params && params.set == "1");

            viewModel.deselectTool();
        }

        onDeselect(viewModel: ViewModel) {

        }
    }

    export class ChangeTileType extends BaseTool {

        onSelect(viewModel: ViewModel) {

        }

        onDeselect(viewModel: ViewModel) {

        }

        onMouseUp(viewModel: ViewModel) {
            if (viewModel.selectedTool === 'changeTileType')
                viewModel.deselectTool();
            return false;
        }

        changeTile = (newType: string) => {
            var controller = Controller.Current,
                viewModel = controller.viewModel;

            var selectedIds = viewModel.selected.map((o) => {
                return o.instanceData.Id;
            });

            AManager.AjaxWithLoading('Anordnung3DService.asmx', 'ChangeModuleObjectTypes', { idStrings: selectedIds, newType: newType }, (clientObjects: SolarProTool.Client3DObjectHolder) => {
                if (clientObjects)
                    controller.setClientObjects(clientObjects, true);
            }, () => {
                DManager.showErrorMessage("Error on sending changes to the server.");
            });

            viewModel.deselectTool();
        };
    }

    export class InsertSingle extends BaseTool {
        previewObject3d: THREE.Object3D = null;
        previewBounds = new THREE.Box3();
        center = new THREE.Vector3();
        localPosition = new THREE.Vector3();
        clientObjectPositions: { clientObject: ClientObject, positions: THREE.Vector3[], scales: THREE.Vector3[], colors: THREE.Color[] }[] = null;
        scaleInsert: boolean = false;
        scaleX: boolean = false;
        scaleY: boolean = false;
        savedPosition = new THREE.Vector3();
        savedCenter = new THREE.Vector3();
        step = 0;
        //target: string;
        //typeId: string;
        //orientation: number;
        //modCount: number;
        //id: number;
        initialized: boolean;
        canInsert: boolean;
        //options: number;

        onSelect(viewModel: ViewModel, params: { multiple?: string }) {
            var isMultiple = params && params.multiple == "1";

            var cl: { [id: string]: { clientObject: ClientObject, positions: THREE.Vector3[], scales: THREE.Vector3[], colors: THREE.Color[] } } = {},
                colorNeutral = new THREE.Color(0);
            this.scaleInsert = false;
            if (isMultiple) {

                if (!viewModel.selected.length) {
                    viewModel.deselectTool();
                    return;
                }

                viewModel.selected.forEach(sel => {
                    if (!sel._isDisposed && sel.clientObject && sel.clientObject.dataType) {
                        var co = sel.clientObject;

                        if (!cl[co.id])
                            cl[co.id] = { clientObject: co, positions: [], scales: [], colors: [] };

                        cl[co.id].positions.push(sel.position);
                        cl[co.id].scales.push(sel.scale);
                        if (sel.instanceData.InstanceColor)
                            cl[co.id].colors.push(new THREE.Color().setHex(sel.instanceData.InstanceColor).multiplyScalar(2).addScalar(-1));
                        else
                            cl[co.id].colors.push(colorNeutral);
                    }
                });
            } else {
                if (!viewModel.selectedInsertable) {
                    viewModel.deselectTool();
                    return;
                }
                var co = viewModel.selectedInsertable;
                var col = co.dataType.indexOf("RailObject") != -1 ? new THREE.Color(1.25, -0.75, -0.75) : colorNeutral;
                cl[co.id] = { clientObject: co, positions: [new THREE.Vector3()], scales: [new THREE.Vector3(1, 1, 1)], colors: [col] };
                var scaleInsert = this.scaleInsert = co.canScaleInsertInstances;
                if (scaleInsert) {
                    this.scaleX = co.canBeScaledX;
                    this.scaleY = co.canBeScaledY;
                }
            }

            //var clientObject = viewModel.selectedInsertable;
            //var controller = Controller.Current;

            this.clientObjectPositions = Object.keys(cl).map(k => cl[k]);

            if (!this.clientObjectPositions.length || this.clientObjectPositions.every(clp => !clp.positions.length)) {
                viewModel.deselectTool();
                viewModel.selectedInsertable = null;
                return;
            }

            //this.target = clientObject.dataType;
            //this.orientation = clientObject.userData.Orientation;
            //this.modCount = clientObject.userData.ModCount;
            //this.id = clientObject.id;
            //this.options = clientObject.userData ? clientObject.userOptions : 0;
            //this.typeId = clientObject.userData.TypeId;

            //if (!clientObject.dataType || !controller.Objects[clientObject.id]) {
            //    viewModel.deselectTool();
            //    viewModel.selectedInsertable = null;
            //    return;
            //}

            this.initialized = false;
            this.canInsert = false;
            this.step = 0;
        }

        buildPreview(viewModel: ViewModel) {
            var bounds = this.previewBounds,
                boundingSphere: THREE.Sphere = new THREE.Sphere(),
                clientObjectPositions = this.clientObjectPositions,
                previewObject3D = this.previewObject3d = (this.previewObject3d || new THREE.Object3D());

            bounds.makeEmpty();

            //var isMultiple = clientObjectPositions.length > 1 || clientObjectPositions.some(clp => clp.positions.length > 1);

            clientObjectPositions.forEach(clp => {
                var clientObject = clp.clientObject,
                    sharedMeshes = clientObject.SharedMeshes,
                    positions = clp.positions,
                    clScales = clp.scales,
                    clColors = clp.colors,
                    maxInstancedCount = positions.length,
                    posBounds = new THREE.Box3(),
                    geometryBounds = new THREE.Box3();

                if (!positions.length)
                    return;

                var hasScale = (clientObject.userOptions & Client3DObectOptions.ScaleX) !== 0 || (clientObject.userOptions & Client3DObectOptions.ScaleY) !== 0;

                var offsets = new THREE.InstancedBufferAttribute(new Float32Array(maxInstancedCount * 3), 3, false, 1),
                    scales = hasScale ? new THREE.InstancedBufferAttribute(new Float32Array(maxInstancedCount * 3), 3, false, 1) : null,
                    colors = new THREE.InstancedBufferAttribute(new Float32Array(maxInstancedCount * 4), 4, false, 1);

                var maxScale = 1;
                var instanceIndex = 0;

                colors.needsUpdate = true;
                offsets.needsUpdate = true;
                if (scales) {
                    scales.needsUpdate = true;

                    for (var i = maxInstancedCount; i--;) {
                        var pos = positions[i],
                            scale = clScales[i],
                            col = clColors[i];

                        maxScale = Math.max(maxScale, scale.x, scale.y);
                        posBounds.expandByPoint(pos);
                        offsets.setXYZ(instanceIndex, pos.x, pos.y, pos.z);
                        scales.setXYZ(instanceIndex, scale.x, scale.y, scale.z);
                        colors.setXYZW(instanceIndex, col.r, col.g, col.b, 1);
                        ++instanceIndex;
                    }
                } else {
                    for (var i = maxInstancedCount; i--;) {
                        var pos = positions[i],
                            col = clColors[i];

                        posBounds.expandByPoint(pos);
                        offsets.setXYZ(instanceIndex, pos.x, pos.y, pos.z);
                        colors.setXYZW(instanceIndex, col.r, col.g, col.b, 1);
                        ++instanceIndex;
                    }
                }

                for (var j = sharedMeshes.length; j--;) {
                    var sharedMesh = sharedMeshes[j],
                        sharedMeshGeometry = sharedMesh.geometry instanceof THREE.Geometry ? (new THREE.BufferGeometry()).fromGeometry(sharedMesh.geometry) : (new THREE.BufferGeometry()).copy(sharedMesh.geometry);

                    sharedMeshGeometry.applyMatrix4(sharedMesh.matrix);

                    if (sharedMeshGeometry.boundingBox === null)
                        sharedMeshGeometry.computeBoundingBox();

                    if (sharedMeshGeometry.boundingSphere === null)
                        sharedMeshGeometry.computeBoundingSphere();

                    geometryBounds.union(sharedMeshGeometry.boundingBox);

                    var buildGeometry = (new THREE.InstancedBufferGeometry()).copy(sharedMeshGeometry as THREE.InstancedBufferGeometry);
                    buildGeometry.maxInstancedCount = maxInstancedCount;
                    buildGeometry.setAttribute('offseti', offsets);
                    buildGeometry.setAttribute('colori', colors);
                    if (scales)
                        buildGeometry.setAttribute('scalei', scales);

                    var mesh = new THREE.Mesh(buildGeometry, sharedMesh.material);

                    if (sharedMesh.renderOrder)
                        mesh.renderOrder = sharedMesh.renderOrder;
                    previewObject3D.add(mesh);
                }

                if (positions.length > 1) {
                    posBounds.expandByPoint(geometryBounds.min.clone().multiplyScalar(maxScale).add(posBounds.min));
                    posBounds.expandByPoint(geometryBounds.max.clone().multiplyScalar(maxScale).add(posBounds.max));
                    bounds.union(posBounds);
                } else {
                    bounds.expandByPoint(geometryBounds.min.clone().multiplyScalar(maxScale).add(positions[0]));
                    bounds.expandByPoint(geometryBounds.max.clone().multiplyScalar(maxScale).add(positions[0]));
                }
            });

            bounds.getBoundingSphere(boundingSphere);

            previewObject3D.children.forEach(child => {
                if (child instanceof THREE.Mesh) {
                    var geometry = child.geometry;
                    geometry.boundingSphere = boundingSphere;
                }
            });

            bounds.getCenter(this.center).setZ(0);

            previewObject3D.position.copy(viewModel.roofPosition).sub(this.center);

            previewObject3D.visible = true;

            Controller.Current.viewModel.currentInstance.add(previewObject3D, true);

            this.previewObject3d = previewObject3D;

            this.canInsert = true;
            this.initialized = true;
        }

        onDeselect(viewModel: ViewModel) {
            var controller = Controller.Current,
                previewObject3D = this.previewObject3d;
            if (previewObject3D) {
                controller.viewModel.currentInstance.remove(previewObject3D, true);

                var children: THREE.Object3D[] = [];
                for (var i = 0, j = previewObject3D.children.length; i < j; i++) {
                    children.push(previewObject3D.children[i]);
                }
                for (var i = 0, j = children.length; i < j; i++) {
                    var child = children[i];
                    previewObject3D.remove(child);
                    spt.ThreeJs.utils.disposeObject3D(child, true, true, false);
                }
                previewObject3D.visible = false;
            }
            this.clientObjectPositions = null;
        }

        onMouseMove(viewModel: ViewModel) {
            var controller = Controller.Current;
            if (!this.initialized)
                this.buildPreview(viewModel);

            var previewObject3D = this.previewObject3d;

            if (this.scaleInsert && this.step === 1) {
                var rp = viewModel.roofPosition,
                    sp = this.savedPosition,
                    c = this.savedCenter.copy(rp).sub(sp).multiplyScalar(0.5),
                    scaleX = this.scaleX,
                    scaleY = this.scaleY;

                if (!scaleX)
                    c.setX(0);
                if (!scaleY)
                    c.setY(0);

                c.add(sp);

                previewObject3D.scale.set(scaleX ? Math.abs(sp.x - rp.x) : 1, scaleY ? Math.abs(sp.y - rp.y) : 1, 1);
                previewObject3D.position.copy(c).setZ(10).sub(this.center);
            } else {
                previewObject3D.scale.set(1, 1, 1);
                previewObject3D.position.copy(viewModel.roofPosition).setZ(10).sub(this.center);
            }

            if (viewModel.enableSnapping && viewModel.canSnap)
                previewObject3D.position.add(controller.linesHelper.GetSnapWithBoxOffset(this.previewBounds, previewObject3D.position, controller.ClickTolerance / controller.orthoCamera.zoom));
            return false;
        }

        onMouseDown(viewModel: ViewModel): boolean {
            if (!this.canInsert)
                return false;

            if (this.scaleInsert && this.step === 0) {
                this.savedPosition.copy(viewModel.roofPosition);
                this.step = 1;
                return;
            }
            return false;
        }

        onMouseUp(viewModel: ViewModel) {
            var previewObject3D = this.previewObject3d;

            if (!this.canInsert)
                return false;

            this.step = 0;

            if (viewModel.selected.length) {
                viewModel.deselectAll();
                ko.tasks.runEarly();
            }

            var params: SolarProTool.InsertClientObjectsParams[] = [],
                offset = previewObject3D.position,
                scale = previewObject3D.scale,
                clientObjectPositions = this.clientObjectPositions;

            clientObjectPositions.forEach(clp => {
                var clientObject = clp.clientObject,
                    param: SolarProTool.InsertClientObjectsParams = {
                        Positions: clp.positions.map(p => p.clone().add(offset).setZ(0)),
                        Scales: clp.scales.map(p => p.clone().multiply(scale).setZ(0)),
                        ModCount: clientObject.userData.ModCount,
                        Orientation: clientObject.userData.Orientation,
                        TypeString: clientObject.dataType,
                        Options: clientObject.userOptions,
                        TypeId: clientObject.userData.TypeId,
                        SourceId: clientObject.id
                    };
                params.push(param);
            });

            this.canInsert = false;

            SolarProTool.Ajax("WebServices/Anordnung3DService.asmx").Call("InsertClientObjects").Data({ clientObjectsParams: params, ctrlDown: !!viewModel.ctrlDown, disableSnapping: !!Controller.Current.viewModel.snappingDeactivated, compressData: true }).CallBack(result => {
                if (result && result.ClientObjectsDatas) {
                    result.ClientObjectsDatas.forEach(clientObjectsData => {
                        var genericClientObjectInstanceData = clientObjectsData.ClientObectData,
                            clientObject = Controller.Current.Objects[clientObjectsData.SourceId];

                        //self.handleModifiedChanges(changes, false);
                        if (clientObject && genericClientObjectInstanceData && genericClientObjectInstanceData.Id && genericClientObjectInstanceData.Id.length) {
                            IterateClientObectDataArrays(genericClientObjectInstanceData, data => { clientObject.insertClientObjectInstance(data, true); });
                            Controller.Current.updateManager.onNoError();
                        }
                    });

                    var changes: IChange[] = (result.Changes) as any;

                    if (changes && changes.length)
                        Controller.Current.updateManager.handleModifiedChanges(changes, false);
                }

                this.canInsert = true;
            }, () => {
                DManager.showErrorMessage("Error on sending changes to the server.");
                this.canInsert = true;
            });

            return false;
        }

        onKeyDown(viewModel: ViewModel) {
            if (viewModel.keyPressed(27)) { //esc
                viewModel.deselectTool();
                viewModel.selectedInsertable = null;
                return true;
            }
            return false;
        }
    }

    export class PanTool extends BaseTool {
        cursor = 'grab';

        onSelect(viewModel: ViewModel) {
            var controller = Controller.Current;
            if (!controller) {
                viewModel.deselectTool();
                return;
            }
            var controls = controller.controls;
            if (!controls) {
                viewModel.deselectTool();
                return;
            }
            controls.convertLeftMouse = controls.STATE.PAN;
        }

        onDeselect(viewModel: ViewModel) {
            var controller = Controller.Current;
            if (!controller)
                return;
            var controls = controller.controls;
            if (!controls)
                return;
            controls.convertLeftMouse = controls.STATE.NONE;
        }

        onMouseDown(viewModel: ViewModel) {
            Controller.Current.viewModel.Cursor = (<any>'grabbing');
            return false;
        }

        onMouseUp(viewModel: ViewModel) {
            Controller.Current.viewModel.Cursor = (<any>'grab');
            return false;
        }
    }

    export class RotateTool extends BaseTool {
        onSelect(viewModel: ViewModel) {
            var controller = Controller.Current;
            if (!controller) {
                viewModel.deselectTool();
                return;
            }
            var controls = controller.controls;
            if (!controls) {
                viewModel.deselectTool();
                return;
            }
            controls.convertLeftMouse = controls.STATE.ROTATE;
        }

        onDeselect(viewModel: ViewModel) {
            var controller = Controller.Current;
            if (!controller)
                return;
            var controls = controller.controls;
            if (!controls)
                return;
            controls.convertLeftMouse = controls.STATE.NONE;
        }
    }

    export class ZoomTool extends BaseTool {
        onSelect(viewModel: ViewModel) {
            var controller = Controller.Current;
            if (!controller) {
                viewModel.deselectTool();
                return;
            }
            var controls = controller.controls;
            if (!controls) {
                viewModel.deselectTool();
                return;
            }
            controls.convertLeftMouse = controls.STATE.ZOOM;
        }

        onDeselect(viewModel: ViewModel) {
            var controller = Controller.Current;
            if (!controller)
                return;
            var controls = controller.controls;
            if (!controls)
                return;
            controls.convertLeftMouse = controls.STATE.NONE;
        }
    }

    export class ParkingAreaTool extends BaseTool {
        cursor = 'crosshair';
        private _lastPosition = new THREE.Vector3();
        private _step: number = 0;
        private _directedArea: spt.ThreeJs.utils.DirectedAreaDefinition = null;
        private _directedAreaVerticalLine: DirectedAreaVerticalLine = null;
        private _lastSubArea: number = -1;
        mode = 0;
        static ModeEdit = 0;
        static ModeMeasure = 1;
        static ModeToggleAreas = 2;
        static ModeExcludeIncludeAreas = 3;

        onSelect(viewModel: ViewModel, params: { mode?: string }) {
            var controller = Controller.Current,
                pointingHelper = controller.pointingHelper,
                directedAreaHolder = controller.directedAreaHolder;

            pointingHelper.visible = false;

            this._directedAreaVerticalLine = null;
            this._lastSubArea = -1;

            this.mode = params && params.mode ? parseInt(params.mode) : 0;

            this.cursor = this.mode === ParkingAreaTool.ModeMeasure ? 'help' : 'crosshair';

            viewModel.deselectAll();

            directedAreaHolder.deselectAll();

            directedAreaHolder.setHoverAreaObj(null);

            this.reset();
        }

        onDeselect(viewModel: ViewModel) {
            var controller = Controller.Current,
                pointingHelper = controller.pointingHelper,
                directedAreaHolder = controller.directedAreaHolder;

            this._directedAreaVerticalLine = null;
            this._lastSubArea = -1;

            pointingHelper.visible = false;

            viewModel.deselectAll();
            directedAreaHolder.deselectAll();

            directedAreaHolder.setHoverAreaObj(null);

            this.reset();
        }

        onMouseMove(viewModel: ViewModel) {
            var controller = Controller.Current,
                segmentHighlightHelper = controller.segmentHighlightHelper,
                directedAreaHolder = controller.directedAreaHolder,
                pointingHelper = controller.pointingHelper,
                camera = controller.orthoCamera,
                tolerance = (controller.ClickTolerance / camera.zoom) * 0.5,
                linesHelper = controller.linesHelper,
                rp = this._lastPosition.copy(viewModel.roofPosition);

            controller.viewModel.Cursor = this.cursor;

            directedAreaHolder.setHoverAreaObj(null);

            if (this.mode === ParkingAreaTool.ModeToggleAreas) {

                let mObj = directedAreaHolder.getClosestByPosition(rp, tolerance * 0.5);
                if (mObj) {

                    var subArea = mObj.area.getSubAreaByIndex(mObj.area.getSubAreaIndexByPoint(rp), false, true);

                    if (subArea) {

                        var saWidth = subArea[0].distanceTo(subArea[1]);

                        controller.viewModel.Cursor = 'pointer';

                        segmentHighlightHelper.setStartEnd(subArea[0].add(subArea[1]).multiplyScalar(0.5), subArea[2].add(subArea[3]).multiplyScalar(0.5), true, saWidth, 400);

                        directedAreaHolder.setHoverAreaObj(mObj.area);
                    } else if (segmentHighlightHelper.visible)
                        segmentHighlightHelper.reset();
                } else if (segmentHighlightHelper.visible)
                    segmentHighlightHelper.reset();

                return false;
            }
            else if (this.mode === ParkingAreaTool.ModeExcludeIncludeAreas) {

                let mObj = directedAreaHolder.getClosestByPosition(rp, tolerance * 0.5);
                if (mObj) {

                    var h1 = directedAreaHolder.getHorizontalLengthByPoint(rp, mObj.area);

                    controller.viewModel.Cursor = 'pointer';

                    if (this._directedAreaVerticalLine) {
                        var h2 = this._directedAreaVerticalLine;

                        if (h2.area.IdString === mObj.area.IdString)
                            segmentHighlightHelper.setStartEnd(h1.p1.clone().add(h2.p1).multiplyScalar(0.5), h1.p2.clone().add(h2.p2).multiplyScalar(0.5), true, Math.max(400, h1.p1.distanceTo(h2.p1)), 400);
                        else if (segmentHighlightHelper.visible)
                            segmentHighlightHelper.reset();

                    } else
                        segmentHighlightHelper.setStartEnd(h1.p1, h1.p2, true, 400);

                    directedAreaHolder.setHoverAreaObj(mObj.area);

                } else if (segmentHighlightHelper.visible)
                    segmentHighlightHelper.reset();




                return false;
            }

            var snap = viewModel.shiftDown ? null : linesHelper.GetSnapWithPoint(rp, tolerance);
            if (snap) {
                rp.add(snap);
                pointingHelper.position.copy(rp);
                pointingHelper.visible = true;
            } else if (pointingHelper.visible)
                pointingHelper.visible = false;

            switch (this._step) {
                case 0:
                    {
                        let mObj = directedAreaHolder.getClosestByPosition(rp, tolerance * 0.5);
                        if (mObj) {
                            directedAreaHolder.setHoverAreaObj(mObj.area, mObj.edge, mObj.edgeIdent);

                            if (this.mode === ParkingAreaTool.ModeEdit)
                                controller.viewModel.Cursor = 'pointer';

                            segmentHighlightHelper.setStartEnd(mObj.edge[0], mObj.edge[1], true, Math.min(tolerance, Math.min(mObj.area.length, mObj.area.height) * 0.5), 400);

                            if (pointingHelper.visible)
                                pointingHelper.visible = false;

                            return false;
                        } else {
                            if (segmentHighlightHelper.visible)
                                segmentHighlightHelper.reset();
                        }
                    }
                    break;
                case 1: //set second point
                    {
                        if (this._directedArea) {
                            if (viewModel.shiftDown && linesHelper.hasLines()) {
                                var dir = rp.clone().sub(this._directedArea.start);
                                var dirl = dir.length();
                                if (dirl > 0) {
                                    dir.divideScalar(dirl);
                                    var dl = linesHelper.getByClosestDirection(dir);
                                    if (dl) {
                                        rp.copy(dl.direction);
                                        if (rp.dot(dir) < 0)
                                            rp.negate();
                                        rp.multiplyScalar(dirl).add(this._directedArea.start);
                                    }
                                }

                                //snap afterwards
                                snap = linesHelper.GetSnapWithPoint(rp, tolerance);
                                if (snap) {
                                    rp.add(snap);
                                    pointingHelper.position.copy(rp);
                                    pointingHelper.visible = true;
                                } else if (pointingHelper.visible)
                                    pointingHelper.visible = false;
                            }
                            if (this.mode === ParkingAreaTool.ModeEdit && viewModel.stepsWidth > 0) {
                                var trp = rp.sub(this._directedArea.start);
                                var curLen = trp.length();
                                var targetLen = Math.max(1, Math.round(curLen / viewModel.stepsWidth)) * viewModel.stepsWidth;
                                rp.multiplyScalar(targetLen / curLen).add(this._directedArea.start);
                            }
                            this._directedArea.setPositions(null, rp, null);
                            viewModel.measuredLength = Math.round(this._directedArea.length);
                        }
                    }
                    break;
                case 2: //expand top
                    {
                        if (this._directedArea) {
                            this._directedArea.setTop(rp);
                            if (this.mode === ParkingAreaTool.ModeEdit && viewModel.stepsHeight > 0)
                                this._directedArea.setHeight(Math.max(1, Math.round(this._directedArea.height / viewModel.stepsHeight)) * viewModel.stepsHeight);
                        }
                    }
                    break;
                case 3: //expand left
                case 6: //new subarea left
                    {
                        if (this.mode === ParkingAreaTool.ModeEdit && viewModel.stepsWidth > 0) {
                            let trp = this._directedArea.start.clone().sub(this._directedArea.end).normalize();
                            let curLen = trp.dot(rp.sub(this._directedArea.end));
                            let targetLen = Math.max(1, Math.round(curLen / viewModel.stepsWidth)) * viewModel.stepsWidth;
                            rp.copy(trp).multiplyScalar(targetLen).add(this._directedArea.end);
                        }
                        if (this._directedArea)
                            this._directedArea.setLeft(rp, this._step === 6);
                    }
                    break;
                case 4: //expand right
                case 7: //new subarea right
                    {
                        if (this.mode === ParkingAreaTool.ModeEdit && viewModel.stepsWidth > 0) {
                            let trp = this._directedArea.end.clone().sub(this._directedArea.start).normalize();
                            let curLen = trp.dot(rp.sub(this._directedArea.start));
                            let targetLen = Math.max(1, Math.round(curLen / viewModel.stepsWidth)) * viewModel.stepsWidth;
                            rp.copy(trp).multiplyScalar(targetLen).add(this._directedArea.start);
                        }
                        if (this._directedArea)
                            this._directedArea.setRight(rp, this._step === 7);
                    }
                    break;
                case 5: //expand bottom
                    {
                        if (this._directedArea)
                            this._directedArea.setBottom(rp);
                    }
                    break;
            }

            return false;
        }

        saveDirectedArea(area?: spt.ThreeJs.utils.DirectedAreaDefinition) {
            var controller = Controller.Current,
                viewModel = controller.viewModel;

            var da = area || this._directedArea;

            if (da) {

                if (da.length <= 10 || da.height <= 10) {
                    this.reset();
                    return;
                }

                da.applyFlip();

                var entrySetting = viewModel.genericParameters.getCurrentEntrySetting();
                if (entrySetting) {
                    var usrData = da.isDynamic ? entrySetting.getSettingsObject() : da.userData;

                    if (da.isDynamic) {
                        if (viewModel.stepsWidth > 0) {
                            usrData["ParkingAreaCount"] = [Math.round(100 * da.length / viewModel.stepsWidth) / 100];
                            usrData["ParkingAreaSize"] = [Math.round(viewModel.stepsWidth)];
                        }
                        if (viewModel.stepsHeight > 0)
                            usrData["ParkingAreaRows"] = [Math.round(da.height / viewModel.stepsHeight)];
                    }

                    var idString = da.IdString;

                    da.isDynamic = false;

                    var buildingParam: SolarProTool.BuildingParams = {
                        Id: idString,
                        GenericSettings: JSON.stringify(usrData),
                        BuildingClassName: controller.BuildingClassName,
                        BuildingSettingsClassName: usrData['TypeName'],
                        AreaDefinition: da.GetSimple()
                    };

                    SolarProTool.Ajax("WebServices/Anordnung3DServiceBuildings.asmx").Call('EditBuilding').Data({ buildingParam: buildingParam }).CallBack((result) => {
                        if (result) {
                            if (result.IdString !== idString)
                                controller.directedAreaHolder.removeById(idString);
                            controller.directedAreaHolder.addDirectedAreaDefinition(result);
                        }
                    });
                }
            }

            if (!area)
                this._directedArea = null;
        }

        onMouseDown(viewModel: ViewModel) {
            var controller = Controller.Current,
                segmentHighlightHelper = controller.segmentHighlightHelper,
                directedAreaHolder = controller.directedAreaHolder,
                hoverAreaObj = directedAreaHolder.hoverAreaObj;

            if (LoaderManager.isLoading())
                return;

            if (this.mode === ParkingAreaTool.ModeToggleAreas) {
                let camera = controller.orthoCamera,
                    tolerance = (controller.ClickTolerance / camera.zoom) * 0.5,
                    rp = this._lastPosition.copy(viewModel.roofPosition),
                    mObj = directedAreaHolder.getClosestByPosition(rp, tolerance * 0.5);

                if (mObj && segmentHighlightHelper.visible) {

                    var subAreaIndex = mObj.area.getSubAreaIndexByPoint(rp);

                    if (viewModel.shiftDown && this._directedArea && this._directedArea.IdString === mObj.area.IdString && this._lastSubArea >= 0) {
                        let idx1 = this._lastSubArea, idx2 = subAreaIndex;

                        if (idx1 < idx2) {
                            idx1++;
                        } else if (idx1 > idx2) {
                            idx1 = subAreaIndex;
                            idx2 = this._lastSubArea - 1;
                        }

                        for (var i = idx1; i <= idx2; i++)
                            mObj.area.addToDisabledSubAreas(i, true);

                    } else {
                        mObj.area.addToDisabledSubAreas(subAreaIndex, true);
                    }

                    this._directedArea = mObj.area;
                    this._lastSubArea = subAreaIndex;

                    mObj.area.rebuild();

                    segmentHighlightHelper.reset();

                    this.saveDirectedArea(mObj.area);
                }
                return false;
            }
            else if (this.mode === ParkingAreaTool.ModeExcludeIncludeAreas) {
                let camera = controller.orthoCamera,
                    tolerance = (controller.ClickTolerance / camera.zoom) * 0.5,
                    rp = this._lastPosition.copy(viewModel.roofPosition),
                    mObj = directedAreaHolder.getClosestByPosition(rp, tolerance * 0.5);

                if (mObj) {

                    this._directedAreaVerticalLine = directedAreaHolder.getHorizontalLengthByPoint(rp, mObj.area);

                }

                return false;
            }

            switch (this._step) {
                case 0:
                    {
                        if (this.mode === ParkingAreaTool.ModeEdit && viewModel.shiftDown && hoverAreaObj && hoverAreaObj.length > 0 && hoverAreaObj.height > 0) {

                            switch (directedAreaHolder.hoverEdgeIdent) {
                                case spt.ThreeJs.utils.DirectedAreaDefinition.EdgeBottom:

                                    break;
                                case spt.ThreeJs.utils.DirectedAreaDefinition.EdgeRight:
                                    this._step = 7;
                                    this._directedArea = hoverAreaObj;
                                    if (hoverAreaObj.subRanges.length <= 1 || hoverAreaObj.subRanges[hoverAreaObj.subRanges.length - 1] > 0)
                                        hoverAreaObj.appendSubRange(0);
                                    break;
                                case spt.ThreeJs.utils.DirectedAreaDefinition.EdgeTop:

                                    break;
                                case spt.ThreeJs.utils.DirectedAreaDefinition.EdgeLeft:
                                    this._step = 6;
                                    this._directedArea = hoverAreaObj;
                                    if (hoverAreaObj.subRanges.length <= 1 || hoverAreaObj.subRanges[0] > 0)
                                        hoverAreaObj.prependSubRange(0);
                                    break;
                            }

                            //var newStep = 2;

                            //switch (directedAreaHolder.hoverEdgeIdent) {
                            //    case spt.ThreeJs.utils.DirectedAreaDefinition.EdgeBottom:
                            //        this._directedArea = directedAreaHolder.addDirectedAreaDefinition({
                            //            start: hoverAreaObj.start,
                            //            end: hoverAreaObj.end,
                            //            dimensionPosition: hoverAreaObj.start.clone().sub(hoverAreaObj.offsetVector),
                            //            isDynamic: true,
                            //            withArrow: true,
                            //            flip: true
                            //        });
                            //        break;
                            //    case spt.ThreeJs.utils.DirectedAreaDefinition.EdgeRight:
                            //        newStep = 4;
                            //        this._directedArea = directedAreaHolder.addDirectedAreaDefinition({
                            //            start: hoverAreaObj.end,
                            //            end: hoverAreaObj.end.clone().add(hoverAreaObj.directionVector),
                            //            dimensionPosition: hoverAreaObj.end2,
                            //            isDynamic: true,
                            //            withArrow: true
                            //        });
                            //        break;
                            //    case spt.ThreeJs.utils.DirectedAreaDefinition.EdgeTop:
                            //        this._directedArea = directedAreaHolder.addDirectedAreaDefinition({
                            //            start: hoverAreaObj.start2,
                            //            end: hoverAreaObj.end2,
                            //            dimensionPosition: hoverAreaObj.start2.clone().add(hoverAreaObj.offsetVector),
                            //            isDynamic: true,
                            //            withArrow: true,
                            //            flip: true
                            //        });
                            //        break;
                            //    case spt.ThreeJs.utils.DirectedAreaDefinition.EdgeLeft:
                            //        newStep = 3;
                            //        this._directedArea = directedAreaHolder.addDirectedAreaDefinition({
                            //            start: hoverAreaObj.start.clone().sub(hoverAreaObj.directionVector),
                            //            end: hoverAreaObj.start,
                            //            dimensionPosition: hoverAreaObj.start2,
                            //            isDynamic: true,
                            //            withArrow: true
                            //        });
                            //        break;
                            //}

                            //if (this._directedArea)
                            //    this._step = newStep;
                            //else
                            //    this.reset();

                            if (!this._directedArea)
                                this.reset();

                            directedAreaHolder.setHoverAreaObj(null);

                        } else if (this.mode === ParkingAreaTool.ModeEdit && viewModel.ctrlDown && hoverAreaObj && hoverAreaObj.length > 0 && hoverAreaObj.height > 0) {
                            switch (directedAreaHolder.hoverEdgeIdent) {
                                case spt.ThreeJs.utils.DirectedAreaDefinition.EdgeBottom:
                                    this._step = 5;
                                    this._directedArea = hoverAreaObj;
                                    break;
                                case spt.ThreeJs.utils.DirectedAreaDefinition.EdgeRight:
                                    this._step = 4;
                                    this._directedArea = hoverAreaObj;
                                    break;
                                case spt.ThreeJs.utils.DirectedAreaDefinition.EdgeTop:
                                    this._step = 2;
                                    this._directedArea = hoverAreaObj;
                                    break;
                                case spt.ThreeJs.utils.DirectedAreaDefinition.EdgeLeft:
                                    this._step = 3;
                                    this._directedArea = hoverAreaObj;
                                    break;
                            }
                        } else {
                            if (this.mode === ParkingAreaTool.ModeMeasure && segmentHighlightHelper.visible) {
                                viewModel.measuredLength = Math.round(segmentHighlightHelper.start.distanceTo(segmentHighlightHelper.end));
                                return false;
                            } else
                                viewModel.measuredLength = 0;

                            if (segmentHighlightHelper.visible)
                                segmentHighlightHelper.reset();

                            directedAreaHolder.deselectAll();

                            var p = this._lastPosition;

                            this._directedArea = directedAreaHolder.addDirectedAreaDefinition({
                                start: p,
                                end: p,
                                dimensionPosition: p,
                                isDynamic: true,
                                withArrow: true,
                                flip: false,
                                userData: null
                            });

                            directedAreaHolder.setHoverAreaObj(null);

                            this._step = 1;
                        }
                    }
                    break;
                case 1:
                    {
                        if (this._directedArea && this.mode === ParkingAreaTool.ModeEdit)
                            this._step = 2;
                        else
                            this.reset();

                        directedAreaHolder.setHoverAreaObj(null);
                    }
                    break;
                case 2: //expand top
                case 3: //expand left
                case 4: //expand right
                case 5: //expand bottom
                case 6: //new subarea left
                case 7: //new subarea right
                    {
                        if (this._directedArea) {
                            this._directedArea.removeEmptySubranges();
                            this.saveDirectedArea();
                        }

                        directedAreaHolder.setHoverAreaObj(null);
                        this.reset();
                    }
                    break;
            }

            return false;
        }

        onMouseUp(viewModel: ViewModel): boolean {
            var controller = Controller.Current,
                directedAreaHolder = controller.directedAreaHolder;

            if (LoaderManager.isLoading())
                return;

            if (this.mode === ParkingAreaTool.ModeExcludeIncludeAreas && this._directedAreaVerticalLine) {

                let rp = this._lastPosition.copy(viewModel.roofPosition);

                var h = this._directedAreaVerticalLine;

                this._directedAreaVerticalLine = null;

                if (h.area.length < 400)
                    return false;

                var h2 = directedAreaHolder.getHorizontalLengthByPoint(rp, h.area);

                var t1 = Math.min(h.t, h2.t);
                var t2 = Math.max(h.t, h2.t);

                var l = t2 - t1;

                if (l < 400) {

                    let m = (t1 + t2) * 0.5;
                    t1 = m - 200;
                    t2 = m + 200;

                }

                if (t1 < 0) {
                    t2 -= t1;
                    t1 = 0;
                }

                if (t2 > h.area.length) {
                    var td = t2 - h.area.length;
                    t2 -= td;
                    t1 -= td;
                }

                if (l > h.area.length) {
                    t1 = 0;
                    t2 = h.area.length;
                }

                h.area.addInterval(t1, t2, viewModel.shiftDown);

                h.area.rebuild();

                this.saveDirectedArea(h.area);

            }

            return false;
        }

        onKeyDown(viewModel: ViewModel) {
            if (viewModel.keyPressed(27)) // esc
                this.reset();
            return false;
        }

        reset() {
            var controller = Controller.Current,
                directedAreaHolder = controller.directedAreaHolder,
                da = this._directedArea;

            if (da && this.mode !== ParkingAreaTool.ModeToggleAreas) {
                if (da.isDynamic) {
                    directedAreaHolder.remove(da);
                    da.dispose();
                } else if (da.IdString) {
                    var id = da.IdString;
                    SolarProTool.Ajax("WebServices/Anordnung3DServiceBuildings.asmx").Call('GetBuilding').Data({ id: id }).CallBack((result) => {
                        if (result) {
                            //reset area
                            controller.directedAreaHolder.addDirectedAreaDefinition(result);
                        } else {
                            //area doesn't exist
                            var t = controller.directedAreaHolder.getById(id);
                            if (t) {
                                controller.directedAreaHolder.remove(t);
                                t.dispose();
                            }
                        }
                    });
                }
            }

            this._directedArea = null;

            this._step = 0;
        }

    }

    export class RotateObjectTool extends BaseTool {

        pivot: THREE.Vector3 = new THREE.Vector3();
        start: THREE.Vector3 = new THREE.Vector3();
        end: THREE.Vector3 = new THREE.Vector3();
        currentRotationRad: number = 0;
        selUi: ISelectableUi[] = [];

        onSelect(viewModel: ViewModel) {

            var controller = Controller.Current;

            this.pivot.set(0, 0, 0);

            this.currentRotationRad = 0;

            this.selUi = viewModel.selectedUi.filter(selUi => {
                var selPivot = selUi.getPivot();
                if (selPivot) {
                    this.pivot.add(selPivot);
                    return true;
                }
                return false;
            });

            if (!viewModel.selectedUi.length || !this.selUi.length) {
                viewModel.deselectTool();
                return;
            }

            this.pivot.divideScalar(this.selUi.length);
            this.start.copy(viewModel.roofPosition);
            this.end.copy(viewModel.roofPosition);

            if (this.start.distanceToSquared(this.pivot) < 0.001) {
                viewModel.deselectTool();
                return;
            }

            controller.segmentsHelper.setLinePoints([this.pivot, this.end]);

            if (!controller.segmentsHelper.visible)
                controller.segmentsHelper.visible = true;
        }

        onDeselect(viewModel: ViewModel) {
            if (this.currentRotationRad) {
                this.selUi.forEach(sui => sui.rotateByPivot(this.pivot, -this.currentRotationRad));
                this.currentRotationRad = 0;
            }
            this.selUi = [];
        }

        onMouseMove(viewModel: ViewModel) {
            this.end.copy(viewModel.roofPosition);
            this.applyRotation();
            return false;
        }

        onMouseUp(viewModel: ViewModel) {
            this.applyRotation();
            Controller.Current.directedAreaHolder.onDirectedAreasChanged(this.selUi, false);
            this.currentRotationRad = 0;
            this.reset();
            return false;
        }

        onKeyDown(viewModel: ViewModel) {
            if (viewModel.keyPressed(27)) // esc
                this.reset();
            return false;
        }

        reset() {
            var controller = Controller.Current,
                viewModel = controller.viewModel;

            if (this.currentRotationRad && this.selUi.length) {
                this.selUi.forEach(sui => sui.rotateByPivot(this.pivot, -this.currentRotationRad));
                this.currentRotationRad = 0;
            }

            controller.segmentsHelper.setLinePoints(null);

            if (controller.segmentsHelper.visible)
                controller.segmentsHelper.visible = false;

            viewModel.deselectTool();
        }

        applyRotation() {
            var controller = Controller.Current,
                viewModel = controller.viewModel,
                segmentsHelper = controller.segmentsHelper;

            if (viewModel.selectedTool !== 'rotateObjectTool' || this.pivot.distanceToSquared(this.start) < 0.001 || this.pivot.distanceToSquared(this.end) < 0.001) {
                if (segmentsHelper.visible)
                    segmentsHelper.visible = false;
                return;
            }

            var ps = this.start.clone().sub(this.pivot);
            var pe = this.end.clone().sub(this.pivot);

            var rotRad = Math.atan2(pe.y, pe.x) - Math.atan2(ps.y, ps.x);

            var curRot = rotRad - this.currentRotationRad;

            if (curRot) {
                this.selUi.forEach(sui => sui.rotateByPivot(this.pivot, curRot));

                this.currentRotationRad = rotRad;

                if (!segmentsHelper.visible)
                    segmentsHelper.visible = true;

                segmentsHelper.setLinePoints([this.pivot, this.end]);
            }
        }
    }

}