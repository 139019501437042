module LS.Client3DEditor {

    export enum ConduitToolStep {
        default = 0,
        drawing = 1
    }

    export enum ComponentSetOptionsEnum {
        None = 0,// None
        SnapToRails = 1,// SnapToRails
        SnapToModuleBorders = 2,// SnapToModuleBorders
        SnapToElevationBorders = 4// SnapToElevationBorders
    }

    export class ConduitTool extends BaseTool {

        private _v1 = new THREE.Vector3();
        private _v2 = new THREE.Vector3();
        //private _v3 = new THREE.Vector3();
        private _q1 = new THREE.Quaternion();

        private static _up = new THREE.Vector3(0, 0, 1);
        private static _right = new THREE.Vector3(1, 0, 0);
        private _box1 = new THREE.Box3();
        //private _box2 = new THREE.Box3();

        private _targetComponent: ConduitComponent = null;
        private _octree: spt.ThreeJs.utils.Octree = null;
        private _lineMat: THREE.LineBasicMaterial = new THREE.LineBasicMaterial();

        viewModel = new ConduitViewModel();

        //lastComp: IConduitComponentIntersection<ConduitObject> = null;
        _connectionHovered: ConduitConnection = null;

        componentPool: { [id: string]: ConduitComponent[] } = {};
        tempComponents: ConduitComponent[] = [];

        componentRoot = new THREE.Object3D();

        get connectionHovered() {
            return this._connectionHovered;
        }

        set connectionHovered(v: ConduitConnection) {
            this._connectionHovered = v;
            Controller.Current.conduitObjectHolder.setHoverComponent(v && v.parent);
        }

        step: ConduitToolStep = ConduitToolStep.default;

        loadComponents(fn?: () => void) {

            var conduitViewModel = this.viewModel;

            this.clearComponentPool();

            if (!conduitViewModel.componentSets.length) {

                SolarProTool.Ajax("Areas/Electric/WebServices/ElectricServices.asmx").Call("GetConduitComponentSets").Data().CallBack((res) => {
                    conduitViewModel.componentSets.removeAll();
                    conduitViewModel.selectedComponentSet = null;
                    if (res && res.length) {
                        res.forEach(compSetData => {
                            conduitViewModel.componentSets.push(new ConduitComponentSet(compSetData));
                        });
                    }
                    if (fn)
                        fn();
                });

                //var compSet = new ConduitComponentSet(spt.Utils.GenerateGuid(), "Kabelkanal");

                //var compWidth = 300;
                //var compHeight = 50;
                //var BevelSize = 15;
                //var curvePadding = 250;

                //compSet.addLine(null, 500, compWidth, compHeight, false, BevelSize);
                //compSet.addLine(null, 750, compWidth, compHeight, false, BevelSize);
                //compSet.addLine(null, 1000, compWidth, compHeight, false, BevelSize);
                //compSet.addCurve(null, 90, curvePadding, compWidth, compHeight, true, BevelSize);
                //compSet.addCurve(null, 135, curvePadding, compWidth, compHeight, true, BevelSize);
                //compSet.addJunction(null, [0, 90, 180], curvePadding, compWidth, compHeight, BevelSize);
                //compSet.addJunction(null, [0, 270, 180], curvePadding, compWidth, compHeight, BevelSize);
                //compSet.addJunction(null, [0, 90, 270], curvePadding, compWidth, compHeight, BevelSize);
                //compSet.addJunction(null, [0, 90, 180, 270], curvePadding, compWidth, compHeight, BevelSize);

                //conduitViewModel.componentSets.push(compSet);

                //compSet = new ConduitComponentSet(spt.Utils.GenerateGuid(), "Kabelrohr");
                //compSet.optimizeCurves = false;

                //compWidth = 150;
                //compHeight = 90;
                //BevelSize = 45;
                //curvePadding = 10;

                //compSet.addLine(null, 100, compWidth, compHeight, true, BevelSize);
                //for (var i = 90; i < 180; i += 5) {
                //    compSet.addCurve(null, i, curvePadding, compWidth, compHeight, true, BevelSize);
                //}

                //conduitViewModel.componentSets.push(compSet);

            }
            else if (fn)
                fn();


        }

        reset() {
            var controller = Controller.Current,
                pointingHelper = controller.pointingHelper,
                conduitObjectHolder = controller.conduitObjectHolder;

            this.step = ConduitToolStep.default;
            this.connectionHovered = null;
            conduitObjectHolder.setHoverObject(null);
            pointingHelper.visible = false;
            this.hideTempComponents();
            this.componentPoolForeach(c => { c.visible = false; });
            this._targetComponent = null;
        }

        getPooledComponent(setting: SolarProTool.IConduitComponentSetting | SolarProTool.IConduitComponent, index = 0) {
            var componentPool = this.componentPool;

            if (!componentPool[setting.ComponentId])
                componentPool[setting.ComponentId] = [];

            var pool = componentPool[setting.ComponentId];

            while (pool.length <= index) {
                var tempComponent = new ConduitComponent(spt.Utils.GenerateGuid(), setting);
                tempComponent.visible = false;
                tempComponent.alpha = 0.7;
                tempComponent.buildMesh();
                tempComponent.updateMaterial();
                pool.push(tempComponent);
                this.componentRoot.add(tempComponent);
            }

            this.tempComponents.push(pool[index]);

            return pool[index];
        }

        insertTempComponents() {
            var conduitObjectHolder = LS.Client3DEditor.Controller.Current.conduitObjectHolder,
                selectedComponentSet = this.viewModel.selectedComponentSet,
                targetComponent = this._targetComponent;

            if (selectedComponentSet) {

                if (targetComponent && targetComponent.conduitType === ConduitComponentType.Line) {
                    this.tempComponents.forEach(tempComp => {
                        if (!targetComponent._isDisposed && !targetComponent.IsDeleted && tempComp.visible && tempComp.conduitType === ConduitComponentType.Junction)
                            this.insertJunction(tempComp, targetComponent);
                    });
                }

                this.tempComponents.forEach(tempComp => {
                    if (tempComp.visible) {
                        var compSetting = selectedComponentSet.componentSettingsById[tempComp.ComponentId];
                        if (compSetting) {
                            var newComp = compSetting.createComponent(tempComp.position, tempComp.quaternion);
                            if (tempComp.DynamicLength) {
                                newComp.setLength(tempComp.getLength());
                                newComp.isStatic = true;
                            }
                            conduitObjectHolder.insertComponent(newComp, selectedComponentSet.Id);
                        }
                    }
                });
            }

            conduitObjectHolder.checkGroups();
        }

        insertJunction(junctionComponent: ConduitComponent, lineComponent: ConduitComponent) {
            if (!junctionComponent || !lineComponent)
                return;

            var conduitViewModel = LS.Electric.InvertersModel && LS.Electric.InvertersModel.conduitViewModel;

            if (!conduitViewModel)
                return;

            var componentSet = conduitViewModel.ComponentSetById[lineComponent.ConduitObject.ComponentSetID];

            if (!componentSet)
                return;

            var lineComps: ConduitComponent[] = [lineComponent],
                r = lineComponent.Connections[1].rad,
                curLineComp = lineComponent;

            while (curLineComp) {
                var con = curLineComp.GetConnectionByAngle(r).connected;
                if (con && con.parent.conduitType === ConduitComponentType.Line) {
                    curLineComp = con.parent;
                    lineComps.push(curLineComp);
                } else
                    break;
            }

            var conEnd = lineComps[lineComps.length - 1].GetConnectionByAngle(r),
                posEnd = conEnd.getGlobalPosition(new THREE.Vector3()),
                dirEnd = conEnd.getGlobalDirection(new THREE.Vector3()),
                conNext = conEnd.connected;

            r = lineComponent.Connections[0].rad;
            curLineComp = lineComponent;

            while (curLineComp) {
                var con = curLineComp.GetConnectionByAngle(r).connected;
                if (con && con.parent.conduitType === ConduitComponentType.Line) {
                    curLineComp = con.parent;
                    lineComps.unshift(curLineComp);
                } else
                    break;
            }

            var conStart = lineComps[0].GetConnectionByAngle(r),
                posStart = conStart.getGlobalPosition(new THREE.Vector3()),
                dirStart = conStart.getGlobalDirection(new THREE.Vector3()).negate(),
                lineLength = posStart.distanceTo(posEnd),
                conPrev = conStart.connected;

            var connectionToStart = junctionComponent.getClosestConnectionTo(posStart),
                connectionToEnd = junctionComponent.getClosestConnectionTo(posEnd),
                junctionLength = connectionToStart.position.distanceTo(connectionToEnd.position);

            if (connectionToStart === connectionToEnd || junctionLength <= 0 || lineLength < junctionLength)
                return;

            lineComps.forEach(lc => lc.removeInstance());

            ko.tasks.runEarly();

            var compIndices = this.getTempCompIndices();

            var automaticComponents = componentSet.calculateComponents(posStart, connectionToStart.getGlobalPosition(), dirStart, true);
            var genComps = this.generateAutomaticComponents(automaticComponents, dirStart, posStart, compIndices);

            if (genComps.length)
                genComps[genComps.length - 1].Connections[1].alignConnection(connectionToStart);
            else if (conPrev)
                conPrev.alignConnection(connectionToStart);

            automaticComponents = componentSet.calculateComponents(connectionToEnd.getGlobalPosition(), posEnd, dirEnd, true);
            genComps = this.generateAutomaticComponents(automaticComponents, dirEnd, connectionToEnd.getGlobalPosition(), compIndices);

            if (conNext && conNext.parent) {
                var finalCon = genComps.length ? genComps[genComps.length - 1].Connections[1] : connectionToEnd,
                    translate = finalCon.getGlobalPosition(new THREE.Vector3()).sub(conNext.getGlobalPosition(new THREE.Vector3()));

                if (translate.lengthSq() >= 0.01) {
                    var nextComps = conNext.parent.GetAllConnected();
                    nextComps.forEach(nc => {
                        nc.moveBy(translate);
                    });
                }
            }

        }

        hideTempComponents() {
            this.tempComponents.splice(0, this.tempComponents.length).forEach(c => {
                c.visible = false;
            });
        }

        componentPoolForeach(fn: (c: ConduitComponent) => void) {
            var componentPool = this.componentPool;
            Object.keys(componentPool).forEach(k => {
                componentPool[k].forEach(fn);
            });
        }

        clearComponentPool() {
            this.componentPoolForeach(tempComponent => {
                if (tempComponent.parent)
                    tempComponent.parent.remove(tempComponent);
                tempComponent.dispose();
            });

            this.componentPool = {};
        }

        onSelect(viewModel: ViewModel) {
            if (!LS.Electric.InvertersModel) {
                viewModel.deselectTool();
                return;
            }

            if (!LS.Electric.InvertersModel.conduitViewModel)
                LS.Electric.InvertersModel.conduitViewModel = this.viewModel;

            LS.Electric.InvertersModel.showConduitViewModel = true;

            viewModel.currentInstance.add(this.componentRoot, true);
            viewModel.ShowInverterFlyout = true;

            if (!viewModel.FlyoutVisible)
                ToolBox.showInverterFlyout();

            this.reset();
            this.loadComponents();
            this.onViewLayerChanged(viewModel, viewModel.CurrentViewLayerName);
            this.viewModel.selectedComponentSet = null;

            var controller = Controller.Current;
            controller.segmentHighlightHelper.setVisible(false);
        }

        onDeselect(viewModel: ViewModel) {
            this.viewModel.showCables = false;
            //this.viewModel.showPolylines = false;
            ko.tasks.runEarly();

            this.reset();
            this.clearComponentPool();

            if (LS.Electric && LS.Electric.InvertersModel)
                LS.Electric.InvertersModel.showConduitViewModel = false;

            var controller = Controller.Current;

            controller.viewModel.currentInstance.remove(this.componentRoot, true);
            controller.segmentHighlightHelper.setVisible(false);
            controller.pointingHelper.visible = false;
        }

        onMouseMove(viewModel: ViewModel) {
            if (LoaderManager.isLoading()) {
                return false;
            }

            var controller = Controller.Current,
                conduitObjectHolder = controller.conduitObjectHolder,
                precision = controller.linePrecision,
                pointingHelper = controller.pointingHelper,
                selectedComponentSet = this.viewModel.selectedComponentSet,
                roofPosition = viewModel.roofPosition,
                lastRoofPosition = viewModel.startRoofPosition,
                mdistSq = viewModel.diffViewPosition.lengthSq(),
                tempComponents = this.tempComponents,
                cursor = this.cursor,
                segmentHighlightHelper = controller.segmentHighlightHelper;

            segmentHighlightHelper.visible = false;

            if(pointingHelper.visible)
                pointingHelper.visible = false;

            this._targetComponent = null;

            if (this.viewModel.mode === ConduitViewMode.cut) {
                this.hideTempComponents();

                var cmpInter = conduitObjectHolder.closestCenterByRaycaster(controller.localRaycaster, precision),
                    cmp = cmpInter && cmpInter.o;

                if (cmp && cmp.conduitType === ConduitComponentType.Line && cmp.DynamicLength && cmpInter.p) {
                    var cmpW = cmp.ComponentWidth * 0.5 + 0.4 * precision;

                    segmentHighlightHelper.setStartEnd(this._v1.set(0, -cmpW, 0).applyQuaternion(cmp.quaternion).add(cmpInter.p), this._v2.set(0, cmpW, 0).applyQuaternion(cmp.quaternion).add(cmpInter.p), true, 0.2 * precision, 0.4 * precision);
                    
                    cursor = 'pointer';
                    this._targetComponent = cmp;
                }

                controller.viewModel.Cursor = cursor;
                return false;
            }

            if (this.viewModel.mode === ConduitViewMode.replace) {
                this.hideTempComponents();

                conduitObjectHolder.getAllConduitObjects().forEach(co => { co.isHovered = false; });
                var cmpInter = conduitObjectHolder.getByRaycaster(controller.localRaycaster, precision),
                    cmp = cmpInter && cmpInter.o,
                    cmpObj = cmp && cmp.ConduitObject;
                if (cmpObj && selectedComponentSet && selectedComponentSet.ComponentGroup && cmp.ComponentGroup && selectedComponentSet.Id !== cmpObj.ComponentSetID && selectedComponentSet.ComponentGroup === cmp.ComponentGroup) {
                    cmp.isHovered = true;
                    cursor = 'pointer';
                    this._targetComponent = cmp;
                }
                controller.viewModel.Cursor = cursor;
                return false;
            }

            controller.viewModel.Cursor = cursor;

            if (this.step === ConduitToolStep.default && viewModel.mouseDown && mdistSq > 100) {
                this.step = ConduitToolStep.drawing;

                //var edge = controller.searchEdge(lastRoofPosition);
                //if (edge)
                //    lastRoofPosition.copy(spt.ThreeJs.utils.GetClosestPointToLine(edge.closestStart, edge.closestEnd, lastRoofPosition).p);
            }

            var rp = roofPosition as THREE.Vector3;

            if (selectedComponentSet) {
                var opt = ((selectedComponentSet.Options) as any) as ComponentSetOptionsEnum;
                var compSnap = this.getComponentSnap(opt);
                if (compSnap)
                    rp = new THREE.Vector3(compSnap.x, compSnap.y, roofPosition.z);
                else {
                    var snapPoint = controller.linesHelper.GetSnapWithPoint(rp, precision);
                    if (snapPoint)
                        rp = new THREE.Vector3().copy(roofPosition).add(snapPoint);
                }
            }

            if (this.step === ConduitToolStep.drawing) {
                if (selectedComponentSet) {
                    var connectionHovered = this.connectionHovered;

                    var curPos = this._v1.copy(lastRoofPosition),
                        curDir = this._v2.subVectors(rp, lastRoofPosition).normalize();

                    if (connectionHovered) {
                        connectionHovered.getGlobalPosition(curPos);
                        connectionHovered.getGlobalDirection(curDir);
                    } else if (viewModel.shiftDown)
                        spt.ThreeJs.utils.ToClosestAxis(curDir);

                    if (selectedComponentSet.selectedComponentSetting && !selectedComponentSet.selectedComponentSetting.DynamicLength) {
                        //component selected
                        var tempComponent = tempComponents.length && tempComponents[0];
                        //var tempComponent = this.getPooledComponent(selectedComponentSet.selectedComponentSetting);
                        if (tempComponent && tempComponent.visible && !connectionHovered) {

                            var curAngle = spt.ThreeJs.utils.GetCCAngleRad(ConduitTool._right, curDir),
                                curQuat = this._q1.setFromAxisAngle(ConduitTool._up, curAngle);

                            //tempComponent.Connections[0].alignParent();
                            tempComponent.quaternion.copy(curQuat);
                            //tempComponent.position.copy(roofPosition);
                        }
                    } else {
                        //automatic mode
                        this.hideTempComponents();

                        var raycaster = controller.localRaycaster,
                            connectionIntersection = conduitObjectHolder.getEndPositionByRaycaster(raycaster, precision);

                        if (connectionIntersection && (connectionIntersection.idx < 0 || !!connectionIntersection.o.Connections[connectionIntersection.idx].connected))
                            connectionIntersection = null;

                        if (connectionIntersection) {
                            var targetConnection = connectionIntersection.o.Connections[connectionIntersection.idx],
                                conPos = targetConnection.getGlobalPosition(new THREE.Vector3()),
                                targetPos = curDir.clone().multiplyScalar(curDir.dot(conPos.clone().sub(curPos))).add(curPos);
                            if (targetConnection.getGlobalDirection().dot(curDir) < -0.99 && conPos.distanceTo(targetPos) <= precision) {

                                rp = conPos;

                                pointingHelper.position.copy(connectionIntersection.o.Connections[connectionIntersection.idx].position).applyMatrix4(connectionIntersection.o.matrix);
                                pointingHelper.visible = true;
                            }
                        }

                        var automaticComponents = selectedComponentSet.calculateComponents(curPos, rp, curDir, !!selectedComponentSet.selectedComponentSetting);
                        this.generateAutomaticComponents(automaticComponents, curDir, curPos);

                    }
                }
            }
            else if (this.step === ConduitToolStep.default && !viewModel.mouseDown) {

                this.hideTempComponents();
                //this.componentPoolForeach(c => { c.visible = false; });

                var raycaster = controller.localRaycaster,
                    connectionIntersection = conduitObjectHolder.getEndPositionByRaycaster(raycaster, precision),
                    compIntersection = connectionIntersection;

                if (connectionIntersection && (connectionIntersection.idx < 0 || !!connectionIntersection.o.Connections[connectionIntersection.idx].connected))
                    connectionIntersection = null;

                if (connectionIntersection) {
                    pointingHelper.position.copy(connectionIntersection.o.Connections[connectionIntersection.idx].position).applyMatrix4(connectionIntersection.o.matrix);
                    pointingHelper.visible = true;
                }

                var connectionHovered = this.connectionHovered = connectionIntersection && connectionIntersection.o.Connections[connectionIntersection.idx];

                //if (!connectionIntersection && compIntersection && compIntersection.p && compIntersection.o.conduitType === ConduitComponentType.Line && selectedComponentSet && selectedComponentSet.selectedComponentSetting && selectedComponentSet.selectedComponentSetting.DynamicLength) {
                //    if (compIntersection.idx) {

                //    }
                //    pointingHelper.position.copy(compIntersection.p);
                //    pointingHelper.visible = true;
                //} else 
                if (selectedComponentSet && selectedComponentSet.selectedComponentSetting && !selectedComponentSet.selectedComponentSetting.DynamicLength) {
                    var tempComponent = this.getPooledComponent(selectedComponentSet.selectedComponentSetting);

                    if (connectionHovered) {
                        connectionHovered.alignComponent(tempComponent);
                        tempComponent.visible = true;
                    } else if (compIntersection && compIntersection.p && compIntersection.o.conduitType === ConduitComponentType.Line) {
                        if (tempComponent.hasConnections(0, 180)) {
                            var lineCon = compIntersection.o.GetConnectionByAngle(Math.PI);
                            lineCon.alignComponent(tempComponent);
                            //tempComponent.quaternion.set(0, 0, 0, 1);
                            tempComponent.position.copy(compIntersection.p).setZ(0);
                            this._targetComponent = compIntersection.o;
                            tempComponent.visible = true;
                        }
                    } else {
                        //tempComponent.Connections[0].alignParent();
                        tempComponent.quaternion.set(0, 0, 0, 1);
                        tempComponent.position.copy(rp);
                        tempComponent.visible = true;
                    }
                } else if (!connectionIntersection) {
                    pointingHelper.position.copy(rp);
                    pointingHelper.visible = true;

                    //if (!snapPoint) {
                    //    var edge = controller.searchEdge(rp, selectedComponentSet.ComponentSettings[0].ComponentWidth * 0.5);
                    //    if (edge)
                    //        pointingHelper.position.copy(spt.ThreeJs.utils.GetClosestPointToLine(edge.closestStart, edge.closestEnd, rp).p);
                    //}
                }

            }

            return false;
        }

        private generateAutomaticComponents(automaticComponents: { count: number, comp: ConduitComponentSettings, len: number }[], dirFrom: THREE.Vector3, vFrom: THREE.Vector3, comIndices?: { [id: string]: number }) {
            var result: ConduitComponent[] = [];

            if (!automaticComponents)
                return result;

            var curAngle = spt.ThreeJs.utils.GetCCAngleRad(ConduitTool._right, dirFrom),
                curQuat = this._q1.setFromAxisAngle(ConduitTool._up, curAngle);

            var lastCon: ConduitConnection;

            //if (curveComp) {
            //    var tComp = this.getPooledComponent(curveComp);
            //    tComp.Connections[0].alignParent();
            //    tComp.applyQuaternion(curQuat);
            //    tComp.position.applyQuaternion(curQuat).add(curPos);
            //    tComp.updateMatrix();
            //    tComp.visible = true;

            //    lastCon = tComp.Connections[1];
            //}

            if (!comIndices)
                comIndices = {};

            for (var i = 0, l = automaticComponents.length; i < l; i++) {
                var ac = automaticComponents[i],
                    comp = ac.comp,
                    count = ac.count,
                    componentId = comp.ComponentId;
                if (!comIndices[componentId])
                    comIndices[componentId] = 0;
                for (var j = 0; j < count; j++) {
                    var tComp = this.getPooledComponent(comp, comIndices[componentId]++);
                    if (tComp.DynamicLength)
                        tComp.setLength(ac.len);
                    if (lastCon) {
                        lastCon.alignConnection(tComp.Connections[0]);
                    } else {
                        tComp.Connections[0].alignParent();
                        tComp.applyQuaternion(curQuat);
                        tComp.position.applyQuaternion(curQuat).add(vFrom);
                        tComp.updateMatrix();
                    }
                    lastCon = tComp.Connections[1];

                    tComp.visible = true;

                    result.push(tComp);
                }
            }

            return result;
        }

        private getTempCompIndices(): { [id: string]: number } {
            //used for temp component pooling
            var comIndices: { [id: string]: number } = {};

            this.tempComponents.forEach(tempComp => {
                if (tempComp.visible) {
                    var componentId = tempComp.ComponentId;
                    if (!comIndices[componentId])
                        comIndices[componentId] = 1;
                    else
                        comIndices[componentId]++;
                }
            });

            return comIndices;
        }

        onMouseDown(viewModel: ViewModel) {
            var controller = Controller.Current,
                pointingHelper = controller.pointingHelper;

            if (pointingHelper.visible) {
                var startRoofPosition = viewModel.startRoofPosition;
                startRoofPosition.set(pointingHelper.position.x, pointingHelper.position.y, startRoofPosition.z);
                viewModel.roofPosition.copy(startRoofPosition);
            }

            return false;
        }

        onMouseUp(viewModel: ViewModel) {
            if (this.viewModel.mode === ConduitViewMode.cut) {
                var targetComponent = this._targetComponent,
                    segmentHighlightHelper = Controller.Current.segmentHighlightHelper;
                if (targetComponent && targetComponent.Connections && targetComponent.Connections.length === 2 && segmentHighlightHelper.visible && targetComponent.conduitType === ConduitComponentType.Line && targetComponent.DynamicLength) {
                    var cutPos = segmentHighlightHelper.start.clone().add(segmentHighlightHelper.end).multiplyScalar(0.5).setZ(0),
                        compPositions = targetComponent.Connections.map(c => c.getGlobalPosition(new THREE.Vector3())),
                        srcCmpLength = targetComponent.getLength();
                    if (srcCmpLength > 0 && compPositions.every(gp => gp.setZ(0).distanceToSquared(cutPos) > 1) && targetComponent.ConduitObject) {

                        var tp1 = compPositions[0],
                            tp2 = cutPos,
                            tp3 = compPositions[1],
                            conduitObjectHolder = Controller.Current.conduitObjectHolder,
                            targetComponentData = targetComponent.exportData(),
                            compsetId = targetComponent.ConduitObject.ComponentSetID;;

                        targetComponent.removeInstance();
                        ko.tasks.runEarly();
                        
                        var newComp1 = new ConduitComponent(spt.Utils.GenerateGuid(), targetComponentData);
                        newComp1.quaternion.copy(targetComponent.quaternion);
                        newComp1.position.copy(tp1).add(tp2).multiplyScalar(0.5);
                        newComp1.setLength(tp1.distanceTo(tp2));

                        var newComp2 = new ConduitComponent(spt.Utils.GenerateGuid(), targetComponentData);
                        newComp2.quaternion.copy(targetComponent.quaternion);
                        newComp2.position.copy(tp2).add(tp3).multiplyScalar(0.5);
                        newComp2.setLength(tp2.distanceTo(tp3));

                        conduitObjectHolder.insertComponent(newComp1, compsetId);
                        conduitObjectHolder.insertComponent(newComp2, compsetId);

                        viewModel.Cursor = this.cursor;
                    }
                }

                this._targetComponent = null;
            }

            if (this.viewModel.mode === ConduitViewMode.replace) {

                var targetComponent = this._targetComponent,
                    selectedComponentSet = this.viewModel.selectedComponentSet;

                if (targetComponent) {
                    var compSetting = selectedComponentSet.getEqualComponentSetting(targetComponent);
                    if (compSetting) {

                        var newComp = new ConduitComponent(spt.Utils.GenerateGuid(), compSetting);
                        newComp.quaternion.copy(targetComponent.quaternion);
                        newComp.position.copy(targetComponent.position);

                        if (newComp.DynamicLength)
                            newComp.setLength(targetComponent.getLength());

                        targetComponent.removeInstance();
                        ko.tasks.runEarly();

                        Controller.Current.conduitObjectHolder.insertComponent(newComp, selectedComponentSet.Id);
                    }
                }

                this._targetComponent = null;
            }

            Controller.Current.segmentHighlightHelper.setVisible(false);

            this.insertTempComponents();
            this.hideTempComponents();

            this.connectionHovered = null;

            //if (this.step === ConduitToolStep.drawing) {


            //} else if (this.step === ConduitToolStep.default) {

            //    if (selectedComponentSet && selectedComponentSet.selectedComponentSetting) {
            //        if (this.compHovered) {
            //            selectedComponentSet.selectedComponentSetting.createComponent(this.compHovered);
            //            this.compHovered = null;
            //        } else {
            //            selectedComponentSet.selectedComponentSetting.createComponent(viewModel.startRoofPosition, new THREE.Quaternion());
            //        }
            //    }
            //}

            this.step = ConduitToolStep.default;

            return false;
        }

        onKeyDown(viewModel: ViewModel) {
            if (LoaderManager.isLoading())
                return false;

            if (viewModel.keyPressed(27)) // esc
                this.reset();

            return false;
        }

        onViewLayerChanged(viewModel: ViewModel, viewLayerName: string) {
            this.viewModel.CurrentViewLayerName = viewLayerName || null;
            this.viewModel.showCables = false;
            this.viewModel.showPolylines = false;
            this.viewModel.selectedComponentSet = null;
        }

        getComponentSnap(opt: ComponentSetOptionsEnum): THREE.Vector3 {
            if (opt === ComponentSetOptionsEnum.None)
                return null;

            var octree = this._octree;

            if (octree == null) {
                //initialize octree
                octree = this._octree = new spt.ThreeJs.utils.Octree({
                    //scene: scene,
                    undeferred: false,
                    depthMax: Infinity,
                    objectsThreshold: 8,
                    overlapPct: 0.15
                });

                var clientObjects = Controller.Current.Objects;
                for (var id in clientObjects) {
                    var clientObject = clientObjects[id];
                    if (clientObject.dataType.indexOf("RailObject") !== -1) {
                        clientObject.ClientObjectInstances.forEach(ci => {
                            this.addMeshToOctree(ci, ComponentSetOptionsEnum.SnapToRails);
                        });
                    } else if (clientObject.dataType.indexOf("ModuleObject") !== -1) {
                        clientObject.ClientObjectInstances.forEach(ci => {
                            this.addMeshToOctree(ci, ComponentSetOptionsEnum.SnapToModuleBorders);
                        });
                    } else if (clientObject.userData.NumModules) {
                        //Aerodynelevation
                        clientObject.ClientObjectInstances.forEach(ci => {
                            this.addMeshToOctree(ci, ComponentSetOptionsEnum.SnapToElevationBorders);
                        });
                    }
                }

                octree.update();
            }

            var controller = Controller.Current,
                //pointingHelper = controller.pointingHelper,
                tolerance = controller.linePrecision,
                //toleranceSq = tolerance * tolerance,
                raycaster = controller.localRaycaster;

            var ray = raycaster.ray;
            var objs = octree.search(ray.origin, raycaster.far, true, ray.direction).map(m => m.object).filter(o => o.userData["option"] === opt);
            if (!objs.length)
                return null;

            var intersections = raycaster.intersectObjects(objs, false);
            if (intersections && intersections.length) {
                var minDist = Number.MAX_VALUE;
                var minP: THREE.Vector3 = null;

                //var intersecLines = intersections.map((it) => { return it.object as THREE.Line; });

                var p43 = this._v1;

                for (var i = intersections.length; i--;) {
                    var cur = intersections[i],
                        curP = cur.point,
                        d = ray.distanceSqToPoint(curP);

                    if (d < minDist) {
                        minDist = d;
                        minP = p43.copy(curP);
                    }
                }

                return minP ? minP.clone() : null;
            }

            return null;
        }

        addMeshToOctree(mesh: THREE.Mesh, opt: ComponentSetOptionsEnum) {
            var box = this._box1.copy(mesh.geometry.boundingBox).translate(mesh.position);
            if (mesh.parent)
                box.applyMatrix4(mesh.parent.matrixWorld);
            var center = box.getCenter(this._v1);

            switch (opt) {
                case ComponentSetOptionsEnum.SnapToRails:
                    var horizontal = Math.abs(box.min.x - box.max.x) > Math.abs(box.min.y - box.max.y);
                    if (horizontal)
                        this.addLineToOctree(new THREE.Vector3(box.min.x, center.y, center.z), new THREE.Vector3(box.max.x, center.y, center.z), opt);
                    else
                        this.addLineToOctree(new THREE.Vector3(center.x, box.min.y, center.z), new THREE.Vector3(center.x, box.max.y, center.z), opt);
                    break;
                default:
                    this.addLineToOctree(new THREE.Vector3(box.min.x, box.min.y, center.z), new THREE.Vector3(box.max.x, box.min.y, center.z), opt);
                    this.addLineToOctree(new THREE.Vector3(box.max.x, box.min.y, center.z), new THREE.Vector3(box.max.x, box.max.y, center.z), opt);
                    this.addLineToOctree(new THREE.Vector3(box.max.x, box.max.y, center.z), new THREE.Vector3(box.min.x, box.max.y, center.z), opt);
                    this.addLineToOctree(new THREE.Vector3(box.min.x, box.max.y, center.z), new THREE.Vector3(box.min.x, box.min.y, center.z), opt);
                    break;
            }
        }

        addLineToOctree(p1: THREE.Vector3, p2: THREE.Vector3, opt: ComponentSetOptionsEnum) {
            if (!this._octree)
                return;
            var geo = new THREE.Geometry();
            geo.vertices.push(p1, p2);
            geo.computeBoundingSphere();

            geo.boundingSphere.radius += 200;

            var line = new THREE.Line(geo, this._lineMat);
            line.userData["option"] = opt;
            this._octree.add(line);
        }
    }

    export enum ConduitViewMode {
        draw,
        replace,
        cut
    }

    export class ConduitViewModel {

        componentSets: ConduitComponentSet[] = [];
        readonly selectableComponentSets: ConduitComponentSet[];
        selectedComponentSet: ConduitComponentSet = null;
        showPolylines: boolean = false;
        showCables: boolean = false;
        mode: ConduitViewMode = ConduitViewMode.draw;
        CurrentViewLayerName: string = "Stringing";
        replaceMode: boolean;
        drawMode: boolean;
        cutMode: boolean;

        readonly ComponentSetById: { [id: string]: ConduitComponentSet };

        constructor() {
            ko.track(this);

            ko.defineProperty(this, "drawMode",
                {
                    get: () => this.mode === ConduitViewMode.draw,
                    set: (v) => { this.mode = ConduitViewMode.draw; }
                });

            ko.defineProperty(this, "replaceMode",
                {
                    get: () => this.mode === ConduitViewMode.replace,
                    set: (v) => { this.mode = ConduitViewMode.replace; }
                });

            ko.defineProperty(this, "cutMode",
                {
                    get: () => this.mode === ConduitViewMode.cut,
                    set: (v) => { this.mode = ConduitViewMode.cut; }
                });

            ko.defineProperty(this, "selectableComponentSets", () => {
                return this.componentSets.filter(cs => !this.CurrentViewLayerName || !cs.ViewLayer || cs.ViewLayer === this.CurrentViewLayerName);
            });

            ko.defineProperty(this, "ComponentSetById", () => {
                var s: { [id: string]: ConduitComponentSet } = {};
                this.componentSets.forEach(c => {
                    s[c.Id] = c;
                });
                return s;
            });

            ko.getObservable(this, "showPolylines").subscribe((v) => {
                LS.Client3DEditor.Controller.Current.conduitObjectHolder.showPolylines = !!v;
            });

            ko.getObservable(this, "showCables").subscribe((v) => {
                LS.Client3DEditor.Controller.Current.conduitObjectHolder.showCables = !!v;
            });

            ko.getObservable(this, "mode").subscribe((v) => {
                LS.Client3DEditor.Controller.Current.conduitObjectHolder.getAllConduitObjects().forEach(co => { co.isHovered = false; });
                if (LS.Client3DEditor.Controller.Current.viewModel.selectedTool === "conduitTool")
                    LS.Client3DEditor.Controller.Current.viewModel.tools.conduitTool.hideTempComponents();
            });
        }

        selectSettingClick(componentSet: ConduitComponentSet, setting?: ConduitComponentSettings) {
            this.mode = ConduitViewMode.draw;
            componentSet.selectedComponentSetting = setting || null;
        }
    }

    export class ConduitComponentSet {

        Id: string = null;
        Name: string = "";
        Options: SolarProTool.ConduitComponentSetOptionsEnum = 0;
        ComponentSettings: ConduitComponentSettings[] = [];
        selectedComponentSetting: ConduitComponentSettings = null;
        steppingCalculator: LineSteppingCalculator;
        OptimizeCurves: boolean = true;
        LengthStep: number = 0;
        readonly Color: string;
        readonly componentSettingsById: { [id: string]: ConduitComponentSettings };

        private _v1 = new THREE.Vector3();
        private _v2 = new THREE.Vector3();
        //private _v3 = new THREE.Vector3();
        //private static _up = new THREE.Vector3(0, 0, 1);
        readonly ComponentGroup: string;
        readonly ViewLayer: string;

        readonly DynamicLengthComponent: ConduitComponentSettings;
        readonly lineComponentSettings: ConduitComponentSettings[];
        readonly curveComponentSettings: ConduitComponentSettings[];
        readonly DynamicLength: boolean;

        getSteppingCalculator() {
            if (this.steppingCalculator)
                return this.steppingCalculator;

            var lineComps = this.lineComponentSettings,
                curveComps = this.curveComponentSettings;

            if (lineComps.length) {
                var steppingCalculator = this.steppingCalculator = new LineSteppingCalculator(10, 1024);
                steppingCalculator.lineSteppings = lineComps;
                if (curveComps.length)
                    steppingCalculator.curveSteppings = curveComps;
                return steppingCalculator;
            }
            return null;
        }

        calculateComponents(vFrom: THREE.Vector3, vTo: THREE.Vector3, dirFrom: THREE.Vector3, noCurves?: boolean) {
            var result: { count: number, comp: ConduitComponentSettings, len: number }[] = [];
            var steppingCalculator = this.getSteppingCalculator();
            if (!steppingCalculator)
                return result;

            var lineSteppings = steppingCalculator.lineSteppings,
                minLen = lineSteppings[0].lineLength,
                lengthStep = this.LengthStep;

            if (dirFrom && !noCurves) {
                if (this.OptimizeCurves) {
                    //get combinations
                    var cc = steppingCalculator.getCurveStepping(vFrom, vTo, dirFrom);

                    if (cc) {
                        //add lines
                        if (cc.lenFrom > minLen) {
                            if (this.DynamicLength) {
                                var ccLen = cc.lenFrom;
                                if (lengthStep > 0)
                                    ccLen = Math.round(ccLen / lengthStep) * lengthStep;
                                result.push({ count: 1, comp: this.DynamicLengthComponent, len: ccLen });
                            } else {
                                var steppings = steppingCalculator.calculateLength(cc.lenFrom);
                                if (steppings.length) {
                                    for (var i = 0, l = steppings.length; i < l; i++) {
                                        var count = steppings[i];
                                        if (count > 0)
                                            result.push({ count: count, comp: lineSteppings[i] as ConduitComponentSettings, len: cc.lenFrom });
                                    }
                                }
                            }
                        }

                        //add curve
                        result.push({ count: 1, comp: cc.curveStepping as ConduitComponentSettings, len: 0 });

                        //add lines
                        if (cc.lenTo > minLen) {
                            if (this.DynamicLength) {
                                var ccLen = cc.lenTo;
                                if (lengthStep > 0)
                                    ccLen = Math.round(ccLen / lengthStep) * lengthStep;
                                result.push({ count: 1, comp: this.DynamicLengthComponent, len: ccLen });
                            } else {
                                var steppings = steppingCalculator.calculateLength(cc.lenTo);
                                if (steppings.length) {
                                    for (var i = 0, l = steppings.length; i < l; i++) {
                                        var count = steppings[i];
                                        if (count > 0)
                                            result.push({ count: count, comp: lineSteppings[i] as ConduitComponentSettings, len: cc.lenTo });
                                    }
                                }
                            }
                        }
                    }
                } else {
                    var curve = steppingCalculator.getCurveTo(vFrom, vTo, dirFrom) as ConduitComponentSettings;

                    if (curve) {
                        //add curve at the start
                        result.push({ count: 1, comp: curve, len: 0 });

                        var tDirFrom = spt.ThreeJs.utils.RotateCC(this._v1.copy(dirFrom).negate(), curve.curveDegree * Math.PI / 180);

                        var tvFrom = this._v2.copy(dirFrom).multiplyScalar(curve.curvePaddingStart).add(vFrom);

                        //add lines
                        var dLen = Math.max(0, tDirFrom.dot(this._v2.subVectors(vTo, tvFrom)) - curve.curvePaddingEnd);

                        if (dLen >= minLen * 0.5) {
                            if (this.DynamicLength) {
                                if (lengthStep > 0)
                                    dLen = Math.round(dLen / lengthStep) * lengthStep;
                                result.push({ count: 1, comp: this.DynamicLengthComponent, len: dLen });
                            } else {
                                var steppings = steppingCalculator.calculateLength(dLen);
                                if (steppings.length) {
                                    for (var i = 0, l = steppings.length; i < l; i++) {
                                        var count = steppings[i];
                                        if (count > 0)
                                            result.push({ count: count, comp: lineSteppings[i] as ConduitComponentSettings, len: dLen });
                                    }
                                }
                            }
                        }
                    }
                }
            }

            if (!result.length && (!dirFrom || this._v1.subVectors(vTo, vFrom).dot(dirFrom) > 0)) {
                var dLen = vFrom.distanceTo(vTo);

                if (dLen >= minLen * 0.5) {
                    if (lengthStep > 0)
                        dLen = Math.round(dLen / lengthStep) * lengthStep;
                    if (this.DynamicLength) {
                        result.push({ count: 1, comp: this.DynamicLengthComponent, len: dLen });
                    } else {
                        var steppings = steppingCalculator.calculateLength(dLen);
                        if (steppings.length) {
                            for (var i = 0, l = steppings.length; i < l; i++) {
                                var count = steppings[i];
                                if (count > 0)
                                    result.push({ count: count, comp: lineSteppings[i] as ConduitComponentSettings, len: dLen });
                            }
                        }
                    }
                }
            }

            return result;
        }

        constructor(opts?: SolarProTool.IConduitComponentSet) {
            if (opts) {
                Object.keys(opts).forEach(k => {
                    if (k !== "componentSettingsById" && k !== "ComponentSettings" && k !== "selectedComponentSetting" && k !== "steppingCalculator" && !k.startsWith("_"))
                        this[k] = opts[k];
                });

                if (opts.ComponentSettings && opts.ComponentSettings.length) {

                    var minImgBounds = 0;

                    opts.ComponentSettings.forEach(compSetting => {
                        minImgBounds = Math.max(minImgBounds, ArrayHelper.Max(compSetting.Connections, c => Math.max(Math.abs(c.X), Math.abs(c.Y), Math.abs(c.Z))));
                    });

                    opts.ComponentSettings.forEach(compSetting => {
                        this.ComponentSettings.push(new ConduitComponentSettings(compSetting, minImgBounds));
                    });
                }
            }

            ko.track(this, ["ComponentSettings", "selectedComponentSetting"]);

            ko.defineProperty(this, "componentSettingsById", () => {
                var s: { [id: string]: ConduitComponentSettings } = {};
                this.ComponentSettings.forEach(c => {
                    s[c.ComponentId] = c;
                });
                return s;
            });

            ko.defineProperty(this, "lineComponentSettings", () => {
                var lines = this.ComponentSettings.filter(c => c.lineLength > 0);
                lines.sort((a, b) => a.lineLength - b.lineLength);
                return lines;
            });

            ko.defineProperty(this, "curveComponentSettings", () => {
                var curves = this.ComponentSettings.filter(c => c.curveDegree > 0);
                curves.sort((a, b) => a.curveDegree - b.curveDegree);
                return curves;
            });

            ko.defineProperty(this, "DynamicLength", () => {
                return this.ComponentSettings.some(c => c.DynamicLength);
            });

            ko.defineProperty(this, "DynamicLengthComponent", () => {
                return this.DynamicLength ? ArrayHelper.firstOrNull(this.ComponentSettings, c => c.DynamicLength) : null;
            });

            ko.defineProperty(this, "Color", () => {
                return '#' + new THREE.Color(this.ComponentSettings.length ? this.ComponentSettings[0].Color : 0x706f6f).getHexString();
            });

            ko.defineProperty(this, "ComponentGroup", () => {
                return this.ComponentSettings.length ? this.ComponentSettings[0].ComponentGroup : null;
            });

            ko.defineProperty(this, "ViewLayer", () => {
                return this.ComponentSettings.length ? this.ComponentSettings[0].ViewLayer : null;
            });
        }

        isSelected(setting?: ConduitComponentSettings) {
            return this.selectedComponentSetting === (setting || null);
        }

        addLine(name: string, length: number, width: number, height: number, dynamicLength: boolean = false, bevelSize: number = 14, idString?: string, compId?: string) {
            if (length <= 1)
                length = 1;
            var lh = length * 0.5;
            var comp = new ConduitComponentSettings({
                Connections: [
                    new ConduitConnection().setFromRotationDegreeDistance(0, lh),
                    new ConduitConnection().setFromRotationDegreeDistance(180, lh)
                ] as SolarProTool.IConduitConnection[],
                ComponentWidth: width || 300,
                ComponentHeight: height || 80,
                Name: name || ("line comp " + length),
                DynamicLength: dynamicLength,
                BevelSize: bevelSize,
                SmoothNormals: false,
                MaterialColor: 0x646e72,
                MaterialEmissive: 0,
                MaterialMetalness: 0,
                MaterialRoughness: 1,
                ComponentId: compId || spt.Utils.GenerateGuid(),
                Color: 0x646e72
            });

            this.ComponentSettings.push(comp);

            return this;
        }

        addCurve(name: string, degree: number, padding: number, width: number, height: number, createMirror: boolean = true, bevelSize: number = 14, IdString?: string, compId?: string) {
            degree = spt.ThreeJs.utils.DegreeToPositive(degree);
            if (degree === 180 || degree === 0)
                return this.addLine(name, padding * 2, width, height, false, bevelSize, IdString, compId);

            degree = Math.round(degree);
            padding = Math.round(Math.max(padding, ConduitComponentSettings.calcMinPadding(width, degree)));

            var comp = new ConduitComponentSettings({
                Connections: [
                    new ConduitConnection().setFromRotationDegreeDistance(0, padding),
                    new ConduitConnection().setFromRotationDegreeDistance(degree, padding)
                ] as SolarProTool.IConduitConnection[],
                ComponentWidth: width || 300,
                ComponentHeight: height || 80,
                Name: name || ("curve comp " + degree),
                DynamicLength: false,
                BevelSize: bevelSize,
                SmoothNormals: false,
                MaterialColor: 0x646e72,
                MaterialEmissive: 0,
                MaterialMetalness: 0,
                MaterialRoughness: 1,
                ComponentId: compId || spt.Utils.GenerateGuid(),
                Color: 0x646e72
            });

            this.ComponentSettings.push(comp);

            if (createMirror) {
                degree = 360 - degree;

                var comp = new ConduitComponentSettings({
                    Connections: [
                        new ConduitConnection().setFromRotationDegreeDistance(0, padding),
                        new ConduitConnection().setFromRotationDegreeDistance(degree, padding)
                    ] as SolarProTool.IConduitConnection[],
                    ComponentWidth: width || 300,
                    ComponentHeight: height || 80,
                    Name: name || ("curve comp " + degree),
                    DynamicLength: false,
                    BevelSize: bevelSize,
                    SmoothNormals: false,
                    MaterialColor: 0x646e72,
                    MaterialEmissive: 0,
                    MaterialMetalness: 0,
                    MaterialRoughness: 1,
                    ComponentId: compId || spt.Utils.GenerateGuid(),
                    Color: 0x646e72
                });

                this.ComponentSettings.push(comp);
            }

            return this;
        }

        addJunction(name: string, degrees: number[], padding: number, width: number, height: number, bevelSize: number = 14, IdString?: string, compId?: string) {

            if (!degrees) {
                degrees = [0];
            }

            if (!degrees.some(deg => deg === 0))
                degrees.unshift(0);

            degrees.sort((a, b) => a - b);

            var conns = degrees.map(degree => new ConduitConnection().setFromRotationDegreeDistance(spt.ThreeJs.utils.DegreeToPositive(degree), padding));

            var comp = new ConduitComponentSettings({
                Connections: conns,
                ComponentWidth: width || 300,
                ComponentHeight: height || 80,
                Name: name || (`junction comp ${degrees.length} [${degrees.join()}]`),
                DynamicLength: false,
                BevelSize: bevelSize,
                SmoothNormals: false,
                MaterialColor: 0x646e72,
                MaterialEmissive: 0,
                MaterialMetalness: 0,
                MaterialRoughness: 1,
                ComponentId: compId || spt.Utils.GenerateGuid(),
                Color: 0x646e72
            });

            this.ComponentSettings.push(comp);
        }

        getEqualComponentSetting(component: ConduitComponent) {
            var componentSettings = this.ComponentSettings;
            for (var i = componentSettings.length; i--;) {
                var compSetting = componentSettings[i];
                if (compSetting.canReplaceComponent(component))
                    return compSetting;
            }
            return null;
        }
    }

    export class ConduitComponentSettings implements SolarProTool.IConduitComponentSetting, ILineStepping, ICurveStepping {

        static CanvasDrawer: CanvasDrawing.Drawer;

        Color = 0x646e72;
        ComponentWidth = 300;
        ComponentHeight = 80;
        BevelSize = 14;
        SmoothNormals = false;
        MaterialColor = 0x646e72;
        MaterialEmissive = 0;
        MaterialMetalness = 0;
        MaterialRoughness = 1;
        ComponentId: string = null;
        Connections: ConduitConnection[] = [];
        Name: string = "";
        DisplayName: string = "";
        DynamicLength: boolean = false;
        ComponentGroup: string = null;
        ViewLayer: string = null;

        static calcMinPadding(ComponentWidth: number, degree: number) {
            return Math.tan(Math.abs(180 - spt.ThreeJs.utils.DegreeToPositive(degree)) / 180 * Math.PI * 0.5) * ComponentWidth * 0.5;
        }

        readonly lineLength: number;
        readonly curveDegree: number;
        readonly curvePaddingStart: number;
        readonly curvePaddingEnd: number;
        readonly imgSrc: string;
        minImgBounds: number = 0;

        constructor(opts?: SolarProTool.IConduitComponentSetting, minImgBounds = 0) {
            if (opts) {
                Object.keys(opts).forEach(k => {
                    if (k !== "Connections" && !k.startsWith("_") && this[k] !== undefined)
                        this[k] = opts[k];
                });

                if (opts.Connections && opts.Connections.length) {
                    opts.Connections.forEach(con => {
                        this.Connections.push(new ConduitConnection(con));
                    });
                }
            }

            if (!this.ComponentId)
                this.ComponentId = spt.Utils.GenerateGuid();

            ko.track(this, ["Connections"]);

            ko.defineProperty(this, "lineLength", () => {
                var Connections = this.Connections;
                if (Connections.length === 2 && Connections[0].RotationDegree === 0 && Math.abs(Connections[1].RotationDegree - 180) < 1)
                    return Math.abs(Connections[0].position.x - Connections[1].position.x);
                return 0;
            });

            ko.defineProperty(this, "curveDegree", () => {
                var Connections = this.Connections;
                if (Connections.length === 2 && Connections[0].RotationDegree === 0 && Math.abs(Connections[1].RotationDegree - 180) >= 1)
                    return Connections[1].RotationDegree;
                return 0;
            });

            ko.defineProperty(this, "curvePaddingStart", () => {
                var Connections = this.Connections;
                if (this.curveDegree > 0)
                    return Connections[0].position.length();
                return 0;
            });

            ko.defineProperty(this, "curvePaddingEnd", () => {
                var Connections = this.Connections;
                if (this.curveDegree > 0)
                    return Connections[1].position.length();
                return 0;
            });

            this.minImgBounds = minImgBounds;

            if (!ConduitComponentSettings.CanvasDrawer) {
                ConduitComponentSettings.CanvasDrawer = CanvasDrawing.Drawer.GetInstance(30, 30);
            }

            ko.defineProperty(this, "imgSrc", () => {
                this.draw(ConduitComponentSettings.CanvasDrawer);
                return ConduitComponentSettings.CanvasDrawer.toDataURL();
            });
        }

        createComponent(p: THREE.Vector3, q: THREE.Quaternion) {
            var component = new ConduitComponent(spt.Utils.GenerateGuid(), this);
            component.position.copy(p);
            component.quaternion.copy(q);
            return component;
        }

        draw(drawer: CanvasDrawing.Drawer) {
            var style = new CanvasDrawing.Style(new MapDrawing.Color().setHex(this.Color)),
                width = this.ComponentWidth,
                shapes: CanvasDrawing.IShape[] = [],
                center = new MapDrawing.Point2D(0, 0),
                circle = new CanvasDrawing.ShapeCircle(center, width * 0.5, style),
                bw = Math.max(width, this.minImgBounds),
                bds = MapDrawing.Bounds2D.FromCenterSize(center, bw, bw),
                minLen = circle.radius + 10;

            this.Connections.forEach(c => {
                var p = new MapDrawing.Point2D(c.position.x, c.position.y);
                var d = p.sub(center);
                var len = d.GetLength();
                if (len < minLen)
                    p = center.add(d.mul(minLen / len));

                var l = new CanvasDrawing.ShapeLine(center, p, width, style);
                shapes.push(l);
                bds.updateFromBounds(l.bounds);
            });

            if (circle)
                shapes.push(circle);

            bds.expandByScalar(10);
            drawer.viewport.bounds = bds;
            drawer.model.children = shapes;
            drawer.draw();
        }

        canReplace(connections: SolarProTool.IConduitConnection[]) {
            var cons = this.Connections;

            if (!cons || !connections || !cons.length || !connections.length || cons.length !== connections.length)
                return false;

            if (this.DynamicLength)
                return cons.every((c, i) => Math.abs(c.RotationDegree - connections[i].RotationDegree) < 0.001);

            return cons.every((c, i) => c.isEqualTo(connections[i]));
        }

        canReplaceComponent(component: ConduitComponent) {
            return component && this.DynamicLength === component.DynamicLength && this.canReplace(component.Connections);
        }
    }

    export enum ConduitComponentType {
        Any = 0,
        Line = 1,
        Curve = 2,
        Junction = 3
    }

    export class ConduitConnection implements SolarProTool.IConduitConnection {

        readonly position = new THREE.Vector3();
        readonly direction = new THREE.Vector3(1, 0, 0);
        private _v1 = new THREE.Vector3();
        private _q1 = new THREE.Quaternion();

        get X() {
            return this.position.x;
        }

        set X(val: number) {
            this.position.x = val;
        }

        get Y() {
            return this.position.y;
        }

        set Y(val: number) {
            this.position.y = val;
        }

        get Z() {
            return this.position.z;
        }

        set Z(val: number) {
            this.position.z = val;
        }

        //rotation in degree (counterclockwise). 0 points to the left.
        RotationDegree: number;

        //rotation in radian (counterclockwise). 0 points to the left.
        rad: number;

        readonly quaternion = new THREE.Quaternion();

        connected: ConduitConnection = null;

        parent: ConduitComponent = null;

        getGlobalPosition(target?: THREE.Vector3) {
            return (target || this._v1).copy(this.position).setZ(0).applyMatrix4(this.parent.matrix);
        }

        getGlobalQuaternion(target?: THREE.Quaternion) {
            return (target || this._q1).multiplyQuaternions(this.parent.quaternion, this.quaternion);
        }

        getGlobalDirection(target?: THREE.Vector3) {
            return (target || this._v1).copy(this.direction).applyQuaternion(this.parent.quaternion);
        }

        applyTo(component: ConduitComponent) {
            this.getGlobalPosition(component.position);
            this.getGlobalQuaternion(component.quaternion);
        }

        alignParent() {
            var parent = this.parent;
            parent.quaternion.setFromAxisAngle(ConduitComponent.upVector, -this.rad);
            parent.position.copy(this.position).setZ(0).applyQuaternion(parent.quaternion).negate();
        }

        alignObject(o: THREE.Object3D) {
            this.getGlobalPosition(o.position);
            this.getGlobalQuaternion(o.quaternion);

            o.updateMatrix();
        }

        alignComponent(c: ConduitComponent) {
            var con = c.getNextEmptyConnection(0);
            if (con)
                this.alignConnection(con);
        }

        alignConnection(con: ConduitConnection) {
            var connectedComponent = con.parent,
                q = this.getGlobalQuaternion();

            con.alignParent();

            connectedComponent.applyQuaternion(q);
            connectedComponent.position.applyQuaternion(q).add(this.getGlobalPosition());

            connectedComponent.updateMatrix();
        }

        angleTo(other: ConduitConnection) {
            return this.direction.angleTo(other.direction);
        }

        canConnect(other: ConduitConnection, toleranceSq = 1) {
            return (!this.connected && !other.connected && this.getGlobalDirection().dot(other.getGlobalDirection()) < -0.99 && this.getGlobalPosition().distanceToSquared(other.getGlobalPosition()) < toleranceSq);
        }

        tryConnect(other: ConduitConnection, toleranceSq?: number) {
            if (this.canConnect(other, toleranceSq)) {
                this.connect(other);
                return true;
            }
            return false;
        }

        tryAlign(other: ConduitConnection, toleranceSq?: number) {
            if (this.canConnect(other, toleranceSq)) {
                this.alignConnection(other);
                return true;
            }
            return false;
        }

        connect(con: ConduitConnection, moveOther?: boolean) {

            if (moveOther)
                this.alignConnection(con);

            this.connected = con;
            con.connected = this;
        }

        disconnect() {
            if (this.connected) {
                this.connected.connected = null;
                this.connected = null;
            }
        }

        setFromRotationDegreeDistance(rotationDegree: number, distance: number, z?: number) {
            this.RotationDegree = rotationDegree || 0;
            this.rad = rotationDegree * Math.PI / 180;
            spt.ThreeJs.utils.RotateCC(this.position.set(-distance, 0, z || 0), this.rad);
            spt.ThreeJs.utils.RotateCC(this.direction.set(-1, 0, 0), this.rad);
            this.quaternion.setFromAxisAngle(ConduitComponent.upVector, this.rad + Math.PI);
            return this;
        }

        setPosition(pos: THREE.Vector3) {
            this.position.set(pos.x, pos.y, this.position.z);
            return this;
        }

        translate(v: THREE.Vector3) {
            this.position.set(this.position.x + v.x, this.position.y + v.y, this.position.z);
            return this;
        }

        clone() {
            return new ConduitConnection(this);
        }

        getObjectHolder(): ConduitObjectHolder {
            return LS.Client3DEditor.Controller.Current.conduitObjectHolder;
        }

        isEqualTo(other: SolarProTool.IConduitConnection) {
            return Math.abs(this.X - other.X) < 0.001 &&
                Math.abs(this.Y - other.Y) < 0.001 &&
                Math.abs(this.Z - other.Z) < 0.001 &&
                Math.abs(this.RotationDegree - other.RotationDegree) < 0.001;
        }

        constructor(opts?: SolarProTool.IConduitConnection) {
            if (opts) {
                this.position.set(opts.X, opts.Y, opts.Z);
                this.RotationDegree = opts.RotationDegree || 0;
            } else
                this.RotationDegree = 0;

            this.rad = this.RotationDegree * Math.PI / 180;
            this.quaternion.setFromAxisAngle(ConduitComponent.upVector, this.rad + Math.PI);
            this.direction.applyQuaternion(this.quaternion);
        }

        exportData(): SolarProTool.IConduitConnection {
            return {
                X: this.X,
                Y: this.Y,
                Z: this.Z,
                RotationDegree: this.RotationDegree
            };
        }
    }

    export class ConduitComponent extends THREE.Object3D implements SolarProTool.IConduitComponent, IGenericObjectInstance {

        IsActive: boolean = true;
        IsDeleted: boolean = false;
        ClientOptions: SolarProTool.Client3DObectOptions = 0;
        DrawOptions: SolarProTool.DrawOptionsEnum = 0;
        ErrorCode: SolarProTool.ErrorCodeEnum = 0;
        InstanceColor: number = 0;
        IsInErrorState: boolean = false;
        Width: number = 0;
        Height: number = 0;
        IconInsertIndex: number = 0;

        Color = 0x6f6f6f;
        alpha = 1;
        ComponentWidth = 300;
        ComponentHeight = 80;
        BevelSize = 14;
        SmoothNormals = false;
        MaterialColor = 0x646e72;
        MaterialEmissive = 0;
        MaterialMetalness = 0;
        MaterialRoughness = 1;
        IdString: string = null;
        ComponentId: string = null;
        _needsUpdate: boolean = true;
        ComponentGroup: string = null;
        ViewLayer: string = null;

        private _editablePolygon: EditablePolygon2D;
        private _showPolylines: boolean = false;

        get showPolylines() {
            return this._showPolylines;
        }

        set showPolylines(v: boolean) {
            if (this._showPolylines !== v) {
                this._showPolylines = v;
                this.updatePolylines();
            }
        }

        ConduitObjectId: string = null;
        Name: string = "";
        DisplayName: string = "";

        get X() {
            return this.position.x;
        }

        set X(val: number) {
            this.position.x = val;
        }

        get Y() {
            return this.position.y;
        }

        set Y(val: number) {
            this.position.y = val;
        }

        get Z() {
            return this.position.z;
        }

        set Z(val: number) {
            this.position.z = val;
        }

        SizeX: number = 1;
        SizeY: number = 1;

        get RotationDegree() {
            return this.rotation.z / Math.PI * 180;
        }

        set RotationDegree(v: number) {
            this.rotation.z = +v / 180 * Math.PI;
        }

        get ConduitObject() {
            return this.ConduitObjectId ? this.getObjectHolder().conduitObjects[this.ConduitObjectId] : null;
        }

        set ConduitObject(co: ConduitObject) {
            this.ConduitObjectId = co ? co.IdString : null;
        }

        _isDisposed: boolean = false;
        _isSelected: boolean = false;
        _isHovered: boolean = false;
        _isHidden: boolean = false;
        DynamicLength: boolean = false;
        isBuild: boolean = false;
        isStatic: boolean = false;

        get _mesh(): THREE.Mesh {
            return this.children.length && this.children[0] as THREE.Mesh;
        }

        get isSelected() {
            return this._isSelected;
        }

        set isSelected(v: boolean) {
            if (this._isSelected !== v) {
                this._isSelected = v;
                this.OnChanged();
            }
        }

        get isHovered() {
            return this._isHovered;
        }

        set isHovered(v: boolean) {
            if (this._isHovered !== v) {
                this._isHovered = v;
                this.OnChanged();
            }
        }

        get isHidden() {
            return this._isHidden;
        }

        set isHidden(v: boolean) {
            if (this._isHidden !== v) {
                this._isHidden = v;
                this.OnChanged();
            }
        }

        setLength(l: number) {
            if (this.conduitType === ConduitComponentType.Line && l > 0) {
                var Connections = this.Connections,
                    lh = l * 0.5;
                Connections[0].position.x = -lh;
                Connections[1].position.x = lh;

                this.updateConnectionPositions();

                if (this.showPolylines && this._editablePolygon) {
                    var poly = this._editablePolygon.poly;
                    poly.array[0].copy(Connections[0].position);
                    poly.array[1].copy(Connections[1].position);
                    ko.tasks.runEarly();
                    this._editablePolygon.onGeometryChanged();
                    this._editablePolygon.update();
                }

                var geo = this._mesh && this._mesh.geometry;
                if (geo) {
                    geo.boundingSphere.radius = lh;
                    geo.boundingSphere.getBoundingBox(geo.boundingBox);
                }
            }
        }

        getLength() {
            if (this.conduitType === ConduitComponentType.Line) {
                var Connections = this.Connections;
                return Math.abs(Connections[1].position.x - Connections[0].position.x);
            }
            return 0;
        }

        public static upVector = new THREE.Vector3(0, 0, 1);
        public static rightVector = new THREE.Vector3(1, 0, 0);
        public static leftVector = new THREE.Vector3(-1, 0, 0);
        public conduitType: ConduitComponentType = ConduitComponentType.Any;

        private calcConduitType() {
            var len = this.Connections.length;
            this.conduitType = len === 2 && this.Connections[0].RotationDegree === 0 ? (this.Connections[1].RotationDegree === 180 ? ConduitComponentType.Line : ConduitComponentType.Curve) : (len > 2 ? ConduitComponentType.Junction : ConduitComponentType.Any);
        }

        //Connections. RotationDegree angle -> connected object
        readonly Connections: ConduitConnection[] = [];

        getClosestConnectionTo(pos: THREE.Vector3): ConduitConnection {
            if (this._isDisposed || !this.visible)
                return null;

            var dist = Number.MAX_VALUE,
                result: ConduitConnection = null;
            this.Connections.forEach(con => {
                var d = con.getGlobalPosition().distanceToSquared(pos);
                if (d < dist) {
                    dist = d;
                    result = con;
                }
            });

            return result;
        }

        hasConnections(...degrees: number[]) {
            return degrees.every(degree => this.Connections.some(con => Math.abs(con.RotationDegree - degree) < 0.01));
        }

        hasOpenConnections() {
            return this.Connections.some(con => !con.connected);
        }

        getNextEmptyConnection(targetRotationDegree: number) {
            var Connections = this.Connections,
                c: ConduitConnection = null;

            for (var i = Connections.length; i--;) {
                var con = Connections[i];
                if (!con.connected) {
                    if (con.RotationDegree === targetRotationDegree)
                        return con;
                    else if (!c || Math.abs(c.RotationDegree - targetRotationDegree) > Math.abs(con.RotationDegree - targetRotationDegree))
                        c = con;
                }
            }

            return c;
        }

        addConnection(connection: SolarProTool.IConduitConnection) {
            var con = new ConduitConnection(connection);
            this.Connections.push(con);
            con.parent = this;

            if (this.ConduitObject)
                this.ConduitObject.OnComponentGeometryChanged();

            this.calcConduitType();
        }

        //local positions
        getConnectionPositions() {
            return this.Connections.map(c => c.position);
        }
        
        disconnect(comp: ConduitComponent) {
            return this.Connections.some(con => {
                if (con.connected && con.connected.parent === comp) {
                    con.disconnect();
                    return true;
                }
                return false;
            });
        }

        disconnectAll() {
            this.Connections.forEach(c => {
                c.disconnect();
            });
        }

        constructor(id?: string, setting?: SolarProTool.IConduitComponentSetting | SolarProTool.IConduitComponent) {
            super();
            this.IdString = id || spt.Utils.GenerateGuid();
            this.setSettings(setting);
        }

        setSettings(setting: SolarProTool.IConduitComponentSetting | SolarProTool.IConduitComponent) {
            if (!setting)
                return this;

            Object.keys(setting).forEach(k => {
                if (k !== "Connections" && k !== "IdString") {
                    this[k] = setting[k];
                }
            });

            if (setting.Connections && setting.Connections.length) {
                if (this.Connections.length > 1) {
                    this.disconnectAll();
                    this.Connections.splice(0, this.Connections.length);
                }

                setting.Connections.forEach(co => {
                    this.addConnection(co);
                });
            }

            this.updateMatrix();

            return this;
        }

        cloneMe(id?: string): ConduitComponent {
            return new ConduitComponent(id || this.IdString, this.exportData());
        }

        updateMaterial() {
            var mesh = this._mesh;
            if (!mesh)
                return;

            var material = mesh.material as THREE.MeshStandardMaterial;

            material.color.setHex(this.MaterialColor);

            if (this.isSelected || this.isHovered) {
                if (this.isSelected && this.isHovered)
                    material.color.offsetHSL(0, +0.3, +0.4);
                else
                    material.color.offsetHSL(0, +0.15, +0.2);
            }

            material.opacity = this.alpha;
            material.transparent = material.opacity < 1;

            material.needsUpdate = true;
        }

        lines: THREE.Line3[] = [];
        points: THREE.Vector3[] = [];

        private _inverseMat = new THREE.Matrix4();
        private _v1 = new THREE.Vector3();
        private _v2 = new THREE.Vector3();
        private _v3 = new THREE.Vector3();
        private _box1 = new THREE.Box3();
        private _viewBounds = new THREE.Box3();
        private _viewSize = new THREE.Vector3();
        public static QEPS = Math.PI / 180;

        applyMatrix4(matrix: THREE.Matrix4) {
            super.applyMatrix4(matrix);
            this._inverseMat.getInverse(this.matrix);
        }

        updateMatrix() {
            super.updateMatrix();
            this._inverseMat.getInverse(this.matrix);
        }

        intersecsBox(searchBox: THREE.Box3): boolean {
            var bounds = this.getBounds(this._box1);
            if (!this._isDisposed && !bounds.isEmpty() && bounds.intersectsBox(searchBox)) {
                var sb = this._box1.copy(searchBox).applyMatrix4(this._inverseMat);
                return this.lines.some(seg => spt.ThreeJs.utils.LineIntersecsBox(seg.start, seg.end, sb));
            }
            return false;
        }

        getClosestPoint(pt: THREE.Vector3, thresholdSq: number): IObjectIntersection<this> {
            if (this._isDisposed || !this.visible)
                return null;

            var p = this._v1.copy(pt).applyMatrix4(this._inverseMat),
                points = this.getConnectionPositions(),
                pointsCount = points.length,
                lines = this.lines,
                linesCount = lines.length,
                result = null as IObjectIntersection<this>,
                closestDistance: number = Number.MAX_VALUE;

            if (pointsCount > 0) {
                for (var i = pointsCount; i--;) {
                    var tp = points[i],
                        dsq = tp.distanceToSquared(p);
                    if (dsq <= thresholdSq && dsq < closestDistance) {
                        closestDistance = dsq;
                        result = {
                            o: this,
                            p: tp,
                            distSq: dsq,
                            idx: i
                        };
                    }
                }
            }

            if (!result && linesCount > 0) {
                for (var i = linesCount; i--;) {
                    var line = lines[i];

                    var cp = spt.ThreeJs.utils.GetClosestPointToLine(line.start, line.end, p),
                        dsq = cp.p.distanceToSquared(p);
                    if (dsq <= thresholdSq && dsq < closestDistance) {
                        closestDistance = dsq;
                        result = {
                            o: this,
                            p: null, //cp.p,
                            distSq: dsq,
                            idx: -1
                        };
                    }
                }
            }

            if (result && result.p)
                result.p = result.p.clone().applyMatrix4(this.matrix);

            return result;
        }

        private _raycaster = new THREE.Raycaster();
        private _meshMatrixWorld = new THREE.Matrix4();

        getClosestEndPositionToRay(r: THREE.Ray, threshold: number) {
            if (this._isDisposed || !this.visible)
                return null;

            var ray = this._raycaster.ray.copy(r).applyMatrix4(this._inverseMat),
                bounds = this._box1.copy(this._mesh.geometry.boundingBox).expandByScalar(threshold + 1);

            if (!ray.intersectsBox(bounds))
                return null;

            var thresholdSq = threshold * threshold,
                connections = this.Connections,
                connectionsCount = connections.length,
                result = null as IObjectIntersection<this>,
                closestDistance: number = Number.MAX_VALUE,
                p0 = this._v1.set(0, 0, 0),
                p1 = this._v2,
                p2 = this._v3;

            if (connectionsCount > 0) {
                for (var i = connectionsCount; i--;) {
                    var con = connections[i],
                        dsq: number,
                        dsqLine = ray.distanceSqToSegment(p0.setZ(con.position.z), con.position, undefined, p1);
                    if (!con.connected && (dsq = ray.distanceSqToPoint(con.position)) <= thresholdSq && dsq < closestDistance) {
                        closestDistance = dsq;
                        result = {
                            o: this,
                            p: p2.copy(p1),
                            distSq: dsqLine,
                            idx: i
                        };
                    } else if (dsqLine <= thresholdSq && dsqLine < closestDistance) {
                        closestDistance = dsqLine;
                        result = {
                            o: this,
                            p: p2.copy(p1),
                            distSq: dsqLine,
                            idx: -1
                        };
                    }
                }

                //for (var i = pointsCount; i--;) {
                //    var tp = points[i],
                //        dsq = ray.distanceSqToSegment(p0, tp, undefined, p1);
                //    if (dsq <= thresholdSq && dsq < closestDistance) {
                //        closestDistance = dsq;
                //        result = {
                //            o: this,
                //            p: p2.copy(p1),
                //            distSq: dsq,
                //            idx: -1
                //        };
                //    }
                //}
            }

            if (result && result.p)
                result.p = result.p.clone().applyMatrix4(this.matrix);

            return result;
        }

        getClosestPointToRay(r: THREE.Ray, threshold: number): IObjectIntersection<this> {
            if (this._isDisposed || !this.visible)
                return null;

            var mesh = this._mesh,
                ray = this._raycaster.ray.copy(r).applyMatrix4(this._inverseMat),
                bounds = this._box1.copy(mesh.geometry.boundingBox).expandByScalar(threshold + 1);

            if (!ray.intersectsBox(bounds))
                return null;

            var thresholdSq = threshold * threshold,
                result = null as IObjectIntersection<this>,
                //closestDistance: number = Number.MAX_VALUE,
                raycaster = this._raycaster,
                meshMatrixWorld = this._meshMatrixWorld.copy(mesh.matrixWorld);

            raycaster.ray.copy(r);

            mesh.matrixWorld.copy(this.matrix);

            var meshInters = raycaster.intersectObject(mesh);
            if (meshInters && meshInters.length) {
                var inter = meshInters[0],
                    dsq = raycaster.ray.distanceSqToPoint(inter.point);
                if (dsq <= thresholdSq/* && dsq < closestDistance*/) {
                    //closestDistance = dsq;
                    result = {
                        o: this,
                        p: inter.point.clone(),
                        distSq: dsq,
                        idx: -1
                    };
                }
            }

            //if (!result && linesCount > 0) {
            //    var p1 = this._v2,
            //        p2 = this._v3;
            //    for (var i = linesCount; i--;) {
            //        var line = lines[i];

            //        var dsq = ray.distanceSqToSegment(line.start, line.end, undefined, p1);
            //        if (dsq <= thresholdSq && dsq < closestDistance) {
            //            closestDistance = dsq;
            //            result = {
            //                o: this,
            //                p: p2.copy(p1),
            //                distSq: dsq,
            //                idx: -1
            //            };
            //        }
            //    }
            //}

            //if (result && result.p)
            //    result.p = result.p.clone().applyMatrix4(this.matrix);

            mesh.matrixWorld.copy(meshMatrixWorld);

            return result;
        }

        getClosestCenterToRay(r: THREE.Ray, threshold: number): IObjectIntersection<this> {
            if (this._isDisposed || !this.visible)
                return null;

            var ray = this._raycaster.ray.copy(r).applyMatrix4(this._inverseMat),
                bounds = this._box1.copy(this._mesh.geometry.boundingBox).expandByScalar(threshold + 1);

            if (!ray.intersectsBox(bounds))
                return null;

            var thresholdSq = threshold * threshold,
                Connections = this.Connections,
                ConnectionsCount = Connections.length,
                result = null as IObjectIntersection<this>,
                closestDistance: number = Number.MAX_VALUE,
                center = this._v1.set(0, 0, 0),
                pt1 = this._v2,
                pt2 = this._v3;

            for (var i = ConnectionsCount; i--;) {
                var con = Connections[i];

                var dsq = ray.distanceSqToSegment(con.position, center.setZ(con.position.z), null, pt1);
                if (dsq <= thresholdSq && dsq < closestDistance) {
                    closestDistance = dsq;
                    result = {
                        o: this,
                        p: pt2.copy(pt1),
                        distSq: dsq,
                        idx: i
                    };
                }
            }

            if (result && result.p)
                result.p = result.p.clone().applyMatrix4(this.matrix);

            return result;
        }

        getClosestByRay2D(rayOrigin: THREE.Vector3, rayDirection: THREE.Vector3) {
            if (this._isDisposed || !this.visible)
                return null;

            var origin = this._v1.copy(rayOrigin).applyMatrix4(this._inverseMat),
                direction = this._v2.copy(rayDirection).add(rayOrigin).applyMatrix4(this._inverseMat).sub(origin).normalize(),
                lines = this.lines,
                linesCount = lines.length,
                result = null as IObjectIntersection<this>,
                closestDistance: number = Number.MAX_VALUE,
                p1 = this._v3;

            for (var i = linesCount; i--;) {
                var line = lines[i];

                var p = spt.ThreeJs.utils.ray2DSegmentIntersection(origin, direction, line.start, line.end);
                if (!p)
                    continue;

                var cp = spt.ThreeJs.utils.GetClosestPointToLine(line.start, line.end, p1.set(p.x, p.y, 0)),
                    dsq = cp.p.distanceToSquared(origin);
                if (dsq < closestDistance) {
                    closestDistance = dsq;
                    result = {
                        o: this,
                        p: cp.p.clone().applyMatrix4(this.matrix),
                        distSq: dsq,
                        idx: i
                    };
                }
            }

            return result;
        }

        get childComponents() {
            return this.Connections.filter((kv, i) => i > 0).map(kv => kv[1]);
        }

        getBounds(target?: THREE.Box3) {
            if (this.isBuild)
                return (target || this._box1).copy(this._mesh.geometry.boundingBox).applyMatrix4(this.matrix);
            return this._box1.makeEmpty();
        }

        buildMesh() {
            if (this._isDisposed || this.isBuild)
                return this;

            var Connections = this.Connections,
                center = new THREE.Vector3(0, 0, Connections.length ? Connections.reduce((v, con) => v + con.position.z, 0) / Connections.length : 0),
                verts: THREE.Vector3[] = [],
                norms: THREE.Vector3[] = [],
                indices: number[] = [],
                indexOffset = 0,
                h = this.ComponentHeight,
                w = this.ComponentWidth,
                smoothNormals = this.SmoothNormals,
                wh = w * 0.5,
                hh = h * 0.5,
                bs = this.BevelSize,
                ba = Math.max(0, Math.min(w / (Math.sqrt(2) + 2), h / (Math.sqrt(2) + 2), Math.sqrt(bs * bs * 0.5))),
                bevelEnabled = ba > 0,
                color = this.MaterialColor,
                emissive = this.MaterialEmissive,
                metalness = this.MaterialMetalness,
                roughness = this.MaterialRoughness,
                suOff = ba * 0.25; //offset upper point in case of smooth normals

            var lines: THREE.Line3[] = [],
                points: THREE.Vector3[] = [],
                dirUp = new THREE.Vector3(0, 0, 1);

            function genVerts(p: THREE.Vector3, dirX: THREE.Vector3, dirY: THREE.Vector3, addUpperPoint: boolean, reverseFaces: boolean, addVerts: boolean) {
                var dx: number,
                    dy: number,
                    n = dirX.clone().cross(dirY).normalize(),
                    pts: THREE.Vector3[] = addVerts ? verts : [],
                    pCount = 0;
                if (reverseFaces)
                    n.negate();
                if (bevelEnabled) {
                    dx = -wh + ba; dy = -hh;
                    pts.push(new THREE.Vector3(p.x + dirX.x * dx + dirY.x * dy, p.y + dirX.y * dx + dirY.y * dy, p.z + dirX.z * dx + dirY.z * dy));
                    dx = wh - ba;
                    pts.push(new THREE.Vector3(p.x + dirX.x * dx + dirY.x * dy, p.y + dirX.y * dx + dirY.y * dy, p.z + dirX.z * dx + dirY.z * dy));
                    dx = wh; dy = -hh + ba;
                    pts.push(new THREE.Vector3(p.x + dirX.x * dx + dirY.x * dy, p.y + dirX.y * dx + dirY.y * dy, p.z + dirX.z * dx + dirY.z * dy));
                    dy = hh - ba;
                    pts.push(new THREE.Vector3(p.x + dirX.x * dx + dirY.x * dy, p.y + dirX.y * dx + dirY.y * dy, p.z + dirX.z * dx + dirY.z * dy));
                    dx = wh - ba; dy = hh;
                    pts.push(new THREE.Vector3(p.x + dirX.x * dx + dirY.x * dy, p.y + dirX.y * dx + dirY.y * dy, p.z + dirX.z * dx + dirY.z * dy));
                    if (addUpperPoint) {
                        dx = 0;
                        dy = hh + suOff;
                        pts.push(new THREE.Vector3(p.x + dirX.x * dx + dirY.x * dy, p.y + dirX.y * dx + dirY.y * dy, p.z + dirX.z * dx + dirY.z * dy));
                        pCount++;
                    }
                    dx = -wh + ba; dy = hh;
                    pts.push(new THREE.Vector3(p.x + dirX.x * dx + dirY.x * dy, p.y + dirX.y * dx + dirY.y * dy, p.z + dirX.z * dx + dirY.z * dy));
                    dx = -wh; dy = hh - ba;
                    pts.push(new THREE.Vector3(p.x + dirX.x * dx + dirY.x * dy, p.y + dirX.y * dx + dirY.y * dy, p.z + dirX.z * dx + dirY.z * dy));
                    dy = -hh + ba;
                    pts.push(new THREE.Vector3(p.x + dirX.x * dx + dirY.x * dy, p.y + dirX.y * dx + dirY.y * dy, p.z + dirX.z * dx + dirY.z * dy));

                    pCount += 8;
                }
                else {
                    dx = -wh; dy = -hh;
                    pts.push(new THREE.Vector3(p.x + dirX.x * dx + dirY.x * dy, p.y + dirX.y * dx + dirY.y * dy, p.z + dirX.z * dx + dirY.z * dy));
                    dx = wh;
                    pts.push(new THREE.Vector3(p.x + dirX.x * dx + dirY.x * dy, p.y + dirX.y * dx + dirY.y * dy, p.z + dirX.z * dx + dirY.z * dy));
                    dy = hh;
                    pts.push(new THREE.Vector3(p.x + dirX.x * dx + dirY.x * dy, p.y + dirX.y * dx + dirY.y * dy, p.z + dirX.z * dx + dirY.z * dy));
                    if (addUpperPoint) {
                        dx = 0;
                        dy = hh + ba * 0.25;
                        pts.push(new THREE.Vector3(p.x + dirX.x * dx + dirY.x * dy, p.y + dirX.y * dx + dirY.y * dy, p.z + dirX.z * dx + dirY.z * dy));
                        pCount++;
                    }
                    dx = -wh; dy = hh;
                    pts.push(new THREE.Vector3(p.x + dirX.x * dx + dirY.x * dy, p.y + dirX.y * dx + dirY.y * dy, p.z + dirX.z * dx + dirY.z * dy));

                    pCount += 4;
                }

                if (addVerts) {
                    for (var iv = 0; iv < pCount; iv++) {
                        norms.push(n);
                    }

                    if (reverseFaces) {
                        for (var iv = 2; iv < pCount; iv++) {
                            indices.push(
                                indexOffset, indexOffset + iv, indexOffset + iv - 1
                            );
                        }
                    } else {
                        for (var iv = 2; iv < pCount; iv++) {
                            indices.push(
                                indexOffset, indexOffset + iv - 1, indexOffset + iv
                            );
                        }
                    }

                    indexOffset += pCount;
                }

                //add edges and corners
                dx = -wh; dy = -hh;
                points.push(new THREE.Vector3(p.x + dirX.x * dx + dirY.x * dy, p.y + dirX.y * dx + dirY.y * dy, p.z + dirX.z * dx + dirY.z * dy));
                dx = wh; dy = -hh;
                points.push(new THREE.Vector3(p.x + dirX.x * dx + dirY.x * dy, p.y + dirX.y * dx + dirY.y * dy, p.z + dirX.z * dx + dirY.z * dy));
                dx = wh; dy = hh;
                points.push(new THREE.Vector3(p.x + dirX.x * dx + dirY.x * dy, p.y + dirX.y * dx + dirY.y * dy, p.z + dirX.z * dx + dirY.z * dy));
                dx = -wh; dy = hh;
                points.push(new THREE.Vector3(p.x + dirX.x * dx + dirY.x * dy, p.y + dirX.y * dx + dirY.y * dy, p.z + dirX.z * dx + dirY.z * dy));

                var pidx = points.length - 4;

                lines.push(new THREE.Line3(points[pidx + 0], points[pidx + 1]));
                lines.push(new THREE.Line3(points[pidx + 2], points[pidx + 3]));

                return addVerts ? pts.slice(-pCount) : pts;
            }

            function addLine(vFrom: THREE.Vector3, vTo: THREE.Vector3, addStartFaces: boolean, addEndFaces: boolean) {

                var diff = new THREE.Vector3(vTo.x - vFrom.x, vTo.y - vFrom.y, 0);

                if (diff.lengthSq() <= 0)
                    return;

                var dir = diff.clone().normalize(),
                    dirRight = new THREE.Vector3(dir.y, -dir.x, 0);

                var sVerts = genVerts(vFrom, dirRight, dirUp, smoothNormals, false, addStartFaces); // add start face vertices
                var eVerts = genVerts(vTo, dirRight, dirUp, smoothNormals, true, addEndFaces); // add end face vertices

                var edgeCount = sVerts.length;

                if (smoothNormals) {
                    //add (edgeCount - 1) * 2 vertices
                    for (var i1 = 0; i1 < edgeCount; i1++) {
                        verts.push(
                            sVerts[i1],
                            eVerts[i1]);

                        var nv = sVerts[i1].clone().sub(vFrom).normalize();

                        norms.push(
                            nv,
                            nv);

                        if (i1 === 0) {
                            var ec2 = edgeCount * 2;
                            indices.push(
                                indexOffset + ec2 - 2, indexOffset + ec2 - 1, indexOffset + 0,
                                indexOffset + 0, indexOffset + ec2 - 1, indexOffset + 1
                            );
                        }
                        else if (i1 !== 1) {
                            indices.push(
                                indexOffset - 2, indexOffset - 1, indexOffset + 0,
                                indexOffset + 0, indexOffset - 1, indexOffset + 1
                            );
                        }

                        indexOffset += 2;
                    }
                }
                else {
                    var i1 = edgeCount - 1;
                    //add (edgeCount - 1) * 4 vertices
                    for (var i2 = 0; i2 < edgeCount; i2++) {

                        //skip bottom faces
                        if (i2 !== 1) {
                            verts.push(
                                sVerts[i1],
                                eVerts[i1],
                                eVerts[i2],
                                sVerts[i2]);

                            var nv = spt.ThreeJs.utils.getNormal(sVerts[i1], eVerts[i1], sVerts[i2]);

                            norms.push(
                                nv,
                                nv,
                                nv,
                                nv);

                            indices.push(
                                indexOffset + 0, indexOffset + 1, indexOffset + 2,
                                indexOffset + 0, indexOffset + 2, indexOffset + 3
                            );
                            indexOffset += 4;
                        }

                        i1 = i2;
                    }
                }

                //add edges
                var pidx = points.length - 8;

                for (var i = 0; i < 4; i++) {
                    lines.push(new THREE.Line3(points[pidx + i], points[pidx + i + 4]));
                }
            }

            if (Connections.length > 1 && Connections.some(c => c.RotationDegree === 0) && Connections.some(c => c.RotationDegree === 180)) {
                var idx1 = Connections.findIndex(c => c.RotationDegree === 0),
                    idx2 = Connections.findIndex(c => c.RotationDegree === 180);
                addLine(Connections[idx1].position, Connections[idx2].position, true, true);
                if (Connections.length > 2) {
                    Connections.forEach((c, i) => {
                        if (i !== idx1 && i !== idx2)
                            addLine(c.position, center, true, false);
                    });
                }
            }
            else {
                Connections.forEach(c => {
                    addLine(c.position, center, true, false);
                });
            }

            //add circle edge for curves
            if (this.conduitType === ConduitComponentType.Curve) {
                var deg = Connections[1].RotationDegree,
                    pts = deg < 180 ?
                        spt.ThreeJs.utils.GetCirclePoints(wh, 30, (deg - 90) * THREE.MathUtils.DEG2RAD, Math.PI * 0.5) :
                        spt.ThreeJs.utils.GetCirclePoints(wh, 30, -Math.PI * 0.5, (deg - 270) * THREE.MathUtils.DEG2RAD),
                    pLen = pts.length;

                //radius and height
                var rr = bevelEnabled
                    ? [
                        [(wh - ba) / wh, -hh],
                        [1, -hh + ba],
                        [1, hh - ba],
                        [(wh - ba) / wh, hh]
                    ]
                    : [
                        [1, -hh],
                        [1, hh]
                    ],
                    rf1: number,
                    rz1: number,
                    rf2: number,
                    rz2: number;


                //add sides of circle
                if (smoothNormals) {

                    for (var k = 0, rrl = rr.length; k < rrl; k++) {
                        rf1 = rr[k][0];
                        rz1 = rr[k][1];

                        for (var j = 0; j < pLen; j++) {
                            var p1 = pts[j],
                                v = new THREE.Vector3(p1.x * rf1 + dirUp.x * rz1, p1.y * rf1 + dirUp.y * rz1, p1.z * rf1 + dirUp.z * rz1);

                            verts.push(v);

                            norms.push(
                                v.clone().sub(center).normalize());
                        }

                        if (k > 0) {
                            for (var j = 1; j < pLen; j++) {

                                var idx1 = indexOffset + j - 1 - pLen,
                                    idx2 = indexOffset + j - pLen,
                                    idx3 = indexOffset + j,
                                    idx4 = indexOffset + j - 1;

                                indices.push(
                                    idx1, idx2, idx3,
                                    idx1, idx3, idx4
                                );
                            }
                        }

                        indexOffset += pLen;
                    }

                    //add top
                    verts.push(new THREE.Vector3(center.x + dirUp.x * rz1, center.y + dirUp.y * rz1, center.z + dirUp.z * rz1 + suOff));
                    norms.push(new THREE.Vector3(0, 0, 1));

                    for (var j = 1; j < pLen; j++) {
                        indices.push(
                            indexOffset + 0, indexOffset - j - 1, indexOffset - j
                        );
                    }

                    indexOffset += 1;

                }
                else {
                    for (var k = 1, rrl = rr.length; k < rrl; k++) {
                        rf1 = rr[k - 1][0];
                        rz1 = rr[k - 1][1];
                        rf2 = rr[k][0];
                        rz2 = rr[k][1];

                        for (var j = 1; j < pLen; j++) {
                            var p1 = pts[j - 1],
                                p2 = pts[j];
                            verts.push(new THREE.Vector3(p1.x * rf1 + dirUp.x * rz1, p1.y * rf1 + dirUp.y * rz1, p1.z * rf1 + dirUp.z * rz1));
                            verts.push(new THREE.Vector3(p2.x * rf1 + dirUp.x * rz1, p2.y * rf1 + dirUp.y * rz1, p2.z * rf1 + dirUp.z * rz1));
                            verts.push(new THREE.Vector3(p1.x * rf2 + dirUp.x * rz2, p1.y * rf2 + dirUp.y * rz2, p1.z * rf2 + dirUp.z * rz2));
                            verts.push(new THREE.Vector3(p2.x * rf2 + dirUp.x * rz2, p2.y * rf2 + dirUp.y * rz2, p2.z * rf2 + dirUp.z * rz2));

                            var nv = spt.ThreeJs.utils.getNormal(verts[indexOffset + 0], verts[indexOffset + 1], verts[indexOffset + 2]);

                            norms.push(
                                nv,
                                nv,
                                nv,
                                nv);

                            indices.push(
                                indexOffset + 0, indexOffset + 1, indexOffset + 2,
                                indexOffset + 2, indexOffset + 1, indexOffset + 3
                            );

                            indexOffset += 4;
                        }
                    }

                    //add top
                    for (var j = 1; j < pLen; j++) {
                        var p1 = pts[j - 1],
                            p2 = pts[j];

                        verts.push(new THREE.Vector3(p1.x * rf2 + dirUp.x * rz2, p1.y * rf2 + dirUp.y * rz2, p1.z * rf2 + dirUp.z * rz2));
                        verts.push(new THREE.Vector3(p2.x * rf2 + dirUp.x * rz2, p2.y * rf2 + dirUp.y * rz2, p2.z * rf2 + dirUp.z * rz2));
                        verts.push(new THREE.Vector3(center.x + dirUp.x * rz2, center.y + dirUp.y * rz2, center.z + dirUp.z * rz2));

                        indices.push(
                            indexOffset + 0, indexOffset + 1, indexOffset + 2
                        );

                        var nv = spt.ThreeJs.utils.getNormal(verts[indexOffset + 0], verts[indexOffset + 1], verts[indexOffset + 2]);

                        norms.push(
                            nv,
                            nv,
                            nv);

                        indexOffset += 3;
                    }
                }

            }

            if (!indices.length)
                return this;

            var geo = new THREE.BufferGeometry();

            var positions = new Float32Array(verts.length * 3);
            geo.setAttribute('position', new THREE.BufferAttribute(positions, 3).copyVector3sArray(verts));

            var normals = new Float32Array(norms.length * 3);
            geo.setAttribute('normal', new THREE.BufferAttribute(normals, 3).copyVector3sArray(norms));

            geo.setIndex(indices);

            //return THREE.BufferGeometryUtils.mergeBufferGeometries([]).translate(center.x, center.y, center.z);

            //var geo = new THREE.ExtrudeBufferGeometry(shapes, extrudeSettings).translate(center.x, center.y, center.z);

            if (!geo.boundingBox)
                geo.boundingBox = new THREE.Box3();

            if (!geo.boundingSphere)
                geo.boundingSphere = new THREE.Sphere();

            geo.boundingBox.setFromPoints(points);
            geo.boundingBox.getBoundingSphere(geo.boundingSphere);

            //this.geometry = geo;
            this.lines = lines;
            this.points = points;
            this.isBuild = true;

            if (this.DynamicLength && !this.isStatic) {
                //add bones
                var rootBone = new THREE.Bone();
                var bones: THREE.Bone[] = [rootBone];
                this.Connections.forEach(c => {
                    var bone = new THREE.Bone();
                    bone.position.copy(c.position);
                    bone.quaternion.copy(c.quaternion);
                    bones.push(bone);
                    rootBone.add(bone);
                });

                var skeleton = new THREE.Skeleton(bones);

                var mesh = new THREE.SkinnedMesh(geo, new THREE.MeshStandardMaterial({ metalness: metalness, roughness: roughness, skinning: true, color: color, emissive: emissive }));

                mesh.add(rootBone);
                mesh.bind(skeleton);

                //add skinning
                var position = geo.attributes.position as THREE.BufferAttribute;

                var vertex = new THREE.Vector3();

                var skinIndices = [];
                var skinWeights = [];

                for (var i = 0; i < position.count; i++) {

                    vertex.fromBufferAttribute(position, i);

                    //var y = ( vertex.y + sizing.halfHeight );

                    //var skinIndex = Math.floor( y / sizing.segmentHeight );
                    //var skinWeight = ( y % sizing.segmentHeight ) / sizing.segmentHeight;

                    //skinIndices.push( skinIndex, skinIndex + 1, 0, 0 );
                    //skinWeights.push( 1 - skinWeight, skinWeight, 0, 0 );

                    var skinIndex = ArrayHelper.indexByMin(bones, b => b.position.distanceToSquared(vertex));

                    skinIndices.push(skinIndex, 0, 0, 0);
                    skinWeights.push(1, 0, 0, 0);

                }

                geo.setAttribute('skinIndex', new THREE.Uint16BufferAttribute(skinIndices, 4));
                geo.setAttribute('skinWeight', new THREE.Float32BufferAttribute(skinWeights, 4));

                this.add(mesh);
            } else {
                this.add(new THREE.Mesh(geo, new THREE.MeshStandardMaterial({ metalness: metalness, roughness: roughness, color: color, emissive: emissive })));
            }

            this._showPolylines = this.getObjectHolder().showPolylines;

            this.updatePolylines();

            return this;
        }

        updatePolylines() {
            if (!this.isBuild)
                return;

            var showPolylines = this.showPolylines,
                editablePolygon = this._editablePolygon;

            if (!showPolylines) {
                if (editablePolygon) {
                    this.remove(editablePolygon);
                    editablePolygon.dispose();
                    this._editablePolygon = null;
                }
                return;
            } else if (editablePolygon)
                return;

            var localPolygon = new ObservablePolygon();

            var origin = new THREE.Vector3(0, 0, 0);

            var Connections = this.Connections;

            if (Connections.length > 1 && Connections.some(c => c.RotationDegree === 0) && Connections.some(c => c.RotationDegree === 180)) {
                var idx1 = Connections.findIndex(c => c.RotationDegree === 0),
                    idx2 = Connections.findIndex(c => c.RotationDegree === 180);

                localPolygon.push(Connections[idx1].position);
                localPolygon.push(Connections[idx2].position);

                if (Connections.length > 2) {
                    localPolygon.push(origin);

                    Connections.forEach((c, i) => {
                        if (i !== idx1 && i !== idx2) {
                            localPolygon.push(c.position);
                            localPolygon.push(origin.setZ(c.position.z));
                        }
                    });
                }
            }
            else {
                Connections.forEach(c => {
                    localPolygon.push(c.position);
                    localPolygon.push(origin);
                });
            }

            editablePolygon = this._editablePolygon = new EditablePolygon2D(localPolygon, null, null,
                {
                    surfaceColor: 0,
                    surfaceOpacity: 0,
                    lineColor: this.MaterialColor,
                    linewidth: 5
                });

            editablePolygon.editable = true;
            editablePolygon.clickable = false;
            editablePolygon.draggable = false;
            editablePolygon.closed = false;
            editablePolygon.editOptions = EditablePolygon2D.ShowEdges;

            this.add(editablePolygon);
        }

        updateConnectionPositions() {
            if (!this.isBuild || !this.DynamicLength || this.isStatic)
                return;

            var mesh = this._mesh as THREE.SkinnedMesh,
                skeleton = mesh.skeleton,
                bones = skeleton.bones;

            this.Connections.forEach((c, i) => {
                var bone = bones[i + 1];
                bone.position.copy(c.position);
                bone.quaternion.copy(c.quaternion);
            });
        }

        dispose(): void {
            if (!this._isDisposed) {
                this._isDisposed = true;
                spt.ThreeJs.utils.disposeObject3D(this, true, true, false);
            }
        }

        intersecsTestVolume(testVolume: spt.ThreeJs.utils.TestVolume, linePrecision: number): boolean {
            if (!this.isBuild || !this.visible)
                return false;

            var matrixWorld = this.matrixWorld,
                worldBounds = this._box1.copy(this._mesh.geometry.boundingBox).applyMatrix4(matrixWorld).expandByScalar(linePrecision);

            if (!testVolume.testBoxForSeparationAxis(worldBounds)) {

                var lines = this.lines,
                    p1 = this._v1,
                    p2 = this._v2;

                for (var i = lines.length; i--;) {
                    var line = lines[i];
                    p1.copy(line.start).applyMatrix4(matrixWorld);
                    p2.copy(line.end).applyMatrix4(matrixWorld);
                    if (!testVolume.testEdgeForSeparationAxis([p1, p2]))
                        return true;
                }

                //var instanceMesh = this.instanceMesh;
                //if (!testVolume.testBufferGeometryForSeparationAxis(instanceMesh.geometry as THREE.BufferGeometry, instanceMesh.matrixWorld))
                //    return true;
            }

            return false;
        }

        GetConnectionByAngle(rad: number): ConduitConnection {
            var dir = spt.ThreeJs.utils.RotateCC(this._v1.set(-1, 0, 0), rad),
                d = -1,
                con: ConduitConnection = null,
                conns = this.Connections,
                len = conns.length;

            for (var i = len; i--;) {
                var c = conns[i],
                    cd = c.direction.dot(dir);
                if (cd > d) {
                    d = cd;
                    con = c;
                }
            }

            return con;
        }

        GetAllConnected(): ConduitComponent[] {
            var result: ConduitComponent[] = [this],
                workset: ConduitConnection[] = this.Connections.slice();

            while (workset.length > 0) {
                var con = workset.splice(0, 1)[0];
                if (con.connected) {
                    var comp = con.connected.parent,
                        cIdx = result.indexOf(comp);
                    if (cIdx === -1) {
                        result.push(comp);
                        workset.push.apply(workset, comp.Connections);
                    }
                }
            }

            return result;
        }

        tryConnect(other: ConduitComponent, toleranceSq?: number) {
            return this.Connections.some(con1 => other.Connections.some(con2 => con1.tryConnect(con2, toleranceSq)));
        }

        tryAlign(other: ConduitComponent, toleranceSq?: number) {
            return this.Connections.some(con1 => other.Connections.some(con2 => con1.tryAlign(con2, toleranceSq)));
        }

        getConnectionDistance(other: ConduitComponent, toleranceSq?: number) {
            var dist = Number.MAX_VALUE;

            this.Connections.forEach(con1 => {
                if (!con1.connected) {
                    other.Connections.forEach(con2 => {
                        if (con2.canConnect(con1, toleranceSq)) {
                            var d = con1.getGlobalPosition().distanceToSquared(con2.getGlobalPosition());
                            if (d < dist)
                                dist = d;
                        }

                    });
                }
            });

            return dist;
        }

        getObjectHolder(): ConduitObjectHolder {
            return LS.Client3DEditor.Controller.Current.conduitObjectHolder;
        }

        update(): void {
            if (this._isDisposed || !this._needsUpdate)
                return;

            this._needsUpdate = false;

            if (this.isHidden) {
                this.visible = false;
                return;
            } else if (!this.visible)
                this.visible = true;

            if (!this.isBuild)
                this.buildMesh();

            this._viewBounds.copy(this._mesh.geometry.boundingBox).applyMatrix4(this.matrix);
            this._viewBounds.getSize(this._viewSize);

            this.updateMaterial();

        }

        exportData(): SolarProTool.IConduitComponent {

            var pos = this.position;
            //if (this.parent)
            //    pos = pos.clone().applyMatrix4(this.parent.matrix);

            return {
                IdString: this.IdString || spt.Utils.GuidEmpty(),
                RotationDegree: this.rotation.z / Math.PI * 180,
                ComponentId: this.ComponentId || spt.Utils.GuidEmpty(),
                X: pos.x,
                Y: pos.y,
                Z: pos.z,
                SizeX: 1,
                SizeY: 1,
                Color: this.Color, // System.Int32
                ComponentWidth: this.ComponentWidth, // System.Double
                ComponentHeight: this.ComponentHeight, // System.Double
                BevelSize: this.BevelSize, // System.Double
                SmoothNormals: this.SmoothNormals,
                MaterialColor: this.MaterialColor,
                MaterialEmissive: this.MaterialEmissive,
                MaterialMetalness: this.MaterialMetalness,
                MaterialRoughness: this.MaterialRoughness,
                Connections: this.Connections.map(c => c.exportData()),
                Name: this.Name, // System.String
                DisplayName: this.DisplayName,
                DynamicLength: this.DynamicLength, // System.Boolean
                IsActive: true, // System.Boolean
                IsDeleted: false, // System.Boolean
                ClientOptions: 0, // enum SPTSettingInterface.Client3DObectOptions
                DrawOptions: 0, // enum SPTSettingInterface.DrawOptionsEnum
                ErrorCode: 0, // enum SPTEnums.ErrorCodeEnum
                InstanceColor: 0, // System.Int32
                IsInErrorState: false, // System.Boolean
                Width: 0, // System.Double
                Height: 0, // System.Double
                IconInsertIndex: 0, // System.Int32
                ComponentGroup: this.ComponentGroup,
                ViewLayer: this.ViewLayer
            };
        }

        moveBy(v: THREE.Vector3) {
            this.ConduitObject.moveBy(v);
        }

        applyTranslation(v: THREE.Vector3) {
            this.position.add(v);
            this.updateMatrix();
        }

        removeInstance(skipUpdate?: boolean) {
            this.getObjectHolder().removeObject(this, skipUpdate);
        }

        GetPositionProperty(k: string): number {
            if (this.isBuild)
                return this._viewBounds.min[k];
            return this.position[k];
        }

        GetSizeProperty(k: string): number {
            if (this.isBuild)
                return this._viewSize[k];
            return 0;
        }

        OnChanged(viewModel?: ViewModel) {
            if (viewModel && viewModel.isDragging)
                this.ConduitObject.applyDrag();
            this._needsUpdate = true;
            if (this.ConduitObject)
                this.ConduitObject.OnComponentChanged();
        }

        applyObjectPosition() {
            this.ConduitObject.applyObjectPosition();
        }

        duplicate(count?: number, distance?: number, direction?: THREE.Vector3): void {
            return;
        }

        rotateByPivot(pivot: THREE.Vector3, angleRad: number) {
            return;
        }

        getClosestEntryPoint(point: THREE.Vector3, target: THREE.Vector3): THREE.Vector3 {

            if (this.conduitType === ConduitComponentType.Line)
                return spt.ThreeJs.utils.GetClosestPointOnLine(this.Connections[0].getGlobalPosition(), this.Connections[1].getGlobalPosition(), point, target);

            var pt = this._v1.copy(point).applyMatrix4(this._inverseMat),
                center = this._v2.set(0, 0, 0),
                tp = this._v3,
                len = Number.MAX_VALUE,
                conns = this.Connections;

            for (var i = conns.length; i--;) {
                var conPos = conns[i].position;
                spt.ThreeJs.utils.GetClosestPointOnLine(center.setZ(conPos.z), conPos, pt, tp);
                var l = tp.distanceToSquared(pt);
                if (l < len) {
                    len = l;
                    target.copy(tp);
                }
            }

            return target.applyMatrix4(this.matrix);

        }

        getPivot(): THREE.Vector3 {
            return new THREE.Vector3();
        }

        getPoints(): THREE.Vector3[] {
            return [];
            //return this.points;
        }

        getLines(): THREE.Line3[] {
            return [];
            //return this.lines;
        }

        save() {
            this.getObjectHolder().saveConduitObject(this.ConduitObject);
        }
    }

    export class ConduitObject extends THREE.Object3D implements SolarProTool.IConduitObject {
        IsActive: boolean = true;
        IsDeleted: boolean = false;
        ClientOptions: SolarProTool.Client3DObectOptions = 0;
        DrawOptions: SolarProTool.DrawOptionsEnum = 0;
        ErrorCode: SolarProTool.ErrorCodeEnum = 0;
        InstanceColor: number = 0;
        IsInErrorState: boolean = false;
        Width: number = 0;
        Height: number = 0;
        IconInsertIndex: number = 0;
        _isDisposed: boolean = false;
        _needsUpdate: boolean = true;
        _positionChanged = 0;
        _v1 = new THREE.Vector3();
        _vt = new THREE.Vector3();
        private _box1 = new THREE.Box3();
        _updateMove: boolean = false;
        _updateDrag: boolean = false;

        private _subscriptions: KnockoutSubscription[] = [];

        get X() {
            return this.position.x;
        }

        set X(val: number) {
            this.position.x = val;
        }

        get Y() {
            return this.position.y;
        }

        set Y(val: number) {
            this.position.y = val;
        }

        get Z() {
            return this.position.z;
        }

        set Z(val: number) {
            this.position.z = val;
        }

        SizeX: number = 1;
        SizeY: number = 1;

        Components: ConduitComponent[] = [];
        ComponentSetID: string;

        getComponentSet(viewModel: ConduitViewModel): ConduitComponentSet {
            return viewModel.ComponentSetById[this.ComponentSetID];
        }

        componentBounds: THREE.Box3;

        readonly ComponentGroup: string;
        readonly ViewLayer: string;

        get isSelected() {
            return this.Components.some(c => c.isSelected);
        }

        set isSelected(v: boolean) {
            this.Components.forEach(c => {
                c.isSelected = v;
            });
        }

        get isHovered() {
            return this.Components.some(c => c.isHovered);
        }

        set isHovered(v: boolean) {
            if (!v) {
                this.Components.forEach(c => {
                    c.isHovered = false;
                });
            }
        }

        get isHidden() {
            return this.Components.some(c => c.isHidden);
        }

        set isHidden(v: boolean) {
            this.Components.forEach(c => {
                c.isHidden = v;
            });
        }

        private _idString: string = null;

        get IdString() {
            return this._idString;
        }

        set IdString(v: string) {
            if (this._isDisposed || !v)
                return;
            if (this._idString !== v) {
                var instances = this.getObjectHolder().conduitObjects,
                    oldId = this._idString;
                if (oldId && instances[oldId]) {
                    delete instances[oldId];
                    this._idString = null;
                }
                if (v) {
                    var existing = instances[v];
                    if (existing && existing !== this)
                        existing.dispose();
                    this._idString = v;
                    instances[v] = this;
                }
            }
        }

        pointOffset = new THREE.Vector3();

        constructor(id?: string, compSetId?: string, data?: SolarProTool.IConduitObject) {
            super();
            this.IdString = id || spt.Utils.GenerateGuid();
            this.ComponentSetID = compSetId || spt.Utils.GenerateGuid();

            ko.track(this, ["Components"]);

            var subscriptions = this._subscriptions;

            subscriptions.push(ko.getObservable(this, "Components").subscribe((changes: { value: ConduitComponent, status: string }[]) => {
                //var subscriptions = this._subscriptions;
                changes.forEach((change) => {
                    var v = change.value;
                    if (v) {
                        switch (change.status) {
                            case "deleted":
                                v.ConduitObject = null;
                                this.remove(v);
                                break;
                            case "added":
                                v.ConduitObject = this;
                                if (!v.isBuild)
                                    v.buildMesh();
                                //v.updateMatrix();
                                this.add(v);
                                break;
                        }
                    }

                });
            }, null, "arrayChange"));

            var bounds = new THREE.Box3(),
                bd = new THREE.Box3();

            ko.defineProperty(this, "componentBounds", () => {
                bounds.makeEmpty();
                this.Components.forEach(c => {
                    if (!c.isBuild)
                        c.buildMesh();

                    var b = c.getBounds(bd);
                    bounds.expandByPoint(b.min).expandByPoint(b.max);
                });
                return bounds;
            });

            this.getObjectHolder().conduitObjects[id] = this;

            if (data)
                this.setData(data);

            ko.defineProperty(this, "ComponentGroup", () => {
                return this.Components.length ? this.Components[0].ComponentGroup : null;
            });

            ko.defineProperty(this, "ViewLayer", () => {
                return this.Components.length ? this.Components[0].ViewLayer : null;
            });
        }

        getPointOffset(): THREE.Vector3 {
            return this.pointOffset;
        }

        getObjectHolder(): ConduitObjectHolder {
            return LS.Client3DEditor.Controller.Current.conduitObjectHolder;
        }

        intersecsBox(searchBox: THREE.Box3): boolean {
            var bounds = this.getBounds();
            if (!this._isDisposed && this.visible && bounds && !bounds.isEmpty() && bounds.intersectsBox(searchBox) && this.Components.some(c => c.intersecsBox(searchBox))) {
                return true;
            }
            return false;
        }

        getClosestPoint(p: THREE.Vector3, thresholdSq: number): IObjectIntersection<ConduitComponent> {
            var searchBox = this._box1.makeEmpty().expandByPoint(p).expandByScalar(Math.sqrt(thresholdSq) + 1),
                bounds = this.getBounds();

            if (!this._isDisposed && this.visible && bounds && !bounds.isEmpty() && bounds.intersectsBox(searchBox)) {
                var res = this.Components.reduce((closest, comp) => {
                    var o = comp.getClosestPoint(p, thresholdSq);
                    return o && (!closest || closest.distSq > o.distSq) ? o : closest;
                }, null as IObjectIntersection<ConduitComponent>);

                return res;

                //return res ? {
                //    o: this,
                //    p: res.p,
                //    distSq: res.distSq,
                //    l: res.l,
                //    idx: res.idx,
                //    component: res.o
                //} : null;
            }

            return null;
        }

        getClosestEndPositionToRay(ray: THREE.Ray, thresholdSq: number): IObjectIntersection<ConduitComponent> {
            var threshold = Math.sqrt(thresholdSq),
                bounds = this._box1.copy(this.getBounds()).expandByScalar(threshold + 1);

            if (!ray.intersectsBox(bounds))
                return null;

            var res = this.Components.reduce((closest, comp) => {
                var o = comp.getClosestEndPositionToRay(ray, threshold);
                return o && (!closest || closest.distSq > o.distSq) ? o : closest;
            }, null as IObjectIntersection<ConduitComponent>);

            return res;
        }

        getClosestPointToRay(ray: THREE.Ray, thresholdSq: number): IObjectIntersection<ConduitComponent> {
            var threshold = Math.sqrt(thresholdSq),
                bounds = this._box1.copy(this.getBounds()).expandByScalar(threshold + 1);

            if (!ray.intersectsBox(bounds))
                return null;

            var res = this.Components.reduce((closest, comp) => {
                var o = comp.getClosestPointToRay(ray, threshold);
                return o && (!closest || closest.distSq > o.distSq) ? o : closest;
            }, null as IObjectIntersection<ConduitComponent>);

            return res;
        }

        getClosestCenterToRay(ray: THREE.Ray, threshold: number): IObjectIntersection<ConduitComponent> {
            var bounds = this._box1.copy(this.getBounds()).expandByScalar(threshold + 1);
            if (!ray.intersectsBox(bounds))
                return null;

            var res = this.Components.reduce((closest, comp) => {
                var o = comp.getClosestCenterToRay(ray, threshold);
                return o && (!closest || closest.distSq > o.distSq) ? o : closest;
            }, null as IObjectIntersection<ConduitComponent>);

            return res;

            //return res ? {
            //    o: this,
            //    p: res.p,
            //    distSq: res.distSq,
            //    l: res.l,
            //    idx: res.idx,
            //    component: res.o
            //} : null;
        }

        getClosestByRay2D(origin: THREE.Vector3, direction: THREE.Vector3) {
            var bounds = this._box1.copy(this.getBounds()).expandByScalar(/*Math.sqrt(thresholdSq) + */1);
            if (!spt.ThreeJs.utils.ray2DintersecsBox2D(origin, direction, bounds.min, bounds.max))
                return null;

            return this.Components.reduce((closest, comp) => {
                var o = comp.getClosestByRay2D(origin, direction);
                return o && (!closest || closest.distSq > o.distSq) ? o : closest;
            }, null as IObjectIntersection<ConduitComponent>);
        }

        OnComponentChanged(): void {
            this._needsUpdate = true;
            //ko.getObservable(this, "Components").valueHasMutated();
        }

        OnComponentGeometryChanged(): void {
            ko.getObservable(this, "Components").valueHasMutated();
        }

        applyDrag() {
            this._updateDrag = true;
        }

        tryGetSnapPosition(component: ConduitComponent, viewModel: ViewModel) {
            //try to snap this position so that the comp is align with another open connection
            var conduitObjects = this.getObjectHolder().getAllConduitObjects().filter(co => co !== this && co.position.lengthSq() <= 0);

            if (!component.Connections.some(con => !con.connected) || !conduitObjects.length)
                return null;

            var translation = this.position,
                sourceConnections = component.Connections.filter(cc => !cc.connected),
                precision = Controller.Current.linePrecision,
                thresholdSq = precision * precision,
                compBounds = this._box1.makeEmpty(),// comp.getBounds().expandByScalar(Math.max(10, precision)),
                foundConnectionSelf: ConduitConnection = null,
                foundConnectionOther: ConduitConnection = null,
                dist = Number.MAX_VALUE;

            sourceConnections.forEach(sc => compBounds.expandByPoint(sc.getGlobalPosition().add(translation)));

            compBounds.expandByScalar(Math.max(10, precision));

            for (var i = conduitObjects.length; i--;) {
                var co = conduitObjects[i],
                    comps = co.getComponentByBounds(compBounds);

                for (var j = comps.length; j--;) {
                    var cm = comps[j];

                    sourceConnections.forEach(sc => {
                        cm.Connections.forEach(con2 => {
                            if (!con2.connected && sc.getGlobalDirection().dot(con2.getGlobalDirection()) < -0.99 && sc.getGlobalPosition().add(translation).distanceToSquared(con2.getGlobalPosition()) < thresholdSq) {
                                var d = sc.getGlobalPosition().add(translation).distanceToSquared(con2.getGlobalPosition());
                                if (d < dist) {
                                    dist = d;
                                    foundConnectionSelf = sc;
                                    foundConnectionOther = con2;
                                }
                            }
                        });
                    });
                }
            }

            if (foundConnectionOther != null) {
                var tr = foundConnectionOther.getGlobalPosition().sub(foundConnectionSelf.getGlobalPosition().add(translation));
                if (tr.lengthSq() > 0) {
                    //this.position.add(tr);
                    return tr;
                }
            }

            return null;
            //connections.some(con => con.canConnect())
        }

        moveBy(v: THREE.Vector3) {
            //buffer translation for one frame
            this._vt.copy(v);
            this._updateMove = true;
        }

        applyObjectPosition() {
            if (this.position.lengthSq() > 0) {
                var v = this.position.clone();
                this.position.set(0, 0, 0);

                this.Components.forEach(c => {
                    c.applyTranslation(v);
                    c._needsUpdate = true;
                    c.update();
                });
                this.OnComponentGeometryChanged();
                this.save();
                this.getObjectHolder().checkForConnectedGroups();

            }
        }

        update(): void {
            if (this._isDisposed)
                return;

            if (this._updateDrag) {
                this._updateDrag = false;

                var curComps = this.Components,
                    viewModel = Controller.Current.viewModel,
                    dist = Number.MAX_VALUE,
                    snapTranslation = this._v1.set(0, 0, 0);

                this.position.copy(viewModel.diffSnapPosition);

                //try to snap to other components while dragging
                for (var i = curComps.length; i--;) {
                    var curComp = curComps[i];
                    //if (!curComp.isSelected)
                    //    continue;
                    var tr = this.tryGetSnapPosition(curComp, viewModel);
                    if (tr) {
                        var d = tr.lengthSq();
                        if (d < dist) {
                            dist = d;
                            snapTranslation.copy(tr);
                        }

                        break;
                    }
                }

                this.position.add(snapTranslation);
            }

            if (this._updateMove) {
                this._updateMove = false;
                var v = this._vt;
                this.position.add(v);
                v.set(0, 0, 0);

                if (this._positionChanged)
                    clearTimeout(this._positionChanged);

                this._positionChanged = setTimeout(() => {
                    this._positionChanged = 0;
                    this.getObjectHolder().applyObjectPositions();
                }, 500) as any;
            }

            if (!this._needsUpdate)
                return;

            this._needsUpdate = false;

            this.Components.forEach(c => c.update());

        }

        getById(id: string): ConduitComponent {
            if (!id)
                return null;
            var mObjs = this.children;
            for (var i = mObjs.length; i--;) {
                var mObj = mObjs[i] as ConduitComponent;
                if (mObj.IdString === id)
                    return mObj;
            }
            return null;
        }

        dispose(): void {
            if (!this._isDisposed) {
                this._isDisposed = true;
                this._subscriptions.splice(0, this._subscriptions.length).forEach(sub => {
                    sub.dispose();
                });
                this.Components.splice(0, this.Components.length).forEach(c => c.dispose());
                //this.clear();
                spt.ThreeJs.utils.disposeObject3D(this, true, true, false);
                var instances = this.getObjectHolder().conduitObjects;
                if (this._idString && instances[this._idString])
                    delete instances[this._idString];
            }
        }

        getBounds(): THREE.Box3 {
            return this.componentBounds;
        }

        getComponentByBounds(searchBounds: THREE.Box3) {
            return this.Components.filter(comp => comp.getBounds().intersectsBox(searchBounds));
        }

        intersecsTestVolume(testVolume: spt.ThreeJs.utils.TestVolume, linePrecision: number): boolean {

            var matrixWorld = this.matrixWorld,
                worldBounds = this._box1.copy(this.getBounds()).applyMatrix4(matrixWorld).expandByScalar(linePrecision);

            if (!testVolume.testBoxForSeparationAxis(worldBounds))
                return this.Components.some(c => c.intersecsTestVolume(testVolume, linePrecision));

            return false;
        }

        getComponentsByTestVolume(testVolume: spt.ThreeJs.utils.TestVolume, linePrecision: number): ConduitComponent[] {

            var matrixWorld = this.matrixWorld,
                worldBounds = this._box1.copy(this.getBounds()).applyMatrix4(matrixWorld).expandByScalar(linePrecision);

            if (!testVolume.testBoxForSeparationAxis(worldBounds))
                return this.Components.filter(c => c.intersecsTestVolume(testVolume, linePrecision));

            return [];
        }

        tryConnect(component: ConduitComponent, toleranceSq?: number) {
            //if (component.ConduitObject)
            //    return false;

            var comps = this.getComponentByBounds(component.getBounds().expandByScalar(10));
            for (var i = comps.length; i--;) {
                var comp = comps[i];
                if (comp.tryConnect(component, toleranceSq)) {
                    this.Components.push(component);
                    this.updateConnections(component);
                    return true;
                }
            }

            return false;
        }

        tryConnectObject(otherObject: ConduitObject, toleranceSq?: number) {
            if (!otherObject || this === otherObject || (otherObject.ComponentSetID && (this.ComponentSetID !== otherObject.ComponentSetID)))
                return false;

            var otherComps = otherObject.Components;

            for (var i = otherComps.length; i--;) {
                var otherComp = otherComps[i],
                    comps = this.getComponentByBounds(otherComp.getBounds().expandByScalar(10));
                for (var j = comps.length; j--;) {
                    var comp = comps[j];
                    if (comp.tryConnect(otherComp, toleranceSq)) {

                        otherComps = otherObject.Components.slice();
                        otherObject.Components.removeAll();
                        ko.tasks.runEarly();
                        otherComps.forEach(c => { this.Components.push(c); });
                        otherObject.save();
                        this.save();

                        return true;
                    }
                }
            }

            return false;
        }

        updateConnections(component: ConduitComponent) {
            var components = this.getComponentByBounds(component.getBounds().expandByScalar(10));
            for (var i = 0, l = components.length; i < l; i++) {
                var comp = components[i];
                if (comp !== component && (component.tryConnect(comp) || comp.tryConnect(component)))
                    continue;
            }
        }

        updateAllConnections() {
            this.Components.forEach(c => c.buildMesh());
            this.Components.forEach(this.updateConnections.bind(this));
        }

        getClosestEntryPoint(point: THREE.Vector3, target: THREE.Vector3): ConduitComponent {
            var len = Number.MAX_VALUE,
                tp = this._v1,
                comps = this.Components,
                res: ConduitComponent = null;

            for (var i = comps.length; i--;) {
                var comp = comps[i];
                comp.getClosestEntryPoint(point, tp);
                var l = tp.distanceToSquared(point);
                if (l < len) {
                    len = l;
                    target.copy(tp);
                    res = comp;
                }
            }

            return res;
        }

        getRoute(compFrom: ConduitComponent, compTo: ConduitComponent, start: THREE.Vector3, end: THREE.Vector3) {
            var comps = this.searchRoute(compFrom, compTo);

            var route = [start];

            for (var i = comps.length; i--;) {
                var comp = comps[i];
                route.push(comp.position);
            }

            route.push(end);

            return route;
        }

        private searchRoute(startComp: ConduitComponent, endComp: ConduitComponent) {

            var start = new ComponentNode(startComp);

            if (startComp === endComp)
                return [] as ConduitComponent[];

            var end = new ComponentNode(endComp);

            var wm = new ComponentNodeMap();//new WeakMap<ConduitComponent, ComponentNode>();

            wm.set(startComp, start);
            wm.set(endComp, end);

            start.MinDistToStart = 0;

            start.DistanceToEnd = startComp.position.distanceTo(endComp.position);

            var hashSet = [start];

            do {
                hashSet.sort((a, b) => b.DistanceToEnd - a.DistanceToEnd);
                var node = hashSet.pop();

                if (node === end) {
                    node.Visited = true;
                    break;
                }
                var connections = node.Current.Connections;

                for (var i = connections.length; i--;) {
                    var edge = connections[i];
                    if (!edge.connected)
                        continue;
                    var nextComp = edge.connected.parent;
                    var nextNode = wm.get(nextComp);
                    if (!nextNode) {
                        nextNode = new ComponentNode(nextComp);
                        wm.set(nextComp, nextNode);
                    }
                    var edgeDist = node.Current.position.distanceTo(nextComp.position);
                    var nextNodeCost = node.MinDistToStart + edgeDist;
                    if (nextNode.MinDistToStart > nextNodeCost) {
                        nextNode.MinDistToStart = nextNodeCost;
                        nextNode.NearestToStart = node;
                        if (!nextNode.Visited && hashSet.indexOf(nextNode) === -1) {
                            nextNode.DistanceToEnd = nextComp.position.distanceTo(endComp.position);
                            hashSet.push(nextNode);
                        }
                    }
                }

                node.Visited = true;
            } while (hashSet.length);

            if (!end.Visited)
                return [] as ConduitComponent[];

            var path = [end],
                nd = end;

            while (nd.NearestToStart) {
                nd = nd.NearestToStart;
                path.push(nd);
            }

            //path.reverse();

            return path.map(n => n.Current);
        }

        setData(data: SolarProTool.IConduitObject) {
            if (!data)
                return this;

            this.IdString = data.IdString;
            this.ComponentSetID = data.ComponentSetID;

            Object.keys(data).forEach(k => {
                if (k !== "Components" && !k.startsWith("_"))
                    this[k] = data[k];
            });

            if (data.Components && data.Components.length) {
                if (this.Components.length) {
                    var comps = this.Components.slice();
                    comps.forEach(comp => comp.removeInstance(true));
                }
                data.Components.forEach(comp => {
                    var component = new ConduitComponent(comp.IdString, comp);
                    this.Components.push(component);
                });
            }

            this._needsUpdate = true;

            return this;
        }

        exportData(noComponents?: boolean): SolarProTool.IConduitObject {
            this.updateMatrix();

            return {
                IdString: this.IdString || spt.Utils.GuidEmpty(),
                ComponentSetID: this.ComponentSetID || spt.Utils.GuidEmpty(),
                Components: !noComponents && !this._isDisposed && this.Components ? this.Components.map(c => c.exportData()) : null,
                IsActive: !this._isDisposed, // System.Boolean
                IsDeleted: !!this._isDisposed, // System.Boolean
                ClientOptions: 0, // enum SPTSettingInterface.Client3DObectOptions
                DrawOptions: 0, // enum SPTSettingInterface.DrawOptionsEnum
                ErrorCode: 0, // enum SPTEnums.ErrorCodeEnum
                InstanceColor: 0, // System.Int32
                IsInErrorState: false, // System.Boolean
                X: 0, // System.Double
                Y: 0, // System.Double
                Z: 0, // System.Double
                SizeX: 0, // System.Double
                SizeY: 0, // System.Double
                Width: 0, // System.Double
                Height: 0, // System.Double
                IconInsertIndex: 0 // System.Int32
            };
        }

        _revData: SolarProTool.IConduitObject;

        save() {
            this.getObjectHolder().saveConduitObject(this);
        }
    }

    class ComponentNode {

        constructor(current: ConduitComponent) {
            this.Current = current;
        }

        MinDistToStart = Number.MAX_VALUE;
        Current: ConduitComponent;
        NearestToStart: ComponentNode = null;
        Visited = false;
        DistanceToEnd = 0;
    }

    class ComponentNodeMap {
        private map: { [id: string]: ComponentNode } = {};

        set(co: ConduitComponent, n: ComponentNode) {
            this.map[co.id] = n;
        }

        get(co: ConduitComponent) {
            return this.map[co.id];
        }
    }

    export class ConduitObjectHolder extends GenericObjectHolder<ConduitComponent> {

        conduitObjects: { [id: string]: ConduitObject } = {};
        private _saveObjects: Set<ConduitObject> = new Set<ConduitObject>();
        private _checkGroups = false;

        private _showPolylines: boolean = false;

        get showPolylines() {
            return this._showPolylines;
        }

        set showPolylines(v: boolean) {
            this._showPolylines = v;

            var conduitObjects = this.getAllConduitObjects();
            for (var i = conduitObjects.length; i--;) {
                var comps = conduitObjects[i].Components;
                for (var j = comps.length; j--;) {
                    var comp = comps[j];
                    comp.showPolylines = v;
                }
            }

            Controller.Current.viewModel.tools.conduitTool.componentPoolForeach(comp => {
                comp.showPolylines = v;
            });
        }

        private _showCables: boolean = false;

        get showCables() {
            return this._showCables;
        }

        set showCables(v: boolean) {
            this._showCables = v;

            this.updateCables();
        }

        private _v1 = new THREE.Vector3();
        private _v2 = new THREE.Vector3();
        private _v3 = new THREE.Vector3();
        private _v4 = new THREE.Vector3();

        hoverObject: ConduitComponent = null;

        setHoverObject(o: IObjectIntersection<ConduitComponent>) {
            this.setHoverComponent(o && o.o);
        }

        setHoverComponent(c: ConduitComponent) {
            if (this.hoverObject) {
                this.hoverObject.isHovered = false;
                this.hoverObject = null;
            }
            if (c) {
                c.isHovered = true;
                this.hoverObject = c;
            }
        }

        insertComponent(component: ConduitComponent, compSetId: string) {
            if (!component || !compSetId)
                return;
            if (!component.isBuild)
                component.buildMesh();

            component.updateMatrix();

            if (component.ConduitObject) {
                component.disconnectAll();
                component.ConduitObject.Components.remove(component);
                ko.tasks.runEarly();
            }

            var conduitObjects = this.getAllConduitObjects();

            for (var i = conduitObjects.length; i--;) {
                var co = conduitObjects[i];
                if (co.ComponentSetID === compSetId && co.tryConnect(component)) {
                    co.save();
                    return;
                }
            }

            var conduitObject = this.addNewConduitObject(spt.Utils.GenerateGuid(), compSetId);
            conduitObject.Components.push(component);
            conduitObject.save();
        }

        addNewConduitObject(id: string, compSetId?: string, data?: SolarProTool.IConduitObject): ConduitObject {
            var so = new ConduitObject(id, compSetId, data);
            this.add(so);
            return so;
        }

        getShortestCableConnection(from: THREE.Vector3, to: THREE.Vector3) {
            var result: THREE.Vector3[] = null;

            var conduitObjects = this.getAllConduitObjects(),
                s = this._v1,
                e = this._v2,
                compStart: ConduitComponent = null,
                compEnd: ConduitComponent = null,
                start = this._v3.copy(from),
                end = this._v4.copy(to),
                len = Math.abs(to.x - from.x) + Math.abs(to.y - from.y) + 1000;

            for (var i = conduitObjects.length; i--;) {
                var co = conduitObjects[i];

                var comp1 = co.getClosestEntryPoint(from, s);
                var comp2 = co.getClosestEntryPoint(to, e);

                var sLen = Math.abs(s.x - from.x) + Math.abs(s.y - from.y);
                var eLen = Math.abs(e.x - to.x) + Math.abs(e.y - to.y);

                var l = sLen + eLen;
                if (l < len) {
                    len = l;
                    compStart = comp1;
                    compEnd = comp2;
                    start.copy(s);
                    end.copy(e);
                }
            }

            if (compStart)
                result = compStart.ConduitObject.getRoute(compStart, compEnd, start, end);

            if (!result)
                result = [start, end];

            if (result[0].distanceToSquared(from) > 0)
                result.unshift(from);

            if (result[result.length - 1].distanceToSquared(to) > 0)
                result.push(to);

            return result;
        }

        updateCables() {
            //LS.Electric.InvertersModel
            var controller = Controller.Current,
                viewModel = controller.viewModel;

            var lineSegmentsHelper = controller.lineSegmentsHelper;

            if (lineSegmentsHelper.hasSegments())
                lineSegmentsHelper.clear();

            if (!this.showCables)
                return;

            var stringObjects = controller.stringObjectHolder.getAllStringObjects(),
                stringingTool = viewModel.tools.stringingTool;

            for (var i = stringObjects.length; i--;) {
                var stringObject = stringObjects[i],
                    mso = stringObject.getModulesStringObject(),
                    diffpoint = stringObject.getDiffusionPointObject();

                if (!diffpoint || !mso || !stringObject.Joints.length)
                    continue;

                var dp = stringingTool.getPositionFromClientObjectInstance(diffpoint),
                    color = stringObject.stringColor1,
                    joint = stringObject.Joints[0],
                    jp = joint.position,
                    f2 = stringObject.Joints.length <= 1 ? 100 : 200,
                    f1 = f2 / 2,
                    xOff = (Math.abs(jp.y) * 0.05) % f2 - f1,
                    yOff = (Math.abs(jp.x) * 0.05) % f2 - f1,
                    zOff = (xOff + yOff + f2) * 0.5,
                    jz = jp.z + f1 + zOff,
                    path = this.getShortestCableConnection(dp, jp);

                lineSegmentsHelper.addPolyline(path.map(p => new THREE.Vector3(p.x + xOff, p.y + yOff, jz)), color, true);

                if (stringObject.Joints.length > 2) {
                    joint = stringObject.Joints[stringObject.Joints.length - 1];

                    path = this.getShortestCableConnection(dp, joint.position);

                    lineSegmentsHelper.addPolyline(path.map(p => new THREE.Vector3(p.x + xOff, p.y + yOff, jz)), color, true);
                }
            }

        }

        getAllConduitObjects() {
            return this.children as ConduitObject[];
        }

        getClosestByPosition(p: THREE.Vector3, tolerance: number): IObjectIntersection<ConduitComponent> {
            var sos = this.getByBox(new THREE.Box3().expandByPoint(p).expandByScalar(tolerance)),
                minDist = Number.MAX_VALUE,
                thresholdSq = tolerance * tolerance,
                result = null as IObjectIntersection<ConduitComponent>;

            sos.forEach(so => {
                var cp = so.getClosestPoint(p, thresholdSq);
                if (cp && cp.distSq < thresholdSq && cp.distSq < minDist) {
                    minDist = cp.distSq;
                    result = cp;
                }

            });

            return result;
        }

        getByBox(searchBox: THREE.Box3): ConduitComponent[] {
            var result: ConduitComponent[] = [],
                cos = this.getAllConduitObjects();
            if (cos.length) {
                cos.filter((mObj: ConduitObject) => mObj.intersecsBox(searchBox)).forEach((mObj: ConduitObject) => {
                    result.push.apply(result, mObj.getComponentByBounds(searchBox));
                });
            }
            return result;
        }

        getEndPositionByRaycaster(raycaster: THREE.Raycaster, tolerance?: number): IObjectIntersection<ConduitComponent> {
            var ray = raycaster.ray;

            var thresholdSq = Math.pow(tolerance || raycaster.params.Line.threshold, 2);

            var sos = this.getAllConduitObjects();

            var result = null as IObjectIntersection<ConduitComponent>;
            var minDist = Number.MAX_VALUE;

            for (var i = sos.length; i--;) {
                var so = sos[i] as ConduitObject;

                var cp = so.getClosestEndPositionToRay(ray, thresholdSq);
                if (cp && cp.distSq < thresholdSq && cp.distSq < minDist) {
                    minDist = cp.distSq;
                    result = cp;
                }
            }

            return result;
        }

        getByRaycaster(raycaster: THREE.Raycaster, tolerance?: number): IObjectIntersection<ConduitComponent> {
            var ray = raycaster.ray;

            var thresholdSq = Math.pow(tolerance || raycaster.params.Line.threshold, 2);

            var sos = this.getAllConduitObjects();

            var result = null as IObjectIntersection<ConduitComponent>;
            var minDist = Number.MAX_VALUE;

            for (var i = sos.length; i--;) {
                var so = sos[i] as ConduitObject;

                var cp = so.getClosestPointToRay(ray, thresholdSq);
                if (cp && cp.distSq < thresholdSq && cp.distSq < minDist) {
                    minDist = cp.distSq;
                    result = cp;
                }
            }

            return result;
        }

        getByTestVolume(testVolume: spt.ThreeJs.utils.TestVolume): ConduitComponent[] {
            var controller = Controller.Current,
                linePrecision = controller.linePrecision,
                result: ConduitComponent[] = [],
                cos = this.getAllConduitObjects();

            if (cos.length) {
                cos.forEach((mObj: ConduitObject) => {
                    result.push.apply(result, mObj.getComponentsByTestVolume(testVolume, linePrecision));
                });
            }
            return result;
        }

        getComponentByRaycaster(raycaster: THREE.Raycaster, tolerance?: number): ConduitComponent {
            var o = this.getByRaycaster(raycaster, tolerance) as IObjectIntersection<ConduitComponent>;
            return o && o.o;
        }

        closestCenterByRaycaster(raycaster: THREE.Raycaster, tolerance?: number): IObjectIntersection<ConduitComponent> {
            var ray = raycaster.ray;

            var threshold = tolerance || raycaster.params.Line.threshold;
            var thresholdSq = threshold * threshold;

            var sos = this.getAllConduitObjects();

            var result = null as IObjectIntersection<ConduitComponent>;
            var minDist = Number.MAX_VALUE;

            for (var i = sos.length; i--;) {
                var so = sos[i];

                var cp = so.getClosestCenterToRay(ray, threshold);
                if (cp && cp.distSq < thresholdSq && cp.distSq < minDist) {
                    minDist = cp.distSq;
                    result = cp;
                }
            }

            return result;
        }

        closestCenterByRay2D(origin: THREE.Vector3, direction: THREE.Vector3): IObjectIntersection<ConduitComponent> {
            var sos = this.getAllConduitObjects();

            var result = null as IObjectIntersection<ConduitComponent>;
            var minDist = Number.MAX_VALUE;

            for (var i = sos.length; i--;) {
                var so = sos[i];

                var cp = so.getClosestByRay2D(origin, direction);
                if (cp && cp.distSq < minDist) {
                    minDist = cp.distSq;
                    result = cp;
                }
            }

            return result;
        }

        applyObjectPositions() {
            var cos = this.getAllConduitObjects();

            for (var i = cos.length; i--;) {
                var co = cos[i];
                if (co.position.lengthSq() > 0) {
                    co.applyObjectPosition();
                }
            }

            Controller.Current.viewModel.selectionUiNeedsUpdate = true;
        }

        setConduitObjects(conduitObjects: SolarProTool.IConduitObject[]) {
            this.clear();

            if (!conduitObjects || !conduitObjects.length)
                return;

            conduitObjects.forEach(data => {
                var co = this.addNewConduitObject(data.IdString, data.ComponentSetID, data);
                co.updateAllConnections();
            });

            //update conduit rev datas
            this.getAllConduitObjects().forEach(co => {
                co._revData = co.exportData();
            });
        }
        
        checkGroups() {
            this._checkGroups = true;
        }

        updateObjects() {
            if (this._checkGroups) {
                this._checkGroups = false;
                this.checkForSeperatedGroups();
                this.checkForConnectedGroups();
            }
            super.updateObjects();
            this.updateSaveConduitObjects();
        }

        groupConnectedComponents(components: ConduitComponent[]) {

            var result: ConduitComponent[][] = [];

            if (!components.length)
                return result;

            var workset: ConduitConnection[] = components[0].Connections.slice(),
                rSet: ConduitComponent[] = [components[0]],
                comps = components.slice(1);

            result.push(rSet);

            while (comps.length > 0) {

                while (workset.length > 0 && comps.length) {
                    var con = workset.splice(0, 1)[0];
                    if (con.connected) {
                        var comp = con.connected.parent,
                            cIdx = comps.indexOf(comp);
                        if (cIdx >= 0) {
                            rSet.push(comps[cIdx]);
                            workset.push.apply(workset, comps.splice(cIdx, 1)[0].Connections);
                        }
                    }
                }
                if (comps.length > 0) {
                    rSet = [comps[0]];
                    result.push(rSet);
                    workset = comps.splice(0, 1)[0].Connections.slice();
                }
            }

            return result;
        }

        removeObject(so: ConduitComponent, skipUpdate?: boolean) {
            if (!so)
                return;
            so.disconnectAll();

            var conduitObject = so.ConduitObject;

            if (conduitObject) {
                conduitObject.Components.remove(so);

                this.checkGroups();

                if (!skipUpdate)
                    conduitObject.save();
            }

            so.dispose();
        }

        checkForSeperatedGroups() {
            var viewModel = Controller.Current.viewModel.tools.conduitTool.viewModel;

            //seperate groups if needed
            this.getAllConduitObjects().slice().forEach(conduitObject => {
                if (conduitObject.Components.length > 1) {
                    var compGroups = this.groupConnectedComponents(conduitObject.Components);
                    if (compGroups.length > 1) {
                        var compSetId = conduitObject.ComponentSetID,
                            separatedComps: ConduitComponent[] = [];

                        for (var i = 1, l = compGroups.length; i < l; i++) {
                            compGroups[i].forEach(comp => {
                                comp.disconnectAll();
                                conduitObject.Components.remove(comp);
                                separatedComps.push(comp);
                            });
                        }

                        ko.tasks.runEarly();

                        separatedComps.forEach(c => {
                            this.insertComponent(c, compSetId);
                        });
                    }
                }
            });
        }

        checkForConnectedGroups() {
            //join groups if possible
            var conduitObjects = this.getAllConduitObjects(),
                len = conduitObjects.length;

            for (var i = 0; i < len; i++) {
                var co1 = conduitObjects[i];
                for (var j = i + 1; j < len; j++) {
                    var co2 = conduitObjects[j];

                    if (co1.tryConnectObject(co2)) {
                        this.checkForConnectedGroups();
                        return;
                    }
                }
            }
        }

        removeConduitObject(o: ConduitObject) {
            if (!o)
                return;
            this.remove(o);
            o.dispose();
            this.saveConduitObject(o);
        }

        saveConduitObject(co: ConduitObject) {
            if (!co)
                return;
            if (!co.Components.length && !co._isDisposed) {
                this.removeConduitObject(co);
                return;
            }
            this._saveObjects.add(co);
        }

        updateCheckpoint(objs: ConduitObject[]) {
            var ids = objs.map(o => o.IdString),
                oldDatas = objs.map(o => {
                    var rd = o._revData;
                    if (!rd) {
                        rd = o.exportData(true);
                        rd.IsDeleted = true;
                        rd.IsActive = false;
                    }
                    return rd;
                }),
                newDatas = objs.map(o => { return o._revData = o.exportData(); });
            
            Controller.Current.updateManager.dynamicUpdate(() => {
                var conduitObjectHolder = Controller.Current.conduitObjectHolder;

                for (var i = ids.length; i--;) {
                    var id = ids[i],
                        d = newDatas[i],
                        co = conduitObjectHolder.conduitObjects[id] as ConduitObject;
                    if (!co) {
                        if (!d.IsDeleted) {
                            co = conduitObjectHolder.addNewConduitObject(d.IdString, d.ComponentSetID, d);
                            co.updateAllConnections();
                            co._revData = d;
                        }
                    } else {
                        if (d.IsDeleted) {
                            conduitObjectHolder.remove(co);
                            co.dispose();
                        } else {
                            co.setData(d);
                            co.updateAllConnections();
                            co._revData = d;
                        }
                    }
                }
                conduitObjectHolder._saveObjects.clear();
                conduitObjectHolder.SaveConduitObjects(newDatas);
            }, () => {
                var conduitObjectHolder = Controller.Current.conduitObjectHolder;

                for (var i = ids.length; i--;) {
                    var id = ids[i],
                        d = oldDatas[i],
                        co = conduitObjectHolder.conduitObjects[id] as ConduitObject;
                    if (!co) {
                        if (!d.IsDeleted) {
                            co = conduitObjectHolder.addNewConduitObject(d.IdString, d.ComponentSetID, d);
                            co.updateAllConnections();
                            co._revData = d;
                        }
                    } else {
                        if (d.IsDeleted) {
                            conduitObjectHolder.remove(co);
                            co.dispose();
                        } else {
                            co.setData(d);
                            co.updateAllConnections();
                            co._revData = d;
                        }
                    }
                }
                conduitObjectHolder._saveObjects.clear();
                conduitObjectHolder.SaveConduitObjects(oldDatas);
            });
        }

        private updateSaveConduitObjects() {
            if (!this._saveObjects.size)
                return;
            var saved = this._saveObjects,
                objs: ConduitObject[] = [];
            saved.forEach(co => {
                objs.push(co);
            });
            saved.clear();

            this.updateCheckpoint(objs);
            this.SaveConduitObjects(objs.map(co => co._revData));
        }

        SaveConduitObjects(objs: SolarProTool.IConduitObject[]) {
            if (!objs || !objs.length)
                return;

            SolarProTool.Ajax("Areas/Electric/WebServices/ElectricServices.asmx").Call("SetConduitObjects").Data({ conduitObjects: objs }).CallBack((result) => {
                if (!result)
                    console.error("Error on calling SetConduitObjects.");
            });
        }
    }
}