interface Window {
    CheckStaticAndLoadBackup: () => void;
    ReDrawConstruction: (checkstatic?: boolean) => void;
    DrawStaticOnLoad: (checkstatic?: boolean) => void;
    GetCurrentCADLayersUri: (seperator?: string) => string;
    GetCurrentCameraMvmUri: (seperator?: string) => string;
    ShowAo3dHelp: () => void;
}

module LS.Client3DEditor {
    export function switchToAnordnung(e?: JQueryEventObject) {
        if (e)
            e.preventDefault();

        if (window.EditorViewMode === "Anordnung" || LoaderManager.isLoading()) {
            DManager.Navigate($('#_itemAnordnung').attr("href"));
            return;
        }
        var viewModel = Controller.Current.viewModel;

        if (viewModel && ToolBox && viewModel.FlyoutVisible) {
            viewModel.ShowInverterFlyout = false;
            ToolBox.toggleFlyout();
        }
        if (viewModel && viewModel.selectedTool) {
            viewModel.deselectTool();
            ko.tasks.runEarly();
            setTimeout(() => { switchToAnordnung(); }, 0);
            return;
        }

        if (window["OnEnd" + window.EditorViewMode])
            window["OnEnd" + window.EditorViewMode]();

        //See AnordnungController Edit
        window.EditorViewMode = "Anordnung";

        LoaderManager.addLoadingJob();

        $.ajax({
            url: "/Anordnung/PartialAnordnung", type: "POST", dataType: "html",
            context: document.body,
            data: {},
            contentType: "application/json",
            success: (html) => {
                if (html) {
                    var controller = Controller.Current;
                    controller.updateManager.clearHistory();
                    controller.clearClientObjects(true);
                    //controller.removeRoofObject();

                    //history.pushState({ page: "/Anordnung/Edit" }, document.title, "/Anordnung/Edit?pid=" + spt.Utils.GenerateGuid());
                    history.pushState({ page: "/Anordnung/Edit" }, document.title, "/Anordnung/Edit");

                    var $html = $(html),
                        $cmenu = $html.filter("#ao-cmenu-content"),
                        $tb = $html.filter("#ao-toolbox-content"),
                        $progress = $html.filter("#ao-progress-content");

                    var $flyOutContainer = $('#ao-tools-overview');
                    ko.cleanNode($flyOutContainer.get(0));
                    $flyOutContainer.empty().append($tb.contents());
                    ko.applyBindings(controller.viewModel, $flyOutContainer.get(0));
                    controller.viewModel.EditorViewMode = window.EditorViewMode;

                    clearExtraContent();

                    ThemeManager.ResetTheme("Anordnung");

                    $('#clientEditor-contextmenu').empty().append($cmenu.contents());
                    controller.loadAnordnungObjects();

                    $html.empty();

                    if (window["OnStart" + window.EditorViewMode])
                        window["OnStart" + window.EditorViewMode]();

                    LoaderManager.finishLoadingJob();

                } else
                    DManager.Navigate('/home/index');
            },
            error: (jqXHR, textStatus, errorThrown) => {
                DManager.Navigate('/home/index');
            }
        });
    }

    export function switchToAutoCad(e?: JQueryEventObject) {
        if (e)
            e.preventDefault();

        //var initDialogs = !$('#ScaleContainer').length;

        if (window.EditorViewMode === "AutoCAD" || LoaderManager.isLoading()) {
            DManager.Navigate($('#_itemAutoCAD').attr("href"));
            return;
        }

        var viewModel = Controller.Current.viewModel;

        if (viewModel && ToolBox && viewModel.FlyoutVisible) {
            viewModel.ShowInverterFlyout = false;
            ToolBox.toggleFlyout();
        }
        if (viewModel && viewModel.selectedTool) {
            viewModel.deselectTool();
            ko.tasks.runEarly();
            setTimeout(() => { switchToAutoCad(); }, 0);
            return;
        }

        if (window["OnEnd" + window.EditorViewMode])
            window["OnEnd" + window.EditorViewMode]();

        //See AutoCADController Edit
        window.EditorViewMode = "AutoCAD";

        LoaderManager.addLoadingJob();

        $.ajax({
            url: "/AutoCAD/PartialAutoCad", type: "POST", dataType: "html",
            context: document.body,
            data: {},
            contentType: "application/json",
            success: (html) => {

                if (html) {
                    var controller = Controller.Current;
                    controller.updateManager.clearHistory();
                    controller.clearClientObjects(true);
                    //controller.removeRoofObject();

                    //history.pushState({ page: "/AutoCAD/Edit" }, document.title, "/AutoCAD/Edit?pid=" + spt.Utils.GenerateGuid());
                    history.pushState({ page: "/AutoCAD/Edit" }, document.title, "/AutoCAD/Edit");

                    var $html = $(html),
                        $cmenu = $html.filter("#cad-cmenu-content"),
                        $tb = $html.filter("#cad-layers-content");

                    var $flyOutContainer = $('#ao-tools-overview');
                    ko.cleanNode($flyOutContainer.get(0));
                    $flyOutContainer.empty().append($tb.contents());
                    ko.applyBindings(controller.viewModel, $flyOutContainer.get(0));
                    controller.viewModel.EditorViewMode = window.EditorViewMode;

                    clearExtraContent();

                    ThemeManager.ResetTheme("AutoCAD");

                    $('#clientEditor-contextmenu').empty().append($cmenu.contents());
                    controller.loadCADObjects(true, true);

                    $html.empty();

                    if ($('#ScaleContainer').length)
                        DManager.init('ScaleContainer', 400, "auto", SetWidthDialogTitleBarWidth, SetPaddingBack);

                    if (window["OnStart" + window.EditorViewMode])
                        window["OnStart" + window.EditorViewMode]();

                    LoaderManager.finishLoadingJob();
                } else
                    DManager.Navigate('/home/index');
            },
            error: (jqXHR, textStatus, errorThrown) => {
                DManager.Navigate('/home/index');
            }
        });
    }

    export function switchToStatic(e?: JQueryEventObject) {
        if (e)
            e.preventDefault();

        //var initDialogs = !$('#ScaleContainer').length;

        if (window.EditorViewMode === "Static" || LoaderManager.isLoading()) {
            DManager.Navigate($('#_itemStatic').attr("href"));
            return;
        }

        var viewModel = Controller.Current.viewModel;

        if (viewModel && ToolBox && viewModel.FlyoutVisible) {
            viewModel.ShowInverterFlyout = false;
            ToolBox.toggleFlyout();
        }
        if (viewModel && viewModel.selectedTool) {
            viewModel.deselectTool();
            ko.tasks.runEarly();
            setTimeout(() => { switchToStatic(); }, 0);
            return;
        }

        if (window["OnEnd" + window.EditorViewMode])
            window["OnEnd" + window.EditorViewMode]();

        //See StaticController Edit
        window.EditorViewMode = "Static";

        LoaderManager.addLoadingJob();

        $.ajax({
            url: "/Static/PartialStatic", type: "POST", dataType: "html",
            context: document.body,
            data: {},
            contentType: "application/json",
            success: (html) => {

                if (html) {
                    var controller = Controller.Current;
                    controller.updateManager.clearHistory();
                    controller.clearClientObjects(true);
                    //controller.removeRoofObject();

                    //history.pushState({ page: "/Static/Edit" }, document.title, "/Static/Edit?pid=" + spt.Utils.GenerateGuid());
                    history.pushState({ page: "/Static/Edit" }, document.title, "/Static/Edit");

                    var $html = $(html),
                        $cmenu = $html.filter("#static-cmenu-content"),
                        $tb = $html.filter("#static-layers-content");

                    var $flyOutContainer = $('#ao-tools-overview');
                    ko.cleanNode($flyOutContainer.get(0));
                    $flyOutContainer.empty().append($tb.contents());
                    ko.applyBindings(controller.viewModel, $flyOutContainer.get(0));
                    controller.viewModel.EditorViewMode = window.EditorViewMode;

                    clearExtraContent();

                    ThemeManager.ResetTheme("Static");

                    $('#clientEditor-contextmenu').empty().append($cmenu.contents());
                    controller.loadStaticObjects(true, true);

                    $html.empty();

                    if (window["OnStart" + window.EditorViewMode])
                        window["OnStart" + window.EditorViewMode]();

                    LoaderManager.finishLoadingJob();
                } else
                    DManager.Navigate('/home/index');
            },
            error: (jqXHR, textStatus, errorThrown) => {
                DManager.Navigate('/home/index');
            }
        });

    }

    export function switchToElectric(e?: JQueryEventObject) {
        if (e)
            e.preventDefault();

        if (window.EditorViewMode === "Electric" || LoaderManager.isLoading()) {
            DManager.Navigate($('#_itemElectric').attr("href"));
            return;
        }
        var viewModel = Controller.Current.viewModel;
        if (viewModel && ToolBox && viewModel.FlyoutVisible) {
            viewModel.ShowInverterFlyout = false;
            ToolBox.toggleFlyout();
        }
        if (viewModel && viewModel.selectedTool) {
            viewModel.deselectTool();
            ko.tasks.runEarly();
            setTimeout(() => { switchToElectric(); }, 0);
            return;
        }

        if (window["OnEnd" + window.EditorViewMode])
            window["OnEnd" + window.EditorViewMode]();

        //See ElectricController Edit
        window.EditorViewMode = "Electric";

        LoaderManager.addLoadingJob();

        $.ajax({
            url: "/Electric/Electric/PartialElectric", type: "POST", dataType: "html",
            context: document.body,
            data: {},
            contentType: "application/json",
            success: (html) => {
                if (html) {
                    var controller = Controller.Current;
                    controller.updateManager.clearHistory();
                    controller.clearClientObjects(true);
                    //controller.removeRoofObject();

                    //history.pushState({ page: "/Electric/Electric/Edit" }, document.title, "/Electric/Electric/Edit?pid=" + spt.Utils.GenerateGuid());
                    history.pushState({ page: "/Electric/Electric/Edit" }, document.title, "/Electric/Electric/Edit");

                    var $html = $(html),
                        $cmenu = $html.filter("#electric-cmenu-content"),
                        $tb = $html.filter("#electric-toolbox-content"),
                        $bc = $html.filter("#electric-body-content");

                    var $flyOutContainer = $('#ao-tools-overview');
                    ko.cleanNode($flyOutContainer.get(0));
                    $flyOutContainer.empty().append($tb.contents());
                    ko.applyBindings(controller.viewModel, $flyOutContainer.get(0));
                    controller.viewModel.EditorViewMode = window.EditorViewMode;

                    clearExtraContent();

                    $('#ao-extra-content').append($bc.contents());

                    ThemeManager.ResetTheme("Electric");

                    $('#clientEditor-contextmenu').empty().append($cmenu.contents());

                    $html.empty();

                    if ($('#ScaleContainer').length)
                        DManager.init('ScaleContainer', 400, "auto", SetWidthDialogTitleBarWidth, SetPaddingBack);

                    controller.loadElectricObjects(true, () => {
                        if (window["OnStart" + window.EditorViewMode])
                            window["OnStart" + window.EditorViewMode]();
                    });

                    LoaderManager.finishLoadingJob();

                } else
                    DManager.Navigate('/home/index');
            },
            error: (jqXHR, textStatus, errorThrown) => {
                DManager.Navigate('/home/index');
            }
        });
    }

    export function clearExtraContent() {
        $('#infoPlaceholder').empty();
        $('#electric-body-content').empty();
    }
    
    //Global window functions

    window.ReDrawConstruction = (checkstatic?: boolean) => {
        var controller = Controller.Current;
        controller.updateManager.clearHistory();
        controller.clearClientObjects(true);
        switch (window.EditorViewMode) {
            case "Static":
                controller.loadStaticObjects(false, checkstatic);
                break;
            default: //case "AutoCAD":
                controller.loadCADObjects(false, checkstatic);
                break;
        }
    };

    window.GetCurrentCADLayersUri = (seperator?: string) => {
        var layers = [];
        $('input[name=Layer]:checked').each((idx, elem) => {
            var val = $(elem).val() as string;
            if (val.indexOf('|') != -1) {
                layers.push(val.split('|')[0]);
                layers.push(val.split('|')[1]);
            }
            else {
                layers.push($(elem).val());
            }

        });
        if (!seperator)
            seperator = "?";
        if (layers.length) {
            return seperator + "layer=" + layers.join("+");
        }
        return seperator + "layer=+";
    };

    window.GetCurrentCameraMvmUri = (seperator?: string) => {
        if (!seperator)
            seperator = "?";
        if (Controller.Current && Controller.Current.orthoCamera) {
            var m = Controller.Current.orthoCamera.matrixWorldInverse;
            if (m)
                return seperator + "mvm=" + spt.Utils.arrayFrom(m.elements).join("+");
        }
        return seperator + "mvm=+";
    };

    window.DrawStaticOnLoad = (checkstatic?: boolean) => {
        window.ReDrawConstruction(checkstatic);
    };

    window.CheckStaticAndLoadBackup = () => {
        if (!isElevation) {
            window.CheckStatic();
        } else {
            AManager.Ajax('AppServices.asmx', 'CheckLoadBackup', "{}", (result) => {
                if (result) {
                    DManager.showErrorMessage(result);
                }
            });
        }
    };

    window.ShowAo3dHelp = () => {
        LoaderManager.addLoadingJob();
        $.ajax({
            url: "/Anordnung/GetAO3DHelpDialog",
            type: "POST",
            dataType: "html",
            data: {},
            success: (data) => {
                $('#ao3dHelpDialog').html(data);
                DManager.show('ao3dHelpDialog');
                LoaderManager.finishLoadingJob();
            },
            error: (jqXHR, textStatus, errorThrown) => {
                LoaderManager.finishLoadingJob();
                OnError(jqXHR, textStatus + " on " + "/Anordnung/GetAO3DHelpDialog", errorThrown);
            }
        });
    };
}