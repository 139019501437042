module LS.Client3DEditor {
    import Interference3DOptions = SolarProTool.Interference3DOptions;

    export interface IDynamicInterferenceObject extends ISelectableUi, SolarProTool.IInterference3DObject {
        isHovered: boolean;
        update(): void;
        readonly instanceContext: InstanceContext;
        readonly object: THREE.Object3D;
        readonly state: InteferenceState;
        id: number;

        height: number;
        posx: number;
        posy: number;
        scalex: number;
        scaley: number;
        sizex: number;
        sizey: number;
        rotation: number;
        locked: boolean;

        setState(interference?: SolarProTool.IInterference3DObject): this;
        setData(data: number[]): void;
        getData(): number[];
        applyOffset(offsetx: number, offsety: number);
        intersectRaycaster(worldRaycaster: THREE.Raycaster): number;
        intersectTestVolume(testVolume: spt.ThreeJs.utils.TestVolume, linePrecision: number): boolean;
        remove(): void;
        dispose(): void;
        positionAndSize(pos: THREE.Vector3, size: THREE.Vector3);

        setHeight(v: number);
        setIsParallel(b: boolean);
        toggleLocked(b: boolean);
    }

    export class DynamicInterferenceObject implements IDynamicInterferenceObject {
        locked = false;
        IsParallel = false;
        Name = "";
        Type = "DynamicInterferenceObject";
        IconX = 16;
        IconY = 7;
        BaseParentId = null;
        TypeId = null;
        IdString = null;
        ClientOptions: Interference3DOptions = 0;
        readonly object: THREE.Object3D;
        readonly editablePolygon: EditablePolygon2D;
        _suppressUserUpdates;
        Settings: SolarProTool.BaseInterferenceSettings = null as SolarProTool.BaseInterferenceSettings;

        Data: number[];

        get sizex() {
            return this.localPolygon.sizeX;
        }

        set sizex(val: number) {
            var v = +val;
            if (v > 0 && this.localPolygon.sizeX !== v) {
                this.localPolygon.sizeX = v;
                this.onUserUpdated();
            }
        }

        get sizey() {
            return this.localPolygon.sizeY;
        }

        set sizey(val: number) {
            var v = +val;
            if (v > 0 && this.localPolygon.sizeY !== v) {
                this.localPolygon.sizeY = v;
                this.onUserUpdated();
            }
        }

        get posx() {
            return this.localPolygon.positionX;
        }

        set posx(val: number) {
            var v = +val;
            if (!isNaN(v) && isFinite(v) && this.localPolygon.positionX !== v) {
                this.localPolygon.positionX = v;
                this.onUserUpdated();
            }
        }

        get posy() {
            return this.localPolygon.positionY;
        }

        set posy(val: number) {
            var v = +val;
            if (!isNaN(v) && isFinite(v) && this.localPolygon.positionY !== v) {
                this.localPolygon.positionY = v;
                this.onUserUpdated();
            }
        }

        get scalex() {
            return this.localPolygon.scaleX;
        }

        set scalex(val: number) {
            var v = +val;
            if (v > 0 && this.localPolygon.scaleX !== v) {
                this.localPolygon.scaleX = v;
                this.onUserUpdated();
            }
        }

        get scaley() {
            return this.localPolygon.scaleY;
        }

        set scaley(val: number) {
            var v = +val;
            if (v > 0 && this.localPolygon.scaleY !== v) {
                this.localPolygon.scaleY = v;
                this.onUserUpdated();
            }
        }

        get rotation() {
            return this.localPolygon.rotation;
        }

        set rotation(val: number) {
            var v = +val;
            if (!isNaN(v) && isFinite(v) && this.localPolygon.rotation !== v) {
                this.localPolygon.rotation = v;
                this.onUserUpdated();
            }
        }

        get isLocked() {
            return this.locked || (/*!Controller.Current.viewModel.EnableMultiEdit && */(!Controller.Current.viewModel.currentInstance || Controller.Current.viewModel.currentInstance.Id !== this.BaseParentId));
        }

        get id(): number {
            return this.object.id;
        }

        set id(v: number) {
            this.object.id = v;
        }

        get isHovered() {
            return this._isHovered;
        }

        set isHovered(v: boolean) {
            if (this._isHovered != v) {
                this._isHovered = v;
                this.onMaterialChanged();
            }
        }

        get isSelected() {
            return this._selected;
        }

        set isSelected(v: boolean) {
            if (this._selected != v) {
                this._selected = !!v;
                //this.onGeometryChanged();
                this.onMaterialChanged();
            }
        }

        readonly state = new InteferenceState();
        readonly localPolygon: ObservablePolygon;
        localPolySubscription: KnockoutSubscription;

        private _geometryDynamic = false;
        _materialNeedsUpdate = true;
        _geometryNeedsUpdate = true;

        //private _gmNeedsUpdate = true;

        //get _geometryNeedsUpdate() {
        //    return this._gmNeedsUpdate;
        //}

        //set _geometryNeedsUpdate(v: boolean) {
        //    if (v) {
        //        var tt = 0;
        //    }
        //    this._gmNeedsUpdate = v;
        //}

        _positionChanged = 0;
        instanceMesh: THREE.Mesh = null;

        _worldBounds = new THREE.Box3();
        _box3d = new THREE.Box3();
        _v1 = new THREE.Vector3();
        _mat = new THREE.Matrix4();
        _matNormal = new THREE.Matrix3();

        get worldBounds(): THREE.Box3 {
            if (this._worldBounds.isEmpty() && this.instanceMesh)
                this._worldBounds.setFromObject(this.instanceMesh);
            return this._worldBounds;
        }

        readonly localBounds: THREE.Box3;

        constructor() {
            ko.track(this);

            this.localPolygon = new ObservablePolygon();
            this.localPolySubscription = this.localPolygon.subscribe(() => {
                if (this._setPointsOnUpdate)
                    this._geometryNeedsUpdate = true;
            });

            this.object = new THREE.Object3D();
            var editablePolygon = this.editablePolygon = new EditablePolygon2D(this.localPolygon, this.onUserUpdated.bind(this), this.onUserMoved.bind(this));
            this.object.add(editablePolygon);

            ko.getObservable(this, 'BaseParentId').subscribe((bpid: string) => {
                var instanceContext: InstanceContext = bpid ? Controller.Current.viewModel.instancesById[bpid] : null;
                if (instanceContext)
                    instanceContext.remove(this.object, true);
            }, this, "beforeChange");

            ko.getObservable(this, 'BaseParentId').subscribe((bpid) => {
                var instanceContext: InstanceContext = bpid ? Controller.Current.viewModel.instancesById[bpid] : null;
                if (instanceContext)
                    instanceContext.add(this.object, true);
            });

            var bounds = new THREE.Box3();

            ko.defineProperty(this, "localBounds", () => {
                bounds.copy(this.localPolygon.bounds);
                bounds.min.setZ(0);
                return bounds;
            });

            Controller.Current.viewModel.interferenceObjects.push(this);
        }

        moveBy(v: THREE.Vector3): void {
            this.editablePolygon.translatePoly(v);
            this.editablePolygon.userEdited = true;
            //this.editablePolygon.position.add(v);
            //this.instanceMesh.position.add(v);

            if (this._positionChanged)
                clearTimeout(this._positionChanged);

            this._positionChanged = setTimeout(() => {
                this._positionChanged = 0;
                Controller.Current.viewModel.editableObjectsManager.applyUserEdited();
                Controller.Current.notifyRefreshView();
            }, 500) as any;
        }

        GetPositionProperty(k: string): number {
            return this.localPolygon.position[k];
        }

        GetSizeProperty(k: string): number {
            return this.localPolygon.size[k];
        }

        OnChanged(viewModel?: ViewModel): void {
            if (this._positionChanged) {
                clearTimeout(this._positionChanged);
                this._positionChanged = 0;
                Controller.Current.viewModel.editableObjectsManager.applyUserEdited();
            }
        }

        applyObjectPosition(): void {

        }

        duplicate(count?: number, distance?: number, direction?: THREE.Vector3): void {

        }

        rotateByPivot(c: THREE.Vector3, angleRad: number) {
            var m = new THREE.Matrix4().makeTranslation(c.x, c.y, c.z).multiply(new THREE.Matrix4().makeRotationZ(angleRad).multiply(new THREE.Matrix4().makeTranslation(-c.x, -c.y, -c.z)));
            this.localPolygon.applyMatrix4(m);
        }

        getPivot(): THREE.Vector3 {
            return this.localPolygon.center;
        }

        setValue(k: string, val: any) {
            if (k in this)
                this[k] = val;
        }

        setSettings(settings: SolarProTool.IInterferenceSettings) {
            //settings currently not needed on DynamicInterferenceObject
            this.Settings = null as SolarProTool.BaseInterferenceSettings;
        }

        setState(state?: SolarProTool.IInterference3DObject): this {
            if (!state)
                return this;
            if (state.Type !== this.Type)
                throw "setState: Wrong interference type.";
            this._suppressUserUpdates = true;
            if (state) {

                Object.keys(state).forEach(k => {
                    switch (k) {
                        case "Data":
                            break;
                        case "Settings":
                            break;
                        case "BaseParentId":
                            this.BaseParentId = state[k].toLowerCase();
                            break;
                        case "Id":
                        case "id":
                            this.id = state[k].toLowerCase();
                            break;
                        default:
                            this.setValue(k, state[k]);
                            break;
                    }
                });

                if (state.Data)
                    this.setData(state.Data);

                if (state.Settings)
                    this.setSettings(state.Settings);

                this.onMaterialChanged();

                if (state instanceof InteferenceState) {
                    this.localPolygon.resetScale(state.scalex, state.scaley, state.scalez);
                    this.localPolygon.resetRotation(state.rotation);
                }

                this.state.copy(this);
            }
            this._suppressUserUpdates = false;

            return this;
        }

        setData(data: number[]) {
            this.editablePolygon.position.set(0, 0, 0);
            this.editablePolygon.userEdited = false;

            if (data && data.length) {
                var localPolygon = this.localPolygon;
                localPolygon.removeAll();
                for (var i = 0, l = data.length; i < l; i += 3) {
                    localPolygon.push(data[i], data[i + 1], data[i + 2]);
                }
            }
        }

        getData(): number[] {
            return this.localPolygon.getData();
        }

        setHeight(v: number) {
            var h = this.height;
            Controller.Current.viewModel.setLength(this, 'height', v);

            if (this.height < 10)
                this.height = 10;

            if (this.height !== h) {
                this.onGeometryChanged();
                this.onUserUpdated();
            }
        }

        setIsParallel(b: boolean) {
            if (this.IsParallel !== b) {
                this.IsParallel = b;
                this.onGeometryChanged();
                this.onUserUpdated();
            }
        }

        toggleLocked(b: boolean) {
            this.locked = !!b;
            if (b && this.isSelected) {
                Controller.Current.viewModel.selectedUi.remove(this);
            }
        }

        get instanceContext(): InstanceContext {
            return Controller.Current.getInstanceContext(this.BaseParentId);
        }

        private _setPointsOnUpdate = true;
        private _selected: boolean = false;
        private _isHovered = false;
        _isDisposed = false;

        get height() {
            return this.localPolygon.height;
        }

        set height(v: number) {
            this.localPolygon.height = v;
        }

        isDynamicInterferenceObject = true;
        txtHolder: spt.ThreeJs.utils.SDFTextObjectInstances = null;

        applyOffset(offsetx: number, offsety: number, offsetz: number = 0) {
            this.localPolygon.array.forEach(p => { p.set(p.x + offsetx, p.y + offsety, p.z + offsetz) });
        }

        intersectRaycaster(worldRaycaster: THREE.Raycaster): number {
            if (this.isLocked)
                return -1;

            if (this.isSelected) {
                var inter = this.editablePolygon.intersecsRaycaster(worldRaycaster);
                if (inter)
                    return inter.distSq;
                return -1;
            }

            var worldBounds = this._box3d.copy(this.worldBounds).expandByScalar(worldRaycaster.params.Line.threshold),
                v1 = this._v1,
                ray = worldRaycaster.ray;

            if (ray.intersectBox(worldBounds, v1)) {
                var intersects = worldRaycaster.intersectObject(this.instanceMesh);
                if (intersects && intersects.length) {
                    var intersect = intersects[0],
                        distanceSq = v1.copy(intersect.point).distanceToSquared(ray.origin);
                    return distanceSq;
                }
            }

            return -1;
        }

        intersectTestVolume(testVolume: spt.ThreeJs.utils.TestVolume, linePrecision: number): boolean {
            if (this.isLocked)
                return false;

            var worldBounds = this._box3d.copy(this.worldBounds).expandByScalar(linePrecision);

            if (!testVolume.testBoxForSeparationAxis(worldBounds)) {
                var instanceMesh = this.instanceMesh;
                if (!testVolume.testBufferGeometryForSeparationAxis(instanceMesh.geometry as THREE.BufferGeometry, instanceMesh.matrixWorld))
                    return true;
            }

            return false;
        }

        onGeometryChanged() {
            this._geometryNeedsUpdate = true;
        }

        onMaterialChanged() {
            this._materialNeedsUpdate = true;
        }

        update() {
            if (this._geometryNeedsUpdate)
                this.updateGeometry();
            if (this._materialNeedsUpdate)
                this.updateMaterial();
        }

        onUserMoved() {
            if (this._suppressUserUpdates)
                return;

            //move other interfences with this one
            Controller.Current.viewModel.selectedUi.forEach(s => {
                if (s !== this && s instanceof DynamicInterferenceObject) {
                    s.editablePolygon.setPosition(this.editablePolygon.position);
                    s.editablePolygon.userEdited = true;
                }
            });
        }

        onUserUpdated() {
            if (this._suppressUserUpdates)
                return;

            var id = this.IdString;
            var baseParentId = this.BaseParentId;
            var oldState = this.state.clone();
            var newState = new InteferenceState().copy(this);
            this._geometryNeedsUpdate = true;

            Controller.Current.updateManager.dynamicUpdate(() => {
                var o = Controller.Current.interferenceHelper.getById(id);
                if (o) {
                    o.setState(newState);
                    Controller.Current.interferenceHelper.updateSetInterferenceState(id, baseParentId, newState);
                }
            }, () => {
                var o = Controller.Current.interferenceHelper.getById(id);
                if (o) {
                    o.setState(oldState);
                    Controller.Current.interferenceHelper.updateSetInterferenceState(id, baseParentId, oldState);
                }
            });

            Controller.Current.interferenceHelper.updateSetInterferenceState(id, baseParentId, newState);

            this.state.copy(this);
        }

        removeInstance() {
            if (this._isDisposed)
                return;

            var id = this.IdString;
            var baseParentId = this.BaseParentId;
            var state = this.state.clone();

            Controller.Current.updateManager.dynamicUpdate(() => {
                var o = Controller.Current.interferenceHelper.getById(id);
                if (o) {
                    o.remove();
                    Controller.Current.interferenceHelper.updateSetInterferenceState(id, baseParentId, null);
                }
            }, () => {
                Controller.Current.interferenceHelper.createInterferenceObjectFromData(state);
                Controller.Current.interferenceHelper.updateSetInterferenceState(id, baseParentId, state);
            });

            Controller.Current.interferenceHelper.updateSetInterferenceState(id, baseParentId, null);

            this.remove();
        }

        userEditPoint(p: ObservableVector3, k: 'x' | 'y' | 'z', v: number) {
            Controller.Current.viewModel.setLength(p, k, v);
            this.onUserUpdated();
        }

        userRemovePoint(p: ObservableVector3) {
            this.localPolygon.array.remove(p);
            this.onUserUpdated();
        }

        createGeometry(): THREE.BufferGeometry {

            var localPoly = this.localPolygon.array,
                geo = new THREE.BufferGeometry();

            this._setPointsOnUpdate = true;
            var instanceContext = Controller.Current.viewModel.instancesById[this.BaseParentId];
            if (instanceContext && instanceContext.surfacePolygons && instanceContext.surfacePolygons.length && localPoly.length) {

                var interHeight = localPoly[0].z,
                    ptBounds = new THREE.Box3(),
                    isVertical = !this.IsParallel,
                    geometry = new THREE.Geometry();

                var polys = spt.ThreeJs.utils.ProjectPolygonsToSurface(localPoly, instanceContext.surfacePolygons, ptBounds);

                var idxOff = 0;

                for (var i = polys.length; i--;) {
                    var poly = polys[i],
                        pointsLower = poly.points,
                        pointsTop = pointsLower.map(pLower => new THREE.Vector3(pLower.x, pLower.y, isVertical ? Math.max(pLower.z + 5, ptBounds.min.z + interHeight) : interHeight + pLower.z)),
                        ptsLen = pointsLower.length - 1,
                        plyIndices = THREE.ShapeUtils.triangulateShape(pointsTop, []);

                    geometry.vertices.push.apply(geometry.vertices, pointsTop);

                    for (var j = plyIndices.length; j--;) {
                        var pi = plyIndices[j];
                        geometry.faces.push(new THREE.Face3(pi[0] + idxOff, pi[1] + idxOff, pi[2] + idxOff));
                    }

                    idxOff += pointsTop.length;

                    for (var j = ptsLen, j2 = 0; j2 <= ptsLen; j2++) {
                        geometry.vertices.push(
                            pointsLower[j],
                            pointsLower[j2],
                            pointsTop[j2],
                            pointsTop[j]
                            );

                        geometry.faces.push(new THREE.Face3(idxOff, idxOff + 1, idxOff + 2));
                        geometry.faces.push(new THREE.Face3(idxOff, idxOff + 2, idxOff + 3));

                        idxOff += 4;
                        
                        j = j2;
                    }
                }

                geometry.computeFlatVertexNormals();

                geo.fromGeometry(geometry);

                this._setPointsOnUpdate = false;

                return geo;
            }

            var isSelected = this.isSelected,
                vIndizes = THREE.ShapeUtils.triangulateShape(localPoly, []),
                polyLength = localPoly.length,
                vertCount = polyLength * 5,
                indicesCount = polyLength * 6 + vIndizes.length * 3;

            this._geometryDynamic = isSelected;

            var posAttribute = new THREE.BufferAttribute(new Float32Array(vertCount * 3), 3).setUsage(THREE.DynamicDrawUsage);
            var normAttribute = new THREE.BufferAttribute(new Float32Array(vertCount * 3), 3).setUsage(THREE.DynamicDrawUsage);

            geo.setAttribute('position', posAttribute);
            geo.setAttribute('normal', normAttribute);

            //geo.setAttribute('position', new THREE.BufferAttribute(new Float32Array(vertCount * 3), 3).setUsage(THREE.DynamicDrawUsage));
            //geo.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(vertCount * 3), 3).setUsage(THREE.DynamicDrawUsage));
            //geo.computeVertexNormals();

            var indices = new Uint16Array(indicesCount);
            geo.setIndex(new THREE.BufferAttribute(indices, 1));

            var idx = 0;
            var iOff = polyLength;

            //sides
            for (var u = 0; u < polyLength; u++) {

                var i = u * 4;

                indices[idx++] = iOff + i + 2;
                indices[idx++] = iOff + i + 3;
                indices[idx++] = iOff + i + 1;

                indices[idx++] = iOff + i + 2;
                indices[idx++] = iOff + i + 1;
                indices[idx++] = iOff + i;
            }

            //top
            for (var m = 0, l2 = vIndizes.length; m < l2; m++) {
                var ind = vIndizes[m];

                indices[idx++] = ind[0];
                indices[idx++] = ind[1];
                indices[idx++] = ind[2];

            }

            return geo;
        }

        updateGeometry() {
            this._geometryNeedsUpdate = false;
            this._worldBounds.makeEmpty();

            var mesh = this.instanceMesh,
                localPoly: THREE.Vector3[] = this.localPolygon.array,
                localBounds = this.localBounds,
                localBoundsSize = localBounds.getSize(this._v1);

            if (!localPoly || !localPoly.length || localPoly.length < 3 || localBounds.isEmpty() || localBoundsSize.x <= 0 || localBoundsSize.y <= 0) {
                if (mesh && mesh.visible)
                    mesh.visible = false;
                return;
            }

            var geo: THREE.BufferGeometry,
                //mat: THREE.Material,
                polyLength = localPoly.length,
                vertCount = polyLength * 5,
                isVertical = !this.IsParallel,
                geoDynamic = this.isSelected;

            if (!mesh) {
                geo = this.createGeometry();
                var mat = this.createMaterial();

                mesh = this.instanceMesh = new THREE.Mesh(geo, mat);
                this.object.add(mesh);

                this.object.updateMatrixWorld(true);
            } else {
                geo = mesh.geometry as THREE.BufferGeometry;
                //mat = mesh.material as THREE.Material;
                if (!mesh.visible)
                    mesh.visible = true;

                mesh.position.set(0, 0, 0);

                if (!this._setPointsOnUpdate) {
                    geo.dispose();
                    geo = mesh.geometry = this.createGeometry();
                }
            }

            if (!this._setPointsOnUpdate)
                return;

            var positions = geo.getAttribute('position') as THREE.BufferAttribute,
                minZ = 10,
                groundZ = 0;

            if (positions.count !== vertCount || this._geometryDynamic !== geoDynamic) {
                geo.dispose();
                geo = mesh.geometry = this.createGeometry();
                positions = geo.getAttribute('position') as THREE.BufferAttribute;
            }

            if (isVertical) {

                this.object.updateMatrixWorld(false);

                var m = this.object.matrixWorld,
                    min = this._box3d.copy(localBounds).applyMatrix4(m).min,
                    v = this._v1;

                localPoly = localPoly.map(p => {
                    var pt = v.copy(p).clone().setZ(0).applyMatrix4(m);

                    return pt.setZ(Math.max(pt.z + minZ, p.z + min.z));
                });

                groundZ = min.z; //0;
                if (this.localPolygon.positionX < 0 || this.localPolygon.positionZ < 0) {
                    groundZ = this.instanceContext.GroundZ;
                }
                //groundZ = this.instanceContext.GroundZ; // min.z;
            }

            //set positions
            for (var i1 = 0; i1 < polyLength; i1++) {
                var i2 = (i1 + 1) % polyLength;

                var p1 = localPoly[i1],
                    p2 = localPoly[i2];

                //upper point
                positions.setXYZ(i1, p1.x, p1.y, Math.max(minZ, p1.z));

                var idx = polyLength + i1 * 4;

                //4 side points

                //upper
                positions.setXYZ(idx, p1.x, p1.y, Math.max(minZ, p1.z));
                positions.setXYZ(idx + 1, p2.x, p2.y, Math.max(minZ, p2.z));

                //lower
                positions.setXYZ(idx + 2, p1.x, p1.y, groundZ);
                positions.setXYZ(idx + 3, p2.x, p2.y, groundZ);

            }

            if (isVertical) {
                var m = this._mat.getInverse(this.object.matrixWorld);
                positions.applyMatrix4(m);
                //instanceContext.worldToLocalTransform.applyToBufferAttribute(positions);
            }

            geo.computeVertexNormals();
            geo.computeBoundingSphere();

            positions.needsUpdate = true;
        }

        createMaterial() {
            return new THREE.MeshLambertMaterial({ color: 0x333333 });
        }

        updateMaterial() {
            this._materialNeedsUpdate = false;

            var mesh = this.instanceMesh,
                material = mesh && mesh.material as THREE.MeshLambertMaterial;

            if (!material)
                return;

            var selected = this.isSelected,
                isHovered = this.isHovered && !selected;

            var intensity = 0x33; //51

            if (isHovered)
                intensity += 25;

            if (selected)
                intensity += 50;

            intensity = Math.max(0, Math.min(1, intensity / 255));

            material.transparent = !!selected;
            material.opacity = selected ? 0.75 : 1;
            material.color.setRGB(intensity, intensity, intensity);

            material.needsUpdate = true;

            this.onEnableEdit(selected);

        }

        onEnableEdit(selected: boolean) {
            this.editablePolygon.editable = selected;
        }

        positionAndSize(pos: THREE.Vector3, size: THREE.Vector3) {
            pos.set(this.posx, this.posy, 0);
            size.set(this.sizex, this.sizey, this.height);
        }

        remove() {
            if (this._isDisposed)
                return;

            this.dispose();
        }

        dispose() {
            if (this._isDisposed)
                return;
            this._isDisposed = true;

            if (this.localPolySubscription) {
                this.localPolySubscription.dispose();
                this.localPolySubscription = null;
            }

            Controller.Current.viewModel.interferenceObjects.remove(this);

            var instanceContext = this.instanceContext;

            if (instanceContext) {
                instanceContext.remove(this.object, true);
            }

            this.object.children.slice().forEach(ch => {
                spt.ThreeJs.utils.disposeObject3D(ch);
            });
        }

    }

    export class DormerInterferenceObject extends DynamicInterferenceObject implements IDynamicInterferenceObject {
        Type = "DormerInterferenceObject";

        _v2 = new THREE.Vector3();
        _v3 = new THREE.Vector3();
        _v4 = new THREE.Vector3();
        _q1 = new THREE.Quaternion();
        _mat2 = new THREE.Matrix4();
        _mat3 = new THREE.Matrix4();
        _transformControls: THREE.TransformControls[] = [];
        _transformTarget: THREE.Object3D = null;
        Settings: SolarProTool.DormerInterferenceObject = null as SolarProTool.DormerInterferenceObject;

        get sizex() {
            return this.Settings.DormerSizeX;
        }

        set sizex(val: number) {
            var v = +val;
            if (v > 0 && this.Settings.DormerSizeX !== v) {
                this.Settings.DormerSizeX = v;
                this.onUserUpdated();
            }
        }

        get sizey() {
            return this.Settings.DormerSizeY;
        }

        set sizey(val: number) {
            var v = +val;
            if (v > 0 && this.Settings.DormerSizeY !== v) {
                this.Settings.DormerSizeY = v;
                this.onUserUpdated();
            }
        }

        get height() {
            return this.Settings.DormerSizeZ;
        }

        set height(v: number) {
            this.Settings.DormerSizeZ = v;
        }

        get DormerTiltAngle() {
            return this.Settings.DormerTiltAngle;
        }

        set DormerTiltAngle(val: number) {
            var v = +val;
            if (v >= 0 && v < 90 && this.Settings.DormerTiltAngle !== v) {
                this.Settings.DormerTiltAngle = v;
                this.onUserUpdated();
            }
        }

        get DormerHeight() {
            return Math.max(0, Math.tan(this.DormerTiltAngle / 180 * Math.PI) * this.sizex * 0.5);
        }

        set DormerHeight(h: number) {
            if (h <= 0)
                this.Settings.DormerTiltAngle = 0;
            else if (this.sizex > 0)
                this.Settings.DormerTiltAngle = Math.max(0, Math.min(89, Math.atan((h * 2) / this.sizex) / Math.PI * 180));
        }

        GetSizeProperty(k: string): number {
            return k === "y" ? 0 : this.localPolygon.size[k];
        }

        constructor() {
            super();

            this.IconX = 0;
            this.IconY = 8;

            ko.track(this);

            var settings = this.Settings;

            if (!settings)
                settings = this.Settings = {} as SolarProTool.DormerInterferenceObject;

            settings.DormerTiltAngle = 0;
            settings.DormerSizeX = 0;
            settings.DormerSizeY = 0;
            settings.DormerSizeZ = 0;
            settings.InterferenceType = this.Type;

            ko.track(settings);

            if (this.localPolySubscription) {
                this.localPolySubscription.dispose();
                this.localPolySubscription = null;
            }

            this.editablePolygon.clickable = true;
            this.editablePolygon.draggable = true;
            this.editablePolygon.editOptions = EditablePolygon2D.ShowEdges | EditablePolygon2D.ShowSurface;
        }

        onUserUpdated() {
            if (this._suppressUserUpdates)
                return;

            this.updateLocalPoly();

            this._geometryNeedsUpdate = true;

            super.onUserUpdated();
        }

        setState(state?: SolarProTool.IInterference3DObject): this {
            super.setState(state);

            this._geometryNeedsUpdate = true;
            this.updateTransformControls();

            return this;
        }

        updateLocalPoly() {
            var size = new THREE.Vector3(Math.max(10, this.sizex), Math.max(10, this.sizey), Math.max(10, this.height)),
                pos = new THREE.Vector3(this.posx, this.posy, 0),
                pts: THREE.Vector3[] = [
                    pos.clone(),
                    pos.clone().setX(pos.x + size.x),
                    pos.clone().setX(pos.x + size.x).setY(pos.y + size.y),
                    pos.clone().setY(pos.y + size.y)
                ],
                localPoly = this.localPolygon;

            localPoly.setLength(4);

            for (var i = 0, l = pts.length - 1; i <= l; i++) {
                localPoly.array[i].copy(pts[i]);
            }

            this.updateTransformControls();
        }

        updateTransformControls() {
            if (this._transformControls.length) {
                this._transformControls.forEach((tr: any) => {
                    tr.updateMe();
                });
            }
        }

        //setData(data: number[]) {
        //    super.setData(data);

        //    var localPolygon = this.localPolygon;

        //    var p = localPolygon.position;

        //    this.dormerPosX = p.x;
        //    this.dormerPosY = p.y;

        //    this._geometryNeedsUpdate = true;
        //}

        onEnableEdit(selected: boolean) {
            //this.editablePolygon.editable = selected;

            var viewModel = Controller.Current.viewModel;

            if (!selected && this._transformControls.length) {

                this._transformControls.forEach(tr => {
                    tr.detach();
                    if (tr.object) {
                        if (tr.object.parent)
                            tr.object.parent.remove(tr.object);
                        spt.ThreeJs.utils.disposeObject3D(tr.object);
                    }
                    tr.dispose();
                });

                this._transformControls = [];
            }
            else if (selected && !this._transformControls.length && this.instanceMesh) {

                //height control
                var transformControl = this.createTransformControl(false, (tc) => {
                    //update transformcontrol
                    if (this._isDisposed || !tc.object)
                        return;
                    var pos = this._v1.set(this.posx + this.sizex * 0.5, this.posy, 0).add(this._v2.set(0, 0, this.height + this.DormerHeight).applyQuaternion(tc.object.quaternion));
                    tc.object.position.copy(pos);

                }, (tc) => {
                    //on transform change
                    if (this._isDisposed || !tc.object)
                        return;
                    var target = tc.object,
                        pos = this._v1.copy(this.localPolygon.position);
                    pos.setX(pos.x + this.localPolygon.sizeX * 0.5);
                    if (tc.ctrlDown) {
                        this.DormerHeight = Math.max(0, pos.distanceTo(target.position) - this.Settings.DormerSizeZ);
                    } else {
                        if (target.position.z <= 0)
                            this.Settings.DormerSizeZ = 0;
                        else
                            this.Settings.DormerSizeZ = Math.max(0, pos.distanceTo(target.position) - this.DormerHeight);
                    }

                });

                transformControl.enabled = !viewModel.selectedTool;

                transformControl.showX = false;
                transformControl.showY = false;
                transformControl.showZ = true;

                //position
                transformControl = this.createTransformControl(true, (tc) => {
                    //update transformcontrol
                    if (this._isDisposed || !tc.object)
                        return;
                    if (tc.ctrlDown && (tc.axis === "X" || tc.axis === "Y")) {
                        if (tc.axis === "X") {
                            var pos = this._v1.set(this.posx + this.sizex, this.posy /*+ this.sizey * 0.5*/, 0);
                            tc.object.position.copy(pos);

                        } else if (tc.axis === "Y") {
                            var pos = this._v1.set(this.posx + this.sizex * 0.5, this.posy + this.sizey, 0);
                            tc.object.position.copy(pos);
                        }
                    } else {
                        var pos = this._v1.set(this.posx + this.sizex * 0.5, this.posy /*+ this.sizey * 0.5*/, 0);
                        tc.object.position.copy(pos);
                    }

                }, (tc) => {
                    //on transform change
                    if (this._isDisposed || !tc.object)
                        return;
                    var target = tc.object;

                    if (!tc.ctrlDown && Controller.Current.viewModel.ctrlDown) {
                        tc.ctrlDown = true;
                    }

                    if (tc.axis === "X" && tc.ctrlDown) {
                        var m = this._v1.set(this.posx + this.sizex * 0.5, this.posy /*+ this.sizey * 0.5*/, 0);

                        this.Settings.DormerSizeX = Math.max(10, Math.abs(m.x - target.position.x) * 2);
                        this.localPolygon.positionX = m.x - this.Settings.DormerSizeX * 0.5;

                    } else if (tc.axis === "Y" && tc.ctrlDown) {
                        this.Settings.DormerSizeY = Math.max(10, Math.abs(this.localPolygon.positionY - target.position.y));
                    } else {
                        var pos = this._v1.set(target.position.x - this.sizex * 0.5, target.position.y /*- this.sizey * 0.5*/, 0);
                        this.localPolygon.position = pos;
                    }
                    console.log((tc as any).axis);

                });

                transformControl.showX = true;
                transformControl.showY = true;
                transformControl.showZ = false;

                this.updateTransformControls();

            }
        }

        createTransformControl(local: boolean, doUpdate: (tc: THREE.TransformControls) => void, onChanged: (tc: THREE.TransformControls) => void) {
            var target = this._transformTarget = new THREE.Object3D();

            if (!local) {
                target.applyMatrix4(this._mat.getInverse(this.object.matrixWorld));
            }
            target.position.set(0, 0, 0);

            this.object.add(target);

            var transformControl = Controller.Current.createTransformControls();
            this._transformControls.push(transformControl);

            transformControl.showX = false;
            transformControl.showY = false;
            transformControl.showZ = true;

            transformControl.setSpace('local');
            transformControl.attach(target);

            (<any>transformControl).updateMe = doUpdate.bind(this, transformControl);

            transformControl.addEventListener('objectChange', (event) => {
                onChanged(transformControl);
                this._geometryNeedsUpdate = true;
            });

            //transformControl.addEventListener('mouseDown', (event) => {
            //    if (transformControl.enabled)
            //        doUpdate(transformControl);
            //});

            transformControl.addEventListener('dragging-changed', (event) => {
                if (!event.value) //on dragging end
                    this.onUserUpdated();
            });

            return transformControl;
        }

        setSettings(settings: SolarProTool.IInterferenceSettings) {
            if (settings) {
                var mySettings = this.Settings;
                Object.keys(settings).forEach(k => {
                    if (k in mySettings)
                        mySettings[k] = settings[k];
                });
            }
        }

        createGeometry(): THREE.BufferGeometry {

            this.updateLocalPoly();

            var size = new THREE.Vector3(Math.max(10, this.sizex), Math.max(10, this.sizey), Math.max(10, this.height)),
                pos = new THREE.Vector3(this.posx, this.posy, 0),
                ptsLower: THREE.Vector3[] = this.localPolygon.array.map(p => new THREE.Vector3().copy(p)),
                ptsUpper: THREE.Vector3[] = ptsLower.map(p => p.clone().setZ(p.z + this.height)),
                geo = new THREE.Geometry(),
                dormerAngle = this.Settings.DormerTiltAngle;

            var idx = 0;

            for (var i = 0, l = ptsLower.length - 1; i <= l; i++) {

                var i2 = i == l ? 0 : i + 1;

                geo.faces.push(new THREE.Face3(idx, idx + 1, idx + 2), new THREE.Face3(idx, idx + 2, idx + 3));
                geo.vertices.push(ptsLower[i], ptsLower[i2], ptsUpper[i2], ptsUpper[i]);
                idx += 4;
            }

            if (dormerAngle > 0) {
                var h = Math.tan(dormerAngle / 180 * Math.PI) * size.x * 0.5,
                    mpLower1 = ptsUpper[0].clone().add(ptsUpper[1]).multiplyScalar(0.5),
                    mpLower2 = ptsUpper[2].clone().add(ptsUpper[3]).multiplyScalar(0.5),
                    mpUpper1 = mpLower1.setZ(mpLower1.z + h),
                    mpUpper2 = mpLower2.setZ(mpLower2.z + h);

                geo.faces.push(new THREE.Face3(idx, idx + 1, idx + 2));
                geo.vertices.push(ptsUpper[0], ptsUpper[1], mpUpper1);
                idx += 3;

                geo.faces.push(new THREE.Face3(idx, idx + 1, idx + 2));
                geo.vertices.push(ptsUpper[2], ptsUpper[3], mpUpper2);
                idx += 3;

                geo.faces.push(new THREE.Face3(idx, idx + 1, idx + 2), new THREE.Face3(idx, idx + 2, idx + 3));
                geo.vertices.push(ptsUpper[1], ptsUpper[2], mpUpper2, mpUpper1);
                idx += 4;

                geo.faces.push(new THREE.Face3(idx, idx + 1, idx + 2), new THREE.Face3(idx, idx + 2, idx + 3));
                geo.vertices.push(ptsUpper[3], ptsUpper[0], mpUpper1, mpUpper2);
                idx += 4;

            } else {
                geo.faces.push(new THREE.Face3(idx, idx + 1, idx + 2), new THREE.Face3(idx, idx + 2, idx + 3));
                geo.vertices.push(ptsUpper[0], ptsUpper[1], ptsUpper[2], ptsUpper[3]);
                idx += 4;
            }

            geo.computeFaceNormals();

            geo.computeBoundingSphere();

            var result = new THREE.BufferGeometry().fromGeometry(geo);

            var normalMatrix = this._matNormal.getNormalMatrix(this._mat.getInverse(this.object.matrixWorld));

            var zAxis = this._v1.set(0, 0, 1).applyMatrix3(normalMatrix);

            if (zAxis.z < 1) {
                var xAxis = this._v2.copy(zAxis).cross(this._v3.set(0, 0, 1)).normalize(),
                    yAxis = this._v3.copy(zAxis).cross(xAxis).normalize(),
                    m = this._mat.makeTranslation(pos.x, pos.y, pos.z).multiply(this._mat3.makeBasis(xAxis, yAxis, zAxis)).multiply(this._mat2.makeTranslation(-pos.x, -pos.y, -pos.z));

                result.applyMatrix4(m);
            }

            return result;
        }

        updateGeometry() {
            this._geometryNeedsUpdate = false;
            this._worldBounds.makeEmpty();

            this.object.updateMatrixWorld(false);

            var mesh = this.instanceMesh;

            //if (!localPoly || !localPoly.length || localPoly.length < 3 || localBounds.isEmpty() || localBoundsSize.x <= 0 || localBoundsSize.y <= 0) {
            //    if (mesh && mesh.visible)
            //        mesh.visible = false;
            //    return;
            //}

            if (!mesh) {
                var geo = this.createGeometry();
                var mat = this.createMaterial();

                mesh = this.instanceMesh = new THREE.Mesh(geo, mat);
                this.object.add(mesh);

                this.object.updateMatrixWorld(true);
            } else {
                mesh.geometry.dispose();
                mesh.geometry = this.createGeometry();
            }

            if (!mesh.visible)
                mesh.visible = true;

            //var tr = this._v1,
            //    scale = this._v2,
            //    quat = this._q1;

            //var normalMatrix = this._mat3.getNormalMatrix(this._mat.getInverse(this.object.matrixWorld));

            //this._mat.getInverse(this.object.matrixWorld).decompose(tr, quat, scale);

            //var normal = this._v1.set(0, 0, 1).applyMatrix3(normalMatrix);


            //var zAxis = this._v1.set(0, 0, 1).applyMatrix3(normalMatrix),
            //    xAxis = this._v2.set(0, 0, 1).cross(zAxis).normalize(),
            //    yAxis = this._v3.copy(zAxis).cross(xAxis).normalize();
            //this._v4.projectOnPlane()

        }

        dispose() {
            if (this._isDisposed)
                return;
            super.dispose();

            this._transformControls.forEach(tr => {
                tr.detach();
                if (tr.object) {
                    if (tr.object.parent)
                        tr.object.parent.remove(tr.object);
                    spt.ThreeJs.utils.disposeObject3D(tr.object);
                }
                tr.dispose();
            });

            this._transformControls = [];
        }
    }

    export interface IMultiInterferenceSettings extends SolarProTool.IInterferenceSettings, SolarProTool.DormerInterferenceObject {

    }

    export class MultiInterferenceObjectView {
        interferenceDatas: IDynamicInterferenceObject[];

        Name: string;
        height: number;
        posx: number;
        posy: number;
        sizex: number;
        sizey: number;
        scalex: number;
        scaley: number;
        rotation: number;
        IsParallel: boolean;
        locked: boolean;

        readonly Settings: IMultiInterferenceSettings = {} as IMultiInterferenceSettings;

        detailsViewVisible = false;
        Type: { Name: string, Type: string };
        allTypes: { Name: string, Type: string }[] = [{ Name: AO3DStrings.Interference, Type: "DynamicInterferenceObject" }, { Name: AO3DStrings.Dormer, Type: "DormerInterferenceObject" }];
        readonly isDynamicInterferenceObject: boolean;
        readonly isDormerInterferenceObject: boolean;
        showOptions: boolean;

        constructor(viewModel: LS.Client3DEditor.ViewModel) {
            ko.track(this);

            ko.defineProperty(this, "interferenceDatas", () => {
                //this.detailsViewVisible = false;
                return viewModel.currentInterferenceObjects.filter(o => o.isSelected);
            });

            ko.defineProperty(this, "showOptions", () => {
                return this.interferenceDatas.length > 0;
            });

            ko.getObservable(this, "showOptions").extend({ rateLimit: 100 });

            ko.defineProperty(this, "isDynamicInterferenceObject", () => {
                return this.interferenceDatas.length && this.interferenceDatas.every(inter => inter.Type === "DynamicInterferenceObject");
            });

            ko.defineProperty(this, "isDormerInterferenceObject", () => {
                return this.interferenceDatas.length && this.interferenceDatas.some(inter => inter.Type === "DormerInterferenceObject");
            });

            ko.defineProperty(this.Settings, "DormerTiltAngle",
                {
                    get: () => {
                        if (this.interferenceDatas.length) {
                            var v0: number;
                            if (this.interferenceDatas.some(d => { return !!(v0 = (d.Settings && (d.Settings as SolarProTool.DormerInterferenceObject).DormerTiltAngle)); }))
                                return v0;
                        }
                        return 0;
                    },
                    set: (v: number) => {
                        this.interferenceDatas.forEach(d => {
                            if (d instanceof DormerInterferenceObject) {
                                d.DormerTiltAngle = v;
                            }
                        });
                    }
                });

            ko.defineProperty(this, "Type",
                {
                    get: () => {
                        if (this.interferenceDatas.length === 1) {
                            var allTypes = this.allTypes,
                                t = this.interferenceDatas[0].Type;
                            for (var i = 0, l = allTypes.length; i < l; i++) {
                                if (allTypes[i].Type == t) {
                                    return allTypes[i];
                                }
                            }
                        }

                        return null;
                    },
                    set: (val) => {
                        if (this.interferenceDatas.length === 1 && val && val.Type) {
                            var t = this.interferenceDatas[0].Type;
                            if (t !== val.Type) {
                                viewModel.changeInterType(val.Type, this.interferenceDatas[0]);
                            }
                        }
                    }
                });

            ko.defineProperty(this, "posx",
                {
                    get: () => {
                        return this.interferenceDatas.reduce((c: number, inter) => inter.posx < c ? inter.posx : c, Infinity);
                    },
                    set: (val) => {
                        var v = +val || 0;
                        var d = v - this.posx;
                        this.interferenceDatas.forEach(p => { p.posx += d; });
                    }
                });

            ko.defineProperty(this, "posy",
                {
                    get: () => {
                        return this.interferenceDatas.reduce((c: number, inter) => inter.posy < c ? inter.posy : c, Infinity);
                    },
                    set: (val) => {
                        var v = +val || 0;
                        var d = v - this.posy;
                        this.interferenceDatas.forEach(p => { p.posy += d; });
                    }
                });

            ko.defineProperty(this, "IsParallel",
                {
                    get: () => {
                        return this.interferenceDatas.every(d => d.IsParallel);
                    },
                    set: (v: boolean) => {
                        this.interferenceDatas.forEach(d => { d.setIsParallel(v); });
                    }
                });

            ko.defineProperty(this, "locked",
                {
                    get: () => {
                        return this.interferenceDatas.some(d => d.locked);
                    },
                    set: (v: boolean) => {
                        this.interferenceDatas.forEach(d => { d.toggleLocked(v); });
                    }
                });

            //var sharedBooleans: (keyof this)[] = ["locked"];
            var sharedStrings: (keyof this)[] = ["Name"];
            var sharedNumbers: (keyof this)[] = ["height", "scalex", "scaley", "sizex", "sizey", "rotation"];

            sharedNumbers.forEach(k => {
                ko.defineProperty(this, k as any,
                    {
                        get: () => {
                            if (this.interferenceDatas.length) {
                                var v0 = this.interferenceDatas[0][k as string];
                                return this.interferenceDatas.every(d => d[k as string] === v0) ? v0 : 0;
                            }

                            return 0;
                        },
                        set: (v: number) => {
                            this.interferenceDatas.forEach(d => { d[k as string] = v; });
                        }
                    } as any);
            });

            //sharedBooleans.forEach(k => {

            //    ko.defineProperty(this, k,
            //        {
            //            get: () => {
            //                return this.interferenceDatas.every(d => !!d[k as string]);
            //            },
            //            set: (v: boolean) => {
            //                this.interferenceDatas.forEach(d => { d[k as any] = !!v; });
            //            }
            //        } as any);
            //});

            sharedStrings.forEach(k => {

                ko.defineProperty(this, k as any,
                    {
                        get: () => {
                            if (this.interferenceDatas.length) {
                                var rd = this.interferenceDatas[0][k as string];
                                if (this.interferenceDatas.every(d => d[k as string] === rd))
                                    return rd;
                            }

                            return null;
                        },
                        set: (v: string) => {
                            this.interferenceDatas.forEach(d => { d[k as string] = v; });
                        }
                    } as any);
            });
        }

        setHeight(v: number) {
            this.interferenceDatas.forEach(d => { d.setHeight(v); });
        }

        toggleDetailsViewVisible(b: boolean) {
            this.detailsViewVisible = !!b;
        }

        toggleLocked(b: boolean) {
            this.locked = !!b;
        }

        deleteInters() {
            this.interferenceDatas.slice().forEach(inter => {
                inter.removeInstance();
            });
        }
    }

    export interface IInteferenceState extends SolarProTool.IInterference3DObject {
        getData?(): number[];
        scalex?: number;
        scaley?: number;
        scalez?: number;
        rotation?: number;
    }

    export class InteferenceState implements IInteferenceState {
        IsParallel = false;
        Name = null;
        Type: string = null;
        TypeId: string = null;
        BaseParentId: string = null;
        IdString: string = null;
        ClientOptions: Interference3DOptions = 0;
        Data: number[] = [];
        scalex: number = 0;
        scaley: number = 0;
        scalez: number = 0;
        rotation: number = 0;

        dormerPosX: number = 0;
        dormerPosY: number = 0;
        dormerSizeX: number = 0;
        dormerSizeY: number = 0;
        dormerSizeZ: number = 0;
        DormerTiltAngle: number = 0;

        Settings: SolarProTool.IInterferenceSettings = null as SolarProTool.IInterferenceSettings;

        clone(): InteferenceState {
            return new InteferenceState().copy(this);
        }

        copy(source: IInteferenceState): this {
            Object.keys(source).forEach(k => {
                switch (k) {
                    case 'Data':
                    case 'Settings':
                        break;
                    case 'scalex':
                    case 'scaley':
                    case 'scalez':
                        this[k] = source[k] || 1;
                        break;
                    default:
                        if (k in this)
                            this[k] = source[k];
                }
            });

            if (source.Settings) {
                var settings = {} as SolarProTool.IInterferenceSettings;

                Object.keys(source.Settings).forEach(k => {
                    settings[k] = source.Settings[k];
                });

                this.Settings = settings;
            } else {
                this.Settings = null as SolarProTool.IInterferenceSettings;
            }

            if (source.getData)
                this.Data = source.getData();
            else if (source.Data)
                this.Data = source.Data.slice();

            return this;
        }

        apply(target: IDynamicInterferenceObject) {
            target.setState(this);
        }

        equals(other: SolarProTool.IInterference3DObject): boolean {
            if (other) {
                var keys = Object.keys(other);
                for (var j = 0, kl = keys.length; j < kl; j++) {
                    var k = keys[j];
                    switch (k) {
                        case "Data":
                            var l = this.Data ? this.Data.length : 0;
                            if (l !== (other.Data ? other.Data.length : 0))
                                return false;
                            for (var i = 0; i < l; i++) {
                                if (this.Data[i] !== other.Data[i])
                                    return false;
                            }
                            break;
                        default:
                            if (this[k] !== other[k])
                                return false;
                            break;
                    }
                }

                return true;
            }

            return false;

        }
    }

    export class InterferenceHelper {

        stateUpdateQuery: { id: string, baseParentId: string, state: SolarProTool.IInterference3DObject, callback?: (newId: string) => void }[] = [];
        _needsStateUpdate = false;

        hoverObject: IDynamicInterferenceObject = null;

        update(controller: LS.Client3DEditor.Controller) {
            controller.viewModel.interferenceObjects.forEach(o => o.update());
            if (this._needsStateUpdate) {
                this._needsStateUpdate = false;

                var idStrings: string[] = [],
                    baseParentIds: string[] = [],
                    states: SolarProTool.IInterference3DObject[] = [],
                    callBacks: ((newId: string) => void)[] = [];

                this.stateUpdateQuery.forEach(o => {
                    idStrings.push(o.id);
                    baseParentIds.push(o.baseParentId);
                    states.push(o.state);
                    callBacks.push(o.callback);
                });

                SolarProTool.Ajax("WebServices/Anordnung3DService.asmx").Call("SetInterferenceStates").Data({ idStrings: idStrings, baseParentIds: baseParentIds, states: states }).CallBack((newIds) => {
                    if (newIds && newIds.length) {
                        callBacks.forEach((cb, i) => {
                            if (cb)
                                cb(newIds[i]);
                        });
                    }
                });

                this.stateUpdateQuery = [];
            }
        }

        getById(id: string) {
            id = id.toLowerCase();
            var interferenceObjects = Controller.Current.viewModel.interferenceObjects,
                idx = interferenceObjects.findIndex(o => o && o.IdString === id);
            if (idx !== -1)
                return interferenceObjects[idx];
            return null;
        }

        clearCurrent(controller?: LS.Client3DEditor.Controller) {
            if (!controller)
                controller = Controller.Current;

            var interferenceObjects = controller.viewModel.currentInterferenceObjects.slice();
            interferenceObjects.forEach(o => o.dispose());
        }

        clearAll(controller?: LS.Client3DEditor.Controller) {
            if (!controller)
                controller = Controller.Current;

            var interferenceObjects = controller.viewModel.interferenceObjects.splice(0, controller.viewModel.interferenceObjects.length);
            interferenceObjects.forEach(o => o.dispose());
        }

        getByRaycaster(worlRaycaster: THREE.Raycaster) {
            var controller = Controller.Current,
                viewModel = controller.viewModel,
                interferenceObjects = viewModel.interferenceObjects,
                interObject: IDynamicInterferenceObject = null,
                dist = Number.MAX_VALUE;

            interferenceObjects.forEach(interferenceObject => {
                var d = interferenceObject.intersectRaycaster(worlRaycaster);
                if (d >= 0 && d < dist) {
                    dist = d;
                    interObject = interferenceObject;
                }
            });

            return interObject;
        }

        getByTestVolume(testVolume: spt.ThreeJs.utils.TestVolume): IDynamicInterferenceObject[] {
            var controller = Controller.Current,
                linePrecision = controller.linePrecision;

            return controller.viewModel.interferenceObjects.filter(o => !!o.intersectTestVolume(testVolume, linePrecision));
        }

        setHoverObject(hoverObject?: IDynamicInterferenceObject) {
            if (this.hoverObject) {
                this.hoverObject.isHovered = false;
                this.hoverObject = null;
            }
            if (hoverObject) {
                hoverObject.isHovered = true;
                this.hoverObject = hoverObject;
            }

        }

        createInterferenceObjectFromData(interference: SolarProTool.IInterference3DObject): IDynamicInterferenceObject {
            if (!interference || !interference.IdString || !interference.Type)
                return null;

            return (constructObject(LS.Client3DEditor[interference.Type], []) as IDynamicInterferenceObject).setState(interference);
        }

        updateSetInterferenceState(id: string, baseParentId: string, state: SolarProTool.IInterference3DObject, callback?: (newId: string) => void) {
            this.stateUpdateQuery.push({ id: id, baseParentId: baseParentId, state: state, callback: callback });
            this._needsStateUpdate = true;
        }
    }
}