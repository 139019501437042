module LS.Client3DEditor {
    export interface ITool {
        cursor: string;
        init: () => void;
        onSelect: (viewModel: ViewModel, params?: any) => void;
        onDeselect: (viewModel: ViewModel) => void;
        onMouseDown: (viewModel: ViewModel) => boolean;
        onMouseUp: (viewModel: ViewModel) => boolean;
        onMouseMove: (viewModel: ViewModel) => boolean;
        onKeyDown: (viewModel: ViewModel) => boolean;
        onKeyUp: (viewModel: ViewModel) => boolean;
        onViewLayerChanged: (viewModel: ViewModel, viewLayerName: string) => void;
    }

    export class BaseTool implements ITool {
        init(): void { }
        onSelect(viewModel: ViewModel, params?: any) { }
        onDeselect(viewModel: ViewModel) { }
        onMouseDown(viewModel: ViewModel): boolean { return false; }
        onMouseUp(viewModel: ViewModel): boolean { return false; }
        onMouseMove(viewModel: ViewModel): boolean { return false; }
        onKeyDown(viewModel: ViewModel): boolean { return false; }
        onKeyUp(viewModel: ViewModel): boolean { return false; }
        onViewLayerChanged(viewModel: ViewModel, viewLayerName: string) { }
        cursor: string = 'default';
    }

    export class EmptyTool extends BaseTool {
        onMouseDown(viewModel: ViewModel): boolean { return true; }
        onMouseUp(viewModel: ViewModel): boolean { return true; }
        onMouseMove(viewModel: ViewModel): boolean { return true; }
        onKeyDown(viewModel: ViewModel): boolean { return true; }
        onKeyUp(viewModel: ViewModel): boolean { return true; }
    }
}