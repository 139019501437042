module LS.Client3DEditor {
    export interface IBaseChange {
        //update type
        type: string;
        oldValue?: string | number;
        newValue?: string | number;
        apply?: () => void;
        revert?: () => void;
        skipCheck: boolean;
    }
    
    export interface IIdObject {
        Id: string | number;
    }
    
    export interface IChangeEvent extends IBaseChange {
        //target of this change
        object: IIdObject;
        //property name that has been changed
        name?: string;
        //dataType of object
        dataType: string;
    }

    export interface IChange {
        Uuid: string;
        TypeString: string;
        UpdateType: string;
        IdStrings: string[];
        skipCheck?: boolean;
        NewValueString?: string;
        OldValueString?: string;
        Name?: string;
        apply?: () => void;
        revert?: () => void;
        ErrorCode?: number;
        ErrorMessage?: string;
        Tx?: number;
        Ty?: number;
        Tz?: number;
        Sx?: number;
        Sy?: number;
    }

    export interface IInstanceChanges {
        [key: string]: IBaseChange;
        X?: IBaseChange;
        Y?: IBaseChange;
        Z?: IBaseChange;
        SizeX?: IBaseChange;
        SizeY?: IBaseChange;
    }
    
    export interface IChangeProcessor {
        applyChanges: (change: IChange, revert?: boolean) => void;
        revertChanges: (change: IChange, revert?: boolean) => void;
    }

    export interface IChangeEventHandler {
        handle: (e: IChangeEvent) => string;
    }

    export enum ErrorLevel {
        NoError = 0,
        Fail, //Standard Error. No error message.
        Severe, //Big error message on screen
        Warning, //Big Info message on screen
        Info //Small info message on screen
    }

    export class UpdateManagerChangeEvent implements IChangeEvent {
        constructor(target: IInstanceData, type: string, key: string, old: string | number, v: string | number, skipCheck?: boolean) {
            this.object = target;
            this.type = type || "update";
            this.name = key || null;
            this.oldValue = old || null;
            this.newValue = v || null;
            this.skipCheck = !!skipCheck;
        }

        object: IInstanceData;
        type: string;
        skipCheck: boolean;
        name: string;
        oldValue: string | number;
        newValue: string | number;
        dataType: string;
    }

    var dynamicUpdateId = 0;

    var changeEvent = { type: 'change' };
    var clientObjectUpdateEvent = { type: 'clientObjectUpdate' };
    var handleClientObjectChangesEvent = { type: "HandleClientObjectChanges" };

    export class UpdateManager implements IEventManager {
        
        changeProcessor: { [updateType: string]: IChangeProcessor };
        changeEventHandler: { [updateType: string]: IChangeEventHandler };
        changeSets: IChange[][];
        pointer: number;
        tempChanges: { [dataType: string]: { [id: string]: { [key: string]: IBaseChange } } };
        needsUpdate: boolean;
        acceptChanges: boolean;
        isWaiting: boolean;
        doClearHistory: boolean;

        constructor() {
            this.changeSets = [];
            this.pointer = -1;
            this.tempChanges = {};
            this.needsUpdate = false;
            this.acceptChanges = true;
            this.isWaiting = false;
            this.doClearHistory = false;
            this.changeProcessor = {};
            this.changeEventHandler = {};

            this.init();
        }

        dynamicUpdate(apply: () => void, revert: () => void) {
            var id = dynamicUpdateId++;

            this.handleClientObjectUpdate({
                type: "dynamic",
                name: "dynamic",
                skipCheck: true,
                dataType: "dynamic",
                object: { Id: id },
                apply: apply,
                revert: revert
            });
        }

        //add to temporary changes. those will be summed for the next changeset and likely send to server
        handleClientObjectUpdate(e : IChangeEvent) {
            if (!this.acceptChanges)
                return;

            this.triggerEvent(clientObjectUpdateEvent);

            var data = e.object,
                id = data.Id,
                dataType = e.dataType,
                key = e.name;
            var tempChanges = this.tempChanges;

            if (!tempChanges[dataType])
                tempChanges[dataType] = {};
            var dt = tempChanges[dataType];

            if (!dt[id])
                dt[id] = {};
            var t = dt[id];

            if (this.changeEventHandler[e.type]) {
                key = this.changeEventHandler[e.type].handle(e) || key;
            }

            if (t[key])
                (<any>t[key]).newValue = e.newValue;
            else {
                delete e.object;
                delete e.name;
                t[key] = e;
            }

            if (key === 'Id') {
                if (id !== e.newValue)
                    dt[e.newValue] = dt[id];
                if (id !== e.oldValue)
                    dt[e.oldValue] = dt[id];
            }

            this.needsUpdate = true;
        }

        //check changes and add a new changeset
        processChanges() {
            if (this.isWaiting)
                return;
            this.trimChangesets();
            var tempChanges = this.tempChanges,
                newChanges: IChange[] = [];
            for (var dataType in tempChanges) {
                var tChanges = tempChanges[dataType];
                var nChanges: IChange[] = [];
                for (var id in tChanges) {
                    var instanceChanges = tChanges[id];

                    var cKeys = Object.keys(instanceChanges);
                    
                    this.convertChangeEvents(cKeys, instanceChanges, id, dataType, nChanges);
                }
                if (!newChanges.length)
                    newChanges = nChanges;
                else
                    newChanges = newChanges.concat(nChanges);
            }
            if (newChanges.length) {
                this.changeSets.push(newChanges);
                this.pointer++;

                if (newChanges.some((c) => { return !c.skipCheck; })) {
                    this.reviewChanges(newChanges.filter((c) => { return !c.skipCheck; }), false);
                }
                newChanges.forEach((c) => { delete c.skipCheck; });
            }
            this.tempChanges = {};
            this.needsUpdate = false;
        }

        registerChangeProcessor(name: string, applyFn: (change: IChange, revert?: boolean) => void, revertFn?: (change: IChange, revert?: boolean) => void) {
            this.changeProcessor[name] = {
                applyChanges: applyFn,
                revertChanges: revertFn || applyFn
            };
        }

        registerChangeEventHandler(name: string, handleFn: (e: IChangeEvent) => string) {
            this.changeEventHandler[name] = {
                handle: handleFn
            };
        }
        
        //remove all changesets after current pointer position
        trimChangesets() {
            var changeSets = this.changeSets,
                pointer = this.pointer + 1;
            if (changeSets.length > pointer)
                changeSets.splice(pointer, changeSets.length - pointer);
        }
        
        addListener(type: string, listener: (event: IEventManagerEvent) => void): EventManager { return this; }

        hasListener(type: string, listener: (event: IEventManagerEvent) => void): boolean { return false; }

        removeListener(type?: string, listener?: (event: IEventManagerEvent) => void): void { }

        dispatchEvent(event: IEventManagerEvent): void { }

        triggerEvent(event: IEventManagerEvent): void {
            this.dispatchEvent(event);
            this.onChanged();
        }

        onChanged() {
            this.dispatchEvent(changeEvent);
        }

        reviewChanges(newChanges: IChange[], revert: boolean): void {
            this.isWaiting = true;
            LoaderManager.addLoadingJob();
            //var stopwatch = new SCENE3D.Stopwatch().start();
            SolarProTool.Ajax("WebServices/Anordnung3DService.asmx").Call("HandleClientObjectChanges").Data({changes: newChanges as any[], revert: false, disableSnapping: !!Controller.Current.viewModel.snappingDeactivated}).CallBack((modifiedChanges) => {
                //stopwatch.log("HandleClientObjectChanges");
                if (modifiedChanges && modifiedChanges.length)
                    this.handleModifiedChanges(modifiedChanges, false);
                this.isWaiting = false;
                Controller.Current.viewModel.checkSelection();
                this.triggerEvent(handleClientObjectChangesEvent);
                LoaderManager.finishLoadingJob();
            });
        }

        handleModifiedChanges(changes: IChange[] | SolarProTool.ClientObjectChanges[], revert: boolean) {
            var currentChanges = this.changeSets[this.pointer],
                removeChanges = [],
                newChanges = [],
                hasErrors = false;

            for (var i = 0, j = changes.length; i < j; i++) {
                var change = changes[i] as IChange;

                //check revert change
                if (change.ErrorCode != ErrorLevel.NoError) {
                    hasErrors = true;
                    if (change.Uuid) {
                        for (var k = currentChanges.length; k--;) {
                            var currChange = currentChanges[k];
                            if (currChange.Uuid === change.Uuid) {
                                removeChanges.push(currChange);
                                currentChanges.splice(k, 1);
                                break;
                            }
                        }
                    }
                    this.handleErrorChange(change);
                } else if (change.UpdateType && change.IdStrings && change.IdStrings.length) {
                    newChanges.push(change);
                }
            }

            if (removeChanges.length) {
                if (revert)
                    this.applyChanges(removeChanges, false);
                else
                    this.revertChanges(removeChanges, false);
            }
            if (newChanges.length) {
                if (revert) {
                    this.revertChanges(newChanges, false);
                    for (var n = newChanges.length; n--;)
                        this.changeSets[this.pointer].unshift(newChanges[n]);
                } else {
                    this.applyChanges(newChanges, false);

                    if (currentChanges) {
                        //generate uuids before we add it to changesets
                        for (var l = newChanges.length; l--;) {
                            var nChange = newChanges[l];
                            if (!nChange.Uuid)
                                nChange.Uuid = THREE.MathUtils.generateUUID();
                        }

                        this.changeSets[this.pointer] = currentChanges.concat(newChanges);
                    }
                }
            }
            if (!hasErrors)
                this.onNoError();
        }

        handleErrorChange(change: IChange) {
            switch (change.ErrorCode) {
                case ErrorLevel.Fail:
                    //Silent fail, no error message
                    break;
                case ErrorLevel.Severe:
                    DManager.showErrorMessage(change.ErrorMessage || ("Errorcode: " + change.ErrorCode));
                    break;
                case ErrorLevel.Warning:
                    DManager.showInfoWindow(change.ErrorMessage || ("Errorcode: " + change.ErrorCode));
                    break;
                case ErrorLevel.Info:
                    DManager.ShowSmallInfo(change.ErrorMessage || ("Errorcode: " + change.ErrorCode), true);
                    break;
            }

        }

        applyChanges(changes: IChange[], noIdChanges: boolean) {
            this.acceptChanges = false;

            for (var k = 0, l = changes.length; k < l; k++) {
                var change = changes[k];

                if (noIdChanges && change.UpdateType === 'update' && change.Name === 'Id')
                    continue;
                this.changeProcessor[change.UpdateType].applyChanges(change, false);
            }

            this.acceptChanges = true;
        }

        revertChanges(changes: IChange[], noIdChanges: boolean) {
            this.acceptChanges = false;

            for (var i = changes.length; i--;) {
                var change = changes[i];
                if (noIdChanges && change.UpdateType === 'update' && change.Name === 'Id')
                    continue;
                this.changeProcessor[change.UpdateType].revertChanges(change, true);
            }

            this.acceptChanges = true;
        }

        onNoError() {
            DManager.SmallInfoWindowClear();
        }

        clearHistory() {
            this.changeSets = [];
            this.pointer = -1;
        }

        update() {
            if (this.needsUpdate)
                this.processChanges();
            if (this.doClearHistory) {
                this.clearHistory();
                this.doClearHistory = false;
            }
        }

        insertSerializedInstance(sData: string) {
            var data = JSON.parse(sData);
            delete data.ModCount;
            delete data.Orientation;
            var clientObject = Controller.Current.Objects[data.ClientObjectInstance];
            if (clientObject)
                clientObject.insertClientObjectInstance(data);
        }

        deleteInstances(ids: number[] | string[]) {
            var instances = Controller.Current.Instances;
            (<number[]>ids).forEach((id) => {
                instances[id].removeMe();
            });
        }

        ShrinkChanges(changes: IChange[]) {
            if (changes && changes.length > 1) {
                if (changes.every((change) => { return change.UpdateType === "remove"; })) {
                    var typeDir = {};
                    ArrayHelper.iterateArray(changes, (change) => {
                        if (typeDir[change.TypeString] === undefined) {
                            typeDir[change.TypeString] = {
                                Uuid: null,//THREE.MathUtils.generateUUID(),
                                TypeString: change.TypeString,
                                UpdateType: change.UpdateType,
                                IdStrings: change.IdStrings.slice(),
                                skipCheck: !!change.skipCheck,
                                NewValueString: null,
                                OldValueString: null,
                                Name: change.Name
                            };
                        } else {
                            var idStrings = typeDir[change.TypeString].IdStrings;
                            idStrings.push.apply(idStrings, change.IdStrings);
                        }
                    });
                    return Object.keys(typeDir).map((k) => { return typeDir[k]; }) as IChange[];
                }
            }
            return changes;
        }

        Undo() {
            if (this.isWaiting || this.pointer < 0)
                return;
            var changes = this.changeSets[this.pointer];
            this.revertChanges(changes, true);
            var self = this;
            changes = changes.filter(ch => ch.UpdateType !== "dynamic");
            if (!changes.length) {
                self.pointer--;
                return;
            }
            this.isWaiting = true;
            LoaderManager.addLoadingJob();
            var viewModel = Controller.Current.viewModel;
            //var stopwatch = new SCENE3D.Stopwatch().start();
            SolarProTool.Ajax("WebServices/Anordnung3DService.asmx").Call("HandleClientObjectChanges").Data({changes: changes as any[], revert: true, disableSnapping: !!Controller.Current.viewModel.snappingDeactivated}).CallBack((modifiedChanges) => {
                //stopwatch.log("HandleClientObjectChanges");
                if (modifiedChanges && modifiedChanges.length)
                    self.handleModifiedChanges(modifiedChanges, true);
                self.pointer--;
                self.isWaiting = false;
                viewModel.checkSelection();
                //if (changes.some(function(c) { return c.UpdateType === "remove"; })) {
                //    //reload all since Errorcodes are wrong
                //}
                this.triggerEvent(handleClientObjectChangesEvent);
                LoaderManager.finishLoadingJob();
            });
        }

        Redo() {
            if (this.isWaiting || this.pointer >= this.changeSets.length - 1)
                return;
            this.pointer++;
            var changes = this.changeSets[this.pointer];
            this.applyChanges(changes, true);
            var self = this;
            changes = changes.filter(ch => ch.UpdateType !== "dynamic");
            if (!changes.length)
                return;
            this.isWaiting = true;
            LoaderManager.addLoadingJob();
            var viewModel = Controller.Current.viewModel;
            //var stopwatch = new SCENE3D.Stopwatch().start();
            SolarProTool.Ajax("WebServices/Anordnung3DService.asmx").Call("HandleClientObjectChanges").Data({changes: this.ShrinkChanges(changes) as any[], revert: false, disableSnapping: !!Controller.Current.viewModel.snappingDeactivated}).CallBack((modifiedChanges) => {
                //stopwatch.log("HandleClientObjectChanges");
                if (modifiedChanges && modifiedChanges.length)
                    self.handleModifiedChanges(modifiedChanges, false);
                self.isWaiting = false;
                viewModel.checkSelection();
                this.triggerEvent(handleClientObjectChangesEvent);
                LoaderManager.finishLoadingJob();
            });
        }
        
        convertChangeEventsBase(keys: string[], instanceChanges: { [key: string]: IBaseChange }, id: string, dataType: string, output: IChange[]) {
            keys.forEach((key) => {
                var change = instanceChanges[key];
                var existing = false;
                for (var i = output.length; i--;) {
                    var nChange = output[i];
                    if (nChange.UpdateType === change.type && nChange.Name === key && nChange.NewValueString === change.newValue && nChange.OldValueString === change.oldValue) {
                        nChange.IdStrings.push(id);
                        existing = true;
                        break;
                    }
                }
                if (!existing) {
                    output.push({
                        Uuid: THREE.MathUtils.generateUUID(),
                        TypeString: dataType,
                        UpdateType: change.type,
                        NewValueString: change.newValue !== undefined && change.newValue !== null ? change.newValue.toString() : null,
                        OldValueString: change.oldValue !== undefined && change.oldValue !== null ? change.oldValue.toString() : null,
                        Name: key,
                        IdStrings: [id],
                        skipCheck: !!change.skipCheck,
                        apply: change.apply,
                        revert: change.revert
                    });
                }
            });
        }

        convertChangeEvents(keys: string[], instanceChanges: IInstanceChanges, id: string, dataType: string, output: IChange[]) {
            //move and scale change
            if (keys.some(key => key === "X" || key === "Y" || key === "Z" || key === "SizeX" || key === "SizeY")) {
                var tx = (instanceChanges.X ? Math.round((instanceChanges.X.newValue as number) - (instanceChanges.X.oldValue as number)) : 0);
                var ty = (instanceChanges.Y ? Math.round((instanceChanges.Y.newValue as number) - (instanceChanges.Y.oldValue as number)) : 0);
                var tz = (instanceChanges.Z ? Math.round((instanceChanges.Z.newValue as number) - (instanceChanges.Z.oldValue as number)) : 0);
                var sx = (instanceChanges.SizeX ? Math.round((instanceChanges.SizeX.newValue as number) - (instanceChanges.SizeX.oldValue as number)) : 0);
                var sy = (instanceChanges.SizeY ? Math.round((instanceChanges.SizeY.newValue as number) - (instanceChanges.SizeY.oldValue as number)) : 0);

                var existing = false;
                for (var i = output.length; i--;) {
                    var nChange = output[i];
                    if (nChange.UpdateType === "translate" && nChange.Tx === tx && nChange.Ty === ty && nChange.Tz === tz && nChange.Sx === sx && nChange.Sy === sy) {
                        nChange.IdStrings.push(id);
                        existing = true;
                        break;
                    }
                }
                if (!existing) {
                    output.push({
                        Uuid: THREE.MathUtils.generateUUID(),
                        TypeString: dataType,
                        UpdateType: "translate",
                        Tx: tx,
                        Ty: ty,
                        Tz: tz,
                        Sx: sx,
                        Sy: sy,
                        IdStrings: [id],
                        skipCheck: false
                    });
                }

                keys = keys.filter(key => key !== "X" && key !== "Y" && key !== "Z" && key !== "SizeX" && key !== "SizeY");
            }
            
            this.convertChangeEventsBase(keys, instanceChanges, id, dataType, output);
        }
        
        init() {
            var self = this;

            //change processors

            self.registerChangeProcessor('update', (change, revert) => {
                var instances = Controller.Current.Instances;
                change.IdStrings.forEach((id) => {
                    var instance = instances[id];
                    if (instance) {
                        if (change.Name === 'Id') {
                            if (revert) {
                                if (!instance)
                                    instance = instances[change.NewValueString] || instances[change.OldValueString];
                                delete instances[change.NewValueString];
                                instances[change.OldValueString] = instance;
                            } else {
                                if (!instance)
                                    instance = instances[change.OldValueString] || instances[change.NewValueString];
                                delete instances[change.OldValueString];
                                instances[change.NewValueString] = instance;
                            }
                        }

                        if (instance) {
                            var val = revert ? change.OldValueString : change.NewValueString,
                                dType = typeof instance.instanceData[change.Name];
                            switch (dType) {
                                case "number":
                                    instance.instanceData[change.Name] = parseFloat(val);
                                    break;
                                case "boolean":
                                    instance.instanceData[change.Name] = val === "true" || val === "1";
                                    break;
                                default:
                                    instance.instanceData[change.Name] = val;
                                    break;
                            }
                        }

                        instance.OnChanged();
                    }
                });
            });

            self.registerChangeProcessor('insert', (change) => {
                //apply
                self.insertSerializedInstance(change.NewValueString);
            }, (change) => {
                //revert
                self.deleteInstances(change.IdStrings);
            });

            self.registerChangeProcessor('remove', (change) => {
                //apply
                self.deleteInstances(change.IdStrings);
            }, (change) => {
                //revert
                self.insertSerializedInstance(change.OldValueString);
            });

            self.registerChangeProcessor('translate', (change: IChange, revert) => {
                var instances = Controller.Current.Instances;
                //apply
                change.IdStrings.forEach((id) => {
                    var instance = instances[id];
                    if (instance) {
                        var tx = change.Tx, ty = change.Ty, tz = change.Tz;
                        var sx = change.Sx || 0, sy = change.Sy || 0;
                        if (revert) {
                            instance.instanceData.X -= tx;
                            instance.instanceData.Y -= ty;
                            instance.instanceData.Z -= tz;
                            instance.instanceData.SizeX -= sx;
                            instance.instanceData.SizeY -= sy;
                        } else {
                            instance.instanceData.X += tx;
                            instance.instanceData.Y += ty;
                            instance.instanceData.Z += tz;
                            instance.instanceData.SizeX += sx;
                            instance.instanceData.SizeY += sy;
                        }
                        instance.OnChanged();
                    }
                });
            });
            
            self.registerChangeProcessor('clearHistory', (change: IChange, revert) => {
                self.clearHistory();
            });

            self.registerChangeProcessor('dynamic', (change: IChange, revert) => {
                if (revert)
                    change.revert();
                else
                    change.apply();
            });

            //change event handlers

            self.registerChangeEventHandler('remove', (e: IChangeEvent) => {
                var data = (<IInstanceData>(<any>(e.object)));
                var d = (<IInstanceData>(<any>(data.clone())));
                var clientObject = (data.ClientObjectInstance as ClientObjectInstance).clientObject;
                d.ClientObjectInstance = (<any>clientObject.id);
                d.ModCount = clientObject.userData.ModCount;
                d.Orientation = clientObject.userData.Orientation;
                //serialize removed object
                e.oldValue = JSON.stringify(d);
                return 'remove';
            });

            self.registerChangeEventHandler('insert', (e: IChangeEvent) => {
                var data = (<IInstanceData>(<any>(e.object)));
                var d = (<IInstanceData>(<any>(data.clone())));
                var clientObject = (data.ClientObjectInstance as ClientObjectInstance).clientObject;
                d.ClientObjectInstance = (<any>clientObject.id);
                d.ModCount = clientObject.userData.ModCount;
                d.Orientation = clientObject.userData.Orientation;
                //serialize inserted object
                e.newValue = JSON.stringify(d);
                return 'insert';
            });

            self.registerChangeEventHandler('dynamic', (e: IChangeEvent) => {
                return 'dynamic';
            });
        }
    }

    EventManager.apply(UpdateManager.prototype);
}