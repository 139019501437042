module LS.Client3DEditor {
    export enum StringingToolMode {
        default = 0,
        plus = 1
    }

    export enum StringingToolStep {
        none = 0,
        drawing = 1
    }

    export class StringingTool extends BaseTool {

        //tempStringObject: StringObjectInstance = null;

        hoveredClientObjectId: string = null;

        hoveredJointId: string = null;

        get currentStringObject() {
            return this.currentJoint ? this.currentJoint.getParent() as StringObjectInstance : null;
        }

        currentJoint: StringJoint = null;
        private _v1: THREE.Vector3 = new THREE.Vector3();

        lastMouse: THREE.Vector2 = new THREE.Vector2();
        lastRoofPosition: THREE.Vector3 = new THREE.Vector3();
        step: StringingToolStep = StringingToolStep.none;
        mode: StringingToolMode = StringingToolMode.default;
        resetOnMouseUp: boolean = false;

        getPositionFromClientObjectInstance(inst: ClientObjectInstance): THREE.Vector3 {
            if (!inst)
                return null;

            inst.update();

            var bb = inst.geometry.boundingBox;

            return bb.getCenter(new THREE.Vector3()).setZ(bb.max.z + 50).applyMatrix4(inst.matrixWorld);
        }

        onSelect(viewModel: ViewModel, params: { mode?: string }) {
            viewModel.deselectAll();
            this.reset();
            this.mode = params && params.mode ? StringingToolMode[params.mode] : StringingToolMode.default;
            LS.Electric.InvertersModel.doSelectString();
        }

        onDeselect(viewModel: ViewModel) {
            this.reset();
        }

        reset() {
            Controller.Current.viewModel.Cursor = this.cursor;
            this.cleanUp();
            this.step = StringingToolStep.none;
            this.resetOnMouseUp = false;
            this.hoveredClientObjectId = null;
            this.currentJoint = null;
        }

        onMouseMove(viewModel: ViewModel) {
            if (LoaderManager.isLoading()) {
                this.lastMouse.copy(viewModel.mouse);
                this.lastRoofPosition.copy(viewModel.roofPosition);

                return false;
            }

            var mouse = viewModel.mouse,
                roofPosition = viewModel.roofPosition,
                lastMouse = this.lastMouse,
                lastRoofPosition = this.lastRoofPosition,
                distSq = roofPosition.distanceToSquared(lastRoofPosition);

            if (this.step === StringingToolStep.drawing && this.currentJoint && distSq > 640000) {
                //is drawing
                var sec = Math.ceil(Math.sqrt(distSq) / 800),
                    m = new THREE.Vector2();

                for (var i = 1; i < sec; i++) {
                    this.onMouseMoveIntern(viewModel, m.lerpVectors(lastMouse, mouse, i / sec));
                }
            }

            lastMouse.copy(mouse);
            lastRoofPosition.copy(roofPosition);

            return this.onMouseMoveIntern(viewModel, viewModel.mouse);
        }

        private onMouseMoveIntern(viewModel: ViewModel, mouse: THREE.Vector2) {
            var controller = Controller.Current,
                instanceContext = controller.viewModel.currentInstance,
                raycaster = controller.getWorldRaycaster(null, mouse),
                stringObjectHolder = controller.stringObjectHolder,
                hoveredClientObjectId: string = this.hoveredClientObjectId = null,
                isDrawing = this.step === StringingToolStep.drawing && !!this.currentJoint;

            var intersections = instanceContext.getInstanceIntersections(raycaster).filter(ci => ci && !ci._isDisposed && ci.instanceData && ci.instanceData.Id && ci.clientObject.dataType.match(/ModuleObject|DiffusionPointObject/));

            var canHoverJoint = !isDrawing;

            if (intersections.length > 0) {
                var instance = intersections[0];

                LS.Client3DEditor.setBoxhelper(instance);

                //hoveredInstanceChanged = this.hoveredClientObjectId !== instaceId;
                if (instance.clientObject.dataType.indexOf("ModuleObject") != -1) {
                    this.hoveredClientObjectId = hoveredClientObjectId = instance.instanceData.Id;

                    if(canHoverJoint)
                        canHoverJoint = !!stringObjectHolder.getJointByModuleId(hoveredClientObjectId);
                }

                if (this.resetOnMouseUp)
                    this.resetOnMouseUp = false;

                //cursor = 'pointer';
            } else {
                LS.Client3DEditor.setBoxhelper(null);
            }

            raycaster = controller.getLocalRaycaster(raycaster, instanceContext);
            
            var hoveredJoint = canHoverJoint ? stringObjectHolder.getClosestJointToRaycaster(raycaster) : null;

            this.hoveredJointId = hoveredJoint && !hoveredJoint.isTemporary ? hoveredJoint.ModuleObjectIdString : null;

            controller.viewModel.Cursor = this.hoveredJointId ? 'pointer' : this.cursor;

            if (isDrawing) {
                //is drawing

                var conduitObjectHolder = LS.Client3DEditor.Controller.Current.conduitObjectHolder;
                var prev = this.currentJoint.getPrevious();

                this.currentJoint.setPosition(viewModel.roofPosition.x, viewModel.roofPosition.y);

                if (viewModel.shiftDown && prev)
                    spt.ThreeJs.utils.ProjectVectorToClosestAxis(this.currentJoint.position.sub(prev.position), 100).add(prev.position);

                if (!viewModel.mouseDown && !hoveredClientObjectId) {

                    var co = conduitObjectHolder.closestCenterByRaycaster(raycaster);
                    if (co && co.p) {
                        if (viewModel.shiftDown && prev) {
                            var co2 = co.o.getClosestByRay2D(prev.position, this._v1.copy(this.currentJoint.position).sub(prev.position).normalize());
                            if (co2 && co2.p) {
                                co.p.copy(co2.p.setZ(this.currentJoint.position.z));
                            }
                        }

                        this.currentJoint.position.copy(co.p.setZ(this.currentJoint.position.z));
                    }
                }

                if (viewModel.mouseDown && hoveredClientObjectId && !stringObjectHolder.getJointByModuleId(hoveredClientObjectId)) {

                    var so = this.currentJoint.getParent(),
                        pos = this.getPositionFromClientObjectInstance(controller.Instances[hoveredClientObjectId]);

                    if (so.modulesStringObjectValid()) {
                        //string is already full
                        return false;
                    } else {
                        this.currentJoint.set(pos, hoveredClientObjectId);

                        if (so.modulesStringObjectValid()) {
                            so.save();

                            //break drawing
                            this.reset();
                            LS.Electric.InvertersModel.doSelectString();
                            return false;
                        }
                    }

                    if (so.Joints.indexOf(this.currentJoint) === 0)
                        this.currentJoint = so.prependJoint(pos.clone().setX(viewModel.roofPosition.x).setY(viewModel.roofPosition.y));
                    else
                        this.currentJoint = so.addJoint(pos.clone().setX(viewModel.roofPosition.x).setY(viewModel.roofPosition.y));

                    //this.currentJoint.set(pos, hoveredClientObjectId);

                    //if (so.modulesStringObjectValid()) {
                    //    this.onStringingFinished(so);
                    //    return false;
                    //}
                    //else
                    //    this.currentJoint = so.addJoint(pos.clone().setX(viewModel.roofPosition.x).setY(viewModel.roofPosition.y));

                }
            }

            //controller.viewModel.Cursor = cursor;
            return false;
        }

        onMouseDown(viewModel: ViewModel) {
            var controller = Controller.Current,
                instanceContext = controller.viewModel.currentInstance,
                //camera = controller.camera,
                //raycaster = controller.raycaster,
                //mouse = viewModel.mouse,
                stringObjectHolder = controller.stringObjectHolder,
                hoveredClientObjectId = this.hoveredClientObjectId,
                selectedInverterStringObject = LS.Electric.InvertersModel.getModulesStringObject(),
                hoveredJoint = stringObjectHolder.getJointByModuleId(this.hoveredJointId),
                hoveredClientObjectJoint = hoveredClientObjectId && stringObjectHolder.getJointByModuleId(hoveredClientObjectId),
                //hoveredJoint = hoveredClientObjectId && stringObjectHolder.getJointById(hoveredClientObjectId),
                additionalPoints = viewModel.ctrlDown || this.mode === StringingToolMode.plus;

            if (LoaderManager.isLoading())
                return false;

            this.resetOnMouseUp = this.step === StringingToolStep.drawing && !hoveredJoint && !hoveredClientObjectJoint && !hoveredClientObjectId;

            //pick up existing string
            if (this.step === StringingToolStep.none && hoveredJoint) {
                var curSo = hoveredJoint.getParent(),
                    jIdx = curSo.getIndexOfJointByModuleId(hoveredJoint.ModuleObjectIdString),
                    removeCurrent = !viewModel.ctrlDown;

                if (jIdx > 0) {
                    curSo.removeRange(removeCurrent ? jIdx : jIdx + 1);
                    this.currentJoint = curSo.addJoint(hoveredJoint.position.clone().setX(viewModel.roofPosition.x).setY(viewModel.roofPosition.y));
                    LS.Electric.InvertersModel.doSelectString(curSo.IdString);
                    this.step = StringingToolStep.drawing;
                    return false;
                } else if (jIdx === 0) {
                    if (removeCurrent) {
                        //take root
                        curSo.removeRange(0, 1);

                        //curSo.removeInstance();
                    }

                    //prepend joint to root
                    this.currentJoint = curSo.prependJoint(hoveredJoint.position.clone().setX(viewModel.roofPosition.x).setY(viewModel.roofPosition.y));
                    LS.Electric.InvertersModel.doSelectString(curSo.IdString);
                    this.step = StringingToolStep.drawing;

                    return false;
                }
                //if (jIdx > 0) {
                //    curSo.removeRange(jIdx);
                //    this.currentJoint = curSo.addJoint(hoveredJoint.position.clone().setX(viewModel.roofPosition.x).setY(viewModel.roofPosition.y));
                //    LS.Electric.InvertersModel.doSelectString(curSo.IdString);
                //    this.step = StringingToolStep.drawing;
                //    return false;
                //} else if (jIdx === 0) {
                //    this.currentJoint = null;
                //    LS.Electric.InvertersModel.doSelectString(curSo.IdString);
                //    curSo.removeInstance();
                //    return false;
                //}
            }

            if (hoveredClientObjectId) {
                //if module hovered

                if (!selectedInverterStringObject) {
                    AManager.GetTranslation("ElectricStrings.DoSelectInverterInput", (str) => {
                        DManager.ShowSmallInfo(str);
                    });
                    return false;
                }

                if (!hoveredClientObjectJoint) {
                    //no joint hovered

                    var pos = this.getPositionFromClientObjectInstance(controller.Instances[hoveredClientObjectId]);

                    if (viewModel.ctrlDown) {
                        pos.setX(viewModel.roofPosition.x).setY(viewModel.roofPosition.y);
                    }

                    if (viewModel.shiftDown && this.currentJoint) {
                        var prev = this.currentJoint.getPrevious();
                        if (prev)
                            spt.ThreeJs.utils.ProjectVectorToClosestAxis(pos.copy(this.currentJoint.position).sub(prev.position), 100).add(prev.position);
                    }

                    if (this.step === StringingToolStep.none) {
                        //not currently drawing

                        var newSo = stringObjectHolder.getById(selectedInverterStringObject.Id);
                        if (newSo)
                            newSo.removeAll();
                        else
                            newSo = stringObjectHolder.addNewStringObject(selectedInverterStringObject.Id);
                        newSo.setStringColor(selectedInverterStringObject.Color);
                        newSo.addJoint(pos, hoveredClientObjectId);
                        this.currentJoint = newSo.addJoint(pos.clone().setX(viewModel.roofPosition.x).setY(viewModel.roofPosition.y));
                        this.step = StringingToolStep.drawing;
                        if (newSo.modulesStringObjectValid()) {
                            newSo.save();

                            //break drawing
                            this.reset();
                            LS.Electric.InvertersModel.doSelectString();
                            return false;
                        }
                    } else if (this.step === StringingToolStep.drawing && this.currentJoint) {

                        var so = this.currentJoint.getParent();

                        if (so.modulesStringObjectValid()) {
                            //string is already full

                            this.reset();
                            this.hoveredClientObjectId = hoveredClientObjectId;
                            LS.Electric.InvertersModel.doSelectString();
                            return this.onMouseDown(viewModel);

                        } else {
                            this.currentJoint.set(pos, hoveredClientObjectId);

                            if (so.modulesStringObjectValid()) {
                                so.save();

                                //break drawing
                                this.reset();
                                LS.Electric.InvertersModel.doSelectString();
                                return false;
                            }
                        }
                        if (so.Joints.indexOf(this.currentJoint) === 0)
                            this.currentJoint = so.prependJoint(pos.clone().setX(viewModel.roofPosition.x).setY(viewModel.roofPosition.y));
                        else
                            this.currentJoint = so.addJoint(pos.clone().setX(viewModel.roofPosition.x).setY(viewModel.roofPosition.y));
                        //this.step = StringingToolStep.none;
                    }
                }
                else if (additionalPoints && this.step === StringingToolStep.drawing && this.currentJoint) {
                    //add free joint
                    var currentJoint = this.currentJoint,
                        so = currentJoint.getParent();
                    if (so.Joints.every(jt => jt === currentJoint || jt.position.distanceToSquared(currentJoint.position) > 1)) {
                        currentJoint.ModuleObjectIdString = spt.Utils.GenerateGuid(); // make it persistent

                        if (so.Joints.indexOf(this.currentJoint) === 0)
                            this.currentJoint = so.prependJoint(currentJoint.position.clone().setX(viewModel.roofPosition.x).setY(viewModel.roofPosition.y));
                        else
                            this.currentJoint = so.addJoint(currentJoint.position.clone().setX(viewModel.roofPosition.x).setY(viewModel.roofPosition.y));
                    }
                }
                else if (hoveredJoint && this.step === StringingToolStep.drawing && this.currentJoint && this.currentJoint.getParent().id === hoveredJoint.getParent().id) {
                    //pick up string
                    var so = hoveredJoint.getParent();
                    so.removeJoint(this.currentJoint);
                    var idx = so.Joints.indexOf(hoveredJoint);

                    if (idx > 0) {
                        so.removeRange(idx + 1);
                        this.currentJoint = so.addJoint(this
                            .getPositionFromClientObjectInstance(controller.Instances[hoveredClientObjectId])
                            .setX(viewModel.roofPosition.x).setY(viewModel.roofPosition.y));
                    } else if (idx === 0) {
                        this.currentJoint = so.prependJoint(this
                            .getPositionFromClientObjectInstance(controller.Instances[hoveredClientObjectId])
                            .setX(viewModel.roofPosition.x).setY(viewModel.roofPosition.y));
                    } else {
                        if (so.modulesStringObjectValid())
                            so.save();
                        this.reset();
                    }
                    //if (idx > 0) {
                    //    so.removeRange(idx);
                    //    this.currentJoint = so.addJoint(this
                    //        .getPositionFromClientObjectInstance(controller.Instances[hoveredClientObjectId])
                    //        .setX(viewModel.roofPosition.x).setY(viewModel.roofPosition.y));
                    //} else if (idx === 0) {
                    //    so.removeInstance();
                    //    LS.Electric.InvertersModel.doSelectString(so.IdString);
                    //    this.currentJoint = null;
                    //    this.step = StringingToolStep.none;
                    //}
                }
            } else if (!hoveredJoint) {
                var raycaster = controller.worldRaycaster;

                var intersections = instanceContext.getInstanceIntersections(raycaster).filter(ci => ci && !ci._isDisposed && ci.instanceData && ci.instanceData.Id && ci.clientObject.dataType.indexOf("DiffusionPointObject") != -1);

                if (intersections.length > 0) {
                    var instance = intersections[0];
                    viewModel.addToSelection([instance]);
                }
            }

            return false;
        }

        cleanUp() {
            var controller = Controller.Current,
                currentJoint = this.currentJoint;
            if (currentJoint) {
                var so = currentJoint.getParent();

                so.cleanUp();

                if (!so.Joints.length) {
                    controller.stringObjectHolder.removeObject(so);
                }
                else if (!so.modulesStringObjectValid()) {
                    var mso = so.getModulesStringObject(),
                        maxLength = mso ? "" + mso.MaxModuleCount : "?",
                        msoName = mso ? mso.Name : "?";
                    DManager.ShowSmallInfo(`String length incorrect! Please connect ${maxLength} modules for input ${msoName}.`);
                    controller.stringObjectHolder.removeObject(so);
                }
            }
            this.currentJoint = null;
        }

        onMouseUp(viewModel: ViewModel) {
            if (LoaderManager.isLoading())
                return false;

            var currentJoint = this.currentJoint,
                so = currentJoint && currentJoint.getParent();

            if (this.resetOnMouseUp && this.step === StringingToolStep.drawing && this.currentJoint) {

                var controller = Controller.Current,
                    mouse = viewModel.mouse,
                    raycaster = controller.getWorldRaycaster(null, mouse),
                    conduitObjectHolder = LS.Client3DEditor.Controller.Current.conduitObjectHolder,
                    additionalPoints = viewModel.ctrlDown || this.mode === StringingToolMode.plus,
                    doReset: boolean = false;

                var co = conduitObjectHolder.closestCenterByRaycaster(raycaster);
                if (co && co.p) {
                    var prev = this.currentJoint.getPrevious();

                    if (viewModel.shiftDown && prev) {
                        var co2 = co.o.getClosestByRay2D(prev.position, this._v1.copy(this.currentJoint.position).sub(prev.position).normalize());
                        if (co2 && co2.p /*&& co2.p.setZ(viewModel.roofPosition.z).distanceToSquared(viewModel.roofPosition) < raycaster.linePrecision * raycaster.linePrecision*/) {
                            co.p.copy(co2.p)/*.sub(this.currentJoint.getParent().getPointOffset())*/;
                        }
                    }
                    //var off = this.currentJoint.getParent().getPointOffset();
                    this.currentJoint.position.copy(co.p.setZ(this.currentJoint.position.z))/*.sub(off)*/;
                    doReset = !additionalPoints;
                    additionalPoints = true;
                }

                if (additionalPoints) {
                    //add free joint
                    if (so.Joints.every(jt => jt === currentJoint ||
                        jt.position.distanceToSquared(currentJoint.position) > 1)) {
                        currentJoint.ModuleObjectIdString = spt.Utils.GenerateGuid(); // make it persistent

                        if (so.Joints.indexOf(this.currentJoint) === 0)
                            this.currentJoint = so.prependJoint(currentJoint.position.clone()
                                .setX(viewModel.roofPosition.x).setY(viewModel.roofPosition.y));
                        else
                            this.currentJoint = so.addJoint(currentJoint.position.clone().setX(viewModel.roofPosition.x)
                                .setY(viewModel.roofPosition.y));
                    }

                    if (!doReset) {
                        this.resetOnMouseUp = false;
                        return false;
                    }
                }
            }

            if (this.resetOnMouseUp && viewModel.diffViewPosition.lengthSq() < 100) {
                if (so && so.modulesStringObjectValid())
                    so.save();
                this.reset();
                LS.Electric.InvertersModel.doSelectString();
            }

            return false;
        }

        onKeyDown(viewModel: ViewModel) {
            if (LoaderManager.isLoading())
                return false;

            if (viewModel.keyPressed(27)) // esc
                this.onSelect(viewModel, { mode: StringingToolMode[this.mode] });
            return false;
        }

    }

    export class StringJoint implements SolarProTool.IModuleConnectionJoint {

        ModuleObjectIdString: string;

        get X() {
            return this.position.x;
        }

        set X(v: number) {
            this.position.x = v;
        }

        get Y() {
            return this.position.y;
        }

        set Y(v: number) {
            this.position.y = v;
        }

        get Z() {
            return this.position.z;
        }

        set Z(v: number) {
            this.position.z = v;
        }

        constructor(position?: THREE.Vector3, modId?: string, parent?: StringObjectInstance) {
            this.position = position || new THREE.Vector3();
            this.ModuleObjectIdString = modId || null;
            this.parent = parent || null;
            if (modId)
                this.setStyleToClientObject();
        }

        hasClientObjectInstance(controller?: LS.Client3DEditor.Controller) {
            if (!controller)
                controller = LS.Client3DEditor.Controller.Current;
            return !!(this.ModuleObjectIdString && controller && controller.Instances[this.ModuleObjectIdString]);
        }

        getClientObjectInstance(controller?: LS.Client3DEditor.Controller): LS.Client3DEditor.ClientObjectInstance {
            if (!controller)
                controller = LS.Client3DEditor.Controller.Current;
            return this.ModuleObjectIdString && controller && (controller.Instances[this.ModuleObjectIdString] || null);
        }

        getModulesStringObject(): LS.Electric.ModulesStringObject {
            return this.parent && this.parent.getModulesStringObject();
        }

        getParent(): StringObjectInstance {
            return this.parent;
        }

        getPrevious(): StringJoint {
            var so = this.getParent(),
                joints = so.Joints;

            if (joints.length <= 1)
                return null;

            var idx = joints.indexOf(this);

            if (idx === 0)
                return joints[1];

            return joints[idx - 1];
        }

        set(p: THREE.Vector3, id?: string) {
            var parent = this.parent,
                idChanged: boolean = false;

            this.position.copy(p);

            if (id && this.ModuleObjectIdString !== id) {
                this.ModuleObjectIdString = id;
                idChanged = true;
                this.setStyleToClientObject();
            }

            if (parent)
                parent.onJointChanged(this, idChanged);

            return this;
        }

        setStyleToClientObject(clean?: boolean) {
            var ci = this.getClientObjectInstance(),
                mso = this.getModulesStringObject();

            if (clean) {
                if (ci) {
                    ci.instanceData.InstanceColor = 0;
                    ci.instanceAlphaOverride = 0.7;
                    ci.OnChanged();
                }
            }
            else if (ci && mso) {
                var stringColor = new THREE.Color().setStyle(mso.Color).offsetHSL(0, -0.3, 0),
                    cellColor: THREE.Color = new THREE.Color(0x0b1b3c);

                var co = ci.clientObject;

                for (var i = 0, sl = co.SharedMeshes.length; i < sl; i++) {
                    var sm = co.SharedMeshes[i];

                    if (sm.material instanceof LS.Client3DEditor.ModuleAppearanceShader) {
                        cellColor.copy((<LS.Client3DEditor.ModuleAppearanceShader>sm.material).moduleCellColor);
                        break;
                    }
                }

                //ci.instanceData.InstanceColor;
                //this.instanceColor.setHex(data.InstanceColor).multiplyScalar(2).addScalar(-1);

                var ic = stringColor.sub(cellColor).addScalar(1).multiplyScalar(0.5).getHex();

                ci.instanceData.InstanceColor = ic;
                ci.instanceAlphaOverride = 0.5;
                ci.OnChanged();

            }
        }

        setPosition(x: number, y: number) {
            this.position.setX(x).setY(y);

            if (this.parent)
                this.parent.onJointChanged(this);

            return this;
        }

        dispose() {
            this.setStyleToClientObject(true);
            this.parent = null;
        }

        private parent: StringObjectInstance;
        position: THREE.Vector3;

        //Temporary: ModuleObjectIdString == null
        //joint: ModuleObjectIdString == Guid.Empty
        //moduleObject: ModuleObjectIdString == Guid
        get isTemporary() {
            return !this.ModuleObjectIdString;
        }

        setData(data: SolarProTool.IModuleConnectionJoint) {
            if (data) {
                this.position.set(data.X || 0, data.Y || 0, data.Z || 0);
                this.ModuleObjectIdString = data.ModuleObjectIdString || null;
            }
        }

        exportData(): SolarProTool.IModuleConnectionJoint {
            return {
                X: this.position.x,
                Y: this.position.y,
                Z: this.position.z,
                ModuleObjectIdString: this.ModuleObjectIdString
            };
        }
    }

    export interface IStringObjectIntersection<T> extends IObjectIntersection<T> {
        j1: StringJoint;
        j2?: StringJoint;
    }

    export class StringObjectInstance extends GenericObjectInstance {
        moveBy(v: THREE.Vector3): void {

        }

        GetPositionProperty(k: string): number {
            return 0;
        }

        GetSizeProperty(k: string): number {
            return 0;
        }

        constructor(id?: string) {
            super(id);
            this.renderOrder = LS.Client3DEditor.UIRenderOrder;
            this.drawAngled = LS.Electric.InvertersModel ? (!LS.Electric.InvertersModel.allowDiagonalLinesForStringing) : true;
        }

        private _stringColor = new THREE.Color();
        stringColor1: THREE.Color = new THREE.Color(0xff4116);
        stringColor2: THREE.Color = new THREE.Color(0xff4116);

        drawAngled: boolean = true;

        DiffusionPointObjectId: string = null;

        getDiffusionPointObject(): ClientObjectInstance {
            return this.DiffusionPointObjectId ? Controller.Current.Instances[this.DiffusionPointObjectId] : null;
        }

        searchClosestDiffusionPointObject() {
            var controller = Controller.Current,
                viewModel = controller.viewModel,
                stringingTool = viewModel.tools.stringingTool;

            var diffusionPointObjects = viewModel.selected.filter(o => o.clientObject.dataType.indexOf("DiffusionPointObject") != -1);

            if (!diffusionPointObjects.length) {
                var allInstances = controller.Instances;
                for (var id in allInstances) {
                    var inst = allInstances[id];
                    if (inst && inst.clientObject.dataType.indexOf("DiffusionPointObject") != -1)
                        diffusionPointObjects.push(inst);
                }
            }

            if (!diffusionPointObjects.length)
                return null;

            var dist = Number.MAX_VALUE,
                bb = this._bounds,
                c = bb.getCenter(new THREE.Vector3()),
                diffPoint: ClientObjectInstance = null;

            for (var i = diffusionPointObjects.length; i--;) {
                var diffusionPointObject = diffusionPointObjects[i];
                var p = stringingTool.getPositionFromClientObjectInstance(diffusionPointObject);
                var d = c.distanceToSquared(p);
                if (d < dist) {
                    dist = d;
                    diffPoint = diffusionPointObject;
                }
            }

            return diffPoint;
        }

        setStringColor(s: string): void {
            this.stringColor1.setStyle(s);
            this.stringColor2.copy(this.stringColor1).offsetHSL(0, +0.3, +0.4);
            this._needsUpdate = true;
        }

        updateJointStringColors() {
            this.Joints.forEach(j => {
                if (j.ModuleObjectIdString)
                    j.setStyleToClientObject();
            });
        }

        getColor(): THREE.Color {
            return this._stringColor.copy(this.stringColor1).lerp(this.stringColor2, this.isSelected ? 1 : (this.isHovered ? 0.5 : 0));
        }

        getAlpha(): number {
            return 1;//this.isSelected ? 1 : (this.isHovered ? 0.8 : 0.5);
        }

        getModulesStringObject(): LS.Electric.ModulesStringObject {
            var invertersModel = LS.Electric.InvertersModel;
            return invertersModel && invertersModel.getModulesStringObject(this.IdString);
        }

        modulesStringObjectValid() {
            var mso = this.getModulesStringObject();
            return !!mso && mso.CurrentModuleCount === mso.MaxModuleCount;
        }

        onJointChanged(j: StringJoint, idChanged?: boolean) {
            if (idChanged)
                this.onJointsChanged();
            this._needsUpdate = true;
            this._positionsChanged = true;
        }

        onJointsChanged() {
            var mso = this.getModulesStringObject(),
                controller = LS.Client3DEditor.Controller.Current,
                instances = controller && controller.Instances;
            if (instances && mso)
                mso.CurrentModuleCount = this.Joints.filter(j => j.ModuleObjectIdString && controller.Instances[j.ModuleObjectIdString]).length;

            this._needsUpdate = true;
            this._positionsChanged = true;
        }

        getJointByModuleId(moduleId: string) {
            if (this._isDisposed || !moduleId)
                return null;
            var res = this.Joints.filter(j => j.ModuleObjectIdString === moduleId);
            if (res.length)
                return res[0];
            return null;
        }

        getJointByIndex(idx: number) {
            if (this._isDisposed || idx < 0 || idx > this.Joints.length)
                return null;
            return this.Joints[idx];
        }

        getIndexOfJointByModuleId(modId: string) {
            if (this._isDisposed)
                return -1;

            var joints = this.Joints;
            for (var i = 0, l = joints.length; i < l; i++) {
                if (joints[i].ModuleObjectIdString === modId)
                    return i;
            }

            return -1;
        }

        addJoint(p: THREE.Vector3, id?: string): StringJoint {
            if (this._isDisposed)
                return null;
            var jt = new StringJoint(p, id, this);
            this.Joints.push(jt);
            this.onJointsChanged();
            return jt;
        }

        prependJoint(p: THREE.Vector3, id?: string): StringJoint {
            if (this._isDisposed)
                return null;
            var jt = new StringJoint(p, id, this);
            this.Joints.unshift(jt);
            this.onJointsChanged();
            return jt;
        }

        removeAll(fn?: (item: StringJoint) => boolean): void {
            if (!fn) {
                this.Joints.splice(0, this.Joints.length).forEach(joint => {
                    joint.dispose();
                });
            } else {
                this.Joints = this.Joints.filter(joint => {
                    if (fn(joint)) {
                        joint.dispose();
                        return false;
                    }
                    return true;
                });
            }

            this.onJointsChanged();
        }

        removeRange(startIndex: number, count?: number) {
            if (this._isDisposed)
                return;

            if (count === undefined) {
                this.Joints.splice(startIndex).forEach(joint => {
                    joint.dispose();
                });
            } else {
                this.Joints.splice(startIndex, count).forEach(joint => {
                    joint.dispose();
                });
            }

            this.onJointsChanged();
        }

        removeJoint(joint: StringJoint): void {
            if (this._isDisposed || !joint)
                return;
            var index = this.Joints.indexOf(joint);
            if (index > -1)
                this.removeRange(index, 1);
        }

        cleanUp() {
            var joints = this.Joints.slice(),
                controller = LS.Client3DEditor.Controller.Current;
            for (var i = joints.length; i--;) {
                if (joints[i].isTemporary)
                    this.removeJoint(joints[i]);
            }
            if (joints.every(jt => !jt.getClientObjectInstance(controller)))
                this.removeAll();
        }

        insertBefore(targetJoint: StringJoint, p: THREE.Vector3, id?: string): StringJoint {
            if (this._isDisposed || !targetJoint)
                return null;
            var jt: StringJoint = null;
            var index = this.Joints.indexOf(targetJoint);
            if (index > -1) {
                jt = new StringJoint(p, id, this);
                this.Joints.splice(index, 0, new StringJoint(p, id, this));
            }

            this.onJointsChanged();
            return jt;
        }

        Joints: StringJoint[] = [];

        _positionsChanged: boolean = true;

        _lineMesh: THREE.Mesh = null;
        _pointMesh: THREE.Mesh = null;

        _lineMat: THREE.Material = null;
        _pointMat: THREE.Material = null;

        _linesCount: number = 0;
        _pointsCount: number = 0;

        static LineThickness: number = 100;
        static PointRadius: number = 75;

        txtHolder: spt.ThreeJs.utils.SDFTextObjectInstances = null;

        private _bounds: THREE.Box3 = new THREE.Box3();
        private _boundingSphere: THREE.Sphere = new THREE.Sphere();

        clear() {
            if (this._isDisposed)
                return;

            this._lineMesh = null;
            this._pointMesh = null;

            if (this.txtHolder) {
                this.txtHolder.dispose();
                this.txtHolder = null;
            }

            spt.ThreeJs.utils.emptyObject3D(this, true, true, false);
        }

        build() {
            if (this._isDisposed)
                return;

            var joints = this.Joints,
                drawAngled = this.drawAngled,
                pointsCount = joints.length,
                linesCount = Math.max(0, pointsCount - 1);

            pointsCount = this._pointsCount = Math.ceil(pointsCount / 10) * 10;
            linesCount = this._linesCount = Math.ceil(linesCount / 10) * 10;

            this.clear();

            if (!this._lineMat) {
                this._lineMat = new THREE.BaseInstancedShader(new THREE.Color(0xFFCCCC), null, 0.8, false, true, true);
                this._lineMat.side = THREE.FrontSide;
                //this._lineMat.depthTest = false;
                //this._lineMat.depthWrite = false;
            }

            if (!this._pointMat) {
                var loader = new THREE.TextureLoader();
                var tex = loader.load("../../Content/images/icons/arrow64.png", () => {
                    LoaderManager.notifyResourceLoaded();
                });
                tex.anisotropy = Detector.webglParameters.maxAnisotropy || 1;
                tex.wrapS = tex.wrapT = THREE.RepeatWrapping;

                this._pointMat = new THREE.BaseInstancedShader(new THREE.Color(0xFFCCCC), tex, 0.8, false, true, false, true);
                this._pointMat.side = THREE.FrontSide;
                //this._pointMat.depthTest = false;
                //this._pointMat.depthWrite = false;
            }

            if (pointsCount > 0) {
                var pointGeo = (new THREE.InstancedBufferGeometry()).copy(new THREE.CircleBufferGeometry(StringObjectInstance.PointRadius, 16) as any);
                pointGeo.maxInstancedCount = pointsCount;
                var pointOffsets = new THREE.InstancedBufferAttribute(new Float32Array(pointsCount * 3), 3, false, 1);
                pointOffsets.setUsage(THREE.DynamicDrawUsage);
                var pointsRots = new THREE.InstancedBufferAttribute(new Float32Array(pointsCount * 4), 4, false, 1);
                pointsRots.setUsage(THREE.DynamicDrawUsage);
                pointGeo.setAttribute('offseti', pointOffsets);
                pointGeo.setAttribute('roti', pointsRots);

                var pointMesh = this._pointMesh = new THREE.Mesh(pointGeo, this._pointMat);
                pointMesh.position.setZ(10);
                this.add(pointMesh);
            }

            if (linesCount > 0) {
                if (drawAngled)
                    linesCount *= 2;
                var linesOffsets = new THREE.InstancedBufferAttribute(new Float32Array(linesCount * 3), 3, false, 1);
                linesOffsets.setUsage(THREE.DynamicDrawUsage);
                var linesRots = new THREE.InstancedBufferAttribute(new Float32Array(linesCount * 4), 4, false, 1);
                linesRots.setUsage(THREE.DynamicDrawUsage);
                var linesScales = new THREE.InstancedBufferAttribute(new Float32Array(linesCount * 3), 3, false, 1);
                linesScales.setUsage(THREE.DynamicDrawUsage);

                var halfThickness = StringObjectInstance.LineThickness * 0.5;

                var lineGeo = new THREE.InstancedBufferGeometry();

                lineGeo.setAttribute('position', new THREE.BufferAttribute(new Float32Array([0, -halfThickness, 0, 1, -halfThickness, 0, 1, halfThickness, 0, 0, -halfThickness, 0, 1, halfThickness, 0, 0, halfThickness, 0]), 3));
                lineGeo.setAttribute('offseti', linesOffsets);
                lineGeo.setAttribute('roti', linesRots);
                lineGeo.setAttribute('scalei', linesScales);
                lineGeo.maxInstancedCount = linesCount;

                this.add(this._lineMesh = new THREE.Mesh(lineGeo, this._lineMat));
            }

            this._positionsChanged = true;
        }

        private static setBufferAttributesForLine = (() => {
            var _v1 = new THREE.Vector3();

            return (p1: THREE.Vector3, p2: THREE.Vector3, idx: number, offset: THREE.InstancedBufferAttribute, rot: THREE.InstancedBufferAttribute, scale: THREE.InstancedBufferAttribute) => {
                var d = _v1.copy(p2).sub(p1),
                    len = d.length(),
                    q = new THREE.Quaternion(),
                    s = new THREE.Vector3(len, 1, 1);
                if (len > 0)
                    q.setFromAxisAngle(new THREE.Vector3(0, 0, 1), Math.atan2(d.y, d.x));

                offset.setXYZ(idx, p1.x, p1.y, p1.z);
                rot.setXYZW(idx, q.x, q.y, q.z, q.w);
                scale.setXYZ(idx, s.x, s.y, s.z);

                return q;
            }
        })();

        update() {
            if (this._isDisposed || !this._needsUpdate || !this.Joints || !this.Joints.length)
                return;

            if (this.isHidden) {
                this.visible = false;
                this._needsUpdate = false;
                return;
            }

            var joints = this.Joints,
                drawAngled = this.drawAngled,
                pointsCount = joints.length,
                linesCount = Math.max(0, pointsCount - 1);

            if (!this._lineMesh || !this._pointMesh || this._linesCount < linesCount || this._pointsCount < pointsCount)
                this.build();

            var bounds = this._bounds,
                boundingSphere = this._boundingSphere;

            bounds.makeEmpty();

            if (!pointsCount) {
                this.visible = false;
                this._needsUpdate = false;
                return;
            }

            this.visible = true;

            if (this._positionsChanged) {

                var off = this.getPointOffset(),
                    p1 = new THREE.Vector3(),
                    p2 = new THREE.Vector3();

                if (linesCount) {
                    if (drawAngled)
                        linesCount *= 2;

                    var lineMesh = this._lineMesh,
                        pointMesh = this._pointMesh,
                        pointGeo = <THREE.InstancedBufferGeometry>pointMesh.geometry,
                        pointOffset = pointGeo.getAttribute('offseti') as THREE.InstancedBufferAttribute,
                        pointRot = pointGeo.getAttribute('roti') as THREE.InstancedBufferAttribute,
                        lineGeo = <THREE.InstancedBufferGeometry>lineMesh.geometry,
                        lineOffset = lineGeo.getAttribute('offseti') as THREE.InstancedBufferAttribute,
                        lineRot = lineGeo.getAttribute('roti') as THREE.InstancedBufferAttribute,
                        lineScale = lineGeo.getAttribute('scalei') as THREE.InstancedBufferAttribute;

                    pointGeo.maxInstancedCount = pointsCount;
                    lineGeo.maxInstancedCount = linesCount;
                    pointOffset.needsUpdate = true;
                    pointRot.needsUpdate = true;
                    lineOffset.needsUpdate = true;
                    lineRot.needsUpdate = true;
                    lineScale.needsUpdate = true;

                    if (drawAngled) {
                        var dir = spt.ThreeJs.utils.ToClosestAxis(new THREE.Vector3().subVectors(joints[1].position, joints[0].position)).setZ(0),
                            p3 = new THREE.Vector3(1, 0, 0),
                            halfThickness = StringObjectInstance.LineThickness * 0.5;

                        //p2 -- p3 -- p1
                        for (let i = 0, l = joints.length - 1; i <= l; i++) {
                            p1.copy(joints[i].position).add(off);

                            bounds.expandByPoint(p1);

                            pointOffset.setXYZ(i, p1.x, p1.y, p1.z);

                            if (i > 0) {
                                var i2 = i - 1;

                                p2.copy(joints[i2].position).add(off);

                                p3.subVectors(p1, p2);

                                var q: THREE.Quaternion;
                                var idx = i2 * 2;

                                if (Math.abs(p3.x) < 0.00001 || Math.abs(p3.y) < 0.00001) {
                                    p3.addVectors(p1, p2).multiplyScalar(0.5);

                                    StringObjectInstance.setBufferAttributesForLine(p1, p3, idx, lineOffset, lineRot, lineScale);
                                    q = StringObjectInstance.setBufferAttributesForLine(p3, p2, idx + 1, lineOffset, lineRot, lineScale);
                                } else {
                                    var dt = dir.dot(p3.subVectors(p1, p2));
                                    if (dt < 0) {
                                        dir.set(-dir.y, dir.x, dir.z);
                                        dt = dir.dot(p3);
                                    }
                                    p3.copy(dir).multiplyScalar(dt).add(p2);

                                    StringObjectInstance.setBufferAttributesForLine(p1, p3, idx, lineOffset, lineRot, lineScale);
                                    p3.add(dir.multiplyScalar(dt < 0 ? -halfThickness : halfThickness));

                                    q = StringObjectInstance.setBufferAttributesForLine(p3, p2, idx + 1, lineOffset, lineRot, lineScale);
                                }

                                spt.ThreeJs.utils.ToClosestAxis(dir.subVectors(p1, p3));

                                pointRot.setXYZW(i2, q.x, q.y, q.z, q.w);

                                if (i === l) {
                                    pointRot.setXYZW(i, q.x, q.y, q.z, q.w);
                                }
                            }
                        }
                    } else {
                        for (let i = 0, l = joints.length - 1; i <= l; i++) {
                            p1.copy(joints[i].position).add(off);

                            bounds.expandByPoint(p1);

                            pointOffset.setXYZ(i, p1.x, p1.y, p1.z);

                            //p2 -- p1
                            if (i > 0) {
                                var i2 = i - 1;

                                p2.copy(joints[i2].position).add(off);

                                var q = StringObjectInstance.setBufferAttributesForLine(p1, p2, i2, lineOffset, lineRot, lineScale);

                                pointRot.setXYZW(i2, q.x, q.y, q.z, q.w);

                                if (i === l) {
                                    pointRot.setXYZW(i, q.x, q.y, q.z, q.w);
                                }
                            }
                        }
                    }
                }
                else {
                    var pointMesh = this._pointMesh,
                        pointGeo = <THREE.InstancedBufferGeometry>pointMesh.geometry,
                        pointOffset = pointGeo.getAttribute('offseti') as THREE.InstancedBufferAttribute,
                        pointRot = pointGeo.getAttribute('roti') as THREE.InstancedBufferAttribute;

                    pointGeo.maxInstancedCount = pointsCount;
                    pointOffset.needsUpdate = true;
                    pointRot.needsUpdate = true;

                    pointGeo.maxInstancedCount = pointsCount;

                    var r90 = 1/Math.sqrt(2);

                    for (let i = 0, l = joints.length; i < l; i++) {
                        p1.copy(joints[i].position).add(off);
                        bounds.expandByPoint(p1);

                        pointOffset.setXYZ(i, p1.x, p1.y, p1.z);
                        pointRot.setXYZW(i, 0, 0, r90, r90);
                    }
                }

                bounds.getBoundingSphere(boundingSphere);
                boundingSphere.radius += Math.max(StringObjectInstance.LineThickness, StringObjectInstance.PointRadius);
                boundingSphere.getBoundingBox(bounds);

                if (this._lineMesh)
                    this._lineMesh.geometry.boundingSphere = boundingSphere;

                if (this._pointMesh)
                    this._pointMesh.geometry.boundingSphere = boundingSphere;

                this._positionsChanged = false;
            }

            var col = this.getColor();
            var alpha = this.getAlpha();

            var lineMat = this._lineMat as LS.Client3DEditor.BaseInstancedShader;
            var pointMat = this._pointMat as LS.Client3DEditor.BaseInstancedShader;

            lineMat.color = col;
            pointMat.color = col;

            lineMat.alpha = alpha;
            pointMat.alpha = alpha;

            this._needsUpdate = false;
        }

        getBounds(): THREE.Box3 {
            return this._bounds;
        }
        getPointOffset(): THREE.Vector3 {
            var controller = LS.Client3DEditor.Controller.Current,
                joints = this.Joints;
            if (!joints || joints.length <= 1)
                return new THREE.Vector3();
            var joint = joints.find(jt => !!controller.Instances[jt.ModuleObjectIdString]) || joints[0];
            var x = (Math.abs(joint.position.y) * 0.05) % 200 - 100,
                y = (Math.abs(joint.position.x) * 0.05) % 200 - 100;
            return new THREE.Vector3(x, y, (x + y + 200) * 0.5);
        }
        getPoints(): THREE.Vector3[] {
            return this.Joints.map(j => j.position);
        }
        getLines(angled?: boolean): THREE.Line3[] {
            var result: THREE.Line3[] = [];
            this.iterateLines((p1, p2) => {
                result.push(new THREE.Line3(p1, p2));
                return true;
            });

            return result;
        }

        iterateLines(fn: (start: THREE.Vector3, end: THREE.Vector3, j1?: StringJoint, j2?: StringJoint) => boolean, angled?: boolean) {
            var joints = this.Joints,
                pointsCount = joints.length,
                p1 = new THREE.Vector3(),
                p2 = new THREE.Vector3();

            if (angled && pointsCount > 1) {
                var dir = spt.ThreeJs.utils.ToClosestAxis(new THREE.Vector3().subVectors(joints[1].position, joints[0].position)).setZ(0),
                    p3 = new THREE.Vector3(1, 0, 0);

                //p2 -- p3 -- p1
                for (let i2 = 1, l = pointsCount; i2 < l; i2++) {
                    let i = i2 - 1,
                        j2 = joints[i],
                        j1 = joints[i2];

                    p2.copy(j2.position);
                    p1.copy(j1.position);
                    p3.subVectors(p1, p2);

                    if (Math.abs(p3.x) < 0.00001 || Math.abs(p3.y) < 0.00001) {
                        if (fn(p2, p1, j2, j1) === false)
                            return;
                    } else {
                        var dt = dir.dot(p3.subVectors(p1, p2));
                        if (dt < 0) {
                            dir.set(-dir.y, dir.x, dir.z);
                            dt = dir.dot(p3);
                        }
                        p3.copy(dir).multiplyScalar(dt).add(p2);

                        if (fn(p2, p3, j2, j1) === false)
                            return;
                        if (fn(p3, p1, j2, j1) === false)
                            return;
                    }

                    spt.ThreeJs.utils.ToClosestAxis(dir.subVectors(p1, p3));

                }
            } else {
                for (let i2 = 1; i2 < pointsCount; i2++) {
                    let i = i2 - 1,
                        j2 = joints[i],
                        j1 = joints[i2];

                    p2.copy(j2.position);
                    p1.copy(j1.position);

                    if (fn(p2, p1, j2, j1) === false)
                        return;
                }
            }
        }

        getClosestPoint(p: THREE.Vector3, thresholdSq: number): IStringObjectIntersection<this> {
            if (this._isDisposed || !this.visible)
                return null;

            var joints = this.Joints,
                pointsCount = joints.length,
                off = this.getPointOffset(),
                pos1 = new THREE.Vector3(),
                pos2 = new THREE.Vector3(),
                result = null as IStringObjectIntersection<this>,
                closestDistance: number = Number.MAX_VALUE;

            if (pointsCount > 0) {
                joints.forEach((j, idx) => {
                    if (!j.isTemporary) {
                        pos1.copy(j.position).add(off).setZ(p.z);
                        var jdsq = pos1.distanceToSquared(p);
                        if (jdsq <= thresholdSq && jdsq < closestDistance) {
                            closestDistance = jdsq;
                            result = { o: this, p: pos1, distSq: jdsq, j1: j, idx: idx };
                        }
                    }
                });
            }

            if (result == null) {
                this.iterateLines((p1, p2, j1, j2) => {
                    pos1.copy(p1).add(off).setZ(p.z);
                    pos2.copy(p2).add(off).setZ(p.z);
                    var cp = spt.ThreeJs.utils.GetClosestPointToLine(pos1, pos2, p),
                        dsq = cp.p.distanceToSquared(p);
                    if (dsq <= thresholdSq && dsq < closestDistance) {
                        closestDistance = dsq;
                        result = { o: this, p: cp.p, distSq: dsq, j1: j1, j2: j2, l: cp.l, idx: -1 };
                    }
                    return true;
                }, this.drawAngled);
            }

            return result;
        }

        intersecsBox(searchBox: THREE.Box3) {
            if (!this._isDisposed && this.visible && this.Joints && this.Joints.length && this._bounds && this._bounds.intersectsBox(searchBox)) {
                var joints = this.Joints,
                    pointsCount = joints.length,
                    off = this.getPointOffset(),
                    pos1 = new THREE.Vector3(),
                    pos2 = new THREE.Vector3(),
                    sphere = new THREE.Sphere(),
                    pRadius = StringObjectInstance.PointRadius;

                if (joints.some(j => searchBox.intersectsSphere(sphere.set(pos1.copy(j.position).add(off), pRadius))))
                    return true;

                var intersecs: boolean = false;
                this.iterateLines((p1, p2, j1, j2) => {
                    pos1.copy(p1).add(off);
                    pos2.copy(p2).add(off);

                    //spt.ThreeJs.utils.BoxisIntersectingTriangle()

                    if (spt.ThreeJs.utils.LineIntersecsBox(pos1, pos2, searchBox)) {
                        intersecs = true;
                        return false;
                    }
                    return true;
                }, this.drawAngled);

                return intersecs;
            }
            return false;
        }

        getClosestPointToRay(ray: THREE.Ray, threshold: number): IStringObjectIntersection<this> {
            if (this._isDisposed || !this.visible)
                return null;
            var joints = this.Joints;

            if (joints.length <= 0)
                return null;

            var off = this.getPointOffset(),
                pos1 = new THREE.Vector3(),
                inter = ray.intersectPlane(new THREE.Plane().setFromNormalAndCoplanarPoint(new THREE.Vector3(0, 0, 1), pos1.copy(joints[0].position).add(off)), new THREE.Vector3());

            if (inter)
                return this.getClosestPoint(inter, threshold * threshold);

            return null;
        }

        getClosestJointToRay(ray: THREE.Ray, thresholdSq: number) {
            if (this._isDisposed || !this.visible)
                return null;

            var joints = this.Joints,
                pos1 = new THREE.Vector3(),
                pos2 = new THREE.Vector3(),
                off = this.getPointOffset(),
                result = null as StringJoint,
                closestDistance: number = Number.MAX_VALUE;

            if (joints.length <= 0)
                return null;

            joints.forEach(j => {
                var cp = ray.closestPointToPoint(pos1.copy(j.position).add(off), pos2),
                    jdsq = pos1.distanceToSquared(cp);
                if (jdsq <= thresholdSq && jdsq < closestDistance) {
                    closestDistance = jdsq;
                    result = j;
                }
            });

            return result;
        }

        dispose() {
            if (!this._isDisposed) {
                var mso = this.getModulesStringObject();
                if (mso)
                    mso.CurrentModuleCount = 0;
                this.removeAll();
                this.clear();
                this._isDisposed = true;
            }
        }

        getObjectHolder(): GenericObjectHolder<StringObjectInstance> {
            return LS.Client3DEditor.Controller.Current.stringObjectHolder;
        }

        save(showError?: boolean) {
            var controller = Controller.Current,
                viewModel = controller.viewModel,
                mso = this.getModulesStringObject();

            if (!mso || mso.CurrentModuleCount !== mso.MaxModuleCount) {
                if (showError && mso)
                    DManager.ShowSmallInfo(window.electricTranslation["PolyDes_ElectricInputModuleInfo"].replace("{0}", "" + mso.MaxModuleCount));
                return;
            }

            var moduleObjects = this.Joints.map(j => j.getClientObjectInstance(controller)).filter(m => !!m);

            var diffusionPointObject = this.getDiffusionPointObject() || this.searchClosestDiffusionPointObject();

            if (!moduleObjects.length)
                return;

            //get baseparent id from modules

            var parentList: { [id: string]: number } = {};

            moduleObjects.forEach(m => {
                var bpId = m.clientObject.userData.BaseParentId;
                if (!parentList[bpId])
                    parentList[bpId] = 0;
                parentList[bpId]++;
            });

            var baseParentId: string = null;
            var bpCount = 0;

            Object.keys(parentList).forEach(id => {
                if (parentList[id] > bpCount) {
                    bpCount = parentList[id];
                    baseParentId = id;
                }
            });

            var moduleObjectsIds = moduleObjects.map(m => m.instanceData.Id);
            var diffusionPointObjectId = this.DiffusionPointObjectId = diffusionPointObject ? diffusionPointObject.instanceData.Id : null;

            var joints = this.Joints.filter(j => !j.isTemporary).map(j => j.exportData());

            SolarProTool.Ajax("Areas/Electric/WebServices/ElectricServices.asmx").Call("CreateModuleConnection").Data({ moduleIds: moduleObjectsIds, diffusionPointId: diffusionPointObjectId || null, stringId: this.IdString, baseParentId: baseParentId, joints: joints }).CallBack((result) => {

            });
        }
    }

    export class StringObjectHolder extends GenericObjectHolder<StringObjectInstance> {

        getById(id: string): StringObjectInstance {
            if (!id)
                return null;
            var mObjs = this.children;
            for (var i = mObjs.length; i--;) {
                var mObj = mObjs[i] as StringObjectInstance;
                if (mObj.IdString === id)
                    return mObj;
            }
            return null;
        }

        getAllStringObjects() {
            return this.children as StringObjectInstance[];
        }

        getJointByModuleId(modId: string) {
            if (!modId)
                return null;
            var mObjs = this.children;
            for (var i = mObjs.length; i--;) {
                var mObj = mObjs[i] as StringObjectInstance;
                var j = mObj.getJointByModuleId(modId);
                if (j)
                    return j;
            }
            return null;
        }

        onObjectRemoved(so: StringObjectInstance) {
            SolarProTool.Ajax("Areas/Electric/WebServices/ElectricServices.asmx").Call("DeleteModuleConnection").Data({ stringId: so.IdString }).CallBack(() => {

            });
        }

        addNewStringObject(id: string): StringObjectInstance {
            var so = new StringObjectInstance(id);
            this.add(so);
            return so;
        }

        getClosestJointToRaycaster(raycaster: THREE.Raycaster, tolerance?: number) {
            var so = this.getByRaycaster(raycaster, tolerance);

            if (so && so.o && so.idx >= 0)
                return so.o.Joints[so.idx];

            //return so && so.o ? so.o.getClosestJointToRay(raycaster.ray, thresholdSq) : null;
        }
    }
}