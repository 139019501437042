module LS.Client3DEditor {
    export class ToolBox {

        static navDropdownVisible: boolean = false;

        static toggleNavDropdown() {
            var $navdropdown = $("#nav-dropdown"),
                $aoes5View = $("#aoes5-view"),
                offset = $aoes5View.offset(),
                width = $aoes5View.outerWidth();

            if (ToolBox.navDropdownVisible)
                DManager.hideWithEffect($navdropdown, 'size', { origin: ["top", "right"] }, "fast");
            else
                DManager.showWithEffect($navdropdown.css({ left: offset.left + width - 50, top: offset.top - 17 }), 'size', { origin: ["top", "right"] }, "fast");

            ToolBox.navDropdownVisible = !ToolBox.navDropdownVisible;
        }

        static showInverterFlyout() {
            var viewModel = Controller.Current.viewModel;

            if (!viewModel.FlyoutVisible)
                ToolBox.innerToggleFlyout(viewModel);

            viewModel.ShowInverterFlyout = true;
        }

        static toggleInverterFlyout() {
            var viewModel = Controller.Current.viewModel;

            if (viewModel.selectedTool === "conduitTool")
                viewModel.deselectTool();
            else if (!viewModel.FlyoutVisible || viewModel.ShowInverterFlyout)
                ToolBox.innerToggleFlyout(viewModel);

            viewModel.ShowInverterFlyout = true;
        }

        static toggleFlyout() {

            var viewModel = Controller.Current.viewModel;

            if (viewModel.selectedTool === "conduitTool")
                viewModel.deselectTool();
            if (!viewModel.FlyoutVisible || !viewModel.ShowInverterFlyout)
                ToolBox.innerToggleFlyout(viewModel);

            viewModel.ShowInverterFlyout = false;
        }

        static closeFlyout() {
            var viewModel = Controller.Current.viewModel;

            if (viewModel.FlyoutVisible)
                ToolBox.innerToggleFlyout(viewModel);
        }

        private static innerToggleFlyout(viewModel: LS.Client3DEditor.ViewModel) {
            var $aoFlyout = $("#ao-Flyout");

            if (!viewModel.FlyoutVisible) {
                var windowSize = getSize();
                var aoButtonClientRect = document.getElementById('ao-FlyoutButton').getBoundingClientRect();
                
                var h = Math.max(windowSize[1] - aoButtonClientRect.top - 20, 100);

                parseFloat($('#ao-Toolbox').css('width').slice(0, -2))

                var leftPos = parseFloat($('#ao-Toolbox').css('left').slice(0, -2)) + parseFloat($('#ao-Toolbox').css('width').slice(0, -2)) + 10 + "px";
                var topPos = $('#ao-Toolbox').css('top');

                $aoFlyout.css("max-height", h + "px").show();
                if(<any>$aoFlyout.animate)
                    $aoFlyout.animate({ left: leftPos, top: topPos }, { duration: 500 });
                else
                    $aoFlyout.css({ left: leftPos, top: topPos });
                //$('#ao-FlyoutButton, #ao-inverter-FlyoutButton').addClass('ao-insert-item-selected');
            } else {
                if(<any>$aoFlyout.animate)
                    $aoFlyout.animate({ left: "-400px", top: $('#ao-Toolbox').css('top') }, { duration: 500, complete: () => { $aoFlyout.hide(); } });
                else
                    $aoFlyout.hide();
                //$('#ao-FlyoutButton, #ao-inverter-FlyoutButton').removeClass('ao-insert-item-selected');
            }

            viewModel.FlyoutVisible = !viewModel.FlyoutVisible;
        }

        public static GetMarginTopFromSelectedMenu() {
            
        }

    }



    //Document ready function
    $(() => {
        if(window.SkipAO3DSetup)
            return;

        //$("#ao-Flyout-CloseButton, #ao-FlyoutButton").click(ToolBox.toggleFlyout);
        $("#nav-dropdown").click(ToolBox.toggleNavDropdown);
        $("#ao-Flyout").css('top', document.getElementById('ao-Toolbox').offsetTop - 5);


        $("#ao-UndockButton").click(function () {
            var toolBox = $("#ao-Toolbox");
            var flyOut = $("#ao-Flyout");

            if (!toolBox.hasClass("undockedAOToolbox")) {
                DManager.makeDraggable(toolBox);
                DManager.makeDraggable(flyOut);
                toolBox.addClass("undockedAOToolbox");
                toolBox.css("border-top-color", $("#contextmenu_layout").css("background-color"));
            } else {
                DManager.makeDraggable(toolBox);
                DManager.makeDraggable(flyOut);
                toolBox.removeClass("undockedAOToolbox");
                toolBox.css({ "top": "", "left": "" });
            }
        });

    });
}