module LS.Client3DEditor {
    export enum InterferenceToolMode {
        draw,
        rect,
        circle,
        triangle,
        line
    }

    export class InterferenceTool extends BaseTool {

        mode: InterferenceToolMode = InterferenceToolMode.draw;
        step = 0;

        object: THREE.Object3D;

        editPoly: EditablePolygon2D = null;
        editHandle: EditableHandle = null;

        //currentBaseParentId: string = null;
        //startBaseParentId: string = null;

        //private _bd = new THREE.Box3();
        private _v1 = new THREE.Vector3();
        private _v2 = new THREE.Vector3();
        //private _v3 = new THREE.Vector3();
        //private _mat = new THREE.Matrix4();
        //private _ray = new THREE.Ray();

        onSelect(viewModel: ViewModel, params: { mode?: string }) {
            //var controller = Controller.Current,
            //    interferenceObjects = viewModel.interferenceObjects;

            viewModel.deselectAll();

            this.mode = params && params.mode ? InterferenceToolMode[params.mode] : InterferenceToolMode.draw;

            //this.startBaseParentId = this.currentBaseParentId = viewModel.currentInstance.Id;

            this.reset();

            viewModel.currentInstance.add(this.object, true);
        }

        reset() {
            var editPoly = this.editPoly,
                editHandle = this.editHandle;

            this.step = 0;

            if (!editPoly) {
                var o = this.object = new THREE.Object3D();
                editPoly = this.editPoly = new EditablePolygon2D(new ObservablePolygon());
                o.add(editPoly);

                editHandle = this.editHandle = new EditableHandle(new ObservableVector3());
                o.add(editHandle);
            }

            editPoly.poly.removeAll();
            editPoly.clickable = false;
            editPoly.editable = true;

            editHandle.clickable = false;
            editHandle.draggable = false;
            editHandle.editable = false;
            editHandle.hovered = false;

            switch (this.mode) {
                case InterferenceToolMode.draw:
                case InterferenceToolMode.line:
                    {
                        editPoly.poly.push(0, 0, 0);
                        editPoly.editOptions = EditablePolygon2D.ShowEdges;
                        editPoly.closed = false;
                    }
                    break;
                case InterferenceToolMode.circle:
                case InterferenceToolMode.rect:
                case InterferenceToolMode.triangle:
                    {
                        editPoly.editOptions = EditablePolygon2D.ShowEdges | EditablePolygon2D.ShowSurface;
                        editPoly.closed = true;
                    }
                    break;
            }

            //Controller.Current.viewModel.currentInstance = Controller.Current.getInstanceContext(this.startBaseParentId);
        }

        onDeselect(viewModel: ViewModel) {

            //this.startBaseParentId = this.currentBaseParentId = null;
            this.step = 0;

            var editPoly = this.editPoly,
                editHandle = this.editHandle;

            if (editPoly) {
                editPoly.poly.removeAll();
                editPoly.editable = false;

                editHandle.editable = false;
            }

            if (this.object && this.object.parent)
                this.object.parent.remove(this.object);

            //Controller.Current.viewModel.currentInstance = Controller.Current.getInstanceContext(this.startBaseParentId);
        }

        onKeyDown(viewModel: ViewModel) {
            if (viewModel.keyPressed(27)) { //esc
                viewModel.deselectTool();
                return true;
            } else if (viewModel.keyPressed(13)) { //enter
                var controller = Controller.Current,
                    editPoly = this.editPoly,
                    mode = this.mode,
                    baseParentId = controller.viewModel.currentInstance.Id;

                if (mode === InterferenceToolMode.line && editPoly.poly.array.length > 2) {
                    editPoly.poly.array.pop();
                    editPoly.closed = false;

                    InterferenceTool.createDefaultInterference(baseParentId, editPoly.poly, mode);
                    this.reset();
                }
            }
            return false;
        }

        onKeyUp(viewModel: ViewModel): boolean {
            return false;
        }

        onMouseMove(viewModel: ViewModel) {
            console.log("ao onMouseMove");

            var controller = Controller.Current,
                raycaster = controller.worldRaycaster,
                editPoly = this.editPoly,
                editHandle = this.editHandle,
                rp = viewModel.roofPosition,
                array = editPoly.poly.array;

            switch (this.mode) {
                case InterferenceToolMode.draw:
                case InterferenceToolMode.line: {
                    var p = array[array.length - 1];
                    p.x = rp.x;
                    p.y = rp.y;
                    if (viewModel.shiftDown && array.length > 1) {
                        var pp = array[array.length - 2];
                        if (Math.abs(p.x - pp.x) < Math.abs(p.y - pp.y))
                            p.x = pp.x;
                        else
                            p.y = pp.y;
                    }
                    editHandle.hovered = !!editHandle.intersecsRaycaster(raycaster);
                    break;
                }
                case InterferenceToolMode.circle: {
                    var center = this._v1;
                    if (!array.length)
                        center.set(rp.x, rp.y, 0);
                    else
                        editPoly.poly.setAsCircle(center, this._v2.set(rp.x, rp.y, 0));
                    break;
                }
                case InterferenceToolMode.rect: {
                    var corner1 = this._v1;
                    if (!array.length)
                        corner1.set(rp.x, rp.y, 0);
                    else
                        editPoly.poly.setAsRectangle(corner1, this._v2.set(rp.x, rp.y, 0));
                    break;
                }
                case InterferenceToolMode.triangle: {
                    var corner1 = this._v1;
                    if (!array.length)
                        corner1.set(rp.x, rp.y, 0);
                    else
                        editPoly.poly.setAsTriangle(corner1, this._v2.set(rp.x, rp.y, 0));
                    break;
                }
            }

            //interferenceObjects.forEach(interferenceObject => {
            //    var s = interferenceObject.intersectRaycaster(raycaster);
            //    if (s) {
            //        var d = s.point.distanceToSquared(raycaster.ray.origin);
            //        if (d < dist) {
            //            dist = d;
            //            hoverObject = interferenceObject;
            //        }
            //    }
            //});

            //if (this.hoverObject && (!hoverObject || this.hoverObject !== hoverObject)) {
            //    this.hoverObject.isHovered = false;
            //}

            //this.hoverObject = hoverObject;

            //if (hoverObject)
            //    hoverObject.isHovered = true;

            return false;
        }

        onMouseDown(viewModel: ViewModel) {
            var controller = Controller.Current,
                raycaster = controller.worldRaycaster,
                editPoly = this.editPoly,
                editHandle = this.editHandle,
                baseParentId = controller.viewModel.currentInstance.Id,
                rp = viewModel.roofPosition,
                mode = this.mode;

            switch (mode) {
                case InterferenceToolMode.draw:
                case InterferenceToolMode.line: {
                    if (this.step === 0)
                        this.makePolygonPoint(viewModel);
                    break;
                }
                case InterferenceToolMode.circle: {
                    var center = this._v1;
                    if (!editPoly.poly.array.length) {
                        rp = viewModel.roofPosition;
                        center.copy(rp);
                        editPoly.poly.setAsCircle(center, center);
                    } else {
                        //editPoly.poly.setAsCircle(center, this._v2);
                        //InterferenceTool.createDefaultInterference(baseParentId, editPoly.poly, mode);
                        //this.reset();
                    }
                    break;
                }
                case InterferenceToolMode.rect: {
                    var corner1 = this._v1;
                    if (!editPoly.poly.array.length) {
                        rp = viewModel.roofPosition;
                        corner1.copy(rp);
                        editPoly.poly.setAsRectangle(corner1, corner1);
                    } else {
                        editPoly.poly.setAsRectangle(corner1, this._v2);
                        InterferenceTool.createDefaultInterference(baseParentId, editPoly.poly, mode);
                        this.reset();
                    }
                    break;
                }
                case InterferenceToolMode.triangle: {
                    var corner1 = this._v1;
                    if (!editPoly.poly.array.length) {
                        rp = viewModel.roofPosition;
                        corner1.copy(rp);
                        editPoly.poly.setAsTriangle(corner1, corner1);
                    } else {
                        editPoly.poly.setAsTriangle(corner1, this._v2);
                        InterferenceTool.createDefaultInterference(baseParentId, editPoly.poly, mode);
                        this.reset();
                    }
                    break;
                }
            }

            //if (hoverObject) {
            //    var s = hoverObject.intersectRaycaster(raycaster);
            //    if (s)
            //        hoverObject.selected = s;
            //} else {
            //    interferenceObjects.forEach(o => { o.selected = null; });
            //}

            return false;
        }

        onMouseUp(viewModel: ViewModel) {
            var controller = Controller.Current,
                raycaster = controller.worldRaycaster,
                editPoly = this.editPoly,
                editHandle = this.editHandle,
                baseParentId = controller.viewModel.currentInstance.Id,
                rp = viewModel.roofPosition,
                mode = this.mode;

            switch (mode) {
                case InterferenceToolMode.draw:
                case InterferenceToolMode.line: {
                    if (this.step === 0) {
                        this.step = 1;
                        // if moved then make point
                        let startPoint = editPoly.poly.array[0];
                        let currentPoint = viewModel.roofPosition;
                        // 10mm
                        if (startPoint.distanceTo(currentPoint) >= 10)
                            this.makePolygonPoint(viewModel);
                    }
                    else
                        this.makePolygonPoint(viewModel);
                    break;
                }
                case InterferenceToolMode.circle: {
                    var center = this._v1;
                    this._v2.set(rp.x, rp.y, 0);
                    if (editPoly.poly.array.length) {
                        // 10mm
                        if (center.distanceTo(this._v2) >= 10) {
                            editPoly.poly.setAsCircle(center, this._v2);
                            InterferenceTool.createDefaultInterference(baseParentId, editPoly.poly, mode);
                        }
                        this.reset();
                    }
                    break;
                }
                case InterferenceToolMode.rect: {
                    var corner1 = this._v1;
                    this._v2.set(rp.x, rp.y, 0);
                    if (editPoly.poly.array.length) {
                        // 10mm
                        if (corner1.distanceTo(this._v2) >= 10) {
                            editPoly.poly.setAsRectangle(corner1, this._v2);
                            InterferenceTool.createDefaultInterference(baseParentId, editPoly.poly, mode);
                        }
                        this.reset();
                    }
                    break;
                }
                case InterferenceToolMode.triangle: {
                    var corner1 = this._v1;
                    this._v2.set(rp.x, rp.y, 0);
                    if (editPoly.poly.array.length) {
                        // 10mm
                        if (corner1.distanceTo(this._v2) >= 10) {
                            editPoly.poly.setAsTriangle(corner1, this._v2);
                            InterferenceTool.createDefaultInterference(baseParentId, editPoly.poly, mode);
                        }
                        this.reset();
                    }
                    break;
                }
            }

            return false;
        }

        private makePolygonPoint(viewModel: ViewModel) {
            var controller = Controller.Current,
                raycaster = controller.worldRaycaster,
                editPoly = this.editPoly,
                editHandle = this.editHandle,
                baseParentId = controller.viewModel.currentInstance.Id,
                rp = viewModel.roofPosition,
                mode = this.mode;
            editHandle.hovered = !!this.editHandle.intersecsRaycaster(raycaster);
            if (editHandle.hovered) {
                editPoly.poly.array.pop();
                editPoly.closed = mode !== InterferenceToolMode.line;
                InterferenceTool.createDefaultInterference(baseParentId, editPoly.poly, mode);
                this.reset();
            } else {
                var p = editPoly.poly.array[editPoly.poly.array.length - 1];
                if (editPoly.poly.array.length == 1) {
                    rp = viewModel.roofPosition;
                    p.copy(rp).setZ(0);
                }
                editPoly.poly.push(p.x, p.y, 0);
                if (!editHandle.editable || mode === InterferenceToolMode.line) {
                    var pt = editHandle.point;
                    pt.x = p.x;
                    pt.y = p.y;
                    editHandle.editable = true;
                }
            }
        }

        static createInterferenceState(baseParentId: string, type = "DynamicInterferenceObject", pts?: THREE.Vector3[]): InteferenceState {
            if (!baseParentId)
                baseParentId = Controller.Current.viewModel.currentInstance.Id;

            var state = new InteferenceState();

            state.IsParallel = true;
            state.Name = AO3DStrings.Interference + " " + Controller.Current.viewModel.interferenceObjects.length + 1;
            state.Type = type;
            state.TypeId = null;
            state.BaseParentId = baseParentId;
            state.IdString = spt.Utils.GenerateGuid().toLowerCase();
            state.ClientOptions = 0;
            state.scalex = 1;
            state.scaley = 1;
            state.scalez = 1;
            state.rotation = 0;

            var b = new THREE.Box3();

            if (pts) {
                b.setFromPoints(pts);

                var data: number[] = [];

                for (var i = 0, l = pts.length; i < l; i++) {
                    var p = pts[i];
                    data.push(p.x, p.y, p.z);
                }

                state.Data = data;

                b.min.setZ(Math.min(0, b.min.z));
            }

            if (type === "DynamicInterferenceObject") {
                state.Settings = null as SolarProTool.IInterferenceSettings;
            } else {
                var settings = state.Settings = {} as SolarProTool.DormerInterferenceObject;

                settings.InterferenceType = type;

                switch (type) {
                    case "DormerInterferenceObject":
                        settings.DormerTiltAngle = 33;
                        if (!b.isEmpty()) {
                            var size = b.getSize(new THREE.Vector3());

                            settings.DormerSizeX = size.x;
                            settings.DormerSizeY = size.y;
                            settings.DormerSizeZ = size.z;
                        } else {
                            settings.DormerSizeX = 0;
                            settings.DormerSizeY = 0;
                            settings.DormerSizeZ = 0;
                        }
                        break;
                }
            }

            return state;
        }

        static createDefaultInterference(baseParentId: string, poly: ObservablePolygon, mode: InterferenceToolMode) {
            var controller = Controller.Current,
                viewModel = controller.viewModel;

            if (mode === InterferenceToolMode.line) {
                var lineWidth = viewModel.interferenceLineThickness.internalValue;
                if (lineWidth > 0 || poly && poly.array && poly.array.length >= 2) {

                    spt.ThreeJs.utils.generateOffsetLinePolygons(poly.array, lineWidth).forEach(interPoly => {
                        var state = InterferenceTool.createInterferenceState(baseParentId, "DynamicInterferenceObject", interPoly);
                        InterferenceTool.saveInterferenceState(state, baseParentId, false);
                    });
                }
            } else if (poly && poly.array && poly.array.length >= 3) {
                if (THREE.ShapeUtils.isClockWise(poly.array))
                    poly.array.reverse();

                poly.height = 100;

                var state = InterferenceTool.createInterferenceState(baseParentId, "DynamicInterferenceObject", poly.array);

                InterferenceTool.saveInterferenceState(state, baseParentId, true);

            }

        }

        private static saveInterferenceState(state: InteferenceState, baseParentId: string, openFlyOut: boolean) {
            var controller = Controller.Current,
                viewModel = controller.viewModel;
            controller.interferenceHelper.updateSetInterferenceState(null, baseParentId, state, (id) => {
                if (id) {
                    state.IdString = id.toLowerCase();

                    var inter = controller.interferenceHelper.createInterferenceObjectFromData(state);

                    if (openFlyOut && !inter.isSelected && !inter.locked && !inter._isDisposed) {
                        viewModel.addToSelection([inter], true, false, <any[]>viewModel.selectedUi);
                        var $aoConstrucionSettings = $('#ao-ConstrucionSettings') as any;
                        if ($aoConstrucionSettings.tabs)
                            $aoConstrucionSettings.tabs("option", "active", 1);
                        if (!Controller.Current.viewModel.FlyoutVisible)
                            LS.Client3DEditor.ToolBox.toggleFlyout();
                    }

                    controller.updateManager.dynamicUpdate(() => {
                        Controller.Current.interferenceHelper.createInterferenceObjectFromData(state);
                        Controller.Current.interferenceHelper.updateSetInterferenceState(id, baseParentId, state);
                    }, () => {
                        var o = Controller.Current.interferenceHelper.getById(id);
                        if (o) {
                            o.remove();
                            Controller.Current.interferenceHelper.updateSetInterferenceState(id, baseParentId, null);
                        }
                    });
                }
            });
        }

    }

}