module LS.Client3DEditor {

    export enum RoofBackgroundType {
        Default = 0,
        Maps
    }

    enum DisplayModeEnum {
        Default = 0,
        CAD
    }

    export enum RoofType {
        FlatRoof = 0,
        SlopingRoof = 1
    }

    export interface IRoofOptions {
        HasMapsBackground: boolean;
        RoofBackgroundType: RoofBackgroundType;
    }

    export interface ClientObjectJsonData extends ClientObjectUserData {
        SharedMeshes: THREE.Mesh[];
        Type: string;
        DataArrays: any;
        Count: number;
    }

    export interface IClientObjectLookup {
        [id: string]: ClientObject;
    }

    export interface IClientObjectInstanceLookup {
        [id: string]: ClientObjectInstance;
    }

    var beforeDeleteEvent = { type: 'beforeDelete' };
    var updateEvent = { type: 'update' };
    var stoppedEvent = { type: 'stopped' };

    export class Controller implements IEventManager {
        static Current: Controller = null;

        protected _doAnimation: boolean;

        ViewId: string;
        ContainerId: string;
        TBId: string;
        TBContainerId: string;
        ContentHeightClass: string;
        ContentId: string;
        WrapperId: string;
        StatusbarId: string;
        CameraPadding: number;
        ClickTolerance: number;
        ContextMenu: string;
        DeferredRendering: boolean = false;
        private viewNeedsUpdate: number = 0;

        static viewPaddingX = -10;
        static viewPaddingY = 0;

        CommonMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });
        //roofAngle: THREE.Quaternion = new THREE.Quaternion(0, 0, 0, 1);
        scene: THREE.Scene;
        mainLight: THREE.DirectionalLight;
        uiscene: THREE.Scene;
        globalBox: THREE.Box3;
        globalSphere: THREE.Sphere;
        camera: THREE.OrthographicCamera | THREE.PerspectiveCamera;
        orthoCamera: THREE.OrthographicCamera;
        perspectiveCamera: THREE.PerspectiveCamera;
        uicamera: THREE.OrthographicCamera;
        renderer: THREE.WebGLRenderer;
        light: THREE.Light;
        ambient: THREE.AmbientLight;
        controls: CameraControls;
        private _worldRaycaster = new THREE.Raycaster();
        private _localRaycaster = new THREE.Raycaster();
        testVolume = new spt.ThreeJs.utils.TestVolume();
        selectionHelper: SelectionHelper;
        transformControlers: THREE.TransformControls[] = [];
        interferenceHelper: LS.Client3DEditor.InterferenceHelper;
        controlHelper: NavigationHelper;
        BuildingClassName: string = null;
        Objects: IClientObjectLookup = {};
        Instances: IClientObjectInstanceLookup = {};
        Alpha: number = 1.0;
        $aoes5View: JQuery;
        RoofOptions: IRoofOptions = {
            HasMapsBackground: false,
            RoofBackgroundType: RoofBackgroundType.Default
        };

        get localRaycaster(): THREE.Raycaster {
            return this.getLocalRaycaster(this.getWorldRaycaster(this._localRaycaster));
        }

        get worldRaycaster(): THREE.Raycaster {
            return this.getWorldRaycaster(this._worldRaycaster);
        }

        getWorldRaycaster(targetRaycaster?: THREE.Raycaster, mouse?: { x: number, y: number }): THREE.Raycaster {
            var camera = this.camera;

            if (!mouse)
                mouse = this.viewModel.mouse;

            if (!targetRaycaster)
                targetRaycaster = this._worldRaycaster;

            targetRaycaster.setFromCamera(mouse, camera);

            targetRaycaster.params.Line.threshold = this.linePrecision;

            return targetRaycaster;
        }

        getLocalRaycaster(targetWorldRaycaster: THREE.Raycaster, instanceContext?: InstanceContext): THREE.Raycaster {
            targetWorldRaycaster.ray.applyMatrix4((instanceContext || this.viewModel.currentInstance).worldToLocalTransform);
            return targetWorldRaycaster;
        }

        get boxHelper(): THREE.BoxHelper {
            return this.viewModel.currentInstance.boxHelper;
        }

        get instanceSelectionHelper(): SelectionHelper {
            return this.viewModel.currentInstance.selectionHelper;
        }

        get pointingHelper(): PointingHelper {
            return this.viewModel.currentInstance.pointingHelper;
        }

        get segmentsHelper(): SegmentsHelper {
            return this.viewModel.currentInstance.segmentsHelper;
        }

        get segmentHighlightHelper(): SegmentHighlightHelper {
            return this.viewModel.currentInstance.segmentHighlightHelper;
        }

        get edgeHighlightHelper(): SegmentHighlightHelper {
            return this.viewModel.currentInstance.edgeHighlightHelper;
        }

        get linesHelper(): LinesHelper {
            return this.viewModel.currentInstance.linesHelper;
        }

        get measureObjectHolder(): MeasureObjectHolder {
            return this.viewModel.currentInstance.measureObjectHolder;
        }

        get objectHolders(): GenericObjectHolder<IGenericObjectInstance>[] {
            return this.viewModel.currentInstance.objectHolders;
        }

        get stringObjectHolder(): StringObjectHolder {
            return this.viewModel.currentInstance.stringObjectHolder;
        }

        get conduitObjectHolder(): ConduitObjectHolder {
            return this.viewModel.currentInstance.conduitObjectHolder;
        }

        get directedAreaHolder(): DirectedAreaHolder {
            return this.viewModel.currentInstance.directedAreaHolder;
        }

        get measureVisualizer(): MeasureVisualizer {
            return this.viewModel.currentInstance.measureVisualizer;
        }

        get lineSegmentsHelper(): LineSegmentsHelper {
            return this.viewModel.currentInstance.lineSegmentsHelper;
        }

        get linePrecision() {
            return this.ClickTolerance / this.orthoCamera.zoom;
        }

        static viewModelInstance: ViewModel = null;
        static updateManagerInstance: UpdateManager = null;
        private static viewModelChangeListener: () => void = null;

        get viewModel() {
            return Controller.viewModelInstance;
        }

        set viewModel(vm: ViewModel) {
            Controller.viewModelInstance = vm;
        }

        get updateManager() {
            return Controller.updateManagerInstance;
        }

        set updateManager(um: UpdateManager) {
            Controller.updateManagerInstance = um;
        }

        animate: () => void;

        public static init() {
            if (Controller.Current) {
                Controller.Current.dispose();
                Controller.Current = null;
            }
            return new Controller();
        }

        public async getImageBlob() {

            return new Promise<Blob>(resolve => {
                // pre
                this.renderer.setClearColor(0xFFFFFF, 0);
                this.uiscene.visible = false;

                // render
                this.BeforeRendering();
                this.Render();

                var canvas = this.renderer.domElement;
                canvas.toBlob((blob) => {
                    //this.saveBlob(blob, `screencapture-${canvas.width}x${canvas.height}.png`);

                    // post
                    this.renderer.setClearColor(0xFFFFFF);
                    this.uiscene.visible = true;

                    resolve(blob);
                });
            });
        }

        public saveBlob(blob, fileName) {
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.style.display = 'none';
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.click();
        }

        constructor() {
            var options = LS.Client3DEditor.globalParams;

            this._doAnimation = false;

            var animate: () => void;

            if (options.DeferredRendering) {
                animate = () => {
                    if (this._doAnimation) {
                        requestAnimationFrame(animate);
                        this.BeforeRendering();
                        if (this.viewNeedsUpdate) {
                            //ko.tasks.runEarly();
                            this.Render();
                            --this.viewNeedsUpdate;
                        }
                    } else
                        this.dispatchEvent(stoppedEvent);
                };
            } else {
                animate = () => {
                    if (this._doAnimation) {
                        requestAnimationFrame(animate);
                        this.BeforeRendering();
                        this.Render();
                    } else
                        this.dispatchEvent(stoppedEvent);
                };
            }

            this.animate = animate;
            for (let key in options) {
                if (options.hasOwnProperty(key))
                    this[key] = options[key];
            }

            if (Controller.Current) {
                throw new Error("Single instance error: Instantiation failed: Use Controller.init instead of new.");
            }

            Controller.Current = this;

            this.$aoes5View = $(this.ViewId);

            var scene = this.scene = new THREE.Scene();
            var uiscene = this.uiscene = new THREE.Scene();

            //CAMERAS
            var orthoCameraDistance = 500000;
            var camera = this.orthoCamera = new THREE.OrthographicCamera(-10000, 10000, 10000, -10000, 100, orthoCameraDistance * 2);
            camera.position.set(0, 0, orthoCameraDistance);

            var globalBox = this.globalBox = new THREE.Box3(new THREE.Vector3(0, 0, -5000), new THREE.Vector3(10000, 10000, 5000));
            var globalSphere = this.globalSphere = globalBox.getBoundingSphere(new THREE.Sphere());//new THREE.Sphere(new THREE.Vector3(5000, 5000, 0), 5000);

            var perspectiveCamera = this.perspectiveCamera = this.camera = new THREE.PerspectiveCamera(40, 1, 10, 100000);
            camera.add(perspectiveCamera);

            var _orthoZoom = camera.zoom;
            Object.defineProperty(camera, 'zoom', { enumerable: true, configurable: true, get: () => { return _orthoZoom; }, set: (value) => { _orthoZoom = value; spt.ThreeJs.utils.ComputeProjectionUpdate(perspectiveCamera, camera, globalSphere, orthoCameraDistance); } });

            camera.updateProjectionMatrix = perspectiveCamera.updateProjectionMatrix = () => { spt.ThreeJs.utils.ComputeProjectionUpdate(perspectiveCamera, camera, globalSphere, orthoCameraDistance); };

            var uicamera = this.uicamera = new THREE.OrthographicCamera(-10000, 10000, 10000, -10000, 10, 10000);
            uicamera.position.set(0, 0, 5000);

            //CAMERAS END

            //RENDERER

            var renderer = this.renderer = new THREE.WebGLRenderer({
                antialias: false,
                preserveDrawingBuffer: true,
                alpha: true // makes it possible to make a screenshot/image blob in transparent
            });
            renderer.sortObjects = true;
            renderer.setPixelRatio(window.devicePixelRatio);
            renderer.setClearColor(0xFFFFFF); // to make the image blob transparent set alpha to 0

            //console.log("test:" + this.viewModel.view.css('width'));

            renderer.setSize(this.viewModel.ContainerWidth, this.viewModel.ContainerHeight);
            renderer.autoClear = false;
            var domTarget = $(this.ViewId).get(0);
            domTarget.appendChild(renderer.domElement);
            DManager.makeUnselectable($(renderer.domElement));

            if (!Detector.webglParameters.supportsInstancedArrays) {
                DManager.showErrorMessage($('#ao-WebGLWarningMessage').html() + ' (MISSING_EXTENSION:ANGLE_instanced_arrays)');
                WriteTraceSimple("AO 2.0 ERROR - WEBGL Feature ANGLE_instanced_arrays Missing: " + Detector.browserInfo.name + " v " + Detector.browserInfo.version, "Anordnung");
            }

            spt.ThreeJs.utils.SDFFontMaterial.textureAnisotropy = Detector.webglParameters.maxAnisotropy;
            spt.ThreeJs.utils.SDFFontMaterial.precision = Detector.webglParameters.maxFloatPrecision;
            spt.ThreeJs.utils.SDFFontMaterial.imagePath = SDFFontPath;

            //renderer.gammaInput = true;
            //renderer.gammaOutput = true;

            //renderer.domElement.addEventListener('mousemove', ViewModel.onMouseMove.bind(ViewModel), false);

            //3D control Ui
            var controlHelper = this.controlHelper = new NavigationHelper(camera, uicamera);
            uiscene.add(controlHelper);

            //Axis
            //var axisHelper = new THREE.AxesHelper(1000);
            //scene.add(axisHelper);

            //Grid helper
            //if (window.isDebugMode) {
            //    var gridHelper = new THREE.GridHelper(1000000, 100, 0x888888, 0x8888DD);
            //    gridHelper.rotation.x = Math.PI * 0.5;
            //    scene.add(gridHelper);
            //}

            //LIGHTS
            var light = this.light = this.mainLight = new THREE.DirectionalLight(0xffffff, 0.8);
            light.position.set(1.2, -1, 2).normalize();
            scene.add(light);

            var ambient = this.ambient = new THREE.AmbientLight(0x505050);
            scene.add(ambient);

            // camera controls
            var controls = this.controls = new CameraControls(camera, domTarget, Controller.viewModelInstance);
            //controls.noRotate = true;
            //controls.staticMoving = true;
            controls.addEventListener('change', controlHelper.UpdateOrientation.bind(controlHelper));

            //Boxhelper
            //var boxHelper = this.boxHelper = new THREE.BoxHelper();
            //(boxHelper.geometry as any).dynamic = true;
            //boxHelper.visible = false;
            //scene.add(boxHelper);

            //roofPlane
            //var roofOrigin = this.roofOrigin = new THREE.Vector3();
            //var roofPlane = this.roofPlane = new THREE.Plane().setFromNormalAndCoplanarPoint(new THREE.Vector3(0, 0, 1), roofOrigin);

            //this.testScene(scene);

            //selection helper
            var selectionHelper = this.selectionHelper = new SelectionHelper();
            uiscene.add(selectionHelper);

            this.interferenceHelper = new InterferenceHelper();

            this.viewModel.genericParameters.setOverloadEntries([
                { name: "X", displayName: "X", value: 0, inputType: "int" },
                { name: "Y", displayName: "Y", value: 0, inputType: "int" },
                //{ name: "Z", displayName: "Z", value: 0, inputType: "int" },
                { name: "Width", displayName: "Width", value: 0, inputType: "int" },
                { name: "Height", displayName: "Height", value: 0, inputType: "int" },
                { name: "Rotation", displayName: "Rotation", value: 0, inputType: "float", precision: 2, isAngle: true }
            ]);

            if (options.DeferredRendering) {
                var updateView = Controller.viewModelChangeListener = this.notifyRefreshView.bind(this);

                controls.addEventListener('change', updateView);
                controls.addEventListener('start', updateView);
                controls.addEventListener('end', updateView);
                this.viewModel.addListener('change', updateView);
                this.updateManager.addListener('change', updateView);
                LoaderManager.addListener('finished', updateView);
                LoaderManager.addListener('resourceLoaded', updateView);
            }

        }

        notifyRefreshView() {
            this.viewNeedsUpdate = 2;
        }

        Start() {
            setTimeout(() => {
                if (!this._doAnimation) {
                    this._doAnimation = true;
                    this.animate();
                }
            }, 0);
        }

        Stop(callback?: () => void): void {
            if (this._doAnimation) {
                if (callback) {
                    var stopfunction = () => {
                        this.removeListener('stopped', stopfunction);
                        callback();
                    };
                    this.addListener('stopped', stopfunction);
                }
                this._doAnimation = false;
            } else if (callback) {
                callback();
            }
        }

        addListener(type: string, listener: (event: IEventManagerEvent) => void): EventManager { return this; }

        hasListener(type: string, listener: (event: IEventManagerEvent) => void): boolean { return false; }

        removeListener(type?: string, listener?: (event: IEventManagerEvent) => void): void { }

        dispatchEvent(event: IEventManagerEvent): void { }

        dispose(): void {
            if (this.viewModel && Controller.viewModelChangeListener) {
                this.viewModel.removeListener('change', Controller.viewModelChangeListener);
                this.updateManager.removeListener('change', Controller.viewModelChangeListener);
                LoaderManager.removeListener('finished', Controller.viewModelChangeListener);
                LoaderManager.removeListener('resourceLoaded', Controller.viewModelChangeListener);
                Controller.viewModelChangeListener = null;
            }

            var childs = this.scene.children;
            for (var i = childs.length; i--;) {
                var child = childs[i];
                if (child instanceof ClientObject) {
                    var coChildren = child.children;
                    for (var j = coChildren.length; j--;) {
                        var coChild = coChildren[j] as THREE.Mesh;
                        if (coChild.geometry && coChild.geometry && (coChild.geometry as any).attributes) {
                            var attributes = (coChild.geometry as any).attributes;
                            if (attributes.position)
                                delete (coChild.geometry as any).attributes.position;
                            if (attributes.normal)
                                delete (coChild.geometry as any).attributes.normal;
                            if (attributes.uv)
                                delete (coChild.geometry as any).attributes.uv;
                        }
                    }
                }
            }

            this.Stop();
            this.dispatchEvent(beforeDeleteEvent);
            if (this.renderer)
                this.renderer.state.reset();
            spt.ThreeJs.utils.disposeObject3D(this.scene);
            if (this.renderer && typeof this.renderer.dispose == "function")
                this.renderer.dispose();
        }

        BeforeRendering() {
            var pointingHelper = this.pointingHelper;
            this.controls.update();
            if (pointingHelper.visible) {
                var sc = this.ClickTolerance / this.orthoCamera.zoom;
                pointingHelper.scale.set(sc, sc, sc);
            }

            this.update();
            this.updateManager.update();
            this.viewModel.update();
            this.objectHolders.forEach(o => o.updateObjects());
            this.interferenceHelper.update(this);
            this.lineSegmentsHelper.update();
        }

        Render(): void {
            var renderer = this.renderer;
            renderer.clear();
            renderer.render(this.scene, this.camera);
            renderer.clearDepth();
            renderer.render(this.uiscene, this.uicamera);
        }

        update(): void {
            this.dispatchEvent(updateEvent);
        }

        getInstanceContext(id: string) {
            return this.viewModel.getInstanceContext(id, this);
        }

        clearClientObjects(clearAllInstances?: boolean) {
            var viewModel = this.viewModel;

            if (clearAllInstances) {
                //remove instances
                viewModel.instances.forEach((inst: InstanceContext) => {
                    inst.measureObjectHolder.clear();
                    inst.objectHolders.forEach(o => o.clear());
                    inst.directedAreaHolder.clear();
                });

                var clientObjects = this.Objects;
                for (var id in clientObjects) {
                    clientObjects[id].dispose();
                }
                this.Objects = {};
                viewModel.selected.removeAll();
                viewModel.clearInstances();
            } else {

                //empty only current instance
                var currentInstance = viewModel.currentInstance;
                currentInstance.measureObjectHolder.clear();
                currentInstance.objectHolders.forEach(o => o.clear());
                currentInstance.directedAreaHolder.clear();
                currentInstance.getClientObjects().forEach(co => {
                    co.dispose();
                });
                viewModel.selected.removeAll();
            }

        }

        addClientObjects(clientObjects: ClientObjectJsonData[] | SolarProTool.IClient3DObect[]) {
            for (var i = clientObjects.length; i--;) {
                var clientObjectJson = clientObjects[i],
                    baseParentId = clientObjectJson.BaseParentId || spt.Utils.GenerateGuid(),
                    clientObject = new ClientObject(spt.ThreeJs.utils.ConvertThreeJsObject(clientObjectJson.SharedMeshes), clientObjectJson.Type, baseParentId, clientObjectJson);
                clientObject.SetClientObjectInstancesFromArrayProperties(clientObjectJson.DataArrays as SolarProTool.IClientObectDataArrays, clientObjectJson.Count);
                clientObject.addToScene();

                if (clientObjectJson.Transparency/* this.viewModel.EditorViewMode === "Electric" && clientObjectJson.Type.indexOf("ModuleObject") !== -1*/) {
                    var opacity = 1 - clientObjectJson.Transparency;
                    clientObject.ClientObjectInstances.forEach(ci => {
                        ci.instanceAlphaOverride = opacity;
                    });
                }

                if (clientObjectJson.IsHidden)
                    clientObject.setHidden(true);

                if (clientObject.SharedMeshes && clientObject.SharedMeshes.length) {
                    for (var j = clientObject.SharedMeshes.length; j--;) {
                        var sharedMesh = clientObject.SharedMeshes[j],
                            material = sharedMesh.material;
                        if ((<LS.Client3DEditor.ModuleAppearanceShader>material).isModuleAppearanceShader) {
                            this.viewModel.setModuleAppearance(material as LS.Client3DEditor.ModuleAppearanceShader);
                            break;
                        }
                    }
                }
            }

            this.setAlpha(1);
            this.notifyRefreshView();
        }

        setEditorViewObject(data: SolarProTool.IEditorViewObject, resetView: boolean) {

            var viewModel = this.viewModel,
                controlHelper = this.controlHelper,
                measureObjectHolder = this.measureObjectHolder,
                objectHolders = this.objectHolders,
                directedAreaHolder = this.directedAreaHolder,
                buildingSettings = data.BuildingSettings as IEntrySetting[],
                updateManager = this.updateManager;

            //this.roofAngle.set(data.RoofAngleX, data.RoofAngleY, data.RoofAngleZ, data.RoofAngleW);

            directedAreaHolder.clear();
            measureObjectHolder.clear();
            objectHolders.forEach(o => o.clear());

            var globalOrientationRadian = data.GlobalOrientationRadian || 0;
            var groundZ = data.GroundZ || 0;
            var worldOrigin = new MapDrawing.LatLng(data.WorldOrigin.Latitude, data.WorldOrigin.Longitude);
            var layoutOrientationRad = data.LayoutOrientation == 0 ? 0 : data.LayoutOrientation / 180 * Math.PI;
            var layoutOrientationMoveToZero = new THREE.Vector3().copy(data.LayoutOrientationMoveToZero as any);

            //set roof objects
            this.setEditorViewObjectIntern(data, worldOrigin, globalOrientationRadian, groundZ, layoutOrientationRad, layoutOrientationMoveToZero);

            if (data.EditorViewObjects && data.EditorViewObjects.length) {
                data.EditorViewObjects.forEach(reditorViewObject => {
                    this.setEditorViewObjectIntern(reditorViewObject, worldOrigin, globalOrientationRadian, groundZ, layoutOrientationRad, layoutOrientationMoveToZero);
                });
            }

            viewModel.currentInstance = this.getInstanceContext(data.InstanceHolderId) || null;

            var globalBox = this.globalBox.set(new THREE.Vector3(data.BoundsX, data.BoundsY, data.BoundsZ), new THREE.Vector3(data.BoundsX + data.BoundsWidth, data.BoundsY + data.BoundsHeight, data.BoundsZ + data.BoundsDepth));
            var boxPadding = new THREE.Vector3(5000, 5000, 5000);
            viewModel.instances.forEach((inst) => {
                globalBox.expandByObject(inst.root);
            });
            globalBox.min.sub(boxPadding);
            globalBox.max.add(boxPadding);
            globalBox.getBoundingSphere(this.globalSphere);

            controlHelper.SetCompassOrientation(data.RoofOrientation);
            //controlHelper.SetElevationAngle(this.roofAngle);
            controlHelper.UpdateOrientation();

            if (data.HasShadows) {
                $('#shadevisible').hide();
                $('#shadehide, #aoShadowLegend').show();
            } else {
                $('#shadevisible').show();
                $('#shadehide, #aoShadowLegend').hide();
            }

            if (resetView) {

                onWindowResize();
                this.resetView(true);

                //var w = this.ContainerWidth,
                //    h = this.ContainerHeight,
                //    vw2 = w / 2,
                //    vh2 = h / 2,
                //    rw2 = rw / 2.0,
                //    rh2 = rh / 2.0;

                //camera.zoom = Math.min((this.ContainerWidth - this.CameraPadding) / rw, (this.ContainerHeight - this.CameraPadding) / rh);
                //camera.far = Math.ceil(roofSizeVectorLength * 2.0);

                //var roofUp = new THREE.Vector3(0, 0, 1).applyQuaternion(this.roofAngle),
                //    roofyAngle = Math.atan2(roofUp.x, roofUp.z),
                //    roofyAngleDegree = Math.abs(roofyAngle / Math.PI * 180),
                //    roofxAngle = Math.atan2(roofUp.y, roofUp.z),
                //    roofxAngleDegree = roofxAngle / Math.PI * 180,
                //    yUp = new THREE.Vector3(0, 0, 1);

                //if (roofxAngleDegree > 0 && roofxAngleDegree < 45)
                //    yUp.applyAxisAngle(new THREE.Vector3(1, 0, 0), roofxAngle);

                //if (roofyAngleDegree > 0 && roofyAngleDegree < 45)
                //    yUp.applyAxisAngle(new THREE.Vector3(0, 1, 0), -roofyAngle);

                //yUp.multiplyScalar(roofSizeVectorLength);

                //var viewTarget = new THREE.Vector3(rw2, rh2, 0),
                //    camPos = viewTarget.clone().add(yUp);

                //controls.reset(camPos, viewTarget, -vw2, vw2, vh2, -vh2);
                //controls.handleResize();
                //controls.SetElevationAngle(this.roofAngle);

                //camera.updateProjectionMatrix();

                //uicamera.left = -vw2;
                //uicamera.right = vw2;
                //uicamera.top = vh2;
                //uicamera.bottom = -vh2;
                //uicamera.updateProjectionMatrix();
            }

            if (data.InstanceholderAppearance && typeof (data.InstanceholderAppearance) === "object") {
                var appearance = data.InstanceholderAppearance,
                    roofAppearance = viewModel.roofAppearance;
                Object.keys(appearance).forEach(k => {
                    roofAppearance[k] = appearance[k];
                });
                viewModel.hideRoofAppearance = false;
            } else {
                viewModel.hideRoofAppearance = true;
            }

            this.BuildingClassName = data.BuildingClassName || null;

            this.viewModel.genericParameters.setPossibleEntrySettings(buildingSettings);

            this.setAlpha(1);

            this.RoofOptions.RoofBackgroundType = data.RoofBackgroundType;
            this.RoofOptions.HasMapsBackground = !!data.HasMapsBackground;

            //rotate mainlight
            this.mainLight.position.set(1.2, -1, 2).applyQuaternion(new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 0, 1), THREE.MathUtils.degToRad(180 - data.RoofOrientation))).normalize();

            if (viewModel.selectedTool === "dimensionLines" && viewModel.currentRoofLayers.indexOf(StrCustomDimensioning) === -1)
                viewModel.deselectTool();

            this.conduitObjectHolder.setConduitObjects(data.ConduitObjects);

            updateManager.clearHistory();

            this.notifyRefreshView();
        }

        private setEditorViewObjectIntern(data: SolarProTool.IEditorViewObject, origin: MapDrawing.LatLng, orientationRad: number, groundz: number, layoutOrientationRad: number, layoutOrientationMoveToZero: THREE.Vector3) {
            var viewModel = this.viewModel,
                instanceContext = this.getInstanceContext(data.InstanceHolderId);

            if (instanceContext) {
                viewModel.currentInstance = instanceContext;
                instanceContext.setByEditorViewObject(data, origin, orientationRad, groundz, layoutOrientationRad, layoutOrientationMoveToZero, this);
            }
        }

        loadAnordnungObjects(callBack?: () => void) {
            LoaderManager.addLoadingJob();
            this.Stop(() => {
                //AManager.Service('ProjectServices.asmx').Method('GetProjectOverviewData')
                SolarProTool.Ajax('WebServices/Anordnung3DService.asmx').Call('GetAnordnungObjects').Data({ compressData: true }).CallBack(data => {
                    if (data) {
                        window.CheckStaticAndLoadBackup();

                        var clientObjects = data.ClientObjects;

                        this.setEditorViewObject(data, true);

                        if (clientObjects)
                            this.addClientObjects(clientObjects);

                        if (callBack)
                            callBack();

                    } else {
                        DManager.showErrorMessage("Error on loading roof. Please try again.");
                    }
                    this.Start();
                    LoaderManager.finishLoadingJob();
                }, () => {
                    DManager.showErrorMessage("Error on loading roof. Please try again.");
                    this.Start();
                    LoaderManager.finishLoadingJob();
                });
            });
        }

        loadCADObjects(resetView: boolean, checkStatic?: boolean, callBack?: () => void) {
            LoaderManager.addLoadingJob();
            var layers: string[] = [];
            $('input[name=Layer]:checked').each((idx, elem) => {
                layers.push($(elem).val() as any);
            });
            if (!this._doAnimation)
                resetView = true;
            this.Stop(() => {
                SolarProTool.Ajax('WebServices/Anordnung3DService.asmx').Call('GetAutoCADObjects').Data({ layer: layers }).CallBack(data => {
                    if (data) {

                        if (checkStatic)
                            window.CheckStaticAndLoadBackup();

                        var clientObjects = data.ClientObjects;

                        this.setEditorViewObject(data, resetView);

                        if (clientObjects)
                            this.addClientObjects(clientObjects);

                        if (callBack)
                            callBack();

                    } else {
                        DManager.showErrorMessage("Error on loading roof. Please try again.");
                    }
                    this.Start();
                    LoaderManager.finishLoadingJob();
                }, () => {
                    DManager.showErrorMessage("Error on loading roof. Please try again.");
                    this.Start();
                    LoaderManager.finishLoadingJob();
                });
            });
        }

        loadStaticObjects(resetView: boolean, checkStatic?: boolean, callBack?: () => void) {
            LoaderManager.addLoadingJob();
            var layers = [];
            $('input[name=Layer]:checked').each((idx, elem) => {
                layers.push($(elem).val());
            });

            if (layers.indexOf('Statics') === -1)
                layers.unshift('Statics');
            if (!this._doAnimation)
                resetView = true;
            this.Stop(() => {
                SolarProTool.Ajax('WebServices/Anordnung3DService.asmx').Call('GetStaticObjects').Data({ layer: layers }).CallBack(data => {
                    if (data) {

                        if (checkStatic)
                            window.CheckStaticAndLoadBackup();

                        var clientObjects = data.ClientObjects;

                        this.setEditorViewObject(data, resetView);

                        if (clientObjects)
                            this.addClientObjects(clientObjects);

                        if (callBack)
                            callBack();

                    } else {
                        DManager.showErrorMessage("Error on loading roof. Please try again.");
                    }
                    this.Start();
                    LoaderManager.finishLoadingJob();
                }, () => {
                    DManager.showErrorMessage("Error on loading roof. Please try again.");
                    this.Start();
                    LoaderManager.finishLoadingJob();
                });
            });
        }

        loadElectricObjects(resetView: boolean, callBack?: () => void) {
            LoaderManager.addLoadingJob();
            if (!this._doAnimation)
                resetView = true;
            this.Stop(() => {
                SolarProTool.Ajax('WebServices/Anordnung3DService.asmx').Call('GetElectricObjects').Data().CallBack(data => {
                    if (data) {
                        var clientObjects = data.ClientObjects;

                        this.setEditorViewObject(data, resetView);

                        if (clientObjects)
                            this.addClientObjects(clientObjects);

                        if (callBack)
                            callBack();

                    } else {
                        DManager.showErrorMessage("Error on loading roof. Please try again.");
                    }
                    this.Start();
                    LoaderManager.finishLoadingJob();
                }, () => {
                    DManager.showErrorMessage("Error on loading roof. Please try again.");
                    this.Start();
                    LoaderManager.finishLoadingJob();
                });
            });

        }

        onObjectsInitialized() {
            if (window["OnStart" + window.EditorViewMode])
                window["OnStart" + window.EditorViewMode]();
        }

        getPositionOnRoof(viewPosition: { x: number, y: number }) {
            var raycaster = this._worldRaycaster,
                camera = this.camera,
                viewModel = this.viewModel,
                v = new THREE.Vector2();

            v.set((viewPosition.x / viewModel.width) * 2 - 1, -(viewPosition.y / viewModel.height) * 2 + 1);

            raycaster.setFromCamera(v, camera);

            return viewModel.currentInstance.getLocalPosition(raycaster.ray) || null;
        }

        doUndo() {
            var boxHelper = this.boxHelper;
            if (boxHelper.visible)
                setBoxhelper(null);
            this.updateManager.Undo();
            this.notifyRefreshView();
        }

        doRedo() {
            var boxHelper = this.boxHelper;
            if (boxHelper.visible)
                setBoxhelper(null);
            this.updateManager.Redo();
            this.notifyRefreshView();
        }

        switchActiveStates() {
            this.viewModel.selected.forEach((o) => {
                o.instanceData.update("IsActive", o.instanceData.IsActive ? 0 : 1);
            });
        }

        switchVisibleStates() {
            this.viewModel.selected.forEach((o) => {
                o.setVisible(!o.visible);
            });
        }

        switchStaticSelected(b: boolean) {
            this.viewModel.selected.forEach((o) => {
                o.instanceData.update("OptionCode", b ? (o.instanceData.OptionCode | ClientInstanceOption.StaticSelected) : (o.instanceData.OptionCode & ~ClientInstanceOption.StaticSelected));
            });
        }

        moveSelectedStatic(x: number, y: number, z: number, applyDirect?: boolean, allowMouseDown?: boolean) {
            var viewModel = this.viewModel,
                _v1 = new THREE.Vector3();

            if (viewModel.selectedUi.length) {
                if (viewModel.selectedTool === 'keyboardSteps')
                    viewModel.deselectTool();

                _v1.set(x || 0, y || 0, z || 0);
                for (var i = viewModel.selectedUi.length; i--;) {
                    viewModel.selectedUi[i].moveBy(_v1);
                }
                viewModel.selectionUiNeedsUpdate = true;
                return;
            }

            if (viewModel.selectedTool == null)
                viewModel.setTool('keyboardSteps?enforce=' + (allowMouseDown ? "1" : "0"));
            if (viewModel.selectedTool === 'keyboardSteps')
                (viewModel.tools[viewModel.selectedTool] as KeyboardSteps).moveSelected(x, y, z);
            if (applyDirect)
                viewModel.deselectTool();
        }

        scaleSelectedStatic(len: number, lenNew: number, dirX: number, dirY: number) {
            var viewModel = this.viewModel;
            viewModel.deselectTool();

            var dt = new THREE.Vector3(),
                ds = new THREE.Vector3();

            var dl = lenNew / len;
            var t = lenNew - len;

            ds.set(Math.abs(dl * dirX), Math.abs(dl * dirY), 1);
            dt.set(t * dirX * 0.5, t * dirY * 0.5, 0);

            viewModel.ApplyDiffScalePosition(dt, ds);
        }

        startRotate() {
            if (this.viewModel.selectedUi.length)
                this.viewModel.setTool("rotateObjectTool");
        }

        switchTransparenceAll() {
            var controller = this;
            var alpha = controller.Alpha;
            var objects = controller.Objects;
            if (alpha >= 1.0)
                alpha = 0.3;
            else
                alpha = 1.0;
            for (var id in objects) {
                var clientObject = objects[id] as ClientObject;
                clientObject._alpha = alpha;
                clientObject._needsUpdate = true;
            }
            controller.setAlpha(alpha);

            this.notifyRefreshView();
        }

        setAlpha(alpha: number) {
            var controller = this;
            if (controller.Alpha != alpha) {
                var objects = controller.Objects;
                for (var id in objects) {
                    var clientObject = objects[id];
                    clientObject._alpha = alpha;
                    clientObject._needsUpdate = true;
                }
                controller.directedAreaHolder.setAlpha(alpha);
                controller.Alpha = alpha;
            }
        }

        selectAll() {
            var viewModel = this.viewModel;
            var objects = viewModel.currentInstance.getClientObjects();
            var co = objects.filter((o) => {
                return !!(o.userOptions & Client3DObectOptions.CanSelectInstances);
            });
            var allInstances: ClientObjectInstance[] = [];
            for (var i = co.length; i--;) {
                allInstances = allInstances.concat(co[i].ClientObjectInstances);
            }
            viewModel.selectedUi.removeAll();
            viewModel.addToSelection(allInstances, true);
        }

        deleteSelected() {
            var viewModel = this.viewModel;
            var controller = this;
            var types: { [type: string]: LS.Client3DEditor.IChange } = {};

            var selUi = viewModel.selectedUi.slice();
            if (selUi.length) {
                viewModel.selectedUi.removeAll();
                for (var k = 0, l = selUi.length; k < l; k++) {
                    selUi[k].removeInstance();
                }
            }

            viewModel.selected.forEach((o) => {
                var instanceData = o.instanceData,
                    type = o.clientObject.dataType;
                if (o.clientObject.userOptions & Client3DObectOptions.CanDeleteInstances) {
                    if (!types[type])
                        types[type] = {
                            Uuid: THREE.MathUtils.generateUUID(),
                            TypeString: type,
                            UpdateType: 'remove',
                            NewValueString: null,
                            OldValueString: null,
                            Name: null,
                            IdStrings: [],
                            skipCheck: true
                        };

                    types[type].IdStrings.push(instanceData.Id);
                }
            });

            var changes: LS.Client3DEditor.IChange[] = [];
            for (var type in types) {
                changes.push(types[type]);
            }

            viewModel.selected.removeAll();
            if (changes.length) {
                SolarProTool.Ajax("WebServices/Anordnung3DService.asmx").Call("DeleteClientObjects").Data({ changes: changes as SolarProTool.ClientObjectChanges[] }).CallBack(changes => {
                    if (window.EditorViewMode === "Electric") {
                        controller.reloadElectricPlan();
                        return;
                    }

                    var hasErrors = false;
                    var deletedCount = 0;
                    var additionalChanges: LS.Client3DEditor.IChange[] = [];
                    for (var m = 0, n = changes.length; m < n; m++) {
                        var change = changes[m];
                        if (change.ErrorCode != ErrorLevel.NoError) {
                            hasErrors = true;
                            controller.updateManager.handleErrorChange(change as LS.Client3DEditor.IChange);
                        } else if (change.UpdateType === "remove") {
                            var ids = change.IdStrings;
                            for (var i = 0, j = ids.length; i < j; i++) {
                                (controller.Instances[ids[i]]).removeMe(true);
                            }
                            deletedCount += ids.length;
                        } else {
                            additionalChanges.push(change as LS.Client3DEditor.IChange);
                        }
                    }
                    if (!hasErrors)
                        controller.updateManager.onNoError();
                    if (deletedCount > 200)
                        controller.updateManager.doClearHistory = true;
                    if (additionalChanges.length) {
                        Controller.Current.updateManager.handleModifiedChanges(additionalChanges, false);
                    }
                }, () => {
                    DManager.showErrorMessage("Error on sending changes to the server.");
                });
            }

        }

        connectModules(so?: StringObjectInstance, skipReload?: boolean) {
            var controller = this,
                viewModel = controller.viewModel;

            if (so && !so.modulesStringObjectValid())
                so = null;

            var moduleObjects: ClientObjectInstance[];
            if (so)
                moduleObjects = so.Joints.map(j => j.getClientObjectInstance(this)).filter(m => !!m);
            else
                moduleObjects = viewModel.selected.filter(o => o.clientObject.dataType.indexOf("ModuleObject") != -1);

            var diffusionPointObjects = viewModel.selected.filter(o => o.clientObject.dataType.indexOf("DiffusionPointObject") != -1);

            if (!moduleObjects.length) {
                DManager.ShowSmallInfo(window.electricTranslation["PolyDes_ElectricSelectModules"]);
                return;
            }

            var parentList: { [id: string]: number } = {};

            moduleObjects.forEach(m => {
                var bpId = m.clientObject.userData.BaseParentId;
                if (!parentList[bpId])
                    parentList[bpId] = 0;
                parentList[bpId]++;
            });

            var baseParentId: string = null;
            var bpCount = 0;

            Object.keys(parentList).forEach(id => {
                if (parentList[id] > bpCount) {
                    bpCount = parentList[id];
                    baseParentId = id;
                }
            });

            var moduleObjectsIds = moduleObjects.map(m => m.instanceData.Id);
            var diffusionPointObjectId = diffusionPointObjects.length ? diffusionPointObjects[0].instanceData.Id : null;

            LS.Electric.CreateModuleConnectionGroup(moduleObjectsIds, baseParentId, diffusionPointObjectId, so ? so.IdString : LS.Electric.InvertersModel.selectedString, (b: boolean) => {
                if (!skipReload && b)
                    controller.reloadOnlyElectric();
            });
        }

        setModuleClientConnections(moduleConnections: SolarProTool.IModuleConnectionGroup[]) {
            var stringObjectHolder = this.stringObjectHolder;

            stringObjectHolder.clear();

            if (moduleConnections) {
                var stringingTool = this.viewModel.tools.stringingTool,
                    invertersModel = LS.Electric.InvertersModel;

                moduleConnections.forEach(mcg => {
                    var mso = invertersModel.getModulesStringObject(mcg.ModulesStringObjectId);
                    var diffPointId = mcg.DiffusionPointObjectId;

                    if (mso) {
                        var newSo = stringObjectHolder.getById(mso.Id);
                        if (newSo)
                            newSo.removeAll();
                        else
                            newSo = stringObjectHolder.addNewStringObject(mso.Id);
                        newSo.setStringColor(mso.Color);

                        newSo.DiffusionPointObjectId = diffPointId;

                        if (mcg.Joints && mcg.Joints.length) {
                            mcg.Joints.forEach(jt => {
                                newSo.addJoint(new THREE.Vector3(jt.X, jt.Y, jt.Z), jt.ModuleObjectIdString);
                            });
                        } else {
                            mcg.ModuleObjectIds.forEach(modid => {
                                var mid = modid.toLowerCase(),
                                    pos = stringingTool.getPositionFromClientObjectInstance(this.Instances[mid]);
                                newSo.addJoint(pos, mid);
                            });
                        }
                    }
                });
            }
        }

        reloadOnlyElectric() {
            LS.Electric.GetElectricObjects();
        }

        reloadElectricPlan() {
            this.reloadModulePlan(false, LS.Electric.GetElectricObjects);
        }

        reloadModulePlan(reload?: boolean, callBack?: () => void, opts?: string) {
            if (window.EditorViewMode === "Anordnung" || window.EditorViewMode === "Electric") {
                this.viewModel.selected.removeAll();
                LoaderManager.addLoadingJob();
                this.Stop(() => {
                    AManager.Ajax('Anordnung3DService.asmx', 'GetAllClient3DObects', JSON.stringify({ reload: !!reload, opts: opts || null, mode: window.EditorViewMode }), (clientObjects: SolarProTool.Client3DObjectHolder) => {
                        this.setClientObjects(clientObjects);
                        if (callBack)
                            callBack();
                        this.Start();
                        LoaderManager.finishLoadingJob();
                    }, () => {
                        DManager.showErrorMessage("Error on sending changes to the server.");
                        this.Start();
                        LoaderManager.finishLoadingJob();
                    });
                });
            }
        }

        reloadAll(keepView?: boolean, reload?: boolean, callBack?: () => void) {
            var controller = this;

            controller.updateManager.clearHistory();
            controller.clearClientObjects(true);
            //controller.removeRoofObject();

            switch (window.EditorViewMode) {
                case "Anordnung":
                    controller.loadAnordnungObjects(callBack);
                    break;
                case "Electric":
                    controller.loadElectricObjects(!keepView, callBack);
                    break;
                case "AutoCAD":
                    controller.loadCADObjects(!keepView, !!reload, callBack);
                    break;
                case "Static":
                    controller.loadStaticObjects(!keepView, true, callBack);
                    break;
            }

        }

        regenerateModulePlan(withCorridors?: boolean, callBack?: () => void) {
            if (LoaderManager.isLoading())
                return;

            this.viewModel.selected.removeAll();
            this.Stop(() => {
                SolarProTool.Ajax("WebServices/Anordnung3DService.asmx").Call("GenerateWithGetClient3DObects").Data({ withCorridors: !!withCorridors }).CallBack(clientObjects => {
                    if (clientObjects) {
                        this.setClientObjects(clientObjects);
                        this.Start();
                        if (callBack)
                            callBack();
                    } else {
                        this.Start();
                        setTimeout(() => {
                            this.reloadAll(true, true, callBack);
                        }, 0);
                    }
                }, () => {
                    DManager.showErrorMessage("Error on sending changes to the server.");
                    this.Start();
                });
            });
        }

        fillRoof(callBack?: () => void) {
            if (LoaderManager.isLoading())
                return;

            this.viewModel.selected.removeAll();
            LoaderManager.addLoadingJob();
            this.Stop(() => {
                AManager.Ajax('Anordnung3DService.asmx', 'FillWithGetClient3DObects', "{}", (clientObjects: SolarProTool.Client3DObjectHolder) => {
                    this.setClientObjects(clientObjects);
                    this.Start();
                    LoaderManager.finishLoadingJob();
                    if (callBack)
                        callBack();
                }, () => {
                    DManager.showErrorMessage("Error on sending changes to the server.");
                    this.Start();
                    LoaderManager.finishLoadingJob();
                });
            });
        }

        resetConstructed() {
            if (LoaderManager.isLoading())
                return;
            var controller = this;
            this.viewModel.selected.removeAll();
            LoaderManager.addLoadingJob();
            AManager.Ajax('Anordnung3DService.asmx', 'ResetConstructed', null, (result: SolarProTool.Client3DObjectHolder) => {
                controller.setClientObjects(result);
                LoaderManager.finishLoadingJob();
            }, () => {
                DManager.showErrorMessage("Error on sending changes to the server.");
                LoaderManager.finishLoadingJob();
            });
        }

        setClientObjects(client3DObjectHolder: SolarProTool.Client3DObjectHolder, keepView?: boolean) {
            var controller = this;
            controller.updateManager.clearHistory();
            controller.clearClientObjects();
            if (this.viewModel.selectedInsertable)
                this.viewModel.deselectTool();

            if (client3DObjectHolder) {

                var instanceHolderIds: { [id: string]: boolean } = {},
                    clientObjects = client3DObjectHolder.ClientObjects;

                clientObjects.forEach(co => { if (co.BaseParentId) instanceHolderIds[co.BaseParentId.toLowerCase()] = true; });

                controller.viewModel.instances.forEach(inst => {
                    if (instanceHolderIds[inst.Id.toLowerCase()]) {
                        var clientObjects = inst.getClientObjects();
                        if (clientObjects && clientObjects.length)
                            clientObjects.forEach(co => {
                                co.dispose();
                            });
                    }
                });

                if (clientObjects && clientObjects.length)
                    controller.addClientObjects(clientObjects);
                var directedAreas = client3DObjectHolder.DirectedAreas;
                if (directedAreas && directedAreas.length)
                    controller.directedAreaHolder.addDirectedAreaDefinitions(directedAreas);
                var conduitObjects = client3DObjectHolder.ConduitObjects;
                if (conduitObjects && conduitObjects.length)
                    controller.conduitObjectHolder.setConduitObjects(conduitObjects);
                var measureObjects = client3DObjectHolder.MeasureObjects;
                if (measureObjects && measureObjects.length)
                    this.viewModel.currentInstance.measureObjectHolder.addMeasureObjects(measureObjects);
            }
            if (!keepView)
                controller.resetView();
            this.setAlpha(1);
        }

        onDblclick() {
            if (this.viewModel.selectedTool || window.EditorViewMode !== "Anordnung" || LoaderManager.isLoading())
                return;

            var viewModel = this.viewModel,
                worldRayCaster = this.worldRaycaster;

            var instanceContext = viewModel.findInstanceContext(worldRayCaster.ray, null, 5);

            if (instanceContext && viewModel.currentInstance !== instanceContext)
                this.switchInstanceHolder(instanceContext);
        }

        switchInstanceHolder(instanceContext: InstanceContext, callBack?: () => void) {
            var viewModel = this.viewModel;
            if (window.EditorViewMode !== "Anordnung" || LoaderManager.isLoading() || !instanceContext || viewModel.currentInstance.Id === instanceContext.Id) {
                if (callBack)
                    callBack();
                return;
            }

            viewModel.currentInstance.onDeactivate();

            viewModel.currentInstance = instanceContext;

            instanceContext.onActivate();

            this.resetView();

            SolarProTool.Ajax("WebServices/ProjectServices.asmx").Call("SwitchInstanceholder").Data({ instanceHolderId: instanceContext.Id }).CallBack(result => {
                window.OnProjectInfoCallBack(result);

                if (viewModel.currentInstance.Id !== result.InstanceHolderId)
                    viewModel.currentInstance = viewModel.getInstanceContext(result.InstanceHolderId, this);

                if (callBack)
                    callBack();
            });
        }

        SetupElectricView(viewLayer: IViewLayer) {
            if (!viewLayer)
                return;

            var clientObjects = this.Objects;
            var viewLayerName = viewLayer.Name;

            if (viewLayerName === "Stringing") {
                for (var id in clientObjects) {
                    var clientObject = clientObjects[id];
                    if (clientObject.dataType.indexOf("RailObject") !== -1)
                        clientObject.setHidden(true);
                }
                this.stringObjectHolder.getAllStringObjects().forEach(so => {
                    so.isHidden = false;
                });
                if (this.viewModel.selectedTool !== "conduitTool") {
                    this.viewModel.deselectTool();
                    if (!this.viewModel.FlyoutVisible || !this.viewModel.ShowInverterFlyout)
                        ToolBox.showInverterFlyout();
                }
            } else { //viewLayer.Name === "Grounding"
                for (var id in clientObjects) {
                    var clientObject = clientObjects[id];
                    if (clientObject.dataType.indexOf("RailObject") !== -1)
                        clientObject.setHidden(false);
                }
                this.stringObjectHolder.getAllStringObjects().forEach(so => {
                    so.isHidden = true;
                });
                if (this.viewModel.selectedTool !== "conduitTool") {
                    this.viewModel.deselectTool();
                    if (this.viewModel.FlyoutVisible)
                        ToolBox.closeFlyout();
                }
            }

            //if (viewLayerName) {
            //    var componentSetById = this.viewModel.tools.conduitTool.viewModel.ComponentSetById;
            //    Controller.Current.conduitObjectHolder.getAllConduitObjects().forEach(co => {
            //        var componentSet = co && co.ComponentSetId && componentSetById[co.ComponentSetId];
            //        if (componentSet && componentSet.ViewLayer && componentSet.ViewLayer !== viewLayerName)
            //            co.isHidden = true;
            //        else 
            //            co.isHidden = false;
            //    });
            //}

            if (this.viewModel.selectedTool !== "conduitTool") {
                this.viewModel.deselectTool();
            }
        }

        resetView(fast?: boolean) {
            var controller = this,
                camera = controller.orthoCamera,
                controls = controller.controls,
                currentInstance = controller.viewModel.currentInstance;

            var rw = currentInstance.Width,
                rh = currentInstance.Height,
                angleQuat = currentInstance.AngleQuat,
                transform = new THREE.Matrix4().makeRotationFromQuaternion(angleQuat).premultiply(currentInstance.root.matrix),
                transformNormal = new THREE.Matrix3().getNormalMatrix(transform),
                rw2 = rw / 2,
                rh2 = rh / 2,
                roofUp = new THREE.Vector3(0, 0, 1).applyQuaternion(angleQuat),
                roofyAngle = Math.atan2(roofUp.x, roofUp.z),
                roofyAngleDegree = Math.abs(roofyAngle / Math.PI * 180),
                roofxAngle = Math.atan2(roofUp.y, roofUp.z),
                roofxAngleDegree = roofxAngle / Math.PI * 180,
                yUp = new THREE.Vector3(0, 0, 1);

            if (roofxAngleDegree > 0 && roofxAngleDegree < 45)
                yUp.applyAxisAngle(new THREE.Vector3(1, 0, 0), roofxAngle);

            if (roofyAngleDegree > 0 && roofyAngleDegree < 45)
                yUp.applyAxisAngle(new THREE.Vector3(0, 1, 0), -roofyAngle);

            controls.rotateStart.copy(yUp).applyMatrix3(transformNormal);
            camera.getWorldDirection(controls.rotateEnd).negate();

            controls.rollStart.set(0, 1, 0).applyMatrix3(transformNormal);
            controls.rollEnd.copy(camera.up);

            controls.zoomStart = camera.zoom;
            controls.zoomEnd = Math.min((controller.viewModel.ContainerWidth - this.CameraPadding) / rw, (controller.viewModel.ContainerHeight - this.CameraPadding) / rh);

            controls.panStart.set(0, 0);
            controls.panEnd.set(0, 0);

            controls.moveStart.copy(controls.target);
            controls.moveEnd.set(rw2, rh2, 0).applyMatrix4(transform);

            controls.update(fast);
            if (fast)
                this.controlHelper.UpdateOrientation();
        }

        switchCamera() {
            var controller = this;
            var orthoCamera = controller.orthoCamera,
                perspectiveCamera = controller.perspectiveCamera,
                camera: THREE.Camera;

            if (controller.camera === orthoCamera) {
                camera = controller.camera = perspectiveCamera;

                this.transformControlers.forEach(tc => {
                    tc.camera = camera;
                    tc.setSize(0.5);
                });
            } else {
                camera = controller.camera = orthoCamera;
                this.transformControlers.forEach(tc => {
                    tc.camera = camera;
                    tc.setSize(0.1);
                });
            }
            controller.camera.updateProjectionMatrix();
            this.notifyRefreshView();
        }

        switchMapsBackground() {
            if (this.RoofOptions.HasMapsBackground && this.RoofOptions.RoofBackgroundType !== RoofBackgroundType.Maps) {
                this.RoofOptions.RoofBackgroundType = RoofBackgroundType.Maps;
                this.reloadRoofObject();
            } else if (this.RoofOptions.RoofBackgroundType === RoofBackgroundType.Maps) {
                this.RoofOptions.RoofBackgroundType = RoofBackgroundType.Default;
                this.reloadRoofObject();
            }
        }

        calculateShadowing() {
            LoaderManager.addLoadingJob();
            AManager.Ajax('ShadeService.asmx', 'HasShadowsToCalculate', "{}", (hasShadows) => {
                if (hasShadows) {
                    AManager.Ajax('ShadeService.asmx', 'CalculateAllShadowObjects', "{}", () => {
                        this.RoofOptions.RoofBackgroundType = RoofBackgroundType.Default;
                        //this.reloadRoofObject();
                        this.reloadAll(true, false);
                        LoaderManager.finishLoadingJob();
                    }, () => {
                        LoaderManager.finishLoadingJob();
                    });
                }
                else {
                    LoaderManager.finishLoadingJob();
                }
            }, () => {
                LoaderManager.finishLoadingJob();
            });
        }

        hideShadowing() {
            LoaderManager.addLoadingJob();
            AManager.Ajax('ShadeService.asmx', 'DeleteShadowObjects', "{}", () => {
                //this.reloadRoofObject();
                this.reloadAll(true, false);
                LoaderManager.finishLoadingJob();
            }, () => {
                LoaderManager.finishLoadingJob();
            });
        }

        showShadowLegend(b: boolean) {
            if (b) {
                $('#shadevisible').hide();
                $('#shadehide, #aoShadowLegend').show();
            } else {
                $('#shadevisible').show();
                $('#shadehide, #aoShadowLegend').hide();
            }
        }

        reloadRoofObject(reloadProject?: boolean, resetView = true) {
            SolarProTool.Ajax("WebServices/Anordnung3DService.asmx").Call("GetCurrentRoofObject").Data({ backgroundType: this.RoofOptions.RoofBackgroundType, editorViewMode: this.viewModel.EditorViewMode, reloadProject: !!reloadProject }).CallBack(data => {
                if (data) {
                    this.setEditorViewObject(data, resetView);
                } else {
                    DManager.showErrorMessage("Error on returning loading roof.");
                }
            });
        }

        ToggleDirectedAreaDirection() {
            if (this.viewModel.selectedTool)
                return;
            if (this.viewModel.selectedUi && this.viewModel.selectedUi.length)
                this.directedAreaHolder.changeAreaDirection((this.viewModel.selectedUi.filter(s => s instanceof spt.ThreeJs.utils.DirectedAreaDefinition)) as any);
        }

        ToggleCopyDirectedArea() {
            $(".copyDirectedArea").slideToggle("fast");
        }

        ApplyCopyDirectedArea() {
            var count = spt.Utils.GetFloatFromInput("#copy_da_count", { min: 1, max: 50, isFeet: false, applyArithmetic: true, notImperial: true, precision: 0 });
            var distance = spt.Utils.GetFloatFromInput("#copy_da_distance", { min: 0, max: 100000, isFeet: false, applyArithmetic: true, notImperial: true, precision: 0 });
            var direction = parseInt($("#copy_da_direction").val() as any) || 0;

            var dir: THREE.Vector3 = null;

            switch (direction) {
                case 0:
                    dir = new THREE.Vector3(0, 1, 0);
                    break;
                case 1:
                    dir = new THREE.Vector3(1, 0, 0);
                    break;
                case 2:
                    dir = new THREE.Vector3(0, -1, 0);
                    break;
                case 3:
                    dir = new THREE.Vector3(-1, 0, 0);
                    break;
            }

            if (dir)
                this.doDuplicate(count, distance, dir);

            this.ToggleCopyDirectedArea();
        }

        doDuplicate(count?: number, distance?: number, direction?: THREE.Vector3) {
            if (LoaderManager.isLoading())
                return;
            if (this.viewModel.selected && this.viewModel.selected.length) {
                this.viewModel.selectedToolUri = 'insertSingle?multiple=1';
                ko.tasks.runEarly();
            }
            if (this.viewModel.selectedTool)
                return;
            if (this.viewModel.selectedUi && this.viewModel.selectedUi.length)
                this.viewModel.selectedUi.forEach(s => s.duplicate(count, distance, direction));
        }

        DuplicateModules() {
            if (LoaderManager.isLoading())
                return;
            if (this.viewModel.selected && this.viewModel.selected.length) {
                this.viewModel.selectedToolUri = 'insertSingle?multiple=1';
            } else {
                DManager.ShowSmallInfo("Please select one or more modules.")
            }
        }

        SaveConstructionValues() {
            var controller = this;
            var viewModel = this.viewModel;

            var roofAreaStartX = spt.Utils.ConvertStringToValue(viewModel.RoofAreaStartX || "0");
            var roofAreaStartY = spt.Utils.ConvertStringToValue(viewModel.RoofAreaStartY || "0");
            var roofAreaDistanceToRoofBorder = spt.Utils.ConvertStringToValue(viewModel.RoofAreaDistanceToRoofBorder + '');
            var roofOrientation = spt.Utils.ConvertStringToValue(viewModel.RoofOrientation + '') || 0;
            var optimizeBlockLayout = !!$('#RoofArea_GenericRoofAreaSettings_OptimizeBlockLayout').is(':checked');
            if (roofAreaStartX !== null && roofAreaStartX >= -500000 && roofAreaStartY !== null && roofAreaStartY >= -500000) {
                if (roofAreaDistanceToRoofBorder === null || roofAreaDistanceToRoofBorder < 0)
                    roofAreaDistanceToRoofBorder = -1;

                LoaderManager.addLoadingJob();
                AManager.Ajax('AppServices.asmx', 'SaveKonstrukctionValuesAnordnung', JSON.stringify({ xstart: roofAreaStartX, ystart: roofAreaStartY, distanceToRoof: roofAreaDistanceToRoofBorder, roofOrientation: roofOrientation, optimizeBlockLayout: optimizeBlockLayout }), (result: string) => {
                    LoaderManager.finishLoadingJob();
                    if (result && result.indexOf('success') !== -1) {
                        var reloadRoof = result.indexOf('reloadRoof') !== -1;
                        var recreate = result.indexOf('recreate') !== -1;

                        if (recreate) {
                            controller.regenerateModulePlan(false,
                                reloadRoof
                                    ? () => {
                                        controller.reloadRoofObject();
                                    }
                                    : null);
                        } else {
                            controller.reloadRoofObject(true);
                        }

                    } else {
                        DManager.showErrorMessage(result);
                    }

                }, () => {
                    DManager.showErrorMessage("Error on saving construction values.");
                    LoaderManager.finishLoadingJob();
                });
            }
        }

        ShowModuleMetaDialog() {
            if (LoaderManager.isLoading())
                return;

            var ids = this.viewModel.selected.filter(sel => !sel._isDisposed && !!sel.instanceData).map(sel => sel.instanceData.Id);

            if (!ids.length) {
                DManager.ShowSmallInfo("Please select one or more modules.")
                return;
            }

            if (!$('#ModuleMetaEditDia').length) {
                DManager.showDialogDynamic("/Anordnung/GetEditModuleMetaDialog", "ModuleMetaEditDia", 'auto', 'auto', () => {
                    ko.applyBindings(Controller.Current.viewModel.moduleMetaEdit, $('#ModuleMetaEditDia').get(0));
                    this.GetModuleMetaEditData(ids);
                });
            } else {
                this.GetModuleMetaEditData(ids);
                DManager.show('ModuleMetaEditDia');
            }
        }

        GetModuleMetaEditData(ids: string[]) {
            var metaEdit = Controller.Current.viewModel.moduleMetaEdit;

            metaEdit.genericParams.unbindAll();
            metaEdit.moduleIds.removeAll();
            metaEdit.genericParams.setEntries([]);
            metaEdit.genericParams.overrideElementsCount = 0;

            AManager.AjaxWithLoading('AppServices.asmx', 'GetModuleMetaEdit', { ids: ids }, (result: { ModuleIds: string[], Entries: IClientGenericParamsEntry[], MetaType: string }) => {
                if (result && result.Entries && result.Entries.length) {
                    metaEdit.genericParams.setEntries(result.Entries);
                    metaEdit.moduleIds.splice.apply(metaEdit.moduleIds, ([0, 0] as any[]).concat(result.ModuleIds));
                    metaEdit.metaType = result.MetaType;
                    metaEdit.genericParams.overrideElementsCount = result.ModuleIds.length;

                    var params = metaEdit.params = {};
                    result.Entries.forEach(e => {
                        if (e.name)
                            params[e.name] = e.value === undefined ? null : e.value;
                    });
                    metaEdit.genericParams.bindObject(params);
                } else {
                    DManager.hide('ModuleMetaEditDia');
                }
            }, () => {
                DManager.showErrorMessage("Error on sending changes to the server.");
            });
        }

        SaveModuleMetaDialog() {
            if (LoaderManager.isLoading() || !$('#ModuleMetaEditDia').length)
                return;

            var metaEdit = Controller.Current.viewModel.moduleMetaEdit;

            DManager.hide('ModuleMetaEditDia');

            if (!metaEdit || !metaEdit.moduleIds.length)
                return;

            AManager.AjaxWithLoading('AppServices.asmx', 'SaveModuleMetas', { moduleIds: metaEdit.moduleIds, jsonData: JSON.stringify(metaEdit.params), metaType: metaEdit.metaType }, (result: string) => {
                Controller.Current.reloadModulePlan();
            }, () => {
                DManager.showErrorMessage("Error on sending changes to the server.");
            });
        }

        createTransformControls() {
            var tc = new THREE.TransformControls(this.camera, this.renderer.domElement);

            tc.renderOrder = LS.Client3DEditor.UIRenderOrder;
            //var target = new THREE.Object3D();

            //tc.attach(target);

            if (THREE.TransformControls.prototype.dispose) {
                tc.dispose = function () {
                    THREE.TransformControls.prototype.dispose.apply(tc, arguments);
                    var idx = Controller.Current.transformControlers.indexOf(tc);
                    if (idx !== -1)
                        Controller.Current.transformControlers.splice(idx, 1);
                    Controller.Current.scene.remove(tc);
                    //Controller.Current.scene.remove(target);
                };
            }
            else if (tc.dispose) {
                var tcDispose = tc.dispose;
                tc.dispose = function () {
                    tcDispose.apply(tc, arguments);
                    var idx = Controller.Current.transformControlers.indexOf(tc);
                    if (idx !== -1)
                        Controller.Current.transformControlers.splice(idx, 1);
                    Controller.Current.scene.remove(tc);
                    //Controller.Current.scene.remove(target);
                };
            }
            else {
                tc.dispose = () => {
                    var idx = Controller.Current.transformControlers.indexOf(tc);
                    if (idx !== -1)
                        Controller.Current.transformControlers.splice(idx, 1);
                    Controller.Current.scene.remove(tc);
                    //Controller.Current.scene.remove(target);
                };
            }

            //tc.addEventListener('mouseDown', (event) => {
            //    if (tc.enabled)
            //        tc.ctrlDown = this.viewModel.ctrlDown;
            //});

            tc.addEventListener('dragging-changed', (event) => {

                var en = !event.value && this.viewModel.tool == null;

                tc.ctrlDown = event.value && this.viewModel.ctrlDown;

                this.transformControlers.forEach(t => {
                    if (t !== tc)
                        t.enabled = en;
                });

                var viewModel = this.viewModel;

                viewModel.canSelect = false;
                viewModel.canDrag = false;
                viewModel.isSelecting = false;
                viewModel.isDragging = false;

                setTimeout(() => {
                    viewModel.canSelect = false;
                    viewModel.canDrag = false;
                    viewModel.isSelecting = false;
                    viewModel.isDragging = false;
                    this.notifyRefreshView();
                }, 0);
            });

            //tc.addEventListener('objectChange', function(event) {
            //    console.log('objectChange' + (tt++));
            //});

            if (this.camera === this.perspectiveCamera) {
                tc.setSize(0.5);
            } else {
                tc.setSize(0.1);
            }

            this.transformControlers.push(tc);
            this.scene.add(tc);
            //this.scene.add(target);
            return tc;
        }

        setTransformControlsEnabled(b: boolean) {
            var enabled = !!b;
            this.transformControlers.forEach(tc => {
                tc.enabled = enabled;
            });
        }

        private _box1 = new THREE.Box3();
        private _box2 = new THREE.Box3();
        private _v1 = new THREE.Vector3();
        private _v2 = new THREE.Vector3();
        private _v3 = new THREE.Vector3();
        private _edgeDir = new THREE.Vector3();
        private _closestStart = new THREE.Vector3();
        private _closestEnd = new THREE.Vector3();

        searchEdge(position?: THREE.Vector3, edgePadding = 0) {
            var viewModel = this.viewModel,
                instanceContext = viewModel.currentInstance,
                rp = position || viewModel.roofPosition,
                camera = this.orthoCamera,
                tolerance = this.ClickTolerance / camera.zoom,
                toleranceSq = tolerance * tolerance,
                box1 = this._box1,
                box2 = this._box2,
                v1 = this._v1,
                v2 = this._v2,
                v3 = this._v3,
                edgeDir = this._edgeDir,
                closestStart = this._closestStart,
                closestEnd = this._closestEnd;

            box1.min.set(rp.x - tolerance, rp.y - tolerance, -camera.far);
            box1.max.set(rp.x + tolerance, rp.y + tolerance, camera.far);

            var distance = Number.MAX_VALUE;
            var segmentFound = false;
            var radius = box1.getSize(v2).setZ(0).length() * 0.5;
            var octreeObjects = instanceContext.searchOctree(rp, radius, false);

            for (var i = octreeObjects.length; i--;) {
                var object = octreeObjects[i] as ClientObjectInstance;

                box2.copy(object.geometry.boundingBox).translate(object.position);
                if (object.parent)
                    box2.applyMatrix4(object.parent.matrixWorld);

                if (edgePadding)
                    box2.expandByScalar(edgePadding);

                if (box1.intersectsBox(box2)) {

                    v1.copy(rp).clamp(box2.min, box2.max).setZ(0); // vertical pos
                    v2.copy(v1); // horizontal pos

                    v1.x += (Math.abs(box2.min.x - v1.x) < Math.abs(box2.max.x - v1.x)) ? box2.min.x - v1.x : box2.max.x - v1.x;
                    v2.y += (Math.abs(box2.min.y - v2.y) < Math.abs(box2.max.y - v2.y)) ? box2.min.y - v2.y : box2.max.y - v2.y;

                    //if (spacingMode === this.spacingModeVertical)
                    //    v1.x += (Math.abs(box2.min.x - v1.x) < Math.abs(box2.max.x - v1.x)) ? box2.min.x - v1.x : box2.max.x - v1.x;
                    //if (spacingMode === this.spacingModeHorizontal)
                    //    v1.y += (Math.abs(box2.min.y - v1.y) < Math.abs(box2.max.y - v1.y)) ? box2.min.y - v1.y : box2.max.y - v1.y;

                    var distV = v3.copy(v1).sub(rp).lengthSq();
                    var distH = v3.copy(v2).sub(rp).lengthSq();

                    var dist: number,
                        isVertical = true;

                    if (distV < distH) {
                        dist = distV;
                    } else {
                        isVertical = false;
                        dist = distH;
                        v1.copy(v2);
                    }

                    if (dist < distance && dist <= toleranceSq) {
                        distance = dist;
                        //clientObjectId = object.instanceData.Id;

                        var bz = (box2.min.z + box2.max.z) * 0.5;

                        if (isVertical) {
                            closestStart.copy(box2.min).setX(v1.x).setZ(bz);
                            closestEnd.copy(box2.max).setX(v1.x).setZ(bz);

                            if (Math.abs(v1.x - box2.min.x) < Math.abs(v1.x - box2.max.x)) {
                                // left edge
                                edgeDir.set(-1, 0, 0);
                            } else {
                                // right edge
                                edgeDir.set(1, 0, 0);
                            }
                        }
                        else {
                            closestStart.copy(box2.min).setY(v1.y).setZ(box2.min.z).setZ(bz);
                            closestEnd.copy(box2.max).setY(v1.y).setZ(bz);

                            if (Math.abs(v1.y - box2.min.y) < Math.abs(v1.y - box2.max.y)) {
                                // lower edge
                                edgeDir.set(0, -1, 0);
                            } else {
                                // upper edge
                                edgeDir.set(0, 1, 0);
                            }
                        }

                        segmentFound = true;
                    }
                }
            }

            if (!segmentFound)
                return null;

            return {
                edgeDir: edgeDir,
                closestStart: closestStart,
                closestEnd: closestEnd
            };
        }

        testScene(scene: THREE.Scene) {

            function createGeometry(sizing) {

                var geometry = new THREE.CylinderBufferGeometry(
                    sizing.height / 32 * 5, // radiusTop
                    sizing.height / 32 * 5, // radiusBottom
                    sizing.height, // height
                    8, // radialSegments
                    sizing.segmentCount * 3, // heightSegments
                    true // openEnded
                );

                var position = geometry.attributes.position as THREE.BufferAttribute;

                var vertex = new THREE.Vector3();

                var skinIndices = [];
                var skinWeights = [];

                for (var i = 0; i < position.count; i++) {

                    vertex.fromBufferAttribute(position, i);

                    var y = (vertex.y + sizing.halfHeight);

                    var skinIndex = Math.floor(y / sizing.segmentHeight);
                    var skinWeight = (y % sizing.segmentHeight) / sizing.segmentHeight;

                    skinIndices.push(skinIndex, skinIndex + 1, 0, 0);
                    skinWeights.push(1 - skinWeight, skinWeight, 0, 0);

                }

                geometry.setAttribute('skinIndex', new THREE.Uint16BufferAttribute(skinIndices, 4));
                geometry.setAttribute('skinWeight', new THREE.Float32BufferAttribute(skinWeights, 4));

                return geometry;

            }

            var bones: THREE.Bone[];

            function createBones(sizing) {

                bones = [];

                var prevBone = new THREE.Bone();
                bones.push(prevBone);
                prevBone.position.y = - sizing.halfHeight;

                for (var i = 0; i < sizing.segmentCount; i++) {

                    var bone = new THREE.Bone();
                    bone.position.y = sizing.segmentHeight;
                    bones.push(bone);
                    prevBone.add(bone);
                    prevBone = bone;

                }

                return bones;

            }

            var skeletonHelper: THREE.SkeletonHelper;
            var skeleton: THREE.Skeleton;

            function createMesh(geometry, bones: THREE.Bone[]) {

                var material = new THREE.MeshPhongMaterial({
                    skinning: true,
                    color: 0x156289,
                    emissive: 0x072534,
                    side: THREE.DoubleSide,
                    flatShading: true
                });

                var mesh = new THREE.SkinnedMesh(geometry, material);
                skeleton = new THREE.Skeleton(bones);

                mesh.add(bones[0]);

                mesh.bind(skeleton);

                skeletonHelper = new THREE.SkeletonHelper(mesh);
                (<THREE.LineMaterial>skeletonHelper.material).linewidth = 2;
                scene.add(skeletonHelper);

                return mesh;

            }

            var mesh: THREE.SkinnedMesh;

            var segmentHeight = 800;
            var segmentCount = 4;
            var height = segmentHeight * segmentCount;
            var halfHeight = height * 0.5;

            var sizing = {
                segmentHeight: segmentHeight,
                segmentCount: segmentCount,
                height: height,
                halfHeight: halfHeight
            };

            var geometry = createGeometry(sizing);
            var bones = createBones(sizing);
            mesh = createMesh(geometry, bones);
            //mesh.bindMode = "detached";
            mesh.position.x += 1000;
            scene.add(mesh);

            skeleton.bones[0].rotation.x = -0.1;
            skeleton.bones[1].rotation.x = 0.2;

        }
    }

    EventManager.apply(Controller.prototype);
}

interface Window {
    Controller: typeof LS.Client3DEditor.Controller;
}

window.Controller = LS.Client3DEditor.Controller;