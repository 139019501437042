module LS.Client3DEditor {

    export interface IObjectIntersection<T> {
        o: T;
        p: THREE.Vector3;
        distSq: number;
        l?: number;
        idx?: number;
    }

    export interface IGenericObjectInstance extends THREE.Object3D, LS.Client3DEditor.ISelectableUi {
        IdString: string;
        update(): void;
        dispose(): void;

        intersecsBox(searchBox: THREE.Box3): boolean;

        getClosestPoint(p: THREE.Vector3, thresholdSq: number): IObjectIntersection<this>;
        getClosestPointToRay(ray: THREE.Ray, threshold: number): IObjectIntersection<this>;

        getPoints(): THREE.Vector3[];
        getLines(): THREE.Line3[];

    }

    export class GenericObjectInstance extends THREE.Object3D implements IGenericObjectInstance {
        private _isSelected: boolean = false;
        private _isHovered: boolean = false;
        private _isHidden: boolean = false;
        _isDisposed: boolean = false;
        _needsUpdate: boolean = true;
        IdString: string;

        constructor(id?: string) {
            super();
            this.IdString = id || spt.Utils.GenerateGuid();
        }

        get isSelected() {
            return this._isSelected;
        }

        set isSelected(v: boolean) {
            if (this._isSelected !== v) {
                this._isSelected = v;
                this._needsUpdate = true;
            }
        }

        get isHovered() {
            return this._isHovered;
        }

        set isHovered(v: boolean) {
            if (this._isHovered !== v) {
                this._isHovered = v;
                this._needsUpdate = true;
            }
        }

        get isHidden() {
            return this._isHidden;
        }

        set isHidden(v: boolean) {
            if (this._isHidden !== v) {
                this._isHidden = v;
                this._needsUpdate = true;
            }
        }

        getObjectHolder(): GenericObjectHolder<GenericObjectInstance> {
            return null;
        }
        OnChanged(viewModel?: ViewModel): void {
            this.update();
        }
        clear(): void {
            if (this._isDisposed)
                return;

            spt.ThreeJs.utils.emptyObject3D(this, true, true, false);
        }
        update(): void {
            if (this._isDisposed || !this._needsUpdate)
                return;
            throw new Error("Method not implemented.");
        }
        build(): void {
            if (this._isDisposed)
                return;
            throw new Error("Method not implemented.");
        }
        dispose(): void {
            if (!this._isDisposed) {
                this.clear();
                this._isDisposed = true;
            }
        }
        getBounds(): THREE.Box3 {
            return new THREE.Box3();
        }
        getPointOffset(): THREE.Vector3 {
            return new THREE.Vector3();
        }
        getPoints(): THREE.Vector3[] {
            return [];
        }
        getLines(): THREE.Line3[] {
            return [];
        }

        intersecsBox(searchBox: THREE.Box3): boolean {
            if (this._isDisposed || !this.visible)
                return false;

            var bounds = this.getBounds();
            if (!this._isDisposed && this.visible && bounds && !bounds.isEmpty() && bounds.intersectsBox(searchBox)) {
                var lines = this.getLines();
                if (!lines.length)
                    return true;
                return lines.some(seg => spt.ThreeJs.utils.LineIntersecsBox(seg.start, seg.end, searchBox));
            }
            return false;
        }

        protected _v1 = new THREE.Vector3();
        protected _v2 = new THREE.Vector3();

        getClosestPoint(p: THREE.Vector3, thresholdSq: number): IObjectIntersection<this> {
            if (this._isDisposed || !this.visible)
                return null;

            var points = this.getPoints(),
                pointsCount = points.length,
                off = this.getPointOffset(),
                pos1 = this._v1,
                pos2 = this._v2,
                result = null as IObjectIntersection<this>,
                closestDistance: number = Number.MAX_VALUE;

            if (pointsCount > 0) {

                for (var i = pointsCount; i--;) {
                    var tp = pos1.copy(points[i]).add(off),
                        lsq = tp.distanceToSquared(p);
                    if (lsq <= thresholdSq && lsq < closestDistance) {
                        closestDistance = lsq;
                        result = {
                            o: this,
                            p: tp,
                            distSq: lsq,
                            idx: i
                        };
                    }
                }
            }

            if (result == null) {
                var lines = this.getLines(),
                    linesCount = lines.length;

                if (linesCount > 0) {
                    for (var i = linesCount; i--;) {
                        var line = lines[i];

                        pos1.copy(line.start).add(off);
                        pos2.copy(line.end).add(off);

                        var cp = spt.ThreeJs.utils.GetClosestPointToLine(pos1, pos2, p),
                            dsq = cp.p.distanceToSquared(p);
                        if (dsq <= thresholdSq && dsq < closestDistance) {
                            closestDistance = dsq;
                            result = {
                                o: this,
                                p: cp.p,
                                distSq: dsq,
                                idx: i,
                                l: cp.l
                            };
                        }
                    }
                }
            }

            return result;
        }
        getClosestPointToRay(ray: THREE.Ray, threshold: number): IObjectIntersection<this> {
            if (this._isDisposed || !this.visible)
                return null;

            var points = this.getPoints(),
                p = points.length ? points[0].clone() : new THREE.Vector3(),
                off = this.getPointOffset(),
                inter = ray.intersectPlane(new THREE.Plane().setFromNormalAndCoplanarPoint(new THREE.Vector3(0, 0, 1), p.add(off)), new THREE.Vector3());

            if (inter)
                return this.getClosestPoint(inter, threshold * threshold);

            return null;
        }
        moveBy(v: THREE.Vector3): void {

        }
        removeInstance(): void {
            var objectHolder = this.getObjectHolder();
            if (objectHolder)
                objectHolder.removeObject(this);
            this.dispose();
        }
        GetPositionProperty(k: string): number {
            return this.position[k];
        }
        GetSizeProperty(k: string): number {
            return 0;
        }
        applyObjectPosition(): void {

        }
        duplicate(count?: number, distance?: number, direction?: THREE.Vector3): void {
            return;
        }
        rotateByPivot(pivot: THREE.Vector3, angleRad: number) {
            return;
        }
        getPivot(): THREE.Vector3 {
            return new THREE.Vector3();
        }

    }

    export class GenericObjectHolder<T extends IGenericObjectInstance> extends THREE.Object3D {
        hoverObject: T;

        onObjectRemoved(so: T) { }
        onObjectAdded(so: T) { }

        setHoverObject(o: IObjectIntersection<T>) {
            if (this.hoverObject) {
                this.hoverObject.isHovered = false;
                this.hoverObject.OnChanged();
                this.hoverObject = null;
            }
            if (o && o.o) {
                o.o.isHovered = true;
                o.o.OnChanged();
                this.hoverObject = o.o;
            }
        }

        removeObject(so: T) {
            this.remove(so);
            so.dispose();
            this.onObjectRemoved(so);
        }

        addObject(so: T) {
            this.add(so);
            this.onObjectAdded(so);
        }

        updateObjects() {
            if (!this.children.length)
                return;

            var mObjs = this.children;
            for (var i = mObjs.length; i--;) {
                var mObj = mObjs[i] as T;
                mObj.update();
            }
        }

        clear() {
            var mObjs = this.children;
            for (var i = mObjs.length; i--;) {
                var mObj = mObjs[i] as T;
                mObj.dispose();
            }
            spt.ThreeJs.utils.emptyObject3D(this, true, true);
        }

        getClosestByPosition(p: THREE.Vector3, tolerance: number): IObjectIntersection<T> {
            var sos = this.getByBox(new THREE.Box3().expandByPoint(p).expandByScalar(tolerance)),
                minDist = Number.MAX_VALUE,
                thresholdSq = tolerance * tolerance,
                result = null as IObjectIntersection<T>;

            sos.forEach(so => {
                var cp = so.getClosestPoint(p, thresholdSq);
                if (cp && cp.distSq < thresholdSq && cp.distSq < minDist) {
                    minDist = cp.distSq;
                    result = cp;
                }

            });

            return result;
        }

        getByBox(searchBox: THREE.Box3): T[] {
            if (this.children.length)
                return this.children.filter(mObj => (mObj as T).intersecsBox(searchBox)) as T[];
            return [];
        }

        getByRaycaster(raycaster: THREE.Raycaster, tolerance?: number): IObjectIntersection<T> {
            var ray = raycaster.ray;

            var threshold = (tolerance || raycaster.params.Line.threshold),
                thresholdSq = threshold * threshold;

            var sos = this.children;

            var result = null as IObjectIntersection<T>;
            var minDist = Number.MAX_VALUE;

            for (var i = sos.length; i--;) {
                var so = sos[i] as T;

                var cp = so.getClosestPointToRay(ray, threshold);
                if (cp && cp.distSq < thresholdSq && cp.distSq < minDist) {
                    minDist = cp.distSq;
                    result = cp;
                }
            }

            return result;
        }

        getByTestVolume(testVolume: spt.ThreeJs.utils.TestVolume): T[] {
            //if (this.children.length)
            //    return this.children.filter(mObj => (mObj as T).intersecsTestVolume(testVolume)) as T[];
            return [];
        }

    }
}