module LS.Client3DEditor {
    export interface IEditableObject extends THREE.Object3D {
        _isDisposed: boolean;
        editable: boolean;
        visible: boolean;
        draggable: boolean;
        hoveredPoint: number;
        hovered: boolean;
        update();
        clickable: boolean;
        intersecsRaycaster(worldRayCaster: THREE.Raycaster): IEditableSelection;
        getLocalPosition(worldRayCaster: THREE.Raycaster): THREE.Vector3;
        userEdited: boolean;
        userCallBack?: () => void;
        userMoved?: () => void;
        dispose();
        getPoint(i: number): ObservableVector3;
        setPoint(i: number, x: number, y: number, z?: number);
        setPosition(v: THREE.Vector3);
        applyMatrix4(m: THREE.Matrix4): void;
    }

    export interface IEditableSelection {
        pt: number;
        Edge: [number, number];
        surface: boolean;
        distSq: number;
        source: IEditableObject;
    }

    export class EditableHandle extends THREE.Object3D implements IEditableObject {
        //static HandleTypePoint = 0;

        editable = false;
        hovered = false;
        clickable = true;
        draggable = true;
        //handleType = 0;

        get hoveredPoint() {
            return this.hovered ? 0 : -1;
        }

        set hoveredPoint(v: number) {
            this.hovered = (v !== -1);
        }

        private _point: ObservableVector3 = null;
        //private _direction: ObservableVector3 = null;
        private _subscriptions: KnockoutSubscription[] = [];
        //private _dirSubscriptions: KnockoutSubscription[] = [];
        private _geometryNeedsUpdate = true;
        private _materialNeedsUpdate = true;
        private _bd = new THREE.Box3();
        private _v1 = new THREE.Vector3();
        private _v2 = new THREE.Vector3();
        private _mat = new THREE.Matrix4();
        private _ray = new THREE.Ray();
        private _plane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);
        _isDisposed: boolean;

        private _pointMesh: THREE.Points;
        //private _edge: THREE.Line2;
        //private _pointer: THREE.Mesh;

        get point() {
            return this._point;
        }

        set point(p: ObservableVector3) {
            if (this._point !== p) {
                var pointChanged = this.onGeometryChanged.bind(this),
                    subs = this._subscriptions;
                if (subs.length) {
                    subs.forEach(sub => sub.dispose());
                    subs = this._subscriptions = [];
                }
                this._point = p || null;
                if (p) {
                    subs.push(ko.getObservable(p, 'x').subscribe(pointChanged));
                    subs.push(ko.getObservable(p, 'y').subscribe(pointChanged));
                    subs.push(ko.getObservable(p, 'z').subscribe(pointChanged));
                }

                this.onGeometryChanged();
            }
        }

        constructor(p?: ObservableVector3, userCallBack?: () => void, userMoved?: () => void) {
            super();

            var updateGeometry = this.onGeometryChanged.bind(this);
            var updateMaterial = this.onMaterialChanged.bind(this);

            ko.track(this, ["clickable", "draggable", "editable", "visible", "hovered"]);

            //ko.getObservable(this, "handleType").subscribe(updateGeometry);
            ko.getObservable(this, "editable").subscribe(updateGeometry);
            ko.getObservable(this, "hovered").subscribe(updateMaterial);

            if (p)
                this.point = p;
            if (userCallBack)
                this.userCallBack = userCallBack;
            if (userMoved)
                this.userMoved = userMoved;

            Controller.Current.viewModel.editableObjectsManager.editableObjects.push(this);
        }

        onGeometryChanged() {
            this._geometryNeedsUpdate = true;
            this._materialNeedsUpdate = true;
        }

        onMaterialChanged() {
            this._materialNeedsUpdate = true;
        }

        update() {
            if (this._geometryNeedsUpdate) {
                this._geometryNeedsUpdate = false;
                this.updateGeometry();
            }
            if (this._materialNeedsUpdate) {
                this._materialNeedsUpdate = false;
                this.updateMaterial();
            }
        }

        intersecsRaycaster(worldRayCaster: THREE.Raycaster) {
            var controller = Controller.Current,
                point = this.point,
                clickTolerance = EditablePolygon2D.ClickTolerance / controller.orthoCamera.zoom,
                clickToleranceSq = clickTolerance * clickTolerance,
                inverseMatrix = this._mat.getInverse(this.matrixWorld),
                localRay = this._ray.copy(worldRayCaster.ray).applyMatrix4(inverseMatrix),
                v1 = this._v1;

            var bounds = this._bd.set(point, point).expandByScalar(clickTolerance);

            if (localRay.intersectBox(bounds, v1)) {
                var dist = Number.MAX_VALUE,
                    res: IEditableSelection = { pt: -1, Edge: null, surface: false, distSq: 0, source: this },
                    d: number = localRay.distanceSqToPoint(v1.copy(point).setZ(0));

                if (d < clickToleranceSq && d < dist) {
                    dist = d;
                    res.surface = true;
                    res.distSq = dist;
                }

                if (res.surface)
                    return res;

            }

            return null;
        }

        getLocalPosition(worldRayCaster: THREE.Raycaster) {
            var inverseMatrix = this._mat.getInverse(this.parent ? this.parent.matrixWorld : this.matrixWorld),
                localRay = this._ray.copy(worldRayCaster.ray).applyMatrix4(inverseMatrix),
                plane = this._plane;

            return localRay.intersectPlane(plane, this._v2);
        }

        userEdited = false;
        userCallBack?: () => void;
        userMoved?: () => void;

        updateGeometry() {
            var point = this._point;

            if (!this.editable) {
                this.visible = false;
                return;
            }

            this.visible = true;

            if (!this._pointMesh)
                this.recreate();

            var pointMesh = this._pointMesh,
                cornerGeo = (pointMesh && pointMesh.geometry) as THREE.BufferGeometry;

            if (cornerGeo) {
                var positions = cornerGeo.getAttribute('position') as THREE.BufferAttribute,
                    colors = cornerGeo.getAttribute('color') as THREE.BufferAttribute,
                    arr = positions.array as Float32Array,
                    colArr = colors.array as Float32Array;

                var idx = 0;

                arr[idx] = point.x;
                arr[idx + 1] = point.y;
                //arr[idx + 2] = p.z;

                colArr[idx] = 0.75;
                colArr[idx + 1] = 0.75;
                colArr[idx + 2] = 0.75;

                positions.needsUpdate = true;
                colors.needsUpdate = true;

                cornerGeo.computeBoundingSphere();
            }
        }

        private recreate() {
            this.children.slice().forEach(ch => {
                this.remove(ch);
                spt.ThreeJs.utils.disposeObject3D(ch, true, true, false);
            });

            var cornergeo = new THREE.BufferGeometry();
            if (!cornergeo.boundingSphere)
                cornergeo.boundingSphere = new THREE.Sphere();

            var positionAttribute = new THREE.BufferAttribute(new Float32Array(3), 3).setUsage(THREE.DynamicDrawUsage);
            var colorAttribute = new THREE.BufferAttribute(new Float32Array(3), 3).setUsage(THREE.DynamicDrawUsage);

            cornergeo.setAttribute('position', positionAttribute);
            cornergeo.setAttribute('color', colorAttribute);

            var point = this._pointMesh = new THREE.Points(cornergeo, EditablePolygon2D.getCircleMaterial());

            point.renderOrder = LS.Client3DEditor.UIRenderOrder;

            this.add(point);

            //if (this.handleType === EditableHandle.HandleTypeAxis) {
            //    //edges
            //    var array = new Float32Array(2 * 3);
            //    var edgeGeo = new THREE.LineGeometry();
            //    if (!edgeGeo.boundingSphere)
            //        edgeGeo.boundingSphere = new THREE.Sphere();
            //    edgeGeo.setPositions(array);

            //    var startPositions = edgeGeo.getAttribute('instanceStart') as THREE.InterleavedBufferAttribute;
            //    //var endPositions = edgeGeo.getAttribute('instanceEnd') as THREE.InterleavedBufferAttribute;

            //    var instanceBuffer = startPositions.data as THREE.InstancedInterleavedBuffer;
            //    instanceBuffer.setUsage(THREE.DynamicDrawUsage);

            //    var line = this._edge = new THREE.Line2(edgeGeo, EditablePolygon2D.lineMaterial);
            //    line.computeLineDistances();

            //    line.renderOrder = LS.Client3DEditor.UIRenderOrder - 1;

            //    this.add(line);
            //}

        }

        updateMaterial() {
            if (!this.editable)
                return;

            var pointMesh = this._pointMesh,
                hovered = this.hovered,
                clickable = this.clickable,
                cornerGeo = (pointMesh && pointMesh.geometry) as THREE.BufferGeometry;

            if (cornerGeo) {
                var colors = cornerGeo.getAttribute('color') as THREE.BufferAttribute;

                if (hovered)
                    colors.setXYZ(0, 1, 1, 1);
                else
                    colors.setXYZ(0, 0.75, 0.75, 0.75);

                colors.needsUpdate = true;
            }

        }

        getPoint(i: number): ObservableVector3 {
            return this.point;
        }

        setPoint(i: number, x: number, y: number, z?: number) {
            var p = this.point;
            p.x = x;
            p.y = y;
            if (z !== undefined)
                p.z = z;
        }

        setPosition(v: THREE.Vector3) {
            this.position.copy(v);
        }

        applyMatrix4(m: THREE.Matrix4) {
            this.point.applyMatrix4(m);
        }

        dispose() {
            if (this._isDisposed)
                return;

            this._isDisposed = true;

            if (this._subscriptions.length) {
                this._subscriptions.forEach(sub => sub.dispose());
                this._subscriptions = null;
            }

            Controller.Current.viewModel.editableObjectsManager.editableObjects.remove(this);

            this.children.slice().forEach(ch => {
                spt.ThreeJs.utils.disposeObject3D(ch, true, true, false);
            });
        }
    }

    export interface ILineStepping {
        readonly lineLength: number;
    }

    export interface ICurveStepping {
        readonly curveDegree: number; //]-180 ... +180]
        readonly curvePaddingStart: number;
        readonly curvePaddingEnd: number;
    }

    export class LineSteppingCalculator {
        private _lineSteppings: ILineStepping[] = [];
        private _curveSteppings: ICurveStepping[] = [];

        private combinations: number[][];
        private combinationMax: number = 0;
        private _v1 = new THREE.Vector3();
        private _v2 = new THREE.Vector3();
        private _v3 = new THREE.Vector3();
        private _v4 = new THREE.Vector3();
        private _v5 = new THREE.Vector3();
        private _r1 = new THREE.Ray();
        private _p1 = new THREE.Plane();

        maxNumber: number;
        maxCombinations: number;

        constructor(maxNumber?: number, maxCombinations?: number) {
            this.maxNumber = maxNumber || 10;
            this.maxCombinations = maxCombinations || 1024;
        }

        get lineSteppings() {
            return this._lineSteppings;
        }

        set lineSteppings(val: ILineStepping[]) {

            if (val && val.length) {
                //distinct
                var v = val.filter((ls, idx) => val.findIndex(ls2 => ls.lineLength === ls2.lineLength) === idx);
                //sort
                v.sort((a, b) => a.lineLength - b.lineLength);
                this._lineSteppings = v;
            }

            this.updateLineSteppings();
        }

        updateLineSteppings() {
            var lineSteppings = this.lineSteppings;
            if (!lineSteppings || !lineSteppings.length) {
                this.combinations = [];
                this.combinationMax = 0;
                return;
            }

            if (lineSteppings.length === 1)
                this.combinations = [[1, lineSteppings[0].lineLength]];
            else
                this.combinations = ArrayHelper.calculateCombinations(lineSteppings.map(ls => ls.lineLength), this.maxNumber, this.maxCombinations);

            this.combinationMax = this.combinations[this.combinations.length - 1][lineSteppings.length];
        }

        get curveSteppings() {
            return this._curveSteppings;
        }

        set curveSteppings(val: ICurveStepping[]) {
            if (val && val.length) {
                //clamp degree
                //val.forEach(cs => {
                //    cs.degree = Math.min(180, Math.max(0.001, Math.abs(cs.degree)));
                //    cs.padding = Math.max(0, cs.padding);
                //});
                //distinct
                var v = val.filter((cs, idx) => val.findIndex(cs2 => cs.curveDegree === cs2.curveDegree) === idx);
                //sort
                v.sort((a, b) => a.curveDegree - b.curveDegree);
                this._curveSteppings = v;
            }

            //this.updateCurveSteppings();
        }

        //updateCurveSteppings() {
        //    var curveSteppings = this._curveSteppings;
        //    if (curveSteppings.every(cs => cs.curveDegree !== 180))
        //        curveSteppings.push({ curveDegree: 180, curvePaddingStart: 0, curvePaddingEnd: 0 });
        //}

        calculateLength(length: number, lineSteppings?: ILineStepping[]): number[] {
            if (!lineSteppings)
                lineSteppings = this.lineSteppings;

            if (!lineSteppings || !lineSteppings.length)
                return null;

            var result = lineSteppings.map(() => 0 as number),
                steppingMaxLength = this.combinationMax,
                smallestLength = lineSteppings[0].lineLength;

            if (length <= smallestLength) {
                result[0] = 1;
                return result;
            }

            var biggestLength = lineSteppings[lineSteppings.length - 1].lineLength;

            while (length > steppingMaxLength && length > biggestLength) {
                length -= biggestLength;
                result[result.length - 1]++;
            }

            var steppingMap = this.combinations;

            if (!steppingMap || !steppingMap.length)
                return result;

            var lIdx = lineSteppings.length;
            var idx = ArrayHelper.binarySearchClosestIndex(steppingMap, length, (s => s[lIdx]));
            return steppingMap[idx].slice(0, lIdx).map((num, i) => num + result[i]);
        }

        getLength(combination: number[], lineSteppings?: ILineStepping[]) {
            if (!combination)
                return 0;

            if (!lineSteppings)
                lineSteppings = this.lineSteppings;

            var result = 0;

            for (var i = 0, l = combination.length; i < l; i++) {
                result += combination[i] * lineSteppings[i].lineLength;
            }

            return result;
        }

        getSteppings(combination: number[], lineSteppings?: ILineStepping[]) {

            var result: { count: number, lineStepping: ILineStepping }[] = [];

            if (!combination)
                return result;

            if (!lineSteppings)
                lineSteppings = this.lineSteppings;

            for (var i = 0, l = combination.length; i < l; i++) {
                result.push({
                    count: combination[i],
                    lineStepping: lineSteppings[i]
                });
            }

            return result;
        }

        getCurveStepping(pFrom: THREE.Vector3, pTo: THREE.Vector3, dirFrom: THREE.Vector3) {
            var curveSteppings = this.curveSteppings;
            if (!curveSteppings.length)
                return null;

            var ray = this._r1,//.set(pFrom, dirFrom),
                closest = this._v1.subVectors( pTo, pFrom ), //ray.closestPointToPoint(pTo, this._v1);
                dirDist = closest.dot( dirFrom );

            if (dirDist < 1) {
                var dr = this._v2.set(dirFrom.y, -dirFrom.x, 0).normalize();
                
                var rDist = dr.dot(closest);
                if (Math.abs(rDist) > 1) {
                    dirDist = 1;
                    pTo = pFrom.clone().add(dr.multiplyScalar(rDist).add(dirFrom/*.multiplyScalar(dirDist)*/));
                }
            }

            closest.copy( dirFrom ).multiplyScalar( Math.max(1, dirDist) ).add( pFrom );

            if (closest.distanceToSquared(pFrom) < 1 || closest.distanceToSquared(pTo) < 1)
                return null;

            var plane = this._p1.setFromNormalAndCoplanarPoint(this._v2.subVectors(pTo, closest).normalize(), closest),
                degToRad = THREE.MathUtils.DEG2RAD,
                dirTo = this._v3,
                cPos = this._v4,
                len = Number.MAX_VALUE,
                lenTo = 0,
                lenFrom = 0,
                curve: ICurveStepping = null;

            curveSteppings.forEach(curveStepping => {
                ray.set(pTo, spt.ThreeJs.utils.RotateCC(dirTo.copy(dirFrom).negate(), curveStepping.curveDegree * degToRad).negate());
                if (ray.intersectPlane(plane, cPos) && dirFrom.dot(dirTo.subVectors(cPos, pFrom)) > 0) {
                    var l1 = cPos.distanceTo(pTo);
                    if (l1 >= curveStepping.curvePaddingEnd) {
                        var l2 = cPos.distanceTo(pFrom),
                            l = l1 + l2;
                        if (l2 >= 0 && l < len) {
                            curve = curveStepping;
                            len = l;
                            lenTo = l1;
                            lenFrom = l2;
                        }
                    }
                }
            });

            return curve ? {
                curveStepping: curve,
                lenTo: Math.max(0, lenTo - curve.curvePaddingEnd),
                lenFrom: Math.max(0, lenFrom - curve.curvePaddingStart)
            } : null;

        }

        getCurveTo(pFrom: THREE.Vector3, pTo: THREE.Vector3, dirFrom: THREE.Vector3) {
            var curveSteppings = this.curveSteppings;
            if (!curveSteppings.length)
                return null;
            
            if (curveSteppings && curveSteppings.length > 1) {
                var deg = spt.ThreeJs.utils.GetCCAngleRad(this._v1.copy(dirFrom).negate(), this._v2.subVectors(pTo, pFrom).normalize()) * 180 / Math.PI,
                    absDeg = Math.abs(deg);
                if (absDeg > 0 && absDeg < 180) {
                    deg = (deg + 360) % 360; //spt.ThreeJs.utils.DegreeToPositive(deg);
                    var res = ArrayHelper.byMin(curveSteppings, cs => Math.abs(cs.curveDegree - deg));
                    if (res.curveDegree !== 180 && Math.abs(res.curveDegree - deg) < Math.abs(180 - deg))
                        return res;
                }
            }
            return null;
        }

        getPosition(target: THREE.Vector3, prev1: THREE.Vector3, prev2?: THREE.Vector3) {
            var curveSteppings = this.curveSteppings,
                lineSteppings = this.lineSteppings,
                dir1 = this._v1.subVectors(target, prev1),
                dLen = dir1.length(),
                pad = 0;
            dir1.multiplyScalar(1 / dLen);

            if (prev2 && curveSteppings && curveSteppings.length > 1) {
                var dir2 = this._v2.subVectors(prev2, prev1).normalize(),
                    deg = spt.ThreeJs.utils.GetCCAngleRad(dir2, dir1) * 180 / Math.PI,
                    absDeg = Math.abs(deg);
                if (absDeg > 0 && absDeg < 180) {

                    var curveStepping = ArrayHelper.byMin(curveSteppings, cs => Math.abs(cs.curveDegree - deg));

                    deg = curveStepping.curveDegree;

                    var axis = this._v3.crossVectors(dir1, dir2);
                    var d1 = this._v4.copy(dir2).applyAxisAngle(axis, deg * Math.PI / 180);
                    var d2 = this._v5.copy(dir2).applyAxisAngle(axis.negate(), deg * Math.PI / 180);

                    if (d1.dot(dir1) > d2.dot(dir1))
                        dir1.copy(d1);
                    else
                        dir1.copy(d2);

                    //if (curveStepping.curvePaddingStart > 0) {
                    //    prev1 = this._v3.copy(prev1).add(dir2.negate().multiplyScalar(curveStepping.curvePaddingStart));
                    //    dLen = this._v4.subVectors(target, prev1).length();
                    //}
                    pad = curveStepping.curvePaddingEnd;
                    dLen = Math.max(0, dLen - pad);
                }
            }

            if (lineSteppings && lineSteppings.length) {

                if (dLen < lineSteppings[0].lineLength * 0.5)
                    dLen = pad > 0 ? 0 : lineSteppings[0].lineLength;
                else
                    dLen = this.getLength(this.calculateLength(dLen));
            }

            return target.copy(prev1).add(dir1.multiplyScalar(dLen + pad));
        }
    }

    export interface IPolygonMaterialOpts {
        surfaceColor: number;
        surfaceOpacity: number;
        lineColor: number;
        linewidth: number;
    }

    export class EditablePolygon2D extends THREE.Object3D implements IEditableObject {
        clickable = true;
        draggable = true;
        editable = false;
        hovered = false;
        closed = true;
        hoveredPoint: number = -1;
        editOptions: number;
        lineStepping: LineSteppingCalculator = null;

        static ShowCorners = 1;
        static ShowEdges = 1 << 1;
        static ShowSurface = 1 << 2;

        private _poly: ObservablePolygon = null;
        private _polySubscription: KnockoutSubscription = null;
        _isDisposed = false;
        private _pointCount = 0;
        private _geometryNeedsUpdate = true;
        private _materialNeedsUpdate = true;
        private _bd = new THREE.Box3();
        private _v1 = new THREE.Vector3();
        private _v2 = new THREE.Vector3();
        private _mat = new THREE.Matrix4();
        private _ray = new THREE.Ray();
        private _plane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);

        private _corners: THREE.Points;
        private _edges: THREE.Line2;
        private _suface: THREE.Mesh;

        private _surfaceColor: number = 0x07AC00;
        private _surfaceOpacity: number = 0.5;
        private _lineColor: number = 0x0B3818;
        private _linewidth: number = 2.5;

        static ClickTolerance = 6;

        private static circleMaterial: THREE.Material;
        private surfaceMaterial: THREE.Material;
        static linesMaterial: { [k: string]: THREE.LineMaterial } = {};

        static getCircleMaterial() {
            if (!EditablePolygon2D.circleMaterial) {
                var loader = new THREE.TextureLoader();
                var tex = loader.load(UI3DImages.InterCircle, () => {
                    LoaderManager.notifyResourceLoaded();
                });
                tex.anisotropy = Detector.webglParameters.maxAnisotropy || 1;
                tex.wrapS = tex.wrapT = THREE.ClampToEdgeWrapping;

                EditablePolygon2D.circleMaterial = new THREE.PointsMaterial({
                    size: 16,
                    sizeAttenuation: false,
                    map: tex,
                    alphaTest: 0.5,
                    transparent: true,
                    depthTest: false,
                    depthWrite: false,
                    vertexColors: true //THREE.VertexColors
                });
            }
            return EditablePolygon2D.circleMaterial;
        }

        userEdited = false;
        userCallBack: () => void;
        userMoved: () => void;

        get poly() {
            return this._poly;
        }

        set poly(poly: ObservablePolygon) {

            if (this._poly != poly) {
                if (this._polySubscription) {
                    this._polySubscription.dispose();
                    this._polySubscription = null;
                }
                this._poly = poly || null;
                if (poly)
                    this._polySubscription = poly.subscribe(this.onGeometryChanged.bind(this));

                this.onGeometryChanged();
            }
        }

        constructor(poly?: ObservablePolygon, userCallBack?: () => void, userMoved?: () => void, materialOpts?: IPolygonMaterialOpts) {
            super();

            this.editOptions = EditablePolygon2D.ShowCorners | EditablePolygon2D.ShowEdges | EditablePolygon2D.ShowSurface;

            if (materialOpts) {
                this._surfaceColor = materialOpts.surfaceColor;
                this._surfaceOpacity = materialOpts.surfaceOpacity;
                this._lineColor = materialOpts.lineColor;
                this._linewidth = materialOpts.linewidth;
            }

            var updateGeometry = this.onGeometryChanged.bind(this);
            var updateMaterial = this.onMaterialChanged.bind(this);

            ko.track(this, ["clickable", "draggable", "editable", "hoveredPoint", "visible", "hovered", "editOptions", "closed"]);

            //ko.getObservable(this, "clickable").subscribe(updateGeometry);
            //ko.getObservable(this, "draggable").subscribe(updateGeometry);
            ko.getObservable(this, "editOptions").subscribe(updateGeometry);
            ko.getObservable(this, "editable").subscribe(updateGeometry);
            ko.getObservable(this, "hoveredPoint").subscribe(updateMaterial);
            ko.getObservable(this, "hovered").subscribe(updateMaterial);
            ko.getObservable(this, "closed").subscribe(updateGeometry);

            this.surfaceMaterial = new THREE.MeshBasicMaterial({
                color: this._surfaceColor,
                transparent: true,
                opacity: this._surfaceOpacity,
                depthTest: false,
                depthWrite: false,
                side: THREE.DoubleSide
            });

            Controller.Current.viewModel.editableObjectsManager.editableObjects.push(this);

            if (poly)
                this.poly = poly;
            if (userCallBack)
                this.userCallBack = userCallBack;
            if (userMoved)
                this.userMoved = userMoved;
        }

        onGeometryChanged() {
            this._geometryNeedsUpdate = true;
            this._materialNeedsUpdate = true;
        }

        onMaterialChanged() {
            this._materialNeedsUpdate = true;
        }

        update() {
            if (this._geometryNeedsUpdate) {
                this._geometryNeedsUpdate = false;
                this.updateGeometry();
            }
            if (this._materialNeedsUpdate) {
                this._materialNeedsUpdate = false;
                this.updateMaterial();
            }
        }

        translatePoly(t: { x: number, y: number }) {
            var v = this._v1.set(t.x, t.y, 0);
            this.poly.array.forEach(p => { p.add(v); });
        }

        private updateMaterial() {
            var poly = this._poly,
                polyCount = poly ? poly.array.length : 0;

            if (polyCount <= 0 || !this.editable)
                return;

            var cornerGeo = (this._corners && this._corners.geometry) as THREE.BufferGeometry,
                hoveredPoint = this.hoveredPoint,
                surfaceMaterial = this.surfaceMaterial;

            if (cornerGeo) {
                var colors = cornerGeo.getAttribute('color') as THREE.BufferAttribute;

                for (var j = 0; j < polyCount; j++) {
                    colors.setXYZ(j, 0.75, 0.75, 0.75);
                }

                if (hoveredPoint >= 0 && hoveredPoint < polyCount) {
                    colors.setXYZ(hoveredPoint, 1, 1, 1);
                }

                colors.needsUpdate = true;
            }

            var op = this.hovered ? 0.6 : 0.4;

            if (surfaceMaterial.opacity !== op) {
                surfaceMaterial.opacity = op;
                surfaceMaterial.needsUpdate = true;
            }

        }

        private updateGeometry() {
            var poly = this._poly,
                polyCount = poly ? poly.array.length : 0;

            if (polyCount <= 0 || !this.editable) {
                this.visible = false;
                return;
            }

            this.visible = true;

            if (!this._corners || this._pointCount !== polyCount)
                this.recreate(polyCount);

            var points = poly.array,
                corners = this._corners,
                edges = this._edges,
                cornerGeo = (corners && corners.geometry) as THREE.BufferGeometry,
                edgeGeo = (edges && this._edges.geometry) as THREE.LineGeometry,
                surfaceMesh = this._suface,
                showCorners = !!(this.editOptions & EditablePolygon2D.ShowCorners),
                showEdges = !!(this.editOptions & EditablePolygon2D.ShowEdges),
                showSurface = !!(this.editOptions & EditablePolygon2D.ShowSurface);

            if (corners && corners.visible !== showCorners)
                corners.visible = showCorners;

            if (edges && edges.visible !== showEdges)
                edges.visible = showEdges;

            if (surfaceMesh && surfaceMesh.visible !== showSurface)
                surfaceMesh.visible = showSurface;

            var boundingSphere: THREE.Sphere;

            if (cornerGeo) {
                var positions = cornerGeo.getAttribute('position') as THREE.BufferAttribute,
                    colors = cornerGeo.getAttribute('color') as THREE.BufferAttribute,
                    arr = positions.array as Float32Array,
                    colArr = colors.array as Float32Array;

                var idx = 0;

                for (var j = 0; j < polyCount; j++ , idx += 3) {
                    var p = points[j];

                    arr[idx] = p.x;
                    arr[idx + 1] = p.y;
                    //arr[idx + 2] = p.z;

                    colArr[idx] = 0.75;
                    colArr[idx + 1] = 0.75;
                    colArr[idx + 2] = 0.75;
                }

                positions.needsUpdate = true;
                colors.needsUpdate = true;

                cornerGeo.computeBoundingSphere();
                boundingSphere = cornerGeo.boundingSphere;
            } else {
                boundingSphere = poly.getBoundingSphere();
            }

            if (edgeGeo && edges.visible) {

                var instanceBuffer = (edgeGeo.getAttribute('instanceStart') as THREE.InterleavedBufferAttribute).data as THREE.InstancedInterleavedBuffer,
                    arr = instanceBuffer.array as Float32Array;

                var idx = 0,
                    p1: THREE.Vector3,
                    p2: THREE.Vector3,
                    pc = polyCount - 1;

                for (var j = 0; j < pc; j++ , idx += 6) {
                    p1 = points[j];
                    p2 = points[j + 1];

                    arr[idx] = p1.x;
                    arr[idx + 1] = p1.y;
                    //arr[idx + 2] = p1.z;

                    arr[idx + 3] = p2.x;
                    arr[idx + 4] = p2.y;
                    //arr[idx + 5] = p2.z;
                }

                p1 = points[pc];
                p2 = points[0];

                arr[idx] = p1.x;
                arr[idx + 1] = p1.y;
                //arr[idx + 2] = p1.z;

                arr[idx + 3] = p2.x;
                arr[idx + 4] = p2.y;
                //arr[idx + 5] = p2.z;

                edgeGeo.maxInstancedCount = this.closed ? polyCount : polyCount - 1;

                instanceBuffer.needsUpdate = true;

                edgeGeo.boundingSphere.copy(boundingSphere);
            }

            //surface
            if (polyCount >= 3 && surfaceMesh && surfaceMesh.visible) {
                var surfaceGeo = surfaceMesh.geometry as THREE.BufferGeometry,
                    indexAttribute = surfaceGeo.getIndex(),
                    indices = indexAttribute.array as Uint16Array,
                    vIndizes = THREE.ShapeUtils.triangulateShape(poly.array.slice(), []),
                    indicesCount = vIndizes.length * 3;

                for (var i = 0, idx = 0, l = vIndizes.length; i < l; i++ , idx += 3) {
                    var ind = vIndizes[i];

                    indices[idx] = ind[0];
                    indices[idx + 1] = ind[1];
                    indices[idx + 2] = ind[2];
                }

                surfaceGeo.setDrawRange(0, indicesCount);
                indexAttribute.needsUpdate = true;

                surfaceGeo.boundingSphere.copy(boundingSphere);

                //if (!this.wireframe) {
                //    var bd = new THREE.Box3().copy(poly.bounds);
                //    bd.min.setZ(0);

                //    //var m = new THREE.Matrix4().makeTranslation((bd.min.x + bd.max.x) * 0.5, (bd.min.y + bd.max.y) * 0.5, (bd.min.z + bd.max.z) * 0.5);

                //    this.updateMatrixWorld(false);

                //    //this.updateWorldMatrix(true, false);

                //    bd.applyMatrix4(this.matrixWorld);

                //    //m.premultiply(this.matrixWorld);

                //    //wireframe.applyMatrix4(m.premultiply(this.matrixWorld));
                //    //wireframe.applyMatrix4(m);

                //    var size = bd.getSize(new THREE.Vector3());

                //    var geo = new THREE.BoxBufferGeometry(size.x, size.y, size.z);
                //    var geometry = new THREE.WireframeGeometry2( geo );

                //    var wireframe = this.wireframe = new THREE.Wireframe(geometry, EditablePolygon2D.LineMaterial);
                //    wireframe.computeLineDistances();
                //    bd.getCenter(wireframe.position);
                //    wireframe.updateMatrix();

                //    Controller.Current.scene.add(wireframe);
                //}
            }
        }

        private recreate(pointCount) {
            this.children.slice().forEach(ch => {
                this.remove(ch);
                spt.ThreeJs.utils.disposeObject3D(ch, true, true, false);
            });

            this._pointCount = pointCount;

            this._corners = null;
            this._edges = null;
            this._suface = null;

            var maxNumberTriangles = Math.max(0, 2 * pointCount - 5);

            var positionAttribute = new THREE.BufferAttribute(new Float32Array(pointCount * 3), 3).setUsage(THREE.DynamicDrawUsage);
            var colorAttribute = new THREE.BufferAttribute(new Float32Array(pointCount * 3), 3).setUsage(THREE.DynamicDrawUsage);

            //corners
            var cornergeo = new THREE.BufferGeometry();
            if (!cornergeo.boundingSphere)
                cornergeo.boundingSphere = new THREE.Sphere();

            cornergeo.setAttribute('position', positionAttribute);
            cornergeo.setAttribute('color', colorAttribute);

            var corners = this._corners = new THREE.Points(cornergeo, EditablePolygon2D.getCircleMaterial());

            corners.renderOrder = LS.Client3DEditor.UIRenderOrder;

            this.add(corners);

            if (pointCount > 1) {
                //edges
                var array = new Float32Array((pointCount + 1) * 3);
                var edgeGeo = new THREE.LineGeometry();
                if (!edgeGeo.boundingSphere)
                    edgeGeo.boundingSphere = new THREE.Sphere();
                edgeGeo.setPositions(array);

                var startPositions = edgeGeo.getAttribute('instanceStart') as THREE.InterleavedBufferAttribute;
                //var endPositions = edgeGeo.getAttribute('instanceEnd') as THREE.InterleavedBufferAttribute;

                var instanceBuffer = startPositions.data as THREE.InstancedInterleavedBuffer;
                instanceBuffer.setUsage(THREE.DynamicDrawUsage);

                var linesKey = `w${this._linewidth}c${this._lineColor}`;

                if (!EditablePolygon2D.linesMaterial[linesKey]) {
                    EditablePolygon2D.linesMaterial[linesKey] = spt.ThreeJs.utils.LineMaterialHelper.GetLineMaterial({
                        color: this._lineColor,
                        linewidth: this._linewidth, // in pixels
                        vertexColors: false, //THREE.NoColors,
                        transparent: true,
                        //resolution:  // to be set by renderer, eventually
                        dashed: false,
                        depthTest: false,
                        depthWrite: false
                    });
                }

                var line = this._edges = new THREE.Line2(edgeGeo, EditablePolygon2D.linesMaterial[linesKey]);
                line.computeLineDistances();

                line.renderOrder = LS.Client3DEditor.UIRenderOrder - 1;

                this.add(line);
            }

            //surface
            if (pointCount > 2 && maxNumberTriangles > 0) {

                var indexAttribute = new THREE.BufferAttribute(new Uint16Array(maxNumberTriangles * 3), 1).setUsage(THREE.DynamicDrawUsage);

                var geo = new THREE.BufferGeometry();
                if (!geo.boundingSphere)
                    geo.boundingSphere = new THREE.Sphere();
                geo.setAttribute('position', positionAttribute);
                geo.setIndex(indexAttribute);

                var mesh = this._suface = new THREE.Mesh(geo, this.surfaceMaterial);

                mesh.renderOrder = LS.Client3DEditor.UIRenderOrder - 2;

                this.add(mesh);

            }
        }

        static setDashed(mat: THREE.LineMaterial, dashed: boolean) {
            mat.dashed = dashed;
            // dashed is implemented as a defines -- not as a uniform. this could be changed.
            // ... or LineDashedMaterial could be implemented as a separate material
            // temporary hack - renderer should do this eventually
            if (dashed) mat.defines.USE_DASH = ""; else delete mat.defines.USE_DASH;
            mat.needsUpdate = true;
        }

        intersecsRaycaster(worldRayCaster: THREE.Raycaster) {
            var controller = Controller.Current,
                poly = this.poly,
                points = poly.array,
                polyCount = points.length,
                clickTolerance = EditablePolygon2D.ClickTolerance / controller.orthoCamera.zoom,
                clickToleranceSq = clickTolerance * clickTolerance,
                v1 = this._v1,
                //v2 = this._v2,
                inverseMatrix = this._mat.getInverse(this.matrixWorld),
                localRay = this._ray.copy(worldRayCaster.ray).applyMatrix4(inverseMatrix);

            if (polyCount <= 0)
                return null;

            var bounds = this._bd.copy(poly.bounds);

            bounds.min.set(bounds.min.x - clickTolerance, bounds.min.y - clickTolerance, -clickTolerance);
            bounds.max.set(bounds.max.x + clickTolerance, bounds.max.y + clickTolerance, clickTolerance);

            if (localRay.intersectBox(bounds, v1)) {
                var dist = Number.MAX_VALUE,
                    res: IEditableSelection = { pt: -1, Edge: null, surface: false, distSq: 0, source: this },
                    d: number;

                for (var i = 0; i < polyCount; i++) {
                    var p = points[i];
                    d = localRay.distanceSqToPoint(v1.copy(p).setZ(0));
                    if (d < clickToleranceSq && d < dist) {
                        dist = d;
                        res.pt = i;
                        res.distSq = dist;
                    }
                }

                if (res.pt >= 0)
                    return res;

                //var p1: ObservableVector3,
                //    p2: ObservableVector3,
                //    pc = polyCount - 1;

                //for (var i = 0; i < pc; i++) {
                //    p1 = points[i];
                //    p2 = points[i + 1];
                //    d = localRay.distanceSqToSegment(v1.copy(p1).setZ(0), v2.copy(p2).setZ(0));
                //    if (d < clickToleranceSq && d < dist) {
                //        dist = d;
                //        res.Edge = [i, i +1];
                //        res.distSq = dist;
                //    }
                //}

                //if (pc > 1) {
                //    p1 = points[pc];
                //    p2 = points[0];
                //    d = localRay.distanceSqToSegment(v1.copy(p1).setZ(0), v2.copy(p2).setZ(0));
                //    if (d < clickToleranceSq && d < dist) {
                //        dist = d;
                //        res.Edge = [pc, 0];
                //    }
                //}

                //if (res.Edge)
                //    return res;

                var surfaceMesh = this._suface;

                if (surfaceMesh) {
                    var intersects = worldRayCaster.intersectObject(surfaceMesh);
                    if (intersects && intersects.length) {
                        var intersect = intersects[0];
                        res.surface = true;
                        res.distSq = v1.copy(intersect.point).distanceToSquared(worldRayCaster.ray.origin);
                        return res;
                    }
                }

            }

            return null;
        }

        getLocalPosition(worldRayCaster: THREE.Raycaster) {
            var inverseMatrix = this._mat.getInverse(this.parent ? this.parent.matrixWorld : this.matrixWorld),
                localRay = this._ray.copy(worldRayCaster.ray).applyMatrix4(inverseMatrix),
                plane = this._plane;

            return localRay.intersectPlane(plane, this._v2);
        }

        getPoint(i: number): ObservableVector3 {
            return this.poly.array[i];
        }

        setPoint(i: number, x: number, y: number, z?: number) {
            //var lineSteppings = this.lineSteppings,
            //    array = this.poly.array,
            //    pointCount = array.length;
            //if (lineSteppings && pointCount > 1) {



            //} else {
            //    var p = this.poly.array[i];
            //    p.x = x;
            //    p.y = y;
            //    if (z !== undefined)
            //        p.z = z;
            //}

            var array = this.poly.array,
                pointCount = array.length,
                lineStepping = this.lineStepping,
                p = array[i];

            p.x = x;
            p.y = y;
            if (z !== undefined)
                p.z = z;

            if (lineStepping && pointCount > 1) {
                if (i === 0) {
                    lineStepping.getPosition(p, array[1], pointCount > 2 ? array[2] : null);
                } else {
                    lineStepping.getPosition(p, array[i - 1], i > 1 ? array[i - 2] : null);
                }
            }
        }

        setPosition(v: THREE.Vector3) {
            this.position.copy(v);
        }

        applyMatrix4(m: THREE.Matrix4) {
            this.poly.applyMatrix4(m);
        }

        dispose() {
            if (this._isDisposed)
                return;

            this._isDisposed = true;

            if (this._polySubscription) {
                this._polySubscription.dispose();
                this._polySubscription = null;
            }

            Controller.Current.viewModel.editableObjectsManager.editableObjects.remove(this);

            this.children.slice().forEach(ch => {
                spt.ThreeJs.utils.disposeObject3D(ch, true, true, false);
            });

            this.surfaceMaterial.dispose();
        }
    }

    export class EditableObjectsManager {
        editableObjects: IEditableObject[] = [];
        active = true;
        hovered: IEditableSelection = null;
        startSelected: IEditableSelection = null;
        startPos = new THREE.Vector3();
        isDragging: boolean = false;
        private _v1 = new THREE.Vector3();

        readonly isEnabled: boolean;

        constructor(viewModel: ViewModel) {
            ko.track(this);

            ko.defineProperty(this, "isEnabled", () => this.active && this.editableObjects.some(e => e.editable || e.visible));

            ko.getObservable(this, "hovered").subscribe((oldVal) => {
                if (oldVal && oldVal.source && !oldVal.source._isDisposed) {
                    //var controller = Controller.Current,
                    //    segmentHighlightHelper = controller.segmentHighlightHelper,
                    var o = oldVal.source;
                    o.hoveredPoint = -1;
                    o.hovered = false;
                }
            }, null, "beforeChange");

            ko.getObservable(this, "hovered").subscribe((newVal) => {
                if (newVal && newVal.source && !newVal.source._isDisposed) {
                    var n = newVal.source;
                    if (newVal.pt >= 0)
                        n.hoveredPoint = newVal.pt;
                    else if (newVal.surface) {
                        n.hovered = true;
                    }
                }
            });
        }

        update(viewModel: ViewModel) {
            if (!this.isEnabled) {
                return;
            }

            this.editableObjects.forEach(p => { p.update(); });
        }

        onMouseMove(viewModel: ViewModel) {
            if (!this.isEnabled) {
                if (this.hovered)
                    this.hovered = null;
                return true;
            }

            var controller = Controller.Current,
                worldRaycaster = controller.worldRaycaster;

            if (!this.isDragging && this.startSelected && this.startSelected.source.editable && viewModel.mouseDown && viewModel.diffViewPosition.lengthSq() >= 100)
                this.isDragging = true;

            if (this.isDragging && this.startSelected) {
                var sel = this.startSelected,
                    e = sel.source;

                var localPos = e.getLocalPosition(worldRaycaster);

                if (sel.pt >= 0) {
                    if (viewModel.shiftDown) {
                        var pt = this.startPos;
                        localPos = spt.ThreeJs.utils.ProjectVectorToClosestAxis(this._v1.subVectors(localPos, pt)).add(pt);
                    }

                    e.setPoint(sel.pt, localPos.x, localPos.y);
                } else if (sel.surface && e.draggable) {
                    var pos = this._v1.copy(localPos).sub(this.startPos).setZ(0);

                    if (viewModel.shiftDown)
                        spt.ThreeJs.utils.ProjectVectorToClosestAxis(pos);

                    e.setPosition(pos);
                }

                if (e.userMoved)
                    e.userMoved();

                e.userEdited = true;

                this.hovered = null;

                return false;
            }

            var intersection: IEditableSelection = null;

            this.editableObjects.forEach(e => {
                if (e.clickable) {
                    var inter = e.intersecsRaycaster(worldRaycaster);
                    if (inter && (!intersection || inter.distSq < intersection.distSq))
                        intersection = inter;
                }
            });

            this.hovered = intersection;

            return true;
        }

        onMouseDown(viewModel: ViewModel) {
            if (!this.isEnabled || viewModel.shiftDown || viewModel.ctrlDown || viewModel.spaceDown)
                return true;

            this.applyUserEdited();

            this.isDragging = false;
            this.startSelected = this.hovered || null;

            if (this.startSelected) {
                var sel = this.startSelected,
                    e = sel.source;
                if (sel.pt >= 0)
                    this.startPos.copy(e.getPoint(sel.pt));
                else
                    this.startPos.copy(e.getLocalPosition(Controller.Current.worldRaycaster));
            }

            return true;
        }

        onMouseUp(viewModel: ViewModel) {
            if (!this.isEnabled)
                return true;

            this.applyUserEdited();
            
            this.isDragging = false;
            this.startSelected = null;

            return true;
        }

        applyUserEdited() {
            this.editableObjects.forEach(e => {
                if (e.userEdited) {
                    e.userEdited = false;

                    if (e.position.lengthSq() > 0) {
                        var m = new THREE.Matrix4().makeTranslation(e.position.x, e.position.y, 0);
                        e.position.set(0, 0, 0);
                        e.applyMatrix4(m);
                        e.update();
                    }
                    if (e.userCallBack)
                        e.userCallBack();
                }
            });
        }
    }
}