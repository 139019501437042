module LS.Client3DEditor {
    export class AddSpacingTool extends BaseTool {
        edgeSpacing = 0;
        v1 = new THREE.Vector3();
        v2 = new THREE.Vector3();
        v3 = new THREE.Vector3();
        edgeDir = new THREE.Vector3();
        closestStart = new THREE.Vector3();
        closestEnd = new THREE.Vector3();
        box1 = new THREE.Box3();
        box2 = new THREE.Box3();
        spacingMode = 1;
        spacingModeVertical = 1;
        spacingModeHorizontal = 2;
        segments: { start: THREE.Vector3, end: THREE.Vector3, ids: string[], type: number }[] = [];
        viewModel: SpacingViewModel = new SpacingViewModel(this);
        zPadding = 75;
        Groups: SolarProTool.AnlagenResult[] = [];
        clientObjectId: string = null;
        updateListener: (event: IEventManagerEvent) => void;

        onSelect(viewModel: ViewModel) {
            var controller = Controller.Current,
                segmentHighlightHelper = controller.segmentHighlightHelper,
                segmentsHelper = controller.segmentsHelper;

            segmentHighlightHelper.visible = false;
            segmentsHelper.visible = false;

            this.Groups = [];
            this.loadGroups();

            if (this.updateListener)
                Controller.Current.updateManager.removeListener("HandleClientObjectChanges", this.updateListener);
            Controller.Current.updateManager.addListener("HandleClientObjectChanges", (this.updateListener = this.loadGroups.bind(this)));
        }

        onDeselect(viewModel: ViewModel) {
            var controller = Controller.Current,
                segmentHighlightHelper = controller.segmentHighlightHelper,
                segmentsHelper = controller.segmentsHelper;

            segmentHighlightHelper.visible = false;
            segmentsHelper.visible = false;

            if (this.updateListener)
                Controller.Current.updateManager.removeListener("HandleClientObjectChanges", this.updateListener);
        }

        onMouseMove(viewModel: ViewModel) {
            var controller = Controller.Current,
                instanceContext = controller.viewModel.currentInstance,
                segmentHighlightHelper = controller.segmentHighlightHelper,
                segmentsHelper = controller.segmentsHelper,
                rp = viewModel.roofPosition,
                camera = controller.orthoCamera,
                tolerance = controller.ClickTolerance / camera.zoom,
                toleranceSq = tolerance * tolerance,
                v1 = this.v1,
                v2 = this.v2,
                v3 = this.v3,
                edgeDir = this.edgeDir,
                spacingMode = this.spacingMode,
                closestStart = this.closestStart,
                closestEnd = this.closestEnd,
                box1 = this.box1,
                box2 = this.box2,
                zPadding = this.zPadding;

            box1.min.set(rp.x - tolerance, rp.y - tolerance, -camera.far);
            box1.max.set(rp.x + tolerance, rp.y + tolerance, camera.far);

            var distance = Number.MAX_VALUE;
            var segmentFound = false;
            var radius = box1.getSize(v2).setZ(0).length() * 0.5;
            var octreeObjects = instanceContext.searchOctree(rp, radius, false);
            var zThickness = 100;
            var clientObjectId = null;

            for (var i = octreeObjects.length; i--;) {
                var object = octreeObjects[i] as ClientObjectInstance;

                object.updateSelectionBounds(box2.makeEmpty());

                //box2.copy(object.geometry.boundingBox).translate(object.position);
                //if (object.parent)
                //    box2.applyMatrix4(object.parent.matrixWorld);

                if (box1.intersectsBox(box2)) {

                    v1.copy(rp).clamp(box2.min, box2.max).setZ(0); // vertical pos
                    v2.copy(v1); // horizontal pos

                    v1.x += (Math.abs(box2.min.x - v1.x) < Math.abs(box2.max.x - v1.x)) ? box2.min.x - v1.x : box2.max.x - v1.x;
                    v2.y += (Math.abs(box2.min.y - v2.y) < Math.abs(box2.max.y - v2.y)) ? box2.min.y - v2.y : box2.max.y - v2.y;

                    //if (spacingMode === this.spacingModeVertical)
                    //    v1.x += (Math.abs(box2.min.x - v1.x) < Math.abs(box2.max.x - v1.x)) ? box2.min.x - v1.x : box2.max.x - v1.x;
                    //if (spacingMode === this.spacingModeHorizontal)
                    //    v1.y += (Math.abs(box2.min.y - v1.y) < Math.abs(box2.max.y - v1.y)) ? box2.min.y - v1.y : box2.max.y - v1.y;

                    var distV = v3.copy(v1).sub(rp).lengthSq();
                    var distH = v3.copy(v2).sub(rp).lengthSq();

                    var dist: number,
                        sm: number;

                    if (distV < distH) {
                        sm = this.spacingModeVertical;
                        dist = distV;
                    } else {
                        sm = this.spacingModeHorizontal;
                        dist = distH;
                        v1.copy(v2);
                    }

                    if (dist < distance && dist <= toleranceSq) {
                        distance = dist;
                        spacingMode = sm;
                        clientObjectId = object.instanceData.Id;

                        var bz = (box2.min.z + box2.max.z) * 0.5;

                        if (spacingMode === this.spacingModeVertical) {
                            closestStart.copy(box2.min).setX(v1.x).setZ(bz);
                            closestEnd.copy(box2.max).setX(v1.x).setZ(bz);

                            if (Math.abs(v1.x - box2.min.x) < Math.abs(v1.x - box2.max.x)) {
                                // left edge
                                edgeDir.set(-1, 0, 0);
                            } else {
                                // right edge
                                edgeDir.set(1, 0, 0);
                            }
                        }
                        else if (spacingMode === this.spacingModeHorizontal) {
                            closestStart.copy(box2.min).setY(v1.y).setZ(box2.min.z).setZ(bz);
                            closestEnd.copy(box2.max).setY(v1.y).setZ(bz);

                            if (Math.abs(v1.y - box2.min.y) < Math.abs(v1.y - box2.max.y)) {
                                // lower edge
                                edgeDir.set(0, -1, 0);
                            } else {
                                // upper edge
                                edgeDir.set(0, 1, 0);
                            }
                        }

                        zThickness = box2.max.z + zPadding;

                        segmentFound = true;
                    }
                }
            }

            if (!segmentFound) {
                if (segmentHighlightHelper.visible)
                    segmentHighlightHelper.visible = false;
                if (segmentsHelper.visible)
                    segmentsHelper.visible = false;

                controller.viewModel.Cursor = this.cursor;
                return false;
            }

            this.spacingMode = spacingMode;
            this.clientObjectId = clientObjectId;
            var currentGroup = this.getGroup();

            controller.viewModel.Cursor = 'pointer';

            //search for another edge
            box2.makeEmpty()
                .expandByPoint(v1.copy(edgeDir).add(closestStart).setZ(0))
                .expandByPoint(v1.copy(edgeDir).multiplyScalar(2000).add(closestEnd).setZ(zThickness))
                .expandByScalar(-1);

            octreeObjects = instanceContext.getBoxIntersections(box2);

            var edgeSpacing = -1;

            for (var i = octreeObjects.length; i--;) {
                var co = octreeObjects[i] as ClientObjectInstance,
                    id = co.instanceData.Id;

                if (id === clientObjectId || (currentGroup != null && currentGroup.ClientObjectIds.indexOf(id) === -1))
                    continue;

                co.updateSelectionBounds(box2.makeEmpty());

                //box2.copy(co.geometry.boundingBox).translate(co.position);
                //if (co.parent)
                //    box2.applyMatrix4(co.parent.matrixWorld);

                var d = Math.min(edgeDir.dot(v1.copy(box2.min).sub(closestStart)), edgeDir.dot(v1.copy(box2.max).sub(closestStart)));
                if (d >= 0 && (d < edgeSpacing || edgeSpacing < 0))
                    edgeSpacing = Math.max(0, d);
            }

            //move edge to the center of the space in between
            if (edgeSpacing > 0) {
                closestStart.add(v1.copy(edgeDir).multiplyScalar(edgeSpacing * 0.5));
                closestEnd.add(v1.copy(edgeDir).multiplyScalar(edgeSpacing * 0.5));
            }

            this.edgeSpacing = edgeSpacing;

            if (!segmentHighlightHelper.visible)
                segmentHighlightHelper.visible = true;
            segmentHighlightHelper.setStartEnd(closestStart, closestEnd, true, Math.max(200, edgeSpacing), zThickness);

            if (!segmentsHelper.visible)
                segmentsHelper.visible = true;
            segmentsHelper.setLinePoints([closestStart, closestEnd]);

            return false;
        }

        private loadGroups() {
            if (window.isElevation && Controller.Current.viewModel.selectedTool === "addSpacingTool") {
                SolarProTool.Ajax("WebServices/Anordnung3DService.asmx").Call("GetAnlagen").Data().CallBack(result => {
                    this.Groups = result || [];
                });
            }
        }

        private getGroup(clientObjectId?: string) {
            if (!clientObjectId)
                clientObjectId = this.clientObjectId;
            if (!clientObjectId)
                return null;

            var groups = this.Groups;

            if (clientObjectId && groups.length) {
                for (var i = groups.length; i--;) {
                    if (groups[i].ClientObjectIds.indexOf(clientObjectId) !== -1) {
                        return groups[i];
                    }
                }
            }

            return null;
        }

        onMouseDown(viewModel: ViewModel) {
            var controller = Controller.Current,
                segmentHighlightHelper = controller.segmentHighlightHelper,
                segmentsHelper = controller.segmentsHelper;

            if (segmentHighlightHelper.visible)
                this.ShowSpacingDialog();

            return false;
        }

        ShowSpacingDialog() {
            DManager.loadDynamicDialog("/Anordnung/GetAoSpacingDialog", "ao-spacing-dia", 'auto', 'auto', (dialogId, diaGenerated) => {
                if (diaGenerated)
                    ko.applyBindings(this.viewModel, $(`#${dialogId}`).get(0));

                this.viewModel.clientObjectId = this.clientObjectId;
                this.viewModel.spacingMode = this.spacingMode;
                this.viewModel.edgeDir.copy(this.edgeDir);
                this.viewModel.edgeSpacing = this.edgeSpacing;
                this.viewModel.newLength = Math.max(0, this.edgeSpacing);
                this.viewModel.start.copy(this.closestStart);
                this.viewModel.end.copy(this.closestEnd);
                this.viewModel.SelectableHorizontalGaps.removeAll();
                this.viewModel.SelectableVerticalGaps.removeAll();

                if (this.edgeSpacing < 0) {
                    if (this.edgeDir.x >= 0.9) {
                        this.viewModel.spacingDirectionV = -1;
                        this.viewModel.spacingDirectionH = 0;
                    } else if (this.edgeDir.x <= -0.9) {
                        this.viewModel.spacingDirectionV = 1;
                        this.viewModel.spacingDirectionH = 0;
                    } else if (this.edgeDir.y >= 0.9) {
                        this.viewModel.spacingDirectionV = 0;
                        this.viewModel.spacingDirectionH = -1;
                    } else {
                        this.viewModel.spacingDirectionV = 0;
                        this.viewModel.spacingDirectionH = 1;
                    }
                }

                var group = this.getGroup();
                if (group) {
                    this.viewModel.SelectableHorizontalGaps.push.apply(this.viewModel.SelectableHorizontalGaps, group.SelectableHorizontalGaps || []);
                    this.viewModel.SelectableVerticalGaps.push.apply(this.viewModel.SelectableVerticalGaps, group.SelectableVerticalGaps || []);

                    if (this.viewModel.SelectableVerticalGaps.length && this.viewModel.isVertical) {
                        var newLength = this.viewModel.newLength;
                        this.viewModel.newLength = this.viewModel.SelectableVerticalGaps.reduce((prev, curr) => {
                            return (Math.abs(curr.Value - newLength) < Math.abs(prev.Value - newLength) ? curr : prev);
                        }).Value;
                    }
                    else if (this.viewModel.SelectableHorizontalGaps.length && !this.viewModel.isVertical) {
                        var newLength = this.viewModel.newLength;
                        this.viewModel.newLength = this.viewModel.SelectableHorizontalGaps.reduce((prev, curr) => {
                            return (Math.abs(curr.Value - newLength) < Math.abs(prev.Value - newLength) ? curr : prev);
                        }).Value;
                    }
                }

                setTimeout(() => {
                    DManager.show(dialogId);
                    $('#ao-gap-new-distance-input').focus();
                    Controller.Current.viewModel.hasFocus = false;
                }, 0);
            });
        }

        SaveSpacingDialog() {
            ko.tasks.runEarly();

            var controller = Controller.Current,
                instanceContext = controller.viewModel.currentInstance,
                segmentHighlightHelper = controller.segmentHighlightHelper,
                segmentsHelper = controller.segmentsHelper,
                clientObjectId = this.clientObjectId = this.viewModel.clientObjectId,
                currentGroup = this.getGroup(clientObjectId),
                v1 = this.v1,
                v2 = this.v2,
                v3 = this.v3,
                edgeDir = this.edgeDir.copy(this.viewModel.edgeDir),
                spacingMode = this.spacingMode = this.viewModel.spacingMode,
                start = this.viewModel.start.clone(),
                end = this.viewModel.end.clone(),
                box1 = this.box1,
                box2 = this.box2,
                edgeTolerance = currentGroup ? 2000 : 500,
                edgeSpacing = this.viewModel.edgeSpacing,
                newLength = this.viewModel.newLength,
                isVertical = this.viewModel.isVertical,
                whole = this.viewModel.whole,
                spacingDirection = isVertical ? this.viewModel.spacingDirectionV : this.viewModel.spacingDirectionH,
                expandDirs: THREE.Vector3[] = [],
                expandDist: number[] = [];

            DManager.hide("ao-spacing-dia");

            controller.notifyRefreshView();

            if (segmentHighlightHelper.visible)
                segmentHighlightHelper.visible = false;
            if (segmentsHelper.visible)
                segmentsHelper.visible = false;

            controller.viewModel.Cursor = this.cursor;

            controller.viewModel.hasFocus = true;

            if (newLength < 0)
                return;

            var move = Math.round(newLength - Math.max(0, edgeSpacing));

            if (edgeSpacing < 0) {
                //edge case
                expandDirs.push(edgeDir.clone().negate());
                expandDist.push(move);

                start.add(edgeDir);
                end.add(edgeDir);
            } else {
                if (isVertical) {
                    switch (spacingDirection) {
                        case -1:
                            expandDirs.push(new THREE.Vector3(-1, 0, 0));
                            expandDist.push(move);
                            break;
                        case 1:
                            expandDirs.push(new THREE.Vector3(1, 0, 0));
                            expandDist.push(move);
                            break;
                        default:
                            expandDirs.push(new THREE.Vector3(-1, 0, 0));
                            expandDist.push(Math.floor(move * 0.5));
                            expandDirs.push(new THREE.Vector3(1, 0, 0));
                            expandDist.push(Math.ceil(move * 0.5));
                            break;
                    }
                } else {
                    switch (spacingDirection) {
                        case -1:
                            expandDirs.push(new THREE.Vector3(0, -1, 0));
                            expandDist.push(move);
                            break;
                        case 1:
                            expandDirs.push(new THREE.Vector3(0, 1, 0));
                            expandDist.push(move);
                            break;
                        default:
                            expandDirs.push(new THREE.Vector3(0, -1, 0));
                            expandDist.push(Math.floor(move * 0.5));
                            expandDirs.push(new THREE.Vector3(0, 1, 0));
                            expandDist.push(Math.ceil(move * 0.5));
                            break;
                    }
                }
            }

            box2.min.copy(start).min(end).setZ(0);
            box2.max.copy(end).max(start).setZ(Math.max(start.z, end.z, 1000));

            if (whole) {
                box2.min.x -= edgeTolerance;
                box2.min.y -= edgeTolerance;
                box2.max.x += edgeTolerance;
                box2.max.y += edgeTolerance;
            } else {
                if (isVertical) {
                    box2.min.x -= edgeTolerance;
                    box2.min.y += 1;
                    box2.max.x += edgeTolerance;
                    box2.max.y -= 1;
                } else { //isHorizontal
                    box2.min.x += 1;
                    box2.min.y -= edgeTolerance;
                    box2.max.x -= 1;
                    box2.max.y += edgeTolerance;
                }
            }

            var octreeObjects = instanceContext.getBoxIntersections(box2);
            if (currentGroup)
                octreeObjects = octreeObjects.filter((o: ClientObjectInstance) => currentGroup.ClientObjectIds.indexOf(o.instanceData.Id) !== -1);

            var foundObjects = octreeObjects;
            var foundLength = 0;

            while (foundLength < foundObjects.length) {
                foundLength = foundObjects.length;

                box2.makeEmpty();

                foundObjects.forEach(o => {
                    box2.expandByObject(o);
                });

                if (box2.isEmpty())
                    break;

                if (whole) {
                    box2.min.x -= edgeTolerance;
                    box2.min.y -= edgeTolerance;
                    box2.max.x += edgeTolerance;
                    box2.max.y += edgeTolerance;
                } else {
                    if (isVertical) {
                        box2.min.x -= edgeTolerance;
                        box2.min.y += 1;
                        box2.max.x += edgeTolerance;
                        box2.max.y -= 1;
                    } else { //isHorizontal
                        box2.min.x += 1;
                        box2.min.y -= edgeTolerance;
                        box2.max.x -= 1;
                        box2.max.y += edgeTolerance;
                    }
                }

                octreeObjects = instanceContext.getBoxIntersections(box2).filter(o => foundObjects.indexOf(o) === -1);

                if (currentGroup)
                    octreeObjects = octreeObjects.filter((o: ClientObjectInstance) => currentGroup.ClientObjectIds.indexOf(o.instanceData.Id) !== -1);

                if (octreeObjects.length)
                    foundObjects = foundObjects.concat(octreeObjects);
            }

            foundObjects.forEach((o: ClientObjectInstance) => {
                if (o && o.position && o.instanceData && o.instanceData.Id && o.clientObject && o.clientObject.canMoveInstances) {
                    o.updateSelectionBounds(box1.makeEmpty());
                    var pos = v1.copy(o.position),
                        center = box1.getCenter(v3);

                    for (var i = 0, l = expandDirs.length; i < l; i++) {
                        var dir = expandDirs[i];
                        if (dir.dot(v2.copy(center).sub(start)) > 0) {
                            pos.add(v2.copy(dir).multiplyScalar(expandDist[i]));
                            break;
                        }
                    }

                    if (o.position.x !== pos.x || o.position.y !== pos.y)
                        o.setPosition(pos.x, pos.y, pos.z);
                }
            });

        }
    }

    export class SpacingViewModel {
        SelectableHorizontalGaps: SolarProTool.NameDouble[] = [];
        SelectableVerticalGaps: SolarProTool.NameDouble[] = [];
        readonly currentLength: number;
        newLength = 0;
        spacingDirectionV = 0;
        spacingDirectionH = 0;
        edgeSpacing = 0;
        spacingMode = 1;
        clientObjectId: string = null;
        edgeDir: ObservableVector3 = new ObservableVector3();
        start: THREE.Vector3;
        end: THREE.Vector3;
        readonly isVertical: boolean;
        whole = true;
        tool: AddSpacingTool;

        valueAdjustOpts: spt.Utils.IFloatFromInputOptions = {
            notImperial: true,
            precision: window.UseImperialSystem ? 3 : 0,
            from: "mm",
            to: window.UseImperialSystem ? "ft" : "mm"
        };

        constructor(tool: AddSpacingTool) {
            ko.track(this);

            ko.defineProperty(this, 'isVertical', () => {
                return Math.abs(this.edgeDir.x) > 0.5;
            });

            ko.defineProperty(this, 'currentLength', () => {
                return Math.max(0, this.edgeSpacing);
            });

            this.start = new THREE.Vector3();
            this.end = new THREE.Vector3();

            this.tool = tool;
        }

        setNewLength(v: number | string) {
            var l = spt.Utils.GetFloatFromInputValue("" + v,
                {
                    min: 0,
                    max: 100000,
                    applyArithmetic: true,
                    isFeet: window.UseImperialSystem,
                    notImperial: !window.UseImperialSystem,
                    from: this.valueAdjustOpts.to,
                    to: "mm"
                });
            this.newLength = l;
        }
    }
}