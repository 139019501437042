module LS.Client3DEditor {
    export class CameraControls extends THREE.EventDispatcher {
        constructor(object: THREE.OrthographicCamera, domElement: HTMLElement, viewModel: ViewModel) {
            super();
            var _self = this;
            var STATE = { NONE: -1, ROTATE: 0, ZOOM: 1, PAN: 2, TOUCH_ROTATE: 3, TOUCH_ZOOM_PAN: 4 };

            this.STATE = STATE;

            this.object = object;
            this.domElement = (domElement !== undefined) ? domElement : document;

            var EPS = 0.000001;

            // API

            this.enabled = true;
            this.cancelMouseDown = false;

            this.convertLeftMouse = STATE.NONE;

            this.screen = { left: 0, top: 0, width: 0, height: 0 };

            this.radius = 0;

            this.rotateSpeed = 0.75;
            this.zoomSpeed = 1.2;

            this.noRotate = false;
            this.noZoom = false;
            this.noPan = false;
            this.noRoll = false;

            this.staticMoving = false;
            this.dynamicDampingFactor = 0.4;

            // How far you can orbit vertically, upper and lower limits.
            // Range is 0 to Math.PI radians.
            this.minPolarAngle = EPS * 2; // radians
            this.maxPolarAngle = (Math.PI * 0.5) - (EPS * 2); // radians

            this.keys = [65 /*A*/, 83 /*S*/, 68 /*D*/];

            // internals

            this.target = new THREE.Vector3();

            this.elevationAngle = new THREE.Quaternion();
            this.worldZ = new THREE.Vector3(0, 0, 1);
            this.worldX = new THREE.Vector3(1, 0, 0);

            var orbitQuat = new THREE.Quaternion().setFromUnitVectors(new THREE.Vector3(0, 0, 1), new THREE.Vector3(0, 1, 0));
            var orbitQuatInverse = orbitQuat.clone().inverse();

            var _changed = true;

            var _state = STATE.NONE,
                _prevState = STATE.NONE,

                _eye = new THREE.Vector3(),

                _rotateStart = new THREE.Vector3(),
                _rotateEnd = new THREE.Vector3(),

                orbitRotateStart = new THREE.Vector2(),
                orbitRotateEnd = new THREE.Vector2(),
                orbitRotateDelta = new THREE.Vector2(),

                _refPos = new THREE.Vector3(),
                _refTarget = new THREE.Vector3(),
                _refUp = new THREE.Vector3(),

                // so camera.up is the orbit axis

                //_quat = new THREE.Quaternion(),

                _rollStart = new THREE.Vector3(),
                _rollEnd = new THREE.Vector3(),

                _zoomStartVec = new THREE.Vector2(),

                _touchZoomDistanceStart = 0,
                _touchZoomDistanceEnd = 0,

                _panStart = new THREE.Vector2(),
                _panEnd = new THREE.Vector2(),

                _moveStart = new THREE.Vector3(),
                _moveEnd = new THREE.Vector3();

            this.rotateStart = _rotateStart;
            this.rotateEnd = _rotateEnd;

            this.rollStart = _rollStart;
            this.rollEnd = _rollEnd;

            this.zoomStart = 1;
            this.zoomEnd = 1;

            this.panStart = _panStart;
            this.panEnd = _panEnd;

            this.moveStart = _moveStart;
            this.moveEnd = _moveEnd;

            // for reset

            this.target0 = this.target.clone();
            this.position0 = this.object.position.clone();
            this.up0 = this.object.up.clone();

            this.left0 = this.object.left;
            this.right0 = this.object.right;
            this.top0 = this.object.top;
            this.bottom0 = this.object.bottom;

            // events

            var changeEvent = { type: 'change', target: null };
            var startEvent = { type: 'start', target: null };
            var endEvent = { type: 'end', target: null };


            // methods

            this.SetElevationAngle = (angle: THREE.Quaternion) => {
                _self.elevationAngle.copy(angle).inverse();
                _self.worldZ.set(0, 0, 1).applyQuaternion(_self.elevationAngle);
                //orbitQuat = new THREE.Quaternion().setFromUnitVectors(_self.worldZ, new THREE.Vector3(0, 1, 0));
                //orbitQuatInverse = orbitQuat.clone().inverse();
            };

            this.handleResize = function () {

                if (this.domElement === document) {

                    this.screen.left = 0;
                    this.screen.top = 0;
                    this.screen.width = window.innerWidth;
                    this.screen.height = window.innerHeight;

                } else {
                    var $view = $(this.domElement);
                    var w = $view.innerWidth();
                    var h = $view.innerHeight();
                    var offset = $view.offset();

                    /*var box = this.domElement.getBoundingClientRect();
                    // adjustments come from similar code in the jquery offset() function
                    var d = this.domElement.ownerDocument.documentElement;
                    this.screen.left = box.left + window.pageXOffset - d.clientLeft;
                    this.screen.top = box.top + window.pageYOffset - d.clientTop;
                    this.screen.width = box.width;
                    this.screen.height = box.height;*/

                    this.screen.left = offset.left;
                    this.screen.top = offset.top;
                    this.screen.width = w;
                    this.screen.height = h;
                }

                this.radius = 0.5 * Math.min(this.screen.width, this.screen.height);

                this.left0 = this.object.left;
                this.right0 = this.object.right;
                this.top0 = this.object.top;
                this.bottom0 = this.object.bottom;

                _self.zoomStart = _self.zoomEnd = _self.object.zoom;

            };
            
            this.handleEvent = function (event) {

                if (typeof this[event.type] == 'function') {

                    this[event.type](event);

                }

            };

            var getMouseOnScreen = (() => {

                var vector = new THREE.Vector2();

                return (pageX, pageY) => {

                    vector.set(
                        (pageX - _self.screen.left) / _self.screen.width,
                        (pageY - _self.screen.top) / _self.screen.height
                    );
                    return vector;

                };

            })();

            var getMouseProjectionOnBall = (() => {

                var vector = new THREE.Vector3();
                var objectUp = new THREE.Vector3();
                var mouseOnBall = new THREE.Vector3();
                var euler = new THREE.Euler();
                var _pi2 = Math.PI * 0.5;

                return (pageX, pageY, refPos, refTarget, refUp) => {

                    //refPos = refTarget = refUp = null;

                    mouseOnBall.set(
                        (pageX - _self.screen.width * 0.5 - _self.screen.left) / _self.radius,
                        (_self.screen.height * 0.5 + _self.screen.top - pageY) / _self.radius,
                        0.0
                    );

                    euler.set(mouseOnBall.y * -_pi2, mouseOnBall.x * _pi2, 0);
                    mouseOnBall.set(0, 0, 1).applyEuler(euler).normalize();

                    /*var length = mouseOnBall.length();
    
                    if (_self.noRoll) {
    
                        if (length < Math.SQRT1_2) {
    
                            mouseOnBall.z = Math.sqrt(1.0 - length * length);
    
                        } else {
    
                            mouseOnBall.z = .5 / length;
    
                        }
    
                    } else if (length > 1.0) {
    
                        mouseOnBall.normalize();
    
                    } else {
    
                        mouseOnBall.z = Math.sqrt(1.0 - length * length);
    
                    }*/

                    //refVec.cross(mouseOnBall);

                    var up = refUp || _self.object.up;

                    _eye.copy(refPos || _self.object.position).sub(refTarget || _self.target);

                    vector.copy(up).setLength(mouseOnBall.y);
                    vector.add(objectUp.copy(up).cross(_eye).setLength(mouseOnBall.x));
                    vector.add(_eye.setLength(mouseOnBall.z));

                    return vector;

                };

            })();

            this.rollCamera = (() => {

                var axis = new THREE.Vector3(),
                    quaternion = new THREE.Quaternion();

                return (immediately?: boolean) => {
                    if (Math.abs(_rollStart.x - _rollEnd.x) <= EPS && Math.abs(_rollStart.y - _rollEnd.y) <= EPS && Math.abs(_rollStart.z - _rollEnd.z) <= EPS)
                        return;

                    var angle = Math.acos(_rollStart.dot(_rollEnd) / _rollStart.length() / _rollEnd.length());

                    axis.crossVectors(_rollStart, _rollEnd).normalize();

                    if (!immediately) {
                        angle *= _self.rotateSpeed;

                        if (!_self.staticMoving) {
                            angle *= _self.dynamicDampingFactor;
                        }
                    }

                    quaternion.setFromAxisAngle(axis, -angle);

                    var objUp = _self.object.up;

                    objUp.applyQuaternion(quaternion);

                    if (Math.abs(_rollStart.x - objUp.x) <= EPS && Math.abs(_rollStart.y - objUp.y) <= EPS && Math.abs(_rollStart.z - objUp.z) <= EPS)
                        objUp.copy(_rollStart);

                    _rollEnd.copy(objUp);

                    _changed = true;

                }

            })();

            this.orbitRotateCamera = (() => {

                var v1 = new THREE.Vector3();
                var quat = new THREE.Quaternion();
                var nEPS = 1 - (EPS * 2);

                return (immediately?: boolean) => {

                    orbitRotateDelta.copy(orbitRotateEnd).sub(orbitRotateStart).multiplyScalar(_self.dynamicDampingFactor);
                    if (Math.abs(orbitRotateDelta.x) <= EPS && Math.abs(orbitRotateDelta.y) <= EPS)
                        return;

                    var object = _self.object;

                    quat.copy(_self.elevationAngle).inverse();
                    object.up.applyQuaternion(quat);
                    _eye.applyQuaternion(quat);

                    if (object.up.z <= nEPS) {
                        v1.copy(_eye).normalize();

                        if ((Math.abs(v1.x) <= EPS && Math.abs(v1.y) <= EPS) || ((1 - Math.abs(v1.z)) <= EPS)) {
                            _eye.set(-object.up.x * EPS, -object.up.y * EPS, _eye.z);
                        }
                    }


                    // rotate _eye to "y-axis-is-up" space
                    _eye.applyQuaternion(orbitQuat);

                    // angle from z-axis around y-axis

                    var theta = Math.atan2(_eye.x, _eye.z);

                    // angle from y-axis

                    var rotateSpeed = _self.rotateSpeed;

                    if (immediately)
                        rotateSpeed = 1;

                    var phi = Math.atan2(Math.sqrt(_eye.x * _eye.x + _eye.z * _eye.z), _eye.y);

                    var thetaDelta = (0.5 * Math.PI * -orbitRotateDelta.x * rotateSpeed);

                    theta += thetaDelta;
                    phi += (0.5 * Math.PI * orbitRotateDelta.y * rotateSpeed);

                    // restrict phi to be between desired limits
                    phi = Math.max(_self.minPolarAngle, Math.min(_self.maxPolarAngle, phi));

                    var radius = _eye.length();

                    _eye.x = radius * Math.sin(phi) * Math.sin(theta);
                    _eye.y = radius * Math.cos(phi);
                    _eye.z = radius * Math.sin(phi) * Math.cos(theta);

                    _eye.applyQuaternion(orbitQuatInverse);

                    quat.copy(_self.elevationAngle);
                    object.up.applyQuaternion(quat);
                    _eye.applyQuaternion(quat);

                    _rollEnd.copy(object.up);
                    _rollStart.copy(_self.worldZ);

                    orbitRotateStart.add(orbitRotateDelta);
                    //orbitRotateStart.copy(orbitRotateEnd);

                    _changed = true;

                }

            })();

            this.rotateCamera = (() => {

                var axis = new THREE.Vector3(),
                    quaternion = new THREE.Quaternion();


                return (immediately?: boolean) => {
                    if (Math.abs(_rotateStart.x - _rotateEnd.x) <= EPS && Math.abs(_rotateStart.y - _rotateEnd.y) <= EPS && Math.abs(_rotateStart.z - _rotateEnd.z) <= EPS)
                        return;

                    var angle = Math.acos(_rotateStart.dot(_rotateEnd) / _rotateStart.length() / _rotateEnd.length());

                    axis.crossVectors(_rotateStart, _rotateEnd).normalize();

                    if (!immediately) {
                        angle *= _self.rotateSpeed;

                        if (!_self.staticMoving) {
                            angle *= _self.dynamicDampingFactor;
                        }
                    }

                    quaternion.setFromAxisAngle(axis, -angle);

                    _eye.applyQuaternion(quaternion);

                    if (_self.staticMoving) {

                        _rotateStart.copy(_rotateEnd);

                    } else {

                        quaternion.setFromAxisAngle(axis, angle);
                        _rotateStart.applyQuaternion(quaternion);

                    }

                    if (Math.abs(_rotateStart.x - _rotateEnd.x) <= EPS && Math.abs(_rotateStart.y - _rotateEnd.y) <= EPS && Math.abs(_rotateStart.z - _rotateEnd.z) <= EPS)
                        _rotateStart.copy(_rotateEnd);

                    _changed = true;

                }

            })();

            this.zoomCamera = function (immediately?: boolean) {

                if (_state === STATE.TOUCH_ZOOM_PAN) {

                    var factor = _touchZoomDistanceEnd / _touchZoomDistanceStart;
                    _touchZoomDistanceStart = _touchZoomDistanceEnd;

                    _self.object.zoom *= factor;

                    _changed = true;

                } else {

                    var zoomDiff = _self.zoomEnd - _self.zoomStart;

                    if (!immediately && !_self.staticMoving)
                        zoomDiff *= this.dynamicDampingFactor;

                    //var factor = 1.0 + zoomDiff;

                    if (Math.abs(zoomDiff) > EPS) {
                        if ((_self.object.zoom + zoomDiff) > EPS) {

                            _self.object.zoom += zoomDiff;
                            _self.zoomStart += zoomDiff;
                            _changed = true;

                        } else {
                            _self.zoomEnd = _self.object.zoom;
                        }
                    }
                }

            };

            this.panCamera = (() => {

                var mouseChange = new THREE.Vector2(),
                    //objectUp = new THREE.Vector3(),
                    targetUp = new THREE.Vector3(),
                    v1 = new THREE.Vector3(),
                    targetRight = new THREE.Vector3(),
                    pan = new THREE.Vector3();

                return (immediately?: boolean) => {

                    mouseChange.copy(_panEnd).sub(_panStart);
                    if (mouseChange.lengthSq() > EPS) {

                        v1.copy(_eye).normalize();

                        var object = _self.object;

                        targetUp.copy(object.up);

                        targetRight.copy(v1).cross(targetUp).normalize();
                        targetUp.copy(targetRight).cross(v1).normalize();

                        // Scale movement to keep clicked/dragged position under cursor
                        var scale_x = (object.right - object.left) / object.zoom;
                        var scale_y = (object.top - object.bottom) / object.zoom;
                        mouseChange.x *= scale_x;
                        mouseChange.y *= scale_y;

                        if (immediately || _self.staticMoving) {
                            pan.copy(targetRight).setLength(mouseChange.x);
                            pan.add(targetUp.setLength(mouseChange.y));
                            //pan.copy(_eye).cross(targetUp).setLength(mouseChange.x);
                            //pan.add(objectUp.copy(targetUp).setLength(mouseChange.y));
                        } else {
                            pan.copy(targetRight).setLength(mouseChange.x * _self.dynamicDampingFactor);
                            pan.add(targetUp.setLength(mouseChange.y * _self.dynamicDampingFactor));
                            //pan.copy(_eye).cross(targetUp).setLength(mouseChange.x * _self.dynamicDampingFactor);
                            //pan.add(objectUp.copy(targetUp).setLength(mouseChange.y * _self.dynamicDampingFactor));
                        }

                        object.position.add(pan);
                        _self.target.add(pan);

                        if (_self.staticMoving) {

                            _panStart.copy(_panEnd);

                        } else {

                            _panStart.add(mouseChange.subVectors(_panEnd, _panStart).multiplyScalar(_self.dynamicDampingFactor));

                        }

                        _changed = true;

                    }

                }

            })();

            this.moveCamera = (() => {

                var moveChange = new THREE.Vector3();

                return (immediately?: boolean) => {

                    moveChange.copy(_moveEnd).sub(_moveStart);
                    if (!immediately && !_self.staticMoving)
                        moveChange.multiplyScalar(_self.dynamicDampingFactor);

                    if (moveChange.lengthSq() > EPS) {

                        _self.object.position.add(moveChange);
                        _self.target.add(moveChange);

                        if (_self.staticMoving) {

                            _moveStart.copy(_moveEnd);

                        } else {

                            _moveStart.add(moveChange);

                        }

                        _changed = true;

                    }

                }

            })();

            this.update = (immediately?: boolean) => {

                _eye.subVectors(_self.object.position, _self.target);

                if (!_self.noRotate) {

                    _self.rotateCamera(immediately);
                    _self.rollCamera(immediately);
                    _self.orbitRotateCamera(immediately);

                }

                if (!_self.noZoom) {

                    _self.zoomCamera(immediately);

                    if (_changed) {

                        _self.object.updateProjectionMatrix();

                    }

                }

                if (!_self.noPan) {

                    _self.panCamera(immediately);
                    _self.moveCamera(immediately);
                }

                _self.object.position.addVectors(_self.target, _eye);

                _self.object.lookAt(_self.target);

                if (_changed || immediately) {

                    _self.dispatchEvent(changeEvent);

                    _changed = false;

                }

            };

            this.reset = (position, target, left, right, top, bottom) => {

                if (position)
                    _self.position0.copy(position);
                if (target)
                    _self.target0.copy(target);

                if (left !== undefined)
                    _self.left0 = left;

                if (right !== undefined)
                    _self.right0 = right;

                if (top !== undefined)
                    _self.top0 = top;

                if (bottom !== undefined)
                    _self.bottom0 = bottom;

                _state = STATE.NONE;
                _prevState = STATE.NONE;

                _self.target.copy(_self.target0);
                _self.object.position.copy(_self.position0);
                _self.object.up.copy(_self.up0);

                _eye.subVectors(_self.object.position, _self.target);

                _self.object.left = _self.left0;
                _self.object.right = _self.right0;
                _self.object.top = _self.top0;
                _self.object.bottom = _self.bottom0;

                _self.object.lookAt(_self.target);

                _self.dispatchEvent(changeEvent);

                _self.zoomStart = _self.zoomEnd = _self.object.zoom;

                _changed = false;

            };

            // listeners

            function keydown(event) {

                if (_self.enabled === false) return;

                window.removeEventListener('keydown', keydown);

                _prevState = _state;

                if (_state !== STATE.NONE) {

                    return;

                } else if (event.keyCode === _self.keys[STATE.ROTATE] && !_self.noRotate) {

                    _state = STATE.ROTATE;

                } else if (event.keyCode === _self.keys[STATE.ZOOM] && !_self.noZoom) {

                    _state = STATE.ZOOM;

                } else if (event.keyCode === _self.keys[STATE.PAN] && !_self.noPan) {

                    _state = STATE.PAN;

                }

            }

            function keyup(event) {

                if (_self.enabled === false) return;

                _state = _prevState;

                window.addEventListener('keydown', keydown, false);

            }

            function onMouseDown(px, py) {
                if (_state === STATE.ROTATE && !_self.noRotate) {

                    _refPos.copy(_self.object.position);
                    _refTarget.copy(_self.target);
                    _refUp.copy(_self.object.up);

                    //_rotateStart.copy(_eye.copy(_self.object.position).sub(_self.target).normalize());
                    //_rotateStart.copy(_eye.subVectors(_self.object.position, _self.target));
                    //_rotateStart.set(1, 0, 0);

                    //_rotateStart.copy(getMouseProjectionOnBall(px, py, _refPos, _refTarget, _refUp));
                    //_rotateEnd.copy(_rotateStart);

                    //_rollStart.copy(_self.object.up);
                    //_rollEnd.copy(_rollStart);

                    //orbitRotateStart.copy(getMouseOnScreen(px, py));
                    orbitRotateStart.set(
                        (px - _self.screen.width * 0.5 - _self.screen.left) / _self.radius,
                        (_self.screen.height * 0.5 + _self.screen.top - py) / _self.radius
                    );
                    orbitRotateEnd.copy(orbitRotateStart);
                } else if (_state === STATE.ZOOM && !_self.noZoom) {

                    _zoomStartVec.copy(getMouseOnScreen(px, py));

                } else if (_state === STATE.PAN && !_self.noPan) {

                    _panStart.copy(getMouseOnScreen(px, py));
                    _panEnd.copy(_panStart);

                }
            }

            function mousedown(event) {

                if (_self.enabled === false) return;

                if (_self.cancelMouseDown) {
                    _self.cancelMouseDown = false;
                    return;
                }

                //event.preventDefault();
                event.stopPropagation();

                if (_state === STATE.NONE) {

                    _state = event.button;

                }

                var isLeftMouse = _state === 0;

                if (isLeftMouse)
                    _state = _self.convertLeftMouse;

                //if (isLeftMouse && _self.doRotate)
                //    _state = STATE.ROTATE;

                onMouseDown(event.pageX, event.pageY);

                document.addEventListener('mousemove', mousemove, false);
                document.addEventListener('mouseup', mouseup, false);

                _self.dispatchEvent(startEvent);

            }

            function onMouseMove(px, py) {
                if (_state === STATE.ROTATE && !_self.noRotate) {

                    //_rotateEnd.copy(getMouseProjectionOnBall(px, py, _refPos, _refTarget, _refUp));

                    orbitRotateEnd.set(
                        (px - _self.screen.width * 0.5 - _self.screen.left) / _self.radius,
                        (_self.screen.height * 0.5 + _self.screen.top - py) / _self.radius
                    );

                } else if (_state === STATE.ZOOM && !_self.noZoom) {

                    var d = getMouseOnScreen(px, py),
                        z = _self.object.zoom;
                    _self.zoomEnd += (z * (1.0 + ((d.y - _zoomStartVec.y)))) - z;

                    //_self.zoomEnd *= 1.0 + ((d.y - _zoomStartVec.y));

                    _zoomStartVec.copy(d);

                } else if (_state === STATE.PAN && !_self.noPan) {

                    _panEnd.copy(getMouseOnScreen(px, py));

                }
            }

            function mousemove(event) {

                if (_self.enabled === false) return;

                event.preventDefault();
                event.stopPropagation();

                onMouseMove(event.pageX, event.pageY);

            }

            function mouseup(event) {

                if (_self.enabled === false) return;

                event.preventDefault();
                event.stopPropagation();

                _state = STATE.NONE;

                document.removeEventListener('mousemove', mousemove);
                document.removeEventListener('mouseup', mouseup);
                _self.dispatchEvent(endEvent);

            }

            function mousewheel(event) {

                if (_self.enabled === false) return;

                event.preventDefault();
                event.stopPropagation();

                var delta = 0;

                if (event.wheelDelta) {

                    // WebKit / Opera / Explorer 9

                    delta = event.wheelDelta / 40;

                } else if (event.detail) {

                    // Firefox

                    delta = - event.detail / 3;

                }

                var z = _self.object.zoom;

                _self.zoomEnd += (z * (1.0 + (delta * 0.02))) - z;

                //_self.zoomEnd *= 1.0 + (delta * -0.01);

                _self.dispatchEvent(startEvent);
                _self.dispatchEvent(endEvent);

            }

            function touchstart(event) {

                if (_self.enabled === false) return;

                switch (event.touches.length) {

                    case 1:
                        _state = _self.convertLeftMouse;

                        onMouseDown(event.touches[0].pageX, event.touches[0].pageY);

                        break;

                    case 2:
                        _state = STATE.TOUCH_ZOOM_PAN;
                        var dx = event.touches[0].pageX - event.touches[1].pageX;
                        var dy = event.touches[0].pageY - event.touches[1].pageY;
                        _touchZoomDistanceEnd = _touchZoomDistanceStart = Math.sqrt(dx * dx + dy * dy);

                        var x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
                        var y = (event.touches[0].pageY + event.touches[1].pageY) / 2;
                        _panStart.copy(getMouseOnScreen(x, y));
                        _panEnd.copy(_panStart);
                        break;

                    default:
                        _state = STATE.NONE;

                }
                _self.dispatchEvent(startEvent);

            }

            function touchmove(event) {

                if (_self.enabled === false) return;

                event.preventDefault();
                event.stopPropagation();

                switch (event.touches.length) {

                    case 1:
                        onMouseMove(event.touches[0].pageX, event.touches[0].pageY);
                        break;

                    case 2:
                        var dx = event.touches[0].pageX - event.touches[1].pageX;
                        var dy = event.touches[0].pageY - event.touches[1].pageY;
                        _touchZoomDistanceEnd = Math.sqrt(dx * dx + dy * dy);

                        var x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
                        var y = (event.touches[0].pageY + event.touches[1].pageY) / 2;
                        _panEnd.copy(getMouseOnScreen(x, y));
                        break;

                    default:
                        _state = STATE.NONE;

                }

            }

            function touchend(event) {

                if (_self.enabled === false) return;

                switch (event.touches.length) {

                    case 1:

                        onMouseMove(event.touches[0].pageX, event.touches[0].pageY);

                        break;

                    case 2:
                        _touchZoomDistanceStart = _touchZoomDistanceEnd = 0;

                        var x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
                        var y = (event.touches[0].pageY + event.touches[1].pageY) / 2;
                        _panEnd.copy(getMouseOnScreen(x, y));
                        _panStart.copy(_panEnd);
                        break;

                }

                _state = STATE.NONE;
                _self.dispatchEvent(endEvent);

            }

            function contextmenu(event) {

                event.preventDefault();

            }

            this.dispose = function () {

                this.domElement.removeEventListener('contextmenu', contextmenu, false);
                this.domElement.removeEventListener('mousedown', mousedown, false);
                this.domElement.removeEventListener('mousewheel', mousewheel, false);
                this.domElement.removeEventListener('DOMMouseScroll', mousewheel, false); // firefox

                this.domElement.removeEventListener('touchstart', touchstart, false);
                this.domElement.removeEventListener('touchend', touchend, false);
                this.domElement.removeEventListener('touchmove', touchmove, false);

                document.removeEventListener('mousemove', mousemove, false);
                document.removeEventListener('mouseup', mouseup, false);

                window.removeEventListener('keydown', keydown, false);
                window.removeEventListener('keyup', keyup, false);

            }

            this.domElement.addEventListener('contextmenu', contextmenu, false);
            //this.domElement.addEventListener('mousedown', mousedown, false);
            viewModel.addListener('afterMouseDown', (e) => { mousedown(e.ev); });

            this.domElement.addEventListener('mousewheel', mousewheel, false);
            this.domElement.addEventListener('DOMMouseScroll', mousewheel, false); // firefox

            this.domElement.addEventListener('touchstart', touchstart, false);
            this.domElement.addEventListener('touchend', touchend, false);
            this.domElement.addEventListener('touchmove', touchmove, false);

            //window.addEventListener('keydown', keydown, false);
            //window.addEventListener('keyup', keyup, false);

            this.handleResize();

            // force an update at start
            this.update();
        }
        
        STATE: {
            NONE: number;
            ROTATE: number;
            ZOOM: number;
            PAN: number;
            TOUCH_ROTATE: number;
            TOUCH_ZOOM_PAN: number;
        };

        convertLeftMouse: number;

        rotateStart: THREE.Vector3;
        rotateEnd: THREE.Vector3;
        rollEnd: THREE.Vector3;
        rollStart: THREE.Vector3;
        zoomStart: number;
        zoomEnd: number;
        panStart: THREE.Vector2;
        panEnd: THREE.Vector2;
        moveStart: THREE.Vector3;
        moveEnd: THREE.Vector3;
        target: THREE.Vector3;
        object: THREE.OrthographicCamera;
        domElement: HTMLElement | Document;
        enabled: boolean;
        cancelMouseDown: boolean;
        radius: number;
        screen: { left: number, top: number, width: number, height: number };
        rotateSpeed: number;
        zoomSpeed: number;
        noRotate: boolean;
        noZoom: boolean;
        noPan: boolean;
        noRoll: boolean;
        staticMoving: boolean;
        dynamicDampingFactor: number;
        minPolarAngle: number;
        maxPolarAngle: number;
        keys: number[];
        elevationAngle: THREE.Quaternion;
        worldZ: THREE.Vector3;
        worldX: THREE.Vector3;
        target0: THREE.Vector3;
        position0: THREE.Vector3;
        up0: THREE.Vector3;
        left0: number;
        right0: number;
        top0: number;
        bottom0: number;
        //handleEvent: (event: any) => void;
        rollCamera: (immediately?: boolean) => void;
        orbitRotateCamera: (immediately?: boolean) => void;
        SetElevationAngle: (angle: THREE.Quaternion) => void;
        handleResize: () => void;
        rotateCamera: (immediately?: boolean) => void;
        zoomCamera: (immediately?: boolean) => void;
        panCamera: (immediately?: boolean) => void;
        moveCamera: (immediately?: boolean) => void;
        update: (immediately?: boolean) => void;
        handleEvent: (event: any) => void;
        reset: (position: THREE.Vector3, target: THREE.Vector3, left: number, right: number, top: number, bottom: number) => void;
        dispose: () => void;
    }
}