module LS.Client3DEditor {
    export class NavigationHelper extends THREE.Object3D {

        private _camera: THREE.OrthographicCamera;
        private _uiCamera: THREE.OrthographicCamera;
        private _worldX: THREE.Vector3;
        private _worldY: THREE.Vector3;
        private _worldZ: THREE.Vector3;
        private _isHovering: boolean;
        private _uvDist: number;
        private _uvDistU: number;
        private _sizePx: number;
        private _sizePxHalf: number;
        private _paddingPx: number;
        private _maxSizePx: number;
        private _raycaster: THREE.Raycaster;
        private _minPos: THREE.Vector2;
        private _rotatedRoot: THREE.Object3D;
        private _rotatedObjects: THREE.Object3D;
        private _pointers: THREE.Object3D;
        private _highlights: THREE.Object3D;
        private _highlightsRotated: THREE.Object3D;
        private _maps: IUI3DImages;
        private _compass: THREE.Mesh;
        private _lowerPointer: THREE.Mesh;
        private _pointerMat: THREE.MeshBasicMaterial;
        private _rolls: THREE.Mesh[];
        private _sides: THREE.Mesh[];
        private _ui: THREE.Mesh[];
        private _hideControls: boolean;

        constructor(camera: THREE.OrthographicCamera, uiCamera: THREE.OrthographicCamera, hideControls?: boolean) {
            super();
            this._camera = camera;
            this._uiCamera = uiCamera;
            this._worldX = new THREE.Vector3(1, 0, 0);
            this._worldY = new THREE.Vector3(0, 1, 0);
            this._worldZ = new THREE.Vector3(0, 0, 1);
            this._isHovering = false;
            this._hideControls = !!hideControls;

            var renderOrder = 0;

            var wPx = 44;
            var uvOverlapPx = 1;
            var edgeWidth = 8;
            var paddingPx = 35;

            var sizePx = wPx;
            var globalOpacity = 1.0;
            var uvDist = edgeWidth / wPx;
            var uvDistU = 1 - uvDist;
            var edgeDist = 0.5 - uvDist;

            var uvDistOverlap = (edgeWidth - uvOverlapPx) / wPx;
            var uvDistUOverlap = 1 - uvDistOverlap;
            var edgeDistOverlap = 0.5 - uvDistOverlap;

            var edgePadding = 0.51;
            this._uvDist = uvDist;
            this._uvDistU = uvDistU;
            var sizePxHalf = sizePx / 2;
            var maxSizePx = (new THREE.Vector3(sizePxHalf, sizePxHalf, sizePxHalf)).length();
            
            //Geometries

            var planeGeometry = new THREE.PlaneBufferGeometry(1, 1, 1, 1);

            var compassGeometry = spt.ThreeJs.utils.BufferGeometryFromJsonObject({
                "position": {
                    "array": [1.41552, 0, 1.41552, 1.41552, -0, -1.41552, -1.41552, -0, -1.41552, -1.41552, 0, 1.41552],
                    "type": "Float32Array",
                    "itemSize": 3
                },
                "uv": {
                    "array": [0.744053, 0.255947, 0.744053, 0.744053, 0.255947, 0.744053, 0.255947, 0.255947],
                    "type": "Float32Array",
                    "itemSize": 2
                },
                "index": {
                    "array": [0, 1, 2, 3, 0, 2],
                    "type": "Uint16Array",
                    "itemSize": 3
                }
            });
            
            var pointerGeometry = spt.ThreeJs.utils.BufferGeometryFromJsonObject({
                "position": {
                    "array": [0.152634, -0, -0.74886, 0.152035, -0, -0.807967, -0.152035, -0, -0.807967, 0.032171, -0, -0.592755, -0.152634, -0, -0.74886, -0.032171, -0, -0.592755],
                    "type": "Float32Array",
                    "itemSize": 3
                },
                "uv": {
                    "array": [0.526316, 0.629113, 0.526213, 0.639304, 0.473787, 0.639304, 0.505547, 0.602198, 0.473684, 0.629113, 0.494453, 0.602198],
                    "type": "Float32Array",
                    "itemSize": 2
                },
                "index": {
                    "array": [0, 1, 2, 3, 0, 4, 4, 0, 2, 5, 3, 4],
                    "type": "Uint16Array",
                    "itemSize": 3
                }
            });

            var homeGeometry = spt.ThreeJs.utils.BufferGeometryFromJsonObject({
                "position": {
                    "array": [-1.06336, -0, -1.32994, -1.06336, -0, -1.39259, -1.59856, -0, -1.39259, -1.55077, -0, -1.29337, -1.11115, -0, -1.29337, -1.11115, -0, -1.12238, -1.38193, -0, -1.60317, -1.26692, -0, -1.58623, -1.12213, -0, -1.44742, -1.12213, -0, -1.58623, -1.59856, -0, -1.32994, -1.55077, -0, -1.12238, -1.29205, -0, -1.60317],
                    "type": "Float32Array",
                    "itemSize": 3
                },
                "uv": {
                    "array": [0.316664, 0.729298, 0.316664, 0.7401, 0.224388, 0.7401, 0.232628, 0.722993, 0.308424, 0.722993, 0.308424, 0.693513, 0.261737, 0.776407, 0.281567, 0.773487, 0.306531, 0.749553, 0.306531, 0.773487, 0.224388, 0.729298, 0.232628, 0.693513, 0.277234, 0.776407],
                    "type": "Float32Array",
                    "itemSize": 2
                },
                "index": {
                    "array": [0, 1, 2, 3, 4, 0, 5, 4, 3, 6, 2, 7, 7, 2, 1, 7, 8, 9, 10, 0, 2, 10, 3, 0, 11, 5, 3, 12, 6, 7, 8, 7, 1],
                    "type": "Uint16Array",
                    "itemSize": 3
                }
            });

            var rollGeometry = spt.ThreeJs.utils.BufferGeometryFromJsonObject({
                "position": {
                    "array": [1.19049, -0, -1.3296, 1.19049, -0, -1.60742, 1.06177, -0, -1.47285, 1.28536, -0, -1.3722, 1.32901, -0, -1.34339, 1.43215, -0, -1.45306, 1.22753, -0, -1.3296, 1.23627, -0, -1.38279, 1.23627, -0, -1.53373, 1.33825, -0, -1.52298, 1.22753, -0, -1.60742],
                    "type": "Float32Array",
                    "itemSize": 3
                },
                "uv": {
                    "array": [0.705256, 0.729239, 0.705256, 0.77714, 0.683062, 0.753939, 0.721613, 0.736585, 0.729138, 0.731617, 0.746921, 0.750525, 0.711642, 0.729239, 0.713149, 0.73841, 0.713149, 0.764434, 0.730731, 0.762582, 0.711642, 0.77714],
                    "type": "Float32Array",
                    "itemSize": 2
                },
                "index": {
                    "array": [0, 1, 2, 3, 4, 5, 6, 7, 8, 3, 9, 8, 10, 1, 0, 9, 3, 5, 10, 6, 8, 7, 3, 8, 6, 10, 0],
                    "type": "Uint16Array",
                    "itemSize": 3
                }
            });

            var rollGeometry2 = spt.ThreeJs.utils.BufferGeometryFromJsonObject({
                "index": {
                    "type": "Uint16Array",
                    "itemSize": 3,
                    "array": [0, 1, 2, 3, 4, 5, 6, 7, 8, 8, 4, 3, 0, 2, 7, 9, 3, 5, 10, 6, 8, 10, 8, 3, 6, 0, 7]
                },
                "position": {
                    "type": "Float32Array",
                    "itemSize": 3,
                    "array": [1.3296, -0, -1.19049, 1.47285, -0, -1.06177, 1.60742, -0, -1.19049, 1.3722, -0, -1.28536, 1.52298, -0, -1.33825, 1.45306, -0, -1.43215, 1.3296, -0, -1.22753, 1.60742, -0, -1.22753, 1.53373, -0, -1.23627, 1.34339, -0, -1.32901, 1.38279, -0, -1.23627]
                },
                "uv": {
                    "type": "Float32Array",
                    "itemSize": 2,
                    "array": [0.705256, 0.729239, 0.683062, 0.753939, 0.705256, 0.77714, 0.721613, 0.736585, 0.730731, 0.762582, 0.746921, 0.750525, 0.711642, 0.729239, 0.711642, 0.77714, 0.713149, 0.764434, 0.729138, 0.731617, 0.713149, 0.73841]
                }
            });

            var dropdownGeometry = spt.ThreeJs.utils.BufferGeometryFromJsonObject({
                "position": {
                    "array": [1.60313, 0, 1.35392, 1.56816, 0, 1.3231, 1.33864, 0, 1.3231, 1.4749, 0, 1.60365, 1.60313, 0, 1.4797, 1.30367, 0, 1.4797, 1.30367, 0, 1.35392, 1.43191, 0, 1.60365],
                    "type": "Float32Array",
                    "itemSize": 3
                },
                "uv": {
                    "array": [0.7764, 0.266568, 0.77037, 0.271882, 0.730799, 0.271882, 0.754291, 0.22351, 0.7764, 0.244882, 0.724769, 0.244882, 0.724769, 0.266568, 0.746879, 0.22351],
                    "type": "Float32Array",
                    "itemSize": 2
                },
                "index": {
                    "array": [0, 1, 2, 3, 4, 5, 4, 0, 6, 6, 0, 2, 7, 3, 5, 5, 4, 6],
                    "type": "Uint16Array",
                    "itemSize": 3
                }
            });

            var planeTopGeometry = spt.ThreeJs.utils.BufferGeometryFromJsonObject({
                "position": {
                    "array": [edgeDistOverlap, edgePadding, edgeDistOverlap, edgeDistOverlap, edgePadding, -edgeDistOverlap, -edgeDistOverlap, edgePadding, -edgeDistOverlap, -edgeDistOverlap, edgePadding, edgeDistOverlap],
                    "type": "Float32Array",
                    "itemSize": 3
                },
                "uv": {
                    "array": [uvDistUOverlap, uvDistOverlap, uvDistUOverlap, uvDistUOverlap, uvDistOverlap, uvDistUOverlap, uvDistOverlap, uvDistOverlap],
                    "type": "Float32Array",
                    "itemSize": 2
                },
                "index": {
                    "array": [0, 1, 2, 3, 0, 2],
                    "type": "Uint16Array",
                    "itemSize": 3
                }
            });

            var edgeGeometry = spt.ThreeJs.utils.BufferGeometryFromJsonObject({
                "position": {
                    "array": [-edgePadding, edgeDist, -edgeDistOverlap, -edgePadding, edgeDist, edgeDistOverlap, -edgePadding, edgePadding, edgeDistOverlap, -edgeDist, edgePadding, edgeDistOverlap, -edgeDist, edgePadding, -edgeDistOverlap, -edgePadding, edgePadding, -edgeDistOverlap, -edgePadding, edgePadding, -edgeDistOverlap, -edgePadding, edgePadding, edgeDistOverlap],
                    "type": "Float32Array",
                    "itemSize": 3
                },
                "uv": {
                    "array": [uvDist, uvDistOverlap, uvDist, uvDistUOverlap, 0, uvDistUOverlap, uvDist, uvDistOverlap, uvDist, uvDistUOverlap, 0, uvDistUOverlap, 0, uvDistOverlap, 0, uvDistOverlap],
                    "type": "Float32Array",
                    "itemSize": 2
                },
                "index": {
                    "array": [0, 1, 2, 3, 4, 5, 6, 0, 2, 7, 3, 5],
                    "type": "Uint16Array",
                    "itemSize": 3
                }
            });

            var cornerGeometry = spt.ThreeJs.utils.BufferGeometryFromJsonObject({
                "position": {
                    "array": [-edgePadding, edgeDist, edgePadding, -edgeDist, edgeDist, edgePadding, -edgeDist, edgePadding, edgePadding, -edgePadding, edgeDist, edgeDist, -edgePadding, edgeDist, edgePadding, -edgePadding, edgePadding, edgePadding, -edgePadding, edgePadding, edgePadding, -edgeDist, edgePadding, edgePadding, -edgeDist, edgePadding, edgeDist, -edgePadding, edgePadding, edgePadding, -edgePadding, edgePadding, edgeDist, -edgePadding, edgePadding, edgeDist],
                    "type": "Float32Array",
                    "itemSize": 3
                },
                "uv": {
                    "array": [uvDist, 0, uvDist, uvDist, -0, uvDist, uvDist, uvDist, 0, uvDist, 0, 0, 0, 0, uvDist, 0, uvDist, uvDist, 0, 0, uvDist, 0, 0, uvDist],
                    "type": "Float32Array",
                    "itemSize": 2
                },
                "index": {
                    "array": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 2, 10, 3, 5, 11, 6, 8],
                    "type": "Uint16Array",
                    "itemSize": 3
                }
            });
            
            //END Geometries

            //Textures

            var maps: IUI3DImages = <any>({});

            var maxAnisotropy = Detector.webglParameters.maxAnisotropy;
            var floatPrecision = Detector.webglParameters.maxFloatPrecision;

            var textureloader = new THREE.TextureLoader();

            for (var n in UI3DImages) {
                var tex = textureloader.load(UI3DImages[n] as string, () => {
                    LoaderManager.notifyResourceLoaded();
                });
                tex.anisotropy = maxAnisotropy;
                maps[n] = tex;
            }

            ["top", "bottom", "front", "back", "left", "right"].forEach((n) => {
                (<THREE.Texture>maps[n]).minFilter = THREE.LinearFilter;
            });

            //END Textures

            //Materials

            var topMat = new THREE.RawShaderMaterial({
                uniforms: {
                    map: { type: "t", value: (maps.top) }//,
                    //center: { type: "v3", value: new THREE.Vector3() }
                },
                vertexShader: [
                    "precision " + floatPrecision + " float;",
                    "uniform mat4 modelViewMatrix;",
                    "uniform mat4 projectionMatrix;",
                    //"uniform vec3 center;",
                    "attribute vec3 position;",
                    "attribute vec2 uv;",
                    "varying vec2 vUv;",
                    "void main() {",
                    "vUv = uv;",
                    //"vec4 vc = projectionMatrix * modelViewMatrix * vec4( 0, 0, 0, 1.0 );",
                    //"vec4 vPos = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",
                    //"vc.z = vPos.z;",
                    //"vPos.xyz += (vPos.xyz - vc.xyz) * (1.5 - vPos.z);",
                    //"vPos.xyz += (vc.xyz - vPos.xyz) * 0.1;",
                    "gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",
                    "}"
                ].join("\n"),
                fragmentShader: [
                    "precision " + floatPrecision + " float;",
                    "uniform sampler2D map;",
                    "varying vec2 vUv;",
                    "void main()	{",
                    "gl_FragColor = texture2D(map, vUv);",
                    "}"
                ].join("\n"),
                side: THREE.FrontSide,
                transparent: false,
                fog: false,
                flatShading: true
            });
            
            var hoverMat = new THREE.MeshBasicMaterial({
                transparent: true,
                opacity: globalOpacity,
                map: maps.compassComposedHover as THREE.Texture,
                alphaMap: maps.compassComposedHover_a as THREE.Texture,
                color: new THREE.Color(0xFFFFFF) as any,
                fog: false,
                depthTest: false,
                depthWrite: false
            });

            (maps.compassSidesHover as THREE.Texture).magFilter = (maps.compassSidesHover as THREE.Texture).minFilter = THREE.LinearFilter;

            var sidesHoverMat = new THREE.MeshBasicMaterial({
                transparent: true,
                opacity: globalOpacity * 0.8,
                map: maps.compassSidesHover as THREE.Texture,
                color: new THREE.Color(0xFFFFFF) as any,
                fog: false,
                depthTest: false,
                depthWrite: false
            });

            var stdMat = new THREE.MeshBasicMaterial({
                transparent: true,
                opacity: globalOpacity,
                map: maps.compassComposed as THREE.Texture,
                alphaMap: maps.compassComposed_a as THREE.Texture,
                color: new THREE.Color(0xFFFFFF) as any,
                fog: false,
                depthTest: false,
                depthWrite: false
            });

            var compassMat = new THREE.MeshBasicMaterial({
                transparent: true,
                opacity: globalOpacity,
                map: maps.compassComposedNEWS as THREE.Texture,
                alphaMap: maps.compassComposedNEWS_a as THREE.Texture,
                color: new THREE.Color(0xFFFFFF) as any,
                fog: false,
                depthTest: true,
                depthWrite: true,
                side: THREE.DoubleSide
            });

            //END Materials

            this._sizePx = sizePx;
            this._sizePxHalf = sizePxHalf;
            this._paddingPx = paddingPx;
            this._maxSizePx = maxSizePx;
            this._raycaster = new THREE.Raycaster();
            this._minPos = new THREE.Vector2();

            this.scale.set(sizePx, sizePx, sizePx);

            var rotatedRoot = new THREE.Object3D();
            this.add(rotatedRoot);

            var rotated = new THREE.Object3D();
            rotatedRoot.add(rotated);

            this._rotatedRoot = rotatedRoot;
            this._rotatedObjects = rotated;

            var pointers = new THREE.Object3D();
            this.add(pointers);
            this._pointers = pointers;

            var pi2 = Math.PI / 2.0;

            var top = new THREE.Mesh(planeGeometry, topMat);
            top.renderOrder = renderOrder;
            top.frustumCulled = false;
            top.position.set(0, 0, 0.5);
            rotated.add(top);

            var bottom = top.clone();
            bottom.material = (<THREE.Material>bottom.material).clone();
            //bottom.material.map = (maps.bottom);
            (bottom.material as THREE.RawShaderMaterial).uniforms["map"].value = (maps.bottom);
            bottom.position.set(0, 0, -0.5);
            bottom.rotation.x = Math.PI;
            rotated.add(bottom);

            var front = top.clone();
            front.material = (<THREE.Material>front.material).clone();
            //front.material.map = (maps.front);
            (front.material as THREE.RawShaderMaterial).uniforms["map"].value = (maps.front);
            front.position.set(0, -0.5, 0);
            front.rotation.x = pi2;
            rotated.add(front);

            var back = top.clone();
            back.material = (<THREE.Material>back.material).clone();
            //back.material.map = (maps.back);
            (back.material as THREE.RawShaderMaterial).uniforms["map"].value = (maps.back);
            back.position.set(0, 0.5, 0);
            back.rotation.x = pi2;
            back.rotation.y = Math.PI;
            rotated.add(back);

            var left = top.clone();
            left.material = (<THREE.Material>left.material).clone();
            //left.material.map = (maps.left);
            (left.material as THREE.RawShaderMaterial).uniforms["map"].value = (maps.left);
            left.position.set(-0.5, 0, 0);
            left.rotation.x = pi2;
            left.rotation.y = -pi2;
            rotated.add(left);

            var right = top.clone();
            right.material = (<THREE.Material>right.material).clone();
            //right.material.map = (maps.right);
            (right.material as THREE.RawShaderMaterial).uniforms["map"].value = (maps.right);
            right.position.set(0.5, 0, 0);
            right.rotation.x = pi2;
            right.rotation.y = pi2;
            rotated.add(right);

            var sides = [top, bottom, front, back, left, right];

            for (var i = 0, j = sides.length; i < j; i++) {
                var side = sides[i],
                    map = (side.material as THREE.RawShaderMaterial).uniforms["map"].value;
                //map = side.material.map;
                if (map)
                    map.magFilter = map.minFilte = THREE.LinearFilter;
                (<any>side)._useUv = true;
            }

            (<any>top)._norm = new THREE.Vector3(0, 0, 1);
            (<any>bottom)._norm = new THREE.Vector3(0, 0, -1);
            (<any>front)._norm = new THREE.Vector3(0, -1, 0);
            (<any>back)._norm = new THREE.Vector3(0, 1, 0);
            (<any>left)._norm = new THREE.Vector3(-1, 0, 0);
            (<any>right)._norm = new THREE.Vector3(1, 0, 0);

            //Highligt sides----------------------
        
            var ui = sides.slice(0);

            var highlights = new THREE.Object3D();
            highlights.visible = false;
            this.add(highlights);
            this._highlights = highlights;

            var highlightsRotated = new THREE.Object3D();
            highlightsRotated.visible = false;
            rotated.add(highlightsRotated);
            this._highlightsRotated = highlightsRotated;

            var side = new THREE.Mesh(planeTopGeometry, sidesHoverMat);

            side.renderOrder = renderOrder+1;
            side.frustumCulled = false;
            highlightsRotated.add(side);

            side = side.clone();
            side.rotation.x = pi2;
            highlightsRotated.add(side);

            side = side.clone();
            side.rotation.z = pi2;
            highlightsRotated.add(side);

            //side = side.clone();
            //side.rotation.z = Math.PI;
            //highlightsRotated.add(side);

            side = side.clone();
            side.rotation.z = -pi2;
            highlightsRotated.add(side);

            side = side.clone();
            side.rotation.z = 0;
            side.rotation.x = Math.PI;
            highlightsRotated.add(side);

            var clone4 = (tObj, src, k) => {
                var n = src.clone();
                n.rotation[k] = pi2;
                tObj.add(n);

                n = src.clone();
                n.rotation[k] = Math.PI;
                tObj.add(n);

                n = src.clone();
                n.rotation[k] = -pi2;
                tObj.add(n);
            };

            //edges
            var edge = new THREE.Mesh(edgeGeometry, sidesHoverMat);
            edge.renderOrder = renderOrder+1;
            edge.frustumCulled = false;
            highlightsRotated.add(edge);

            clone4(highlightsRotated, edge, 'z');

            edge = edge.clone();
            edge.rotation.x = pi2;
            highlightsRotated.add(edge);
            clone4(highlightsRotated, edge, 'y');

            //edge = edge.clone();
            //edge.rotation.x = -pi2;
            //highlightsRotated.add(edge);
            //clone4(highlightsRotated, edge, 'y');

            //corners
            var corner = new THREE.Mesh(cornerGeometry, sidesHoverMat);
            corner.renderOrder = renderOrder+1;
            corner.frustumCulled = false;
            highlightsRotated.add(corner);

            clone4(highlightsRotated, corner, 'z');
        
            //corner = corner.clone();
            //corner.rotation.x = -pi2;
            //highlightsRotated.add(corner);
            //clone4(highlightsRotated, corner, 'y');

            //Highligt sides end------------------
        
            //compass elements

            this._maps = maps;

            var compass = new THREE.Mesh(compassGeometry, compassMat);
            compass.rotation.x = pi2;
            compass.renderOrder = renderOrder+2;
            compass.frustumCulled = false;
            this._compass = compass;
            rotated.add(compass);

            var pointer = new THREE.Mesh(pointerGeometry, stdMat.clone());
            pointer.rotation.x = pi2;
            pointer.renderOrder = renderOrder;
            pointer.frustumCulled = false;
            (<any>pointer)._side = 1;
            pointers.add(pointer);

            pointer = pointer.clone();
            pointer.rotation.y = pi2;
            (<any>pointer)._side = 2;
            pointers.add(pointer);

            pointer = pointer.clone();
            pointer.rotation.y = -pi2;
            (<any>pointer)._side = 4;
            pointers.add(pointer);

            pointer = pointer.clone();
            pointer.rotation.y = Math.PI;
            (<any>pointer)._side = 3;
            pointers.add(pointer);

            this._lowerPointer = pointer;
            this._pointerMat = pointer.material as THREE.MeshBasicMaterial;

            for (var l = 0, m = pointers.children.length; l < m; l++) {
                var pt = pointers.children[l] as THREE.Mesh;
                var pth = pt.clone();
                pth.material = hoverMat;
                pth.position.z = 0.01;
                (<any>pt)._highlight = pth;
                highlights.add(pth);
                ui.push(pt);
            }

            //HOME
            var home = new THREE.Mesh(homeGeometry, currentTheme === "Tritec" ? hoverMat : stdMat);
            home.rotation.x = pi2;
            home.renderOrder = renderOrder;
            home.frustumCulled = false;
            (<any>home)._resetView = true;
            (<any>home)._norm = new THREE.Vector3(0, 0, 1);
            this.add(home);
            ui.push(home);

            var homeHighlight = home.clone();
            homeHighlight.material = hoverMat;
            homeHighlight.position.z = 0.01;
            (<any>home)._highlight = homeHighlight;
            highlights.add(homeHighlight);

            //ROLLING

            var rolls: THREE.Mesh[] = [];

            var roll = new THREE.Mesh(rollGeometry, stdMat);
            roll.rotation.x = pi2;
            roll.renderOrder = renderOrder;
            roll.frustumCulled = false;
            (<any>roll)._roll = 1;
            ui.push(roll);
            rolls.push(roll);
            this.add(roll);

            var rollHighlight = roll.clone();
            rollHighlight.material = hoverMat;
            rollHighlight.position.z = 0.01;
            (<any>roll)._highlight = rollHighlight;
            highlights.add(rollHighlight);

            roll = new THREE.Mesh(rollGeometry2, stdMat);
            roll.rotation.x = pi2;
            roll.renderOrder = renderOrder;
            roll.frustumCulled = false;
            (<any>roll)._roll = -1;
            ui.push(roll);
            rolls.push(roll);
            this.add(roll);

            rollHighlight = roll.clone();
            rollHighlight.material = hoverMat;
            rollHighlight.position.z = 0.01;
            (<any>roll)._highlight = rollHighlight;
            highlights.add(rollHighlight);

            //DROPWDOWN

            //var dropdown = new THREE.Mesh(dropdownGeometry, stdMat);
            //dropdown.rotation.x = pi2;
            //dropdown.renderOrder = renderOrder;
            //dropdown.frustumCulled = false;
            //(<any>dropdown)._click = ToolBox.toggleNavDropdown;
            //this.add(dropdown);
            //ui.push(dropdown);

            //var dropdownHighlight = dropdown.clone();
            //dropdownHighlight.material = hoverMat;
            //dropdownHighlight.position.z = 0.01;
            //(<any>dropdown)._highlight = dropdownHighlight;
            //highlights.add(dropdownHighlight);

            this._rolls = rolls;
            this._sides = sides;
            this._ui = ui;

            if (hideControls) {
                rolls.forEach((m: THREE.Object3D) => {
                    m.visible = false;
                });
                pointers.children.forEach((m: THREE.Object3D) => {
                    m.visible = false;
                });
                pointers.visible = false;
                highlights.visible = false;
                //dropdown.visible = false;
                home.visible = false;
                roll.visible = false;
                this._lowerPointer.visible = false;
            }

            //DEBUG
            //var dGeo = new THREE.BoxGeometry(2, 0.5, 1, 1, 1, 1);
            //var dMat = new THREE.MeshBasicMaterial({
            //    color: 0xFF0000,
            //    transparent: true
            //});
            //var dMesh = new THREE.Mesh(dGeo, dMat);
            //dMesh.renderOrder = renderOrder+3;
            //this.add(dMesh);
        }

        UpdateOrientation = (() => {
            var rotmat = new THREE.Matrix4();
            return () => {
                var camera = this._camera,
                    uiCamera = this._uiCamera,
                    paddingPx = this._paddingPx,
                    maxSizePx = this._maxSizePx,
                    pos = this.position;

                camera.matrixWorldInverse.getInverse(camera.matrixWorld);
                rotmat.extractRotation(camera.matrixWorldInverse);
                this._rotatedRoot.setRotationFromMatrix(rotmat);

                var t = Math.ceil(maxSizePx + paddingPx),
                    t2 = t * 2;
                pos.set(uiCamera.right - t, uiCamera.top - t, 0);
                this._minPos.set((uiCamera.right - t2) / uiCamera.right, (uiCamera.top - t2) / uiCamera.top);

                var cn = camera.getWorldDirection(new THREE.Vector3()),
                    pointerMat = this._pointerMat;

                var wx = this._worldX.dot(cn);
                var wy = this._worldY.dot(cn);
                var wz = this._worldZ.dot(cn);

                var pOpacity = (1.0 - Math.min(((Math.abs(wx) + Math.abs(wy) + Math.abs(wz)) - 1.0) * 5, 1.0)) * 0.8;

                if (pointerMat.opacity !== pOpacity) {
                    pointerMat.opacity = pOpacity;
                    pointerMat.needsUpdate = true;
                    this._pointers.visible = pOpacity > 0.01;
                }
            
                //hide lower pointer
                if (!this._hideControls) {
                    var lpVisible = wz < -0.999;
                    var lp = this._lowerPointer;
                    if (lp.visible !== lpVisible)
                        lp.visible = lpVisible;

                    var rolls = this._rolls;
                    for (var i = rolls.length; i--;) {
                        if (rolls[i].visible !== lpVisible)
                            rolls[i].visible = lpVisible;
                    }
                }
            };
        })();

        SetCompassOrientation(degree: number) {
            (<any>this._compass).rotation.y = THREE.MathUtils.degToRad(degree - 180);
        }

        GetCompassOrientation() {
            return THREE.MathUtils.radToDeg((<any>this._compass).rotation.y) + 180;
        }

        SetElevationAngle(angle: THREE.Quaternion) {
            var quat = new THREE.Quaternion().copy(angle).inverse();
            this._rotatedObjects.quaternion.copy(quat);
            this._worldX.set(1, 0, 0).applyQuaternion(quat);
            this._worldY.set(0, 1, 0).applyQuaternion(quat);
            this._worldZ.set(0, 0, 1).applyQuaternion(quat);
            
            //var radx = THREE.MathUtils.degToRad(-degree);
            //var rady = THREE.MathUtils.degToRad(0);
            //this._rotatedObjects.rotation.x = radx;
            //this._rotatedObjects.rotation.y = rady;
            //this._worldZ.set(0, 0, 1).applyEuler(new THREE.Euler(radx, rady, 0));
            //this._worldY.copy(this._worldZ).cross(this._worldX).normalize();
        }

        setHovering(b: boolean) {
            if (this._isHovering !== b) {
                if (b)
                    Controller.Current.viewModel.Cursor = 'pointer';
                else
                    Controller.Current.viewModel.Cursor = Controller.Current.viewModel.GetCurrentDefaultCursor();
                this._isHovering = b;
            }
        }

        onMouseMove(mouse: { x: number; y: number; }) {
            var minPos = this._minPos,
                highlightsRotated = this._highlightsRotated,
                highlights = this._highlights;

            if (mouse.x < minPos.x || mouse.y < minPos.y) {
                if (highlights.visible || highlightsRotated.visible) {
                    highlights.visible = false;
                    highlightsRotated.visible = false;
                }
                this.setHovering(false);
                return;
            }

            var uiCamera = this._uiCamera,
                raycaster = this._raycaster;

            raycaster.setFromCamera(mouse, uiCamera);

            var intersecs = raycaster.intersectObjects(this._ui.filter((ui) => { return ui.visible && (<THREE.Material>ui.material).opacity > 0.1; }));
            if (!intersecs.length) {
                if (highlights.visible || highlightsRotated.visible) {
                    highlights.visible = false;
                    highlightsRotated.visible = false;
                }
                this.setHovering(false);
                return;
            }

            //highlights.children.forEach(function (hs) { hs.visible = true; });
            //var highlightIntersecs = raycaster.intersectObjects(highlights.children.filter(function (ui) { return ui.visible && ui.material.opacity > 0.1; }));
            var highlightIntersecs = intersecs.map((i) => { return (<any>i.object)._highlight as THREE.Mesh; }).filter((i) => { return !!i; });

            if (highlightIntersecs.length) {
                var hIntersec = highlightIntersecs[0];
                highlights.visible = true;
                highlights.children.forEach((hs) => { hs.visible = false; });
                hIntersec.visible = true;
                if (highlightsRotated.visible) highlightsRotated.visible = false;
                this.setHovering(true);
            } else {
                highlightsRotated.children.forEach((hs) => { hs.visible = true; });
                var hlIntersecs = raycaster.intersectObjects(highlightsRotated.children);

                if (hlIntersecs.length) {
                    var hlIntersec = hlIntersecs[0];
                    highlightsRotated.visible = true;
                    highlightsRotated.children.forEach((hs) => { hs.visible = false; });
                    hlIntersec.object.visible = true;
                    if (highlights.visible) highlights.visible = false;
                    this.setHovering(true);
                } else {
                    if (highlights.visible || highlightsRotated.visible) {
                        highlights.visible = false;
                        highlightsRotated.visible = false;
                    }
                    this.setHovering(false);
                }
            }
        }

        handleMouseDown = (() => {
            var cameraNormal = new THREE.Vector3();
            var targetNormal = new THREE.Vector3();
            var targetUpNormal = new THREE.Vector3();
            var targetRight = new THREE.Vector3();
            var roofRotation = new THREE.Quaternion();
            return (mouse: THREE.Vector2, controls: CameraControls, controller: LS.Client3DEditor.Controller) => {
                var minPos = this._minPos;
                if (mouse.x < minPos.x || mouse.y < minPos.y)
                    return false;

                var camera = this._camera,
                    uiCamera = this._uiCamera,
                    raycaster = this._raycaster;

                raycaster.setFromCamera(mouse, uiCamera);

                var intersecs = raycaster.intersectObjects(this._ui.filter(ui => ui.visible && (<THREE.Material>ui.material).opacity > 0.1));
                if (!intersecs.length)
                    return false;
                
                var highlights = this._highlights;
                if (highlights.visible) highlights.visible = false;

                var intersection = intersecs[0];

                if ((<any>intersection.object)._click) {
                    (<any>intersection.object)._click();
                    return true;
                }

                if ((<any>intersection.object)._resetView) {
                    Controller.Current.resetView();
                    return true;
                }

                //var resetView = !!(<any>intersection.object)._resetView;
                var useUv = !!(<any>intersection.object)._useUv;

                camera.getWorldDirection(cameraNormal).negate();

                controls.rollEnd.copy(camera.up);

                if ((<any>intersection.object)._roll) {
                    targetUpNormal.crossVectors(camera.up, cameraNormal).normalize().multiplyScalar((<any>intersection.object)._roll);

                    targetNormal.copy(cameraNormal);

                    controls.rotateStart.copy(targetNormal);
                    controls.rotateEnd.copy(cameraNormal);

                    controls.rollStart.copy(targetUpNormal);

                    return true;
                }

                if ((<any>intersection.object)._norm)
                    targetNormal.copy((<any>intersection.object)._norm);
                else
                    spt.ThreeJs.utils.toNearestAxisNormal(cameraNormal, targetNormal);
                
                spt.ThreeJs.utils.getUpNormal(targetNormal, targetUpNormal);

                if ((<any>intersection.object)._side) {
                    switch ((<any>intersection.object)._side) {
                        case 1:
                            targetNormal.copy(targetUpNormal);
                            break;
                        case 2:
                            targetNormal.crossVectors(targetUpNormal, targetNormal).normalize().negate();
                            break;
                        case 3:
                            targetNormal.copy(targetUpNormal).negate();
                            break;
                        case 4:
                            targetNormal.crossVectors(targetUpNormal, targetNormal).normalize();
                            break;
                    }
                    targetUpNormal = spt.ThreeJs.utils.getUpNormal(targetNormal);
                }

                targetRight.crossVectors(targetUpNormal, targetNormal).normalize();

                var uvDist = this._uvDist,
                    uvDistU = this._uvDistU;

                var isTop = targetNormal.z > 0.1,
                    isBottom = targetNormal.z < -0.1,
                    uv = (<any>intersection).uv,
                    toLeft = useUv && uv.x <= uvDist,
                    toRight = useUv && uv.x >= uvDistU,
                    toBottom = useUv && uv.y <= uvDist,
                    toTop = useUv && uv.y >= uvDistU;

                if (toLeft)
                    targetNormal.sub(targetRight);
                if (toRight)
                    targetNormal.add(targetRight);
                if (toBottom)
                    targetNormal.sub(targetUpNormal);
                if (toTop)
                    targetNormal.add(targetUpNormal);
                targetNormal.normalize();

                if ((!isTop && !isBottom) || toLeft || toRight || toBottom || toTop)
                    targetUpNormal.set(0, 0, -1).cross(targetNormal).normalize().cross(targetNormal).normalize();

                if (targetNormal.z < 0)
                    return false;

                //if (resetView) {
                //    var roofyAngle = Math.atan2(this._worldZ.x, this._worldZ.z),
                //        roofyAngleDegree = Math.abs(roofyAngle / Math.PI * 180),
                //        roofxAngle = Math.atan2(this._worldZ.y, this._worldZ.z),
                //        roofxAngleDegree = -roofxAngle / Math.PI * 180;
                    
                //    if (roofxAngleDegree > 0 && roofxAngleDegree < 45)
                //        targetNormal.applyAxisAngle(new THREE.Vector3(1, 0, 0), -roofxAngle);

                //    if (roofyAngleDegree > 0 && roofyAngleDegree < 45)
                //        targetNormal.applyAxisAngle(new THREE.Vector3(0,1,0), roofyAngle);
                //}

                controls.rotateStart.copy(targetNormal);
                controls.rotateEnd.copy(cameraNormal);

                controls.rollStart.copy(targetUpNormal);

                //if (resetView) {
                //    var rw = controller.roofWidth,
                //        rh = controller.roofHeight,
                //        rw2 = rw / 2,
                //        rh2 = rh / 2,
                //        target = controls.target;

                //    controls.zoomStart = camera.zoom;
                //    controls.zoomEnd = Math.min((controller.ContainerWidth - controller.CameraPadding) / rw, (controller.ContainerHeight - controller.CameraPadding) / rh);

                //    controls.panStart.set(0, 0);
                //    controls.panEnd.set(0, 0);

                //    controls.moveStart.copy(target);
                //    controls.moveEnd.set(rw2, rh2, 0);
                //} else {
                    roofRotation.setFromEuler(this._rotatedObjects.rotation);

                    controls.rotateStart.applyQuaternion(roofRotation);
                    controls.rollStart.applyQuaternion(roofRotation);
                //}
                setTimeout(this.onMouseMove.bind(this, mouse), 0);
                return true;
            };
        })();
    }
}