module LS.Client3DEditor {

    export enum ClientInstanceOption {
        None = 0,
        StaticSelected = 1
    }

    export interface IInstanceData extends MapWithEvents {
        ClientObjectInstance?: ClientObjectInstance;
        Id: string;
        X: number;
        Y: number;
        Z: number;
        SizeX: number;
        SizeY: number;
        IsActive: number;
        InstanceColor: number;
        OptionCode?: number;
        ModCount?: number;
        Orientation?: number;
        Meta: string;
    }

    export class ClientObjectInstance extends THREE.Mesh {
        constructor(clientObject: ClientObject, data: IInstanceData) {
            super(clientObject.ClientObjectGeometry, Controller.Current.CommonMaterial);

            var self = this,
                instanceData = <IInstanceData>(new MapWithEvents(data));
            if (!instanceData.SizeX)
                instanceData.SizeX = 1;
            if (!instanceData.SizeY)
                instanceData.SizeY = 1;

            Object.defineProperty(instanceData, 'ClientObjectInstance', { enumerable: false, configurable: true, writable: false, value: self });
            instanceData.addListener('update', this.OnDataChanged);
            this.clientObject = clientObject;
            this.instanceData = instanceData;
            this.isSelected = false;
            this._needsUpdate = true;
            this.instanceColor = new THREE.Color();
            this.instanceAlpha = 1.0;
            this.instanceAlphaOverride = 0;
            this.matrixAutoUpdate = false;
            clientObject._positionChanged = true;
            Controller.Current.Instances[instanceData.Id] = this;
        }

        removeMe(skipCheck?: boolean) {
            var clientObject = this.clientObject,
                instanceContext = clientObject.instanceContext;

            if (clientObject.canSelectInstances && instanceContext)
                instanceContext.removeFromOctree(<THREE.Object3D>this);

            var clientObjectInstances = clientObject.ClientObjectInstances;

            var idx = clientObjectInstances.indexOf(this);
            if (idx !== -1)
                clientObjectInstances.splice(idx, 1);

            clientObject._positionChanged = true;

            this.OnDataChanged(new UpdateManagerChangeEvent(this.instanceData, 'remove', null, null, null, !!skipCheck));

            this.dispose();
        }

        dispose() {
            if (this._isDisposed)
                return;
            delete Controller.Current.Instances[this.instanceData.Id];
            this.instanceData.removeListener();
            delete this.clientObject;
            delete this.instanceData.ClientObjectInstance;
            this._isDisposed = true;
        }

        OnSharedMeshChanged() {
            this.geometry = this.clientObject.ClientObjectGeometry;
        }

        OnDataChanged(e: IChangeEvent) {
            var clientObjectInstance = (e.object as IInstanceData).ClientObjectInstance as ClientObjectInstance;

            if (e.name === 'Id') {
                delete Controller.Current.Instances[e.oldValue];
                Controller.Current.Instances[e.newValue] = clientObjectInstance;
            }

            e.dataType = clientObjectInstance.clientObject.dataType;

            Controller.Current.updateManager.handleClientObjectUpdate(e);
            clientObjectInstance.OnChanged();
        }

        OnChanged() {
            this._needsUpdate = true;
            this.clientObject._needsUpdate = true;
        }

        setVisible(b: boolean) {
            this.visible = !!b;
            this.clientObject._positionChanged = true;
            this.OnChanged();
        }

        setPosition(x: number, y: number, z: number) {
            var instanceData = this.instanceData;
            instanceData.update("X", x);
            instanceData.update("Y", y);
            instanceData.update("Z", z);
        }

        setSize(x: number, y: number) {
            var instanceData = this.instanceData;
            instanceData.update("SizeX", x);
            instanceData.update("SizeY", y);
        }

        setByVector(v: THREE.Vector3) {
            this.setPosition(v.x, v.y, v.z);
        };

        updateSelectionBounds(selBounds: THREE.Box3) {

            let bb = this.geometry.boundingBox,
                instanceData = this.instanceData,
                sizeX = instanceData.SizeX,
                sizeY = instanceData.SizeY,
                w = this.clientObject.userData.GeoWidth || ((bb.max.x - bb.min.x) * sizeX),
                h = this.clientObject.userData.GeoHeight || ((bb.max.y - bb.min.y) * sizeY),
                d = bb.max.z/* * instanceData.SizeZ*/,
                x = instanceData.X + bb.min.x * sizeX,
                y = instanceData.Y + bb.min.y * sizeY,
                z = instanceData.Z,
                selMin = selBounds.min,
                selMax = selBounds.max;
            
            selMin.set(Math.min(selMin.x, x), Math.min(selMin.y, y), Math.min(selMin.z, z));
            selMax.set(Math.max(selMax.x, x + w), Math.max(selMax.y, y + h), Math.max(selMax.z, z + d));

            //var mat = this.matrixWorld,
            //    bb = this.geometry.boundingBox;
            //selBounds.expandByPoint(bb.min.clone().applyMatrix4(mat));
            //selBounds.expandByPoint(bb.max.clone().applyMatrix4(mat));
        }

        update() {
            if (!this._needsUpdate)// || !this.visible)
                return;
            this._needsUpdate = false;

            var data = this.instanceData,
                position = this.position,
                scale = this.scale;
            if (position.x !== data.X || position.y !== data.Y || position.z !== data.Z || scale.x !== data.SizeX || scale.y !== data.SizeY) {
                this.position.set(data.X, data.Y, data.Z);
                this.scale.set(data.SizeX, data.SizeY, 1)
                this.updateMatrix();
                this.updateMatrixWorld(true);
                //this.matrixWorldNeedsUpdate = true;
                this.clientObject._positionChanged = true;
            }

            if (data.InstanceColor)
                this.instanceColor.setHex(data.InstanceColor).multiplyScalar(2).addScalar(-1);
            else if (data.OptionCode & ClientInstanceOption.StaticSelected)
                this.instanceColor.setRGB(-0.5, 0.5, -0.5);
            else
                this.instanceColor.setRGB(0, 0, 0);

            if (this.isSelected)
                this.instanceColor.addScalar(0.25);

            if (this.instanceAlphaOverride)
                this.instanceAlpha = this.instanceAlphaOverride;
            else if (data.IsActive)
                this.instanceAlpha = 1;
            else
                this.instanceAlpha = 0.65;

        }

        clientObject: ClientObject;
        instanceData: IInstanceData;
        isSelected: boolean;
        private _needsUpdate: boolean;
        instanceColor: THREE.Color;
        instanceAlpha: number;
        instanceAlphaOverride: number;
        _isDisposed: boolean;
    }

    export interface ClientObjectUserData {
        Options: number;
        Orientation: number;
        GeoWidth: number;
        GeoHeight: number;
        ModCount: number;
        IconInsertSet: string;
        IconInsertIndex: number;
        GroupIconInsert: string;
        GroupIconInsertSet?: string;
        GroupIconInsertIndex?: number;
        NumModules: number;
        TypeId: string;
        BaseParentId: string;
        Transparency: number;
        IsHidden: boolean;
    }

    export class ClientObject extends THREE.Object3D {

        static GetClientObjectUserData(clientObjectJson: ClientObjectUserData | SolarProTool.IClient3DObect): ClientObjectUserData {
            return {
                Options: clientObjectJson.Options,
                Orientation: clientObjectJson.Orientation,
                GeoWidth: clientObjectJson.GeoWidth || 0,
                GeoHeight: clientObjectJson.GeoHeight || 0,
                ModCount: clientObjectJson.ModCount,
                IconInsertSet: clientObjectJson.IconInsertSet,
                IconInsertIndex: clientObjectJson.IconInsertIndex,
                NumModules: clientObjectJson.NumModules,
                TypeId: clientObjectJson.TypeId,
                GroupIconInsert: clientObjectJson.GroupIconInsert,
                GroupIconInsertSet: clientObjectJson.GroupIconInsertSet,
                GroupIconInsertIndex: clientObjectJson.GroupIconInsertIndex,
                BaseParentId: null,
                Transparency: 0,
                IsHidden: false
            } as ClientObjectUserData;
        }

        constructor(sharedMeshes: THREE.Mesh[], type: string, baseParentId: string, clientObjectData?: ClientObjectUserData | SolarProTool.IClient3DObect) {
            super();

            var _clientObjectGeometry = null;
            var _clientObjectInstances = [];

            Object.defineProperty(this, 'ClientObjectInstances', { enumerable: true, configurable: true, get: () => { return _clientObjectInstances; }, set: (value) => { _clientObjectInstances = value; this._needsUpdate = true; } });
            Object.defineProperty(this, 'ClientObjectGeometry', {
                enumerable: true, configurable: true, get: () => {
                    if (_clientObjectGeometry === null) {
                        var geometry = new THREE.Geometry(),
                            sharedMeshes = this.SharedMeshes;
                        for (var i = sharedMeshes.length; i--;) {
                            var sharedMesh = sharedMeshes[i];
                            geometry.merge(sharedMesh.geometry instanceof THREE.BufferGeometry ? new THREE.Geometry().fromBufferGeometry(<THREE.BufferGeometry>sharedMesh.geometry) : (<THREE.Geometry>sharedMesh.geometry), sharedMesh.matrix, 0);
                        }
                        geometry.mergeVertices();
                        if (geometry.boundingBox == null)
                            geometry.computeBoundingBox();
                        _clientObjectGeometry = geometry;
                    }
                    return _clientObjectGeometry;
                }
            });

            this.matrixAutoUpdate = false;

            this.SharedMeshes = sharedMeshes;

            //ClientObjectUserData
            var data = ClientObject.GetClientObjectUserData(clientObjectData);
            if (!data.GroupIconInsertSet)
                data.GroupIconInsertSet = data.IconInsertSet;
            if (!data.GroupIconInsertIndex)
                data.GroupIconInsertIndex = data.IconInsertIndex;
            data.BaseParentId = baseParentId ? baseParentId.toLowerCase() : null;
            this.userData = data;
            this.dataType = type;
            Controller.Current.Objects[this.id] = this;

            if (this.canInsertInstances) {
                var instanceContext = this.instanceContext;
                if (instanceContext)
                    instanceContext.insertableClientObjects.push(this);
            }
        }

        userData: ClientObjectUserData;
        _positionChanged: boolean = true;
        private _offsetsAttribute: THREE.InstancedBufferAttribute/* | FakeInstancedBufferAttribute*/ = null;
        private _scalesAttribute: THREE.InstancedBufferAttribute/* | FakeInstancedBufferAttribute*/ = null;
        private _colorsAttribute: THREE.InstancedBufferAttribute/* | FakeInstancedBufferAttribute*/ = null;
        SharedMeshes: THREE.Mesh[];
        BuildMeshes: THREE.Mesh[] = [];
        txtHolder: spt.ThreeJs.utils.SDFTextObjectInstances;
        private _buildSphere: THREE.Sphere = new THREE.Sphere();
        private _buildBounds: THREE.Box3 = new THREE.Box3();
        private _updateBounds: THREE.Box3 = new THREE.Box3();
        private _updateBoundingSphere: THREE.Sphere = new THREE.Sphere();
        _alpha: number = 1.0;
        dataType: string;
        isClientObject = true;
        private _maxInstancedCount: number;
        _needsUpdate: boolean = true;
        ClientObjectInstances: ClientObjectInstance[];
        //virtual geometry of one instance (used for octree)
        ClientObjectGeometry: THREE.Geometry;
        _isDisposed: boolean;
        isHidden: boolean = false;

        setHidden(v: boolean) {
            this.isHidden = !!v;
            this._needsUpdate = true;
        }

        private _onControllerUpdate: () => void = null;
        private _onControllerBeforeDelete: () => void = null;

        get instanceContext(): InstanceContext {
            return this.userData && Controller.Current && Controller.Current.getInstanceContext(this.userData.BaseParentId);
        }

        //get octree(): THREE.Octree {
        //    if (this.canSelectInstances) {
        //        var instanceContext = this.instanceContext;
        //        return instanceContext && instanceContext.octree;
        //    }
        //    return null;
        //}

        get userOptions() {
            return this.isHidden ? Client3DObectOptions.None : this.userData && this.userData.Options;
        }

        get canMoveInstances(): boolean {
            return !!(this.userOptions & Client3DObectOptions.CanMoveInstances);
        }

        get canSelectInstances(): boolean {
            return !!(this.userOptions & Client3DObectOptions.CanSelectInstances);
        }

        get canInsertInstances(): boolean {
            return !!(this.userOptions & (Client3DObectOptions.CanInsertInstances | Client3DObectOptions.CanInsertScaled));
        }
        
        get canScaleInsertInstances(): boolean {
            return !!(this.userOptions & Client3DObectOptions.CanInsertScaled);
        }

        get hasNoSimpleGeometry(): boolean {
            return !!(this.userOptions & Client3DObectOptions.NoSimpleGeometry);
        }

        get hasScale(): boolean {
            return !!(this.userOptions & (Client3DObectOptions.ScaleX | Client3DObectOptions.ScaleY));
        }

        get hasScaleX(): boolean {
            return !!(this.userOptions & Client3DObectOptions.ScaleX);
        }

        get hasScaleY(): boolean {
            return !!(this.userOptions & Client3DObectOptions.ScaleY);
        }

        get canBeScaled(): boolean {
            return !!(this.userOptions & (Client3DObectOptions.CanBeScaledX | Client3DObectOptions.CanBeScaledY));
        }

        get canBeScaledX(): boolean {
            return !!(this.userOptions & Client3DObectOptions.CanBeScaledX);
        }

        get canBeScaledY(): boolean {
            return !!(this.userOptions & Client3DObectOptions.CanBeScaledY);
        }

        dispose() {
            if (this._isDisposed)
                return;

            this.removeFromScene();

            var controller = Controller.Current;

            if (controller) {

                controller.viewModel.updateModuleCount(this.id, 0);
                if (controller.Objects[this.id])
                    delete controller.Objects[this.id];

                if (this.canInsertInstances) {
                    var instanceContext = this.instanceContext;
                    if (instanceContext)
                        instanceContext.insertableClientObjects.remove(this);
                }
            }

            this.BuildMeshes = [];
            if (this.txtHolder) {
                this.txtHolder.dispose();
                this.txtHolder = null;
            }

            this.clearClientObjectInstances();

            this._isDisposed = true;
        }

        updateModuleCount() {
            var data = this.userData, modsPerInstance = data.NumModules;
            if (modsPerInstance)
                Controller.Current.viewModel.updateModuleCount(this.id, modsPerInstance * this.ClientObjectInstances.length);
        }

        clearClientObjectInstances() {
            ArrayHelper.iterateArray(this.ClientObjectInstances, (m: ClientObjectInstance) => {
                m.dispose();
            });
            this.ClientObjectInstances = [];
        }

        insertClientObjectInstance(data: IInstanceData, skipCheck?: boolean) {
            if (!data.Id || data.Id === "")
                data.Id = spt.Utils.GenerateGuid();
            var newInstance = new ClientObjectInstance(this, data),
                instanceContext = this.instanceContext;
            this.ClientObjectInstances.push(newInstance);
            newInstance.updateMatrixWorld(true);

            if (this.canSelectInstances && instanceContext)
                instanceContext.addToOctree(newInstance);

            newInstance.OnDataChanged(new UpdateManagerChangeEvent(newInstance.instanceData, 'insert', null, null, null, !!skipCheck));
        }

        SetClientObjectInstancesFromArrayProperties(genericClientObjectInstanceData: SolarProTool.IClientObectDataArrays, count: number) {
            this.clearClientObjectInstances();
            var clientObjectInstances = this.ClientObjectInstances,
                clientObject = this;

            IterateClientObectDataArrays(genericClientObjectInstanceData, data => {
                var newInstance = new ClientObjectInstance(clientObject, data);
                clientObjectInstances.push(newInstance);
                newInstance.updateMatrixWorld(true);
            }, count);
        }

        clear() {

            this.BuildMeshes = [];
            if (this.txtHolder) {
                this.txtHolder.dispose();
                this.txtHolder = null;
            }
            spt.ThreeJs.utils.emptyObject3D(this, true, true, false);
        }

        update() {
            var bounds = this._updateBounds,
                boundingSphere = this._updateBoundingSphere;

            ArrayHelper.iterateArray(this.ClientObjectInstances, (m) => {
                //if (m.visible)
                m.update();
            });

            var clientObjects = this.ClientObjectInstances, //.filter(function (m) { return m.visible; }),
                objectsLength = clientObjects.length,
                buildMeshes = this.BuildMeshes,
                positionsChanges = this._positionChanged,
                isDragging = Controller.Current.viewModel.isDragging,
                isScaling = Controller.Current.viewModel.isScaling;

            if (!objectsLength || this.isHidden) {
                this.visible = false;
                this._needsUpdate = false;
                return;
            }

            if (!isDragging && bounds.isEmpty())
                positionsChanges = true;

            if (!buildMeshes.length || this._maxInstancedCount < objectsLength/* || (!Detector.webglParameters.supportsInstancedArrays && this._maxInstancedCount !== objectsLength)*/) {
                this.build();
                buildMeshes = this.BuildMeshes;
            }

            var offsets = this._offsetsAttribute,
                scales = this._scalesAttribute,
                colors = this._colorsAttribute,
                alpha = this._alpha,
                maxScale = 1;

            this.visible = true;

            if (offsets != null) {
                //multiple instances

                var instanceIndex = 0;

                offsets.needsUpdate = positionsChanges;
                if (scales)
                    scales.needsUpdate = positionsChanges;
                colors.needsUpdate = true;

                if (isDragging) {
                    bounds.makeEmpty();
                    var diff = Controller.Current.viewModel.diffSnapPosition;
                    offsets.needsUpdate = true;
                    ArrayHelper.iterateArray(clientObjects, (m) => {
                        var pos = m.position;
                        bounds.expandByPoint(pos);

                        if (m.visible) {
                            var a = m.instanceAlpha * alpha;
                            if (m.isSelected)
                                offsets.setXYZ(instanceIndex, pos.x + diff.x, pos.y + diff.y, pos.z + diff.z + 10);
                            else
                                offsets.setXYZ(instanceIndex, pos.x, pos.y, pos.z);
                            var col = m.instanceColor;
                            colors.setXYZW(instanceIndex, col.r, col.g, col.b, a);
                            ++instanceIndex;
                        }
                    });
                } else if (scales && isScaling) {
                    bounds.makeEmpty();
                    var dt = Controller.Current.viewModel.diffScalePosition,
                        ds = Controller.Current.viewModel.diffScale;

                    scales.needsUpdate = true;
                    offsets.needsUpdate = true;
                    ArrayHelper.iterateArray(clientObjects, (m) => {
                        var pos = m.position,
                            sc = m.scale;
                        bounds.expandByPoint(pos);
                        maxScale = Math.max(maxScale, sc.x, sc.y, sc.z);
                        
                        if (m.visible) {
                            var a = m.instanceAlpha * alpha;
                            if (m.isSelected) {
                                offsets.setXYZ(instanceIndex, pos.x + dt.x, pos.y + dt.y, pos.z + dt.z);
                                scales.setXYZ(instanceIndex, sc.x * ds.x, sc.y * ds.y, sc.z * ds.z);
                            }
                            else {
                                offsets.setXYZ(instanceIndex, pos.x, pos.y, pos.z);
                                scales.setXYZ(instanceIndex, sc.x, sc.y, sc.z);
                            }
                            var col = m.instanceColor;
                            colors.setXYZW(instanceIndex, col.r, col.g, col.b, a);
                            ++instanceIndex;
                        }
                    });
                } else if (positionsChanges) {
                    bounds.makeEmpty();
                    if (scales) {
                        ArrayHelper.iterateArray(clientObjects, (m) => {
                            var pos = m.position,
                                sc = m.scale;
                            bounds.expandByPoint(pos);
                            maxScale = Math.max(maxScale, sc.x, sc.y, sc.z);

                            if (m.visible) {
                                var a = m.instanceAlpha * alpha;
                                offsets.setXYZ(instanceIndex, pos.x, pos.y, pos.z);
                                scales.setXYZ(instanceIndex, sc.x, sc.y, sc.z);
                                var col = m.instanceColor;
                                colors.setXYZW(instanceIndex, col.r, col.g, col.b, a);
                                ++instanceIndex;
                            }
                        });
                    } else {
                        ArrayHelper.iterateArray(clientObjects, (m) => {
                            var pos = m.position;
                            bounds.expandByPoint(pos);

                            if (m.visible) {
                                var a = m.instanceAlpha * alpha;
                                offsets.setXYZ(instanceIndex, pos.x, pos.y, pos.z);
                                var col = m.instanceColor;
                                colors.setXYZW(instanceIndex, col.r, col.g, col.b, a);
                                ++instanceIndex;
                            }
                        });
                    }
                } else {
                    ArrayHelper.iterateArray(clientObjects, (m) => {
                        if (m.visible) {
                            //var pos = m.position;
                            //bounds.expandByPoint(pos);
                            var a = m.instanceAlpha * alpha;
                            //offsets.setXYZ(instanceIndex, pos.x, pos.y, pos.z);
                            var col = m.instanceColor;
                            colors.setXYZW(instanceIndex, col.r, col.g, col.b, a);
                            ++instanceIndex;
                        }
                    });
                }

                bounds.getBoundingSphere(boundingSphere);
                boundingSphere.center.add(this._buildSphere.center);
                boundingSphere.radius += this._buildSphere.radius * maxScale;

                for (var i = buildMeshes.length; i--;) {
                    var geometry = buildMeshes[i].geometry;
                    (<THREE.InstancedBufferGeometry>geometry).maxInstancedCount = instanceIndex;
                    geometry.boundingSphere = boundingSphere;
                }

                this.updateMeta();
            }
            else
                //no instance
                this.visible = false;

            if (positionsChanges)
                this.notifyOctree();

            this._needsUpdate = false;
            this._positionChanged = false;
            this.updateModuleCount();
        }

        notifyOctree() {
            if (this.canSelectInstances) {
                var instanceContext = this.instanceContext;
                if (instanceContext)
                    instanceContext.notifyOctree();
            }
        }

        build() {
            var bounds = this._buildBounds;
            try {
                var maxInstancedCount = this._maxInstancedCount = this.ClientObjectInstances.length,
                    sharedMeshes: THREE.Mesh[] = this.SharedMeshes,
                    buildMeshes: THREE.Mesh[] = this.BuildMeshes;

                if (buildMeshes.length) {
                    this.clear();
                    buildMeshes = this.BuildMeshes;
                }

                bounds.makeEmpty();

                var hasScale = (this.userOptions & Client3DObectOptions.ScaleX) !== 0 || (this.userOptions & Client3DObectOptions.ScaleY) !== 0;

                var canBeTransparent = (this.userOptions & Client3DObectOptions.CanDeactivateInstances) !== 0;

                if (this._offsetsAttribute && (<any>this._offsetsAttribute).dispose)
                    (<any>this._offsetsAttribute).dispose();
                this._offsetsAttribute = null;

                if (this._scalesAttribute && (<any>this._scalesAttribute).dispose)
                    (<any>this._scalesAttribute).dispose();
                this._scalesAttribute = null;

                if (this._colorsAttribute && (<any>this._colorsAttribute).dispose)
                    (<any>this._colorsAttribute).dispose();
                this._colorsAttribute = null;

                if (maxInstancedCount === 0) {
                    //no instance
                    return;
                }

                var offsets = new THREE.InstancedBufferAttribute(new Float32Array(maxInstancedCount * 3), 3, false, 1),
                    scales = hasScale ? new THREE.InstancedBufferAttribute(new Float32Array(maxInstancedCount * 3), 3, false, 1) : null,
                    colors = new THREE.InstancedBufferAttribute(new Float32Array(maxInstancedCount * 4), 4, false, 1);

                if (scales)
                    scales.setUsage(THREE.DynamicDrawUsage);
                offsets.setUsage(THREE.DynamicDrawUsage);
                colors.setUsage(THREE.DynamicDrawUsage);

                this._offsetsAttribute = offsets;
                this._scalesAttribute = scales;
                this._colorsAttribute = colors;

                for (var j = sharedMeshes.length; j--;) {
                    var sharedMesh = sharedMeshes[j],
                        sharedMeshGeometry = sharedMesh.geometry instanceof THREE.Geometry ? (new THREE.BufferGeometry()).fromGeometry(sharedMesh.geometry) : (new THREE.BufferGeometry()).copy(sharedMesh.geometry);

                    sharedMeshGeometry.applyMatrix4(sharedMesh.matrix);

                    if (sharedMeshGeometry.boundingBox === null)
                        sharedMeshGeometry.computeBoundingBox();

                    if (sharedMeshGeometry.boundingSphere === null)
                        sharedMeshGeometry.computeBoundingSphere();

                    bounds.union(sharedMeshGeometry.boundingBox);

                    var buildGeometry = (new THREE.InstancedBufferGeometry()).copy(sharedMeshGeometry as THREE.InstancedBufferGeometry);

                    (buildGeometry as THREE.InstancedBufferGeometry).maxInstancedCount = maxInstancedCount;
                    buildGeometry.setAttribute('offseti', offsets);
                    buildGeometry.setAttribute('colori', colors);
                    if (scales)
                        buildGeometry.setAttribute('scalei', scales);

                    var mesh = new THREE.Mesh(buildGeometry, sharedMesh.material);
                    if (canBeTransparent)
                        (<THREE.Material>mesh.material).transparent = true;
                    if (sharedMesh.renderOrder)
                        mesh.renderOrder = sharedMesh.renderOrder;
                    this.add(mesh);
                    buildMeshes.push(mesh);
                }

                bounds.getBoundingSphere(this._buildSphere);

                //this.updateMeta();
            } catch (e) {
                console.trace();
                throw e;
            }
        }

        updateMeta() {
            if ((this.userOptions & Client3DObectOptions.ShowMetaData) !== 0) {

                var txtHolder = this.txtHolder,
                    bounds = this._buildBounds,
                    clientObjects = this.ClientObjectInstances,
                    objectsLength = clientObjects.length,
                    voff = bounds.getCenter(new THREE.Vector3()),
                    isDragging = Controller.Current.viewModel.isDragging;

                if (!txtHolder) {
                    var color = new THREE.Color(0xFFFFFF),
                        txtSize = 180,
                        alignment = new THREE.Vector2(0.5, 0.25);
                    txtHolder = this.txtHolder = new spt.ThreeJs.utils.SDFTextObjectInstances(txtSize, color, null, alignment);
                    this.add(txtHolder);
                }

                if (txtHolder.instanceCount > objectsLength)
                    txtHolder.removeInstances(txtHolder.instanceCount - objectsLength);

                voff.z = bounds.max.z + 20;

                if (isDragging) {
                    var diff = Controller.Current.viewModel.diffSnapPosition;

                    ArrayHelper.iterateArray(clientObjects, (m, i) => {
                        var pos = m.position;
                        if (m.isSelected)
                            txtHolder.setTextXYZ(i, m.instanceData.Meta || "", pos.x + voff.x + diff.x, pos.y + voff.y + diff.y, pos.z + voff.z + diff.z + 10);
                        else
                            txtHolder.setTextXYZ(i, m.instanceData.Meta || "", pos.x + voff.x, pos.y + voff.y, pos.z + voff.z);
                    });
                } else {
                    ArrayHelper.iterateArray(clientObjects, (m, i) => {
                        var pos = m.position;

                        txtHolder.setTextXYZ(i, m.instanceData.Meta || "", pos.x + voff.x, pos.y + voff.y, pos.z + voff.z);
                    });
                }

                txtHolder.update();
            }
        }

        OnSharedMeshChanged() {
            this._needsUpdate = true;
            ArrayHelper.iterateArray(this.ClientObjectInstances, (m) => {
                m.OnSharedMeshChanged();
            });
        }

        addToScene() {

            var controller = Controller.Current,
                instanceContext = this.instanceContext;

            if (controller && !this._onControllerUpdate) {
                controller.addListener('update', this._onControllerUpdate = () => {
                    if (this._needsUpdate)
                        this.update();
                });
            }

            if (controller && !this._onControllerBeforeDelete) {
                controller.addListener('beforeDelete', this._onControllerBeforeDelete = () => {
                    this.dispose();
                });
            }

            if (instanceContext) {
                instanceContext.add(this, true);

                if (this.canSelectInstances)
                    ArrayHelper.iterateArray(this.ClientObjectInstances, (m) => { instanceContext.addToOctree(m); });
            }

        }

        removeFromScene() {

            var controller = Controller.Current,
                instanceContext = this.instanceContext;

            if (controller) {
                controller.removeListener('update', this._onControllerUpdate);
                controller.removeListener('beforeDelete', this._onControllerBeforeDelete);
            }

            if (instanceContext) {
                if (this.canSelectInstances)
                    ArrayHelper.iterateArray(this.ClientObjectInstances, (m) => { instanceContext.removeFromOctree(m); });
                instanceContext.remove(this, true);
            }
        }
    }

    //export class FakeInstancedBufferAttribute {
    //    constructor(instancedCount: number, itemSize: number) {
    //        this.instancedCount = instancedCount;
    //        this.itemSize = itemSize;
    //        this.geometryBindings = [];

    //        Object.defineProperty(this, 'needsUpdate',
    //            {
    //                enumerable: true, configurable: true, get: () => {
    //                    return this.geometryBindings.some((g) => {
    //                        return (<THREE.BufferAttribute>g.geometry.attributes[g.name]).needsUpdate;
    //                    });
    //                }, set: (value) => {
    //                    var geometryBindings = this.geometryBindings;
    //                    for (var i = geometryBindings.length; i--;) {
    //                        var g = geometryBindings[i];
    //                        (<THREE.BufferAttribute>g.geometry.attributes[g.name]).needsUpdate = value;
    //                    }
    //                }
    //            });
    //    }

    //    instancedCount: number;
    //    itemSize: number;
    //    geometryBindings: { geometry: THREE.BufferGeometry, name: string, elementCount: number }[];
    //    needsUpdate: boolean;

    //    addToBufferGeometry(geometry: THREE.BufferGeometry, name: string, elementCount: number) {
    //        var bufferAttribute = new THREE.BufferAttribute(new Float32Array(elementCount * this.instancedCount * this.itemSize), this.itemSize);
    //        bufferAttribute.setUsage(THREE.DynamicDrawUsage);
    //        geometry.setAttribute(name, bufferAttribute);
    //        this.geometryBindings.push({
    //            geometry: geometry,
    //            name: name,
    //            elementCount: elementCount
    //        });
    //    }

    //    dispose() {
    //        this.geometryBindings = [];
    //    }

    //    setXY(index: number, x: number, y: number) {
    //        var geometryBindings = this.geometryBindings;

    //        for (var i = geometryBindings.length; i--;) {
    //            var g = geometryBindings[i],
    //                itemSize = this.itemSize,
    //                elementCount = g.elementCount,
    //                attribute = g.geometry.attributes[g.name] as THREE.BufferAttribute,
    //                array = attribute.array as number[],
    //                idx = index * elementCount * itemSize;
    //            for (var j = elementCount; j--;) {
    //                var off = j * itemSize;
    //                array[idx + off] = x;
    //                array[idx + off + 1] = y;
    //            }
    //        }
    //    }

    //    setXYZ(index: number, x: number, y: number, z: number) {
    //        var geometryBindings = this.geometryBindings;

    //        for (var i = geometryBindings.length; i--;) {
    //            var g = geometryBindings[i],
    //                itemSize = this.itemSize,
    //                elementCount = g.elementCount,
    //                attribute = g.geometry.attributes[g.name] as THREE.BufferAttribute,
    //                array = attribute.array as number[],
    //                idx = index * elementCount * itemSize;
    //            for (var j = elementCount; j--;) {
    //                var off = j * itemSize;
    //                array[idx + off] = x;
    //                array[idx + off + 1] = y;
    //                array[idx + off + 2] = z;
    //            }
    //        }
    //    }

    //    setXYZW(index: number, x: number, y: number, z: number, w: number) {
    //        var geometryBindings = this.geometryBindings;

    //        for (var i = geometryBindings.length; i--;) {
    //            var g = geometryBindings[i],
    //                itemSize = this.itemSize,
    //                elementCount = g.elementCount,
    //                attribute = g.geometry.attributes[g.name] as THREE.BufferAttribute,
    //                array = attribute.array as number[],
    //                idx = index * elementCount * itemSize;
    //            for (var j = elementCount; j--;) {
    //                var off = j * itemSize;
    //                array[idx + off] = x;
    //                array[idx + off + 1] = y;
    //                array[idx + off + 2] = z;
    //                array[idx + off + 3] = w;
    //            }
    //        }
    //    }
    //}
}

if (!(<any>THREE.InstancedBufferAttribute.prototype).dispose)
    (<any>THREE.InstancedBufferAttribute.prototype).dispose = () => { };
